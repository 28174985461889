import React, { useEffect } from 'react'
import Auxiliary from '../../hoc/auxiliary/auxiliary'
import NoDataImage from "./../../assets/images/illustrations/nodata.svg"
import { withRouter } from 'react-router-dom'
import ApiService from './../../Services/apiService';
import { BACK_URL_KEY, ERROR_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';

const UnderMaintenancePage = (props) => {

  useEffect(() => {
    const interval = setInterval(() => {
      ApiService.checkServerState().then((response) => {
        response && goBack();
      }).catch(error => getFail(error))
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const goBack = () => {
    if (localStorage.getItem(BACK_URL_KEY)) {
      props.history.push(localStorage.getItem(BACK_URL_KEY))
      window.location.reload();
      localStorage.removeItem(BACK_URL_KEY);
    } else {
      window.location.href = "/"
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return (
    <Auxiliary>
      <div className="no-data-container mt-5">
        <div className="no-data-wrapper">
          <p>Under Maintenance</p>
          <img src={NoDataImage} alt="NoDataImage" />
        </div>
      </div>
      <a
        href="/"
        className='mt-4'
        style={{ textAlign: "center", display: "block" }}
        onClick={() => {
          localStorage.removeItem(BACK_URL_KEY)
        }}
      >
        Go home page
      </a>
    </Auxiliary>
  );
}

export default withRouter(UnderMaintenancePage);

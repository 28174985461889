import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Carousel from "react-multi-carousel";
import AlertService from "../../Services/alertService";
import CourseBlock from "../../Components/Course/courseBlock";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { BECOME_CONSULTANT_KEY, BECOME_INSTRUCTOR_KEY, CATEGORY_KEY, FROM_INFO_PAGE_KEY, PURE_KEY, TEACH_IN_MINDALAY_KEY } from "../../Constants/urlKeys";
import { compose } from "redux";
import { userLogin } from "../../Store/Actions/user";
import { COURSE_CATEGORIES_TYPE_KEY, ERROR_KEY, OBJECT_KEY, WISH_LIST_PRODUCTS_IDS_KEYS } from "../../Constants/mainKeys";
import NukaCarousel from 'nuka-carousel';
import cryllex from '../../assets/images/cryllex.svg';
import relamy from '../../assets/images/relamy.svg';
import getmepet from '../../assets/images/getmepet.svg';
import frantixmedia from '../../assets/images/frantixmedia.svg';
import ReurnUrl from "../../Services/returnUrl";
import { getCategoriesByType } from './../../Store/Actions/course';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1224 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 1224, min: 968 },
    items: 4,
    slidesToSlide: 4,
  },
  smallTablet: {
    breakpoint: { max: 968, min: 725 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 725, min: 575 },
    items: 3,
    slidesToSlide: 3
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2,
    slidesToSlide: 2
  }
};

const responsiveCourses = {
  desktop: {
    breakpoint: { max: 3000, min: 1224 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1224, min: 968 },
    items: 3,
    slidesToSlide: 3,
  },
  smallTablet: {
    breakpoint: { max: 968, min: 725 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 725, min: 575 },
    items: 2,
    slidesToSlide: 2
  },
  smallMobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};


class Home extends Component {
  _isMounted = false;

  state = {
    pageContent: null,
    sliderContent: [],
    translationService: null,

    topCourses: [],
    developmentCourses: [],
    bestsellerCourses: [],
  };

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getCourses();
    this.getHomePagecontent();
    if (this.props.match.params.username && this.props.match.params.password) {
      this.setState({
        username: this.props.match.params.username,
        password: atob(this.props.match.params.password),
      }, () => {
        this.confirmEmailAndLogin(this.state.username, this.state.password);
      })
    }
    if (!this.props.courseCategories || (this.props.courseCategories && !this.props.courseCategories.length)) {
      this.props.getCategoriesByType(COURSE_CATEGORIES_TYPE_KEY);
    }
  };

  componentDidUpdate() {
    this.setTranslations();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    };
    return true;
  };

  confirmEmailAndLogin = (username, password) => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.confirmEmailAndLogin(username).then(() => {
      this.props.removePageSpinner(spinnerId);
      const form = {
        username,
        password
      }
      this.props.userLogin({ ...form }, spinnerId, this.props.history)
    }).catch(error => this.getFail(spinnerId, error));
  }

  getHomePagecontent = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getHomePagecontent().then(response => {
      const data = { ...response.data };
      const pageContent = data.pageContent;
      const sliderContent = [...data.pageContentResponses];
      this._isMounted && this.setState({ pageContent, sliderContent });
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(spinnerId, error));
  };

  updateCourses = (id) => {
    const { topCourses, developmentCourses, bestsellerCourses } = this.state;

    topCourses && topCourses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
    });

    developmentCourses && developmentCourses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
    });

    bestsellerCourses && bestsellerCourses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
    });

    this.setState({ topCourses, developmentCourses, bestsellerCourses });
  }

  getCourses = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getHomeCourses().then(response => {
      var data = { ...response.data };
      var wishListProductsByIds = localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS);
      if (wishListProductsByIds && typeof JSON.parse(wishListProductsByIds) === OBJECT_KEY && data) {
        JSON.parse(wishListProductsByIds).forEach(courseId => {
          data.topCourses && data.topCourses.forEach(course => {
            if (course.id === courseId) {
              course.isInWishlist = true;
            }
          })
          data.developmentCourses && data.developmentCourses.forEach(course => {
            if (course.id === courseId) {
              course.isInWishlist = true;
            }
          })
          data.bestsellerCourses && data.bestsellerCourses.forEach(course => {
            if (course.id === courseId) {
              course.isInWishlist = true;
            }
          })
        });
      }
      this._isMounted && this.setState({
        topCourses: data.topCourses,
        developmentCourses: data.developmentCourses ? this.shuffle(data.developmentCourses) : [],
        bestsellerCourses: data.bestsellerCourses ? this.shuffle(data.bestsellerCourses) : [],
      })
      this.props.removePageSpinner(spinnerId);
    }).catch(e => this.getFail(spinnerId, e));
  };

  shuffle = (array) => {
    let currentIndex = array.length, randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  redirectToSliderUrlPage = (url) => {
    const { language } = this.props;
    if (url.includes(TEACH_IN_MINDALAY_KEY)) {
      ReurnUrl.setUrl(`/${language}/${PURE_KEY}/${BECOME_INSTRUCTOR_KEY}/${FROM_INFO_PAGE_KEY}`).then(() => {
        this.props.history.push(`/${url}`);
      })
    } else if (url.includes(BECOME_CONSULTANT_KEY)) {
      ReurnUrl.setUrl(`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}/${FROM_INFO_PAGE_KEY}`).then(() => {
        this.props.history.push(`/${url}`);
      })
    } else {
      this.props.history.push(`/${url}`);
    }
  }

  getFail = (spinnerId, error) => {
    spinnerId && this.props.removePageSpinner(spinnerId);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };

  render() {
    const { courseCategories, language } = this.props;
    const { pageContent, translationService, sliderContent, topCourses, developmentCourses, bestsellerCourses } = this.state;

    var recomendedCategories = [];
    var lastCategory = null;
    if (courseCategories.length % 2) lastCategory = courseCategories[courseCategories?.length - 1];

    const chunkArray = (arr, n) => {
      if (!arr || !n) { return; }
      var chunkLength = Math.max(arr.length / n, 1);
      var chunks = [];
      for (var i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
      }
      lastCategory && chunks.push([lastCategory])
      return chunks;
    }

    recomendedCategories = chunkArray(courseCategories, courseCategories?.length / 2);
    
    return translationService ? <Auxiliary>
      <section className="container light-blue-background cover-image">
        <NukaCarousel
          autoplay={sliderContent && sliderContent.length > 1 ? true : false}
          wrapAround={true}
          autoplayInterval={80000}
          speed={1000}
        >
          {
            sliderContent && sliderContent.length ?
              sliderContent.map(content => {
                if (content.fullContent && content.fullContent.length) {
                  return <div key={content.id} className="d-flex align-items-center justify-content-around pb-4">
                    <div className="cover-text-wrapper">
                      <h1 className="cover-title">{JSON.parse(content.fullContent)?.title}<br /><span>{JSON.parse(content.fullContent)?.subtitle}</span></h1>
                      <p>{JSON.parse(content.fullContent)?.description}</p>
                      {
                        content.buttonLink ?
                          <Auxiliary>
                            <hr />
                            <button
                              type="button"
                              className="mindalay--btn mindalay--btn-dark"
                              onClick={() => this.redirectToSliderUrlPage(content.buttonLink)}
                            >
                              {translationService.translate("TR_GET_STARTED")}
                            </button>
                          </Auxiliary>
                          : null
                      }
                    </div>
                    <img src={content.imagePath} alt="/" />
                  </div>
                } else { return false; }
              })
              : null
          }
        </NukaCarousel>
      </section>
      <section className="section m-section">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              <h1 className="section-title">{translationService.translate("TR_CATEGORIES")}</h1>
              {
                recomendedCategories && recomendedCategories.length ?
                  <Carousel
                    responsive={responsiveCourses}
                    arrows={true}
                  // infinite={true}
                  >
                    {
                      recomendedCategories.map((recomendedCategory, index) => {
                        return <div key={index} className="d-flex flex-column mx-2 h-auto">
                          {
                            recomendedCategory.map(category => <Link
                              key={category.id}
                              to={`/${language}/${CATEGORY_KEY}/${category.id}`}
                              className="mindalay--btn-blue mindalay--btn-link w-100 h-100 my-1"
                            >
                              {category.name}
                            </Link>
                            )
                          }
                        </div>
                      })
                    }
                  </Carousel>
                  : null
              }
            </div>
          </div>
        </div>
        <div className="container py-3">
          <div className="row">
            {
              pageContent && JSON.parse(pageContent)?.title ?
                <div className="col-12">
                  <h1 className="section-title text-uppercase font-weight-bold">{JSON.parse(pageContent)?.title}</h1>
                </div>
                : null
            }
            <div className="col-12">
              {
                pageContent && JSON.parse(pageContent)?.description ?
                  <p>{JSON.parse(pageContent)?.description}</p>
                  : null
              }
            </div>
          </div>
        </div>
        {
          topCourses && topCourses.length ?
            <div className="container py-3">
              <Auxiliary>
                <hr />
                <div className="row">
                  <div className="col-12">
                    <h1 className="section-title">{translationService.translate("TR_TOP_COURSES")}</h1>
                  </div>
                  <div className="col-12 course-carousel">
                    <Carousel
                      responsive={responsive}
                      arrows={true}
                    >
                      {
                        topCourses.map(course =>
                          <CourseBlock
                            key={course.id}
                            course={course}
                            updateCourses={this.updateCourses}
                          />)
                      }
                    </Carousel>
                  </div>
                </div>
              </Auxiliary>
            </div>
            : null
        }
        <div className="light-gray-background py-3">
          <div className="container">
            <h1 className="section-title">{translationService.translate("TR_OUR_PARTNERS")}</h1>
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="square-holder"
                  title="CRYLLEX"
                  onClick={() => window.open("https://cryllex.com/", "_blank")}
                >
                  <img alt="/" src={cryllex} />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="square-holder"
                  title="RELAMY"
                  onClick={() => window.open("https://relamy.com/", "_blank")}
                >
                  <img alt="/" src={relamy} />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="square-holder"
                  title="GET ME PET"
                  onClick={() => window.open("https://test.getmepet.com/", "_blank")}
                >
                  <img alt="/" src={getmepet} />
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="square-holder"
                  title="FRANTIXMEDIA"
                  onClick={() => window.open("https://frantixmedia.com/", "_blank")}
                >
                  <img alt="/" src={frantixmedia} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          developmentCourses && developmentCourses.length ?
            <div className="container py-3">
              <div className="row">
                <div className="col-12">
                  <h1 className="section-title">{translationService.translate("TR_DEVELOPMENT_COURSES")}</h1>
                </div>
                <div className="col-12 course-carousel">
                  <Carousel
                    responsive={responsive}
                    arrows={true}
                  >
                    {developmentCourses.map(course =>
                      <CourseBlock
                        key={course.id}
                        course={course}
                        updateCourses={this.updateCourses}
                      />)}
                  </Carousel>
                </div>
              </div>
            </div>
            : null
        }
        {
          bestsellerCourses && bestsellerCourses.length ?
            <div className="container py-3">
              <hr />
              <div className="row">
                <div className="col-12">
                  <h1 className="section-title">{translationService.translate("TR_BESTSELLERS")}</h1>
                </div>
                <div className="col-12 course-carousel">
                  <Carousel
                    responsive={responsive}
                    arrows={true}
                  >
                    {bestsellerCourses.map(course =>
                      <CourseBlock
                        key={course.id}
                        course={course}
                        creatorId={course.creatorUserId}
                        updateCourses={this.updateCourses}
                      />)}
                  </Carousel>
                </div>
              </div>
            </div>
            : null
        }
      </section>
    </Auxiliary> : null;
  }
}

const mapStateToProps = state => ({
  courseCategories: state.course.courseCategories,
  language: state.language.language,
  translations: state.translation.translations,
  user: state.user.user,
});

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  userLogin,
  getCategoriesByType
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Home);


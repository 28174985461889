
import { takeLatest, call, put } from 'redux-saga/effects';
import uuid from 'react-uuid';
import ApiService from '../../Services/apiService';
import AlertService from "../../Services/alertService";
import { addPageSpinner, removePageSpinner } from '../Actions/spinner';
import { ERROR_KEY } from "../../Constants/mainKeys";
import { GET_LANGUAGES_REQUEST } from "../../Constants/reduxRequestKeys";
import { GET_LANGUAGES_SUCCESS } from "../../Constants/reduxKeys";

export default function* watcher() {
  yield takeLatest(GET_LANGUAGES_REQUEST, getLanguages);
};

function* getLanguages() {
  const spinnerId = uuid();
  try {
    yield put(addPageSpinner(spinnerId));
    const languagesData = yield call(ApiService.getLanguages);
    // var languages = [];
    // if(languagesData && languagesData.data && languagesData.data.length){
    //   languages = languagesData.data.splice(0, languagesData.data.length - 1)
    // }
    yield put({
      type: GET_LANGUAGES_SUCCESS,
      payload: languagesData.data || [],
    });
    yield put(removePageSpinner(spinnerId));
  } catch (error) {
    yield put(removePageSpinner(spinnerId));
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  };
};



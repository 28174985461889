import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import TranslationService from "../Services/translationService";
import ArrowBackSvg from "../Components/Svg/arrowBackSvg";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../Constants/mainKeys";
import uuid from "react-uuid";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import Input from "../Components/Inputs/input";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import ActionButton from "../Components/ActionButton/actionButton";
import MainService from "../Services/mainService";
import moment from "moment";
import Textarea from "../Components/Inputs/textArea";

const buttonSpinnerId = uuid();

function CurrencyForm(props) {
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { currencyId } = props.match.params;
	const dispatch = useDispatch();
	const [values, setValues] = useState({
		decimalCount: "",
		isMain: false,
		isocode: "",
		name: "",
		rate: "",
		symbol: "",
		symbolPosition: false,
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [currencys, setCurrency] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (currencyId) {
			getCurrencyById(currencyId);
		}
	}, [currencyId]);

	const getCurrencyById = id => {
		if (!id) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCurrencyById(id)
			.then(response => {
				if (response.data) {
					const newValues = {};
					Object.entries(values).forEach(([key, value]) => {
						if (response.data.hasOwnProperty(key) && response.data[key]) {
							newValues[key] = response.data[key];
						}
					});
					newValues.id = id;
					setValues({ ...values, ...newValues });
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onNumberChange = (event, fieldname, maxValue = Infinity) => {
		if (event.target.value.includes("e") || event.target.value.includes(" ")) {
			return false;
		}
		if (maxValue && maxValue < event.target.value.length) {
			return false;
		}
		setIsInvalidSubmit(false);
		setValues(values => ({
			...values,
			[fieldname]: event.target.value ? +event.target.value : "",
		}));
	};

	const onCheckBoxChange = (event, name) => {
		setValues(values => ({
			...values,
			[name]: event.target.checked,
		}));
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 200);
	};

	const onSubmit = e => {
		e.preventDefault();
		let clon = { ...values };
		// decimalCount: "",
		// isMain: false,
		// isocode: "",
		// name: "",
		// rate: "",
		// symbol: "",
		// symbolPosition: false,
		if (
			!values.name.trim().length ||
			!values.decimalCount ||
			!values.isocode.trim().length ||
			!values.rate ||
			!values.symbol.trim()
		) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(!currencyId
			? ApiService.createCurrency(values)
			: ApiService.upDateCurrency(values)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				setIsInvalidSubmit(false);
				props.history.push(`/${language}/developer/currency`);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	return (
		translationService && (
			<div className="container">
				<div className="row">
					<div className="col-12 content-title">
						<div className="d-flex align-items-center">
							<Link
								to={`/${language}/developer/currency`}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{!currencyId
									? translationService.translate("TR_CREATE_CURRENCY")
									: translationService.translate("TR_UPDATE_CURRENCY")}
							</h2>
						</div>
					</div>
				</div>
				<hr />

				<form className="row" onSubmit={onSubmit}>
					<div className="col-12 col-md-6">
						<Input
							type="text"
							id="name"
							name="name"
							value={values.name}
							isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event => onChange(event, "name")}
						/>
					</div>
					<div className={`col-12 col-md-6  my-1`}>
						<div className="position-relative">
							<Input
								type="text"
								id="isocode"
								name="isocode"
								value={values.isocode}
								// fieldLength={fieldsLength.nameMaxLength}
								isInvalidSubmit={isInvalidSubmit && !values.isocode}
								labelValue={`${translationService.translate("TR_ISO_CODE")}*`}
								onChange={event => onChange(event, "isocode")}
							/>
						</div>
					</div>
					<div className="col-12 col-md-6 my-1">
						<Input
							type="text"
							id="symbol"
							name="symbol"
							value={values.symbol}
							isInvalidSubmit={isInvalidSubmit && !values.symbol.trim().length}
							labelValue={`${translationService.translate("TR_SYMBOL")}*`}
							onChange={event => onChange(event, "symbol", 100)}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<Input
							type="number"
							id="decimalCount"
							name="decimalCount"
							value={values.decimalCount}
							isInvalidSubmit={isInvalidSubmit && !values.decimalCount}
							labelValue={`${translationService.translate(
								"TR_DECIMAL_COUNT",
							)}*`}
							onChange={event => onNumberChange(event, "decimalCount", 9)}
						/>
					</div>
					<div className="col-12 col-md-6 my-1">
						<Input
							type="number"
							id="rate"
							name="rate"
							value={values.rate}
							isInvalidSubmit={isInvalidSubmit && !values.rate}
							labelValue={`${translationService.translate("TR_RATE")}*`}
							onChange={event => onNumberChange(event, "rate")}
						/>
					</div>
					<div className="col-12 col-md-6"></div>
					<div className="col-12 col-md-6  my-1">
						<label htmlFor="symbolPosition">
							{translationService.translate("TR_SYMBOL_POSITION_LEFT")}
						</label>
						<InputCheckBox
							id={`symbolPosition`}
							name={`symbolPosition`}
							checked={values.symbolPosition}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "symbolPosition");
							}}
						/>
					</div>
					<div className="col-12 col-md-6  my-1">
						<label htmlFor="isMain">
							{translationService.translate("TR_IS_MAIN")}
						</label>
						<InputCheckBox
							id={`isMain`}
							name={`isMain`}
							checked={values.isMain}
							labelValue={translationService.translate("TR_YES")}
							onChange={event => {
								onCheckBoxChange(event, "isMain");
							}}
						/>
					</div>
					<div className="col-12">
						<hr />
					</div>
					<div className="col-12">
						<div>
							<ActionButton
								spinnerId={buttonSpinnerId}
								clicked={onSubmit}
								type="submit"
								className="mindalay--btn-default float-right mb-4 px-5"
								name={translationService.translate("TR_SAVE")}
							/>
						</div>
					</div>
				</form>
			</div>
		)
	);
}
export default withRouter(CurrencyForm);

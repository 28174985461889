import { all, fork } from 'redux-saga/effects';
import translation from "./translation";
import user from "./user";
import language from "./language";
import course from "./course";
import main from "./main";
import consultation from "./consultation";

export default function* watchers() {
  yield all([
    translation,
    user,
    language,
    course,
    main,
    consultation
  ].map(fork))
}
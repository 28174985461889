import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import CourseForm from "./CourseDashboardComponents/courseForm";
import Curiculumn from "./CourseDashboardComponents/curiculumn";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "./../../Store/Actions/spinner";
import { setCurrentCourse } from "./../../Store/Actions/course";
import ApiService from "./../../Services/apiService";
import AlertService from "../../Services/alertService";
import { CURRENT_COURSE, ERROR_KEY } from "../../Constants/mainKeys";
import uuid from "react-uuid";

const CourseDashboard = props => {
	const dispatch = useDispatch();

	const translations = useSelector(state => state.translation.translations);
	const courseId = props.match.params.courseId;
	const [translationService, setTranslationService] = useState(null);
	const [courseData, setCourseData] = useState(null);
	const [key, setKey] = useState(
		props.match.params.defaultKey
			? props.match.params.defaultKey
			: "general-info",
	);
	const [courseQuizzes, setCourseQuizzes] = useState([]);
	const [assignmentCourse, setAssignmentCourse] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (courseId) {
			getCourseByIdWithOrgUser(courseId);
			getQuizzesByCourseId(courseId);
			getAssignmentByCourseId(courseId);
		}
		return () => {
			localStorage.removeItem(CURRENT_COURSE);
			dispatch(setCurrentCourse({}));
			setCourseQuizzes([]);
		};
	}, []);

	const getQuizzesByCourseId = courseId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getQuizzesByCourseId(courseId)
			.then(response => {
				setCourseQuizzes([...response.data]);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};
	const getAssignmentByCourseId = courseId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAssignmentByCourseId(courseId)
			.then(response => {
				setAssignmentCourse([...response.data]);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};
	const changeAssigmentData = data => {
		setAssignmentCourse(data);
	};

	const getCourseByIdWithOrgUser = courseId => {
		if (!courseId) {
			return;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCourseByIdWithOrgUser(courseId)
			.then(response => {
				if (response.data) {
					const data = { ...response.data };
					// if (data && data.sections && data.sections.length) {
					// 	data.sections.forEach(section => {
					// 		if (section.topics && section.topics.length) {
					// 			section.topics.forEach(topic => {
					// 				topic.imagePath = topic.imagePath
					// 					? `${topic.imagePath}?${uuid()}`
					// 					: null;
					// 			});
					// 		}
					// 	});
					// }
					dispatch(setCurrentCourse(data));
					setCourseData(data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const changeSectionsData = courseData => {
		if (!courseData) {
			return;
		}
		dispatch(setCurrentCourse(courseData));
		setCourseData(courseData);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		courseId && !courseData ? null : (
			<div className="container">
				<div className="tabs-wrapper tab-title-group">
					<div className="row mt-4">
						<div className="col-12">
							<h2 className="section-title">
								{translationService.translate("TR_COURSE")}
							</h2>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-12">
							<Tabs activeKey={key} onSelect={key => setKey(key)}>
								<Tab
									eventKey="general-info"
									title={translationService.translate("TR_GENERAL_INFO")}>
									<CourseForm
										getCourseByIdWithOrgUser={getCourseByIdWithOrgUser}
										setKey={setKey}
										courseQuizzes={courseQuizzes}
										assignmentCourse={assignmentCourse}
									/>
								</Tab>
								{courseId ? (
									<Tab
										eventKey="curriculum"
										title={translationService.translate("TR_CURICULUMN")}>
										<Curiculumn
											getCourseByIdWithOrgUser={getCourseByIdWithOrgUser}
											changeSectionsData={changeSectionsData}
											courseQuizzes={courseQuizzes}
											assignmentCourse={assignmentCourse}
											changeAssigmentData={changeAssigmentData}
										/>
									</Tab>
								) : null}
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		)
	) : null;
};

export default withRouter(CourseDashboard);

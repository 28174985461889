import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import { ERROR_KEY, WARNING_KEY } from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { Helmet } from "react-helmet";
import TranslationService from "../../Services/translationService";

function StudentQuizzes(props) {
	const studentId = props.match.params.studentId || null;
	const coursesId = props.match.params.coursesId || null;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [courseQuizes, setCourseQuizes] = useState(null);
	const [topicQuizes, setTopicQuizes] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (translationService) getQuizResults();
	}, [translationService]);

	const getQuizResults = courseData => {
		if (!coursesId || !studentId) {
			return;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getQuizResults(coursesId, studentId)
			.then(response => {
				if (
					response.data &&
					(response.data.courseQuizes || response.data.topicQuizes)
				) {
					setCourseQuizes(response.data?.courseQuizes);
					setTopicQuizes(response.data?.topicQuizes);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};
	return translationService ? (
		<Auxiliary>
			<Helmet>
				<title>{`${translationService?.translate(
					"TR_QUIZZES",
				)} | Mindalay`}</title>
			</Helmet>

			<div className="container">
				<div className="row">
					<div className="col-12 mt-3">
						<div className="content-title p-0">
							<div className="d-flex align-items-center">
								<Link
									to={`/${language}/student/courses/${studentId}`}
									title={translationService?.translate("TR_BACK")}>
									<ArrowBackSvg />
								</Link>
								<h2 className="content-title p-0">
									{translationService?.translate("TR_STUDENT_QUIZZES")}
								</h2>
							</div>
						</div>
						{/* <PageInfoBlock
								pageTitle={translationService.translate("TR_STUDENT_COURSES")}
								// pageName="curator_lecturer_selection_committee_student_courses"
								infoText={
									"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
								}
								translationService={translationService}
							/> */}
					</div>
				</div>
				<hr />
				{(courseQuizes && courseQuizes.length) ||
				(topicQuizes && topicQuizes?.length) ? (
					<div className="row">
						<div className="col-12">
							<div className="quiz-results-block">
								{courseQuizes && courseQuizes.length ? (
									<div className="org-student-info-block-item">
										<div className="d-flex justify-content-between mb-2">
											<h5>{`${translationService?.translate(
												"TR_COURSE_QUIZ",
											)}`}</h5>
										</div>
										<div className="mindayal-admin-table table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th className="text-nowrap">
															{translationService?.translate("TR_QUIZ_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate(
																"TR_GRADING_METHOD_NAME",
															)}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_MAX_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_FINAL_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_MAX_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_PASS_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_FINAL_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService?.translate("TR_ACTION")}
														</th>
													</tr>
												</thead>
												<tbody>
													{courseQuizes?.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.quizName}</td>
																<td>{item.gradingMethodName}</td>
																<td>{item.maxMark}</td>
																<td>{item.finalMark}</td>
																<td>{item.maxGrade}</td>
																<td>{item.passGrade}</td>
																<td>{item.finalGrade}</td>
																<td>
																	<Link
																		to={`/${language}/student/${studentId}/quiz/${item.quizId}`}
																		className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small">
																		{translationService?.translate(
																			"TR_VIEW_ATTEMPTS",
																		)}
																	</Link>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								) : null}
								{topicQuizes && topicQuizes?.length ? (
									<div className="org-student-info-block-item">
										<div className="d-flex justify-content-between mb-2">
											<h5>{`${translationService.translate(
												"TR_TOPIC_QUIZ",
											)}`}</h5>
										</div>
										<div className="mindayal-admin-table table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th className="text-nowrap">
															{translationService.translate("TR_TOPIC_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_QUIZ_NAME")}
														</th>
														<th className="text-nowrap">
															{translationService.translate(
																"TR_GRADING_METHOD_NAME",
															)}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_MARK")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_MAX_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_PASS_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_FINAL_GRADE")}
														</th>
														<th className="text-nowrap">
															{translationService.translate("TR_ACTION")}
														</th>
													</tr>
												</thead>
												<tbody>
													{topicQuizes.map((item, index) => {
														return (
															<tr key={index}>
																<td>{item.topicName}</td>
																<td>{item.quizName}</td>
																<td>{item.gradingMethodName}</td>
																<td>{item.maxMark}</td>
																<td>{item.finalMark}</td>
																<td>{item.maxGrade}</td>
																<td>{item.passGrade}</td>
																<td>{item.finalGrade}</td>
																<td>
																	<Link
																		to={`/${language}/student/${studentId}/quiz/${item.quizId}`}
																		className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small">
																		{translationService.translate(
																			"TR_VIEW_ATTEMPTS",
																		)}
																	</Link>
																</td>
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				) : (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p>{translationService?.translate("TR_QUIZ_RESULTS_IS_EMPTY")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
		</Auxiliary>
	) : null;
}

export default withRouter(StudentQuizzes);

import React from 'react';

const starFilled = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      style={{ width: props.width }}
      viewBox="0 0 407.7 390.5"
      xmlSpace="preserve">
      <g>
        <path className="st0" d="M96.4,381.6c-4.3,0-8.5-1.3-12.1-3.9c-6.7-4.9-9.9-13.3-8-21.3l25-110.2l-84.9-74.4c-6.2-5.4-8.6-14.1-6.1-22
		c2.6-7.9,9.5-13.5,17.8-14.2l112.3-10.2l44.4-103.9c3.3-7.6,10.7-12.6,19-12.6s15.7,4.9,19,12.5l44.4,103.9l112.3,10.2
		c8.3,0.7,15.2,6.3,17.8,14.2s0.2,16.5-6,22l-84.9,74.4l25,110.2c1.8,8.1-1.3,16.5-8,21.3c-6.7,4.9-15.7,5.2-22.7,1l-96.8-57.9
		L107,378.7C103.8,380.6,100.1,381.6,96.4,381.6z M139.6,127.4C139.6,127.4,139.6,127.4,139.6,127.4L139.6,127.4z M268.1,127.3
		L268.1,127.3C268.1,127.3,268.1,127.3,268.1,127.3z"/>
      </g>
    </svg>
  );
}

export default starFilled;

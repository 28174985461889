import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import TranslationService from '../../Services/translationService';

const Home = (props) => {
  const availablePages = useSelector(state => state.user.availablePages);
  const language = useSelector(state => state.language.language);

  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);


  useEffect(() => {
    if (availablePages && availablePages.length && availablePages.find(availablePage => availablePage.uri !== "/")) {
      var firstPage = availablePages.find(availablePage => availablePage.uri !== "/");
      firstPage && props.history.push(`/${language}/${firstPage.uri}`)
    }
  }, []);

  return (translationService ?
    <div className="no-data-container mt-4">
      <div className="no-data-wrapper">
        <p>{translationService.translate("TR_NO_DATA")}</p>
        <img src={NoDataImage} alt="/" />
      </div>
    </div>
    : null
  );
}

export default withRouter(Home);

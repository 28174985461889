import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import AlertService from "../../../Services/alertService";
import {
	ERROR_KEY,
	STUDENT_USER_TYPE,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import GamifyApiService from "../../../Services/gamifyApiService";
import uuid from "react-uuid";
import NukaCarousel from "nuka-carousel";
import ActionButton from "../../../Components/ActionButton/actionButton";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import NoDataImage from "./../../../assets/images/illustrations/nodata.svg";
import TextSpinner from "../../../Components/Spinners/textSpinner";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import CardSvg from "../../../Components/Svg/cardSvg";
import StarSvg from "../../../Components/Svg/starSvg";

const buttonSpinnerId = uuid();

const Favorites = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const { textSpinners } = useSelector(state => state.spinner);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [favorites, setFavorites] = useState([]);
	const [loaderId, setLoaderId] = useState(null);
	const [isDisabledFields, setIsDisabledFields] = useState(false);
	const [isClosedInfoBlock, setIsClosedInfoBlock] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getFavorites();
	}, []);

	const buyShopItem = (shopItem, spinnerId) => {
		if (!shopItem) return false;
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addButtonSpinner(spinnerId));
			const data = {
				id: shopItem.id,
				userId: user.studentId ? user.studentId.toString() : null,
				quantity: shopItem.quantity,
				shopItemImageSize: 3,
				resourceImageSize: 1,
			};
			GamifyApiService.buyShopItem(data)
				.then(response => {
					if (response && response.data && response.data.shopItem) {
						setFavorites(prev =>
							prev.map(_item => {
								if (_item.shopItem.id === response.data.shopItem.id) {
									let data = { ...response.data.shopItem };
									data.quantity = 1;
									data.isFavorite = shopItem.isFavorite;
									data.isShopingCart = shopItem.isShopingCart;
									return {
										..._item,
										shopItem: data,
									};
								}
								return _item;
							}),
						);
					}
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					dispatch(removeButtonSpinner(spinnerId));
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const getFavorites = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			userId: user.studentId ? user.studentId.toString() : null,
			shopItemImageSize: 3,
			resourceImageSize: 1,
		};
		GamifyApiService.getFavorites(data)
			.then(response => {
				if (response && response.data && response.data.length) {
					let data = [
						...response.data.map(el => ({
							...el,
							shopItem: {
								...el.shopItem,
								quantity: 1,
							},
						})),
					];
					setFavorites(data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const moveAllProductsToShoppingCart = spinnerId => {
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"You want to move all products to shoppong cart.",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addButtonSpinner(spinnerId));
			GamifyApiService.moveAllProductsToShoppingCart(
				user.studentId ? user.studentId.toString() : null,
			)
				.then(response => {
					dispatch(removeButtonSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					setFavorites([]);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const moveToShoppingCart = (item, spinnerId) => {
		if (!item || loaderId) {
			return false;
		}
		dispatch(addButtonSpinner(spinnerId));
		let favoritesCopy = [...favorites];
		GamifyApiService.moveToShoppingCart(item.id)
			.then(response => {
				dispatch(removeButtonSpinner(spinnerId));
				setFavorites(favoritesCopy.filter(_item => _item.id !== item.id));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const removeFavorite = item => {
		if (!item || loaderId) {
			return false;
		}
		let favoritesCopy = [...favorites];
		setLoaderId(item.id);
		GamifyApiService.removeShopItemFromFavorites(item.id)
			.then(response => {
				setFavorites(favoritesCopy.filter(_item => _item.id !== item.id));
				setLoaderId(null);
			})
			.catch(error => getFail(error));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		setIsDisabledFields(false);
	};

	const changeShopItemCount = (item, num) => {
		if (num < 1 || item.availability < num) {
			return false;
		}
		setFavorites(prev =>
			prev.map(_item => {
				if (item.id === _item.shopItem.id) {
					return {
						..._item,
						shopItem: {
							..._item.shopItem,
							quantity: num,
						},
					};
				}
				return _item;
			}),
		);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="p-0 instructor-consultant-navbar">
						<div className="d-flex justify-content-between flex-wrap align-items-center">
							<h2 className="section-title">
								{translationService.translate("TR_FAVORITES")}
							</h2>
							<div className="navbar">
								<ul className="navbar-nav">
									<li
										className={`nav-item position-relative dropdown ${
											isClosedInfoBlock ? "mr-4" : ""
										}`}>
										<Link
											to={`/${language}/gamification/shopping-cart`}
											className="nav-link p-1"
											title={translationService.translate("TR_SHOPPING_CART")}>
											<CardSvg />
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<PageInfoBlock
							pageTitle="Student favorites"
							pageName="student_favorites"
							translationService={translationService}
							closeCb={() => setIsClosedInfoBlock(!isClosedInfoBlock)}
						/>
					</div>
				</div>
			</div>
			<hr />

			{favorites && favorites.length ? (
				<div>
					<div className="row">
						{favorites.map((item, index) => {
							return (
								<div key={index} className="col-12 col-md-6 col-lg-4 p-3">
									<Card className="w-100 h-100">
										{user.userTypeId === STUDENT_USER_TYPE ? (
											<div className="d-flex align-items-center course-ruls-wrapper py-2 position-relative">
												{
													<div
														className="wish-list-icon"
														onClick={() => {
															removeFavorite(item);
														}}>
														{loaderId === item.id ? (
															<div className="mx-1">
																<TextSpinner spinner={true} color="#F7C93D" />
															</div>
														) : (
															<i
																className="fa fa-star cursor-pointer"
																style={{ color: "#F7C93D", fontSize: "20px" }}
															/>
														)}
													</div>
												}
											</div>
										) : null}
										{item.shopItem.imagePaths &&
										item.shopItem.imagePaths.length ? (
											item.shopItem.imagePaths.length > 1 ? (
												<NukaCarousel
													autoplay={
														item.shopItem.imagePaths.length > 1 ? true : false
													}
													wrapAround={true}
													autoplayInterval={80000}
													speed={1000}
													height="200px"
													className="small-carousel"
													renderCenterLeftControls={({ previousSlide }) => (
														<button
															onClick={previousSlide}
															className="arrow left-arrow">
															<i className="fa fa-arrow-left" />
														</button>
													)}
													renderCenterRightControls={({ nextSlide }) => (
														<button
															onClick={nextSlide}
															className="arrow right-arrow">
															<i className="fa fa-arrow-right" />
														</button>
													)}>
													{item.shopItem.imagePaths.map((item, index) => {
														return (
															<div
																key={index}
																className="w-100 pt-3"
																style={{
																	backgroundImage: `url(${item})`,
																	backgroundPosition: "center",
																	backgroundSize: "contain",
																	backgroundRepeat: "no-repeat",
																	height: "200px",
																}}
															/>
														);
													})}
												</NukaCarousel>
											) : (
												<div
													className="w-100 pt-3"
													style={{
														backgroundImage: `url(${item.shopItem.imagePaths[0]})`,
														backgroundPosition: "center",
														backgroundSize: "contain",
														backgroundRepeat: "no-repeat",
														height: "200px",
													}}
												/>
											)
										) : null}
										<Card.Body className="position-relative pt-0 d-flex flex-column justify-content-between p-2">
											<div>
												<h3 className="title text-center">
													{item.shopItem.name}
												</h3>
												<hr />
											</div>
											<div>
												<p className="max-line-3">
													{item.shopItem.description}
												</p>
												<p className="d-flex justify-content-between mt-2">
													<b>
														{translationService.translate("TR_AVAILABILITY")}
													</b>
													<b>{item.shopItem.availability}</b>
												</p>

												<p className="d-flex justify-content-between mt-2 align-items-center">
													<b>{translationService.translate("TR_PRICE")} </b>
													<span className="d-flex align-items-center word-break-break-word max-line-1">
														<small className="mx-2 ">
															{item.shopItem.resourceAmount}
														</small>
														<img
															src={item?.shopItem.resource.imagePath}
															alt={item?.shopItem.resource.name}
															title={item?.shopItem.resource.name}
															style={{ width: "30px", height: "30px" }}
														/>
													</span>
												</p>
											</div>
											<div>
												<div className="mt-3">
													<Auxiliary>
														<hr className="mt-2" />
														<div
															className={`d-flex justify-content-around align-items-center`}>
															<button
																className=" mindalay--btn-blue mindalay--btn outline-none d-flex justify-content-center align-items-center p-3"
																style={{
																	fontSize: "25px",
																	height: "20px",
																	width: "20px",
																	borderRadius: "50%",
																}}
																onClick={() =>
																	changeShopItemCount(
																		item.shopItem,
																		(item.shopItem.quantity || 0) - 1,
																	)
																}>
																<span className="mb-1">-</span>
															</button>
															<b>{item.shopItem.quantity}</b>
															<button
																className=" mindalay--btn-blue mindalay--btn outline-none d-flex justify-content-center align-items-center p-3"
																style={{
																	fontSize: "25px",
																	height: "20px",
																	width: "20px",
																	borderRadius: "50%",
																}}
																onClick={() =>
																	changeShopItemCount(
																		item.shopItem,
																		(item.shopItem.quantity || 0) + 1,
																	)
																}>
																<span className="mb-1">+</span>
															</button>
														</div>
													</Auxiliary>
													<ActionButton
														type="button"
														spinnerId={`${buttonSpinnerId}_move_${item.id}`}
														className="mindalay--btn-secondary mindalay--btn outline-none w-100 max-line-1 my-1 mt-3"
														name={translationService.translate(
															"TR_MOVE_TO_SHOPPING_CARD",
														)}
														clicked={() => {
															moveToShoppingCart(
																item,
																`${buttonSpinnerId}_move_${item.id}`,
															);
														}}
													/>
													<ActionButton
														type="button"
														spinnerId={`${buttonSpinnerId}_${item.id}_but_now`}
														clicked={() =>
															buyShopItem(
																item.shopItem,
																`${buttonSpinnerId}_${item.id}_but_now`,
															)
														}
														className="mindalay--btn-dark w-100 py-2"
														name={translationService.translate("TR_BUY_NOW")}
													/>
												</div>
											</div>
										</Card.Body>
									</Card>
								</div>
							);
						})}
						<div className="col-12">
							<ActionButton
								type="button"
								spinnerId={`${buttonSpinnerId}_move_all`}
								clicked={() =>
									moveAllProductsToShoppingCart(`${buttonSpinnerId}_move_all`)
								}
								className="mindalay--btn-primary-outline mt-3  w-100"
								name={translationService.translate(
									"TR_MOVE_ALL_PRODUCTS_TOSHOPPING_CART",
								)}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="mt-4 w-100">
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" height="200px" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Favorites);

import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import { showOrHideWebinarScreenNotification } from "../../../../Store/Actions/signalR";
import { logout } from "../../../../Store/Actions/user";
import { changeShoppingCartOrWishListCount } from "../../../../Store/Actions/wishListOrShoppingCart";
import {
	CONSULTANT_USER_TYPE,
	INSTRUCTOR_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../../../Constants/mainKeys";
import {
	CONSULTANT_KEY,
	INSTRUCTOR_PATH_KEY,
} from "../../../../Constants/requestKeys";
import {
	PROFILE_KEY,
	CONSULTANT_DASHBOARD_KEY,
	OVERVIEW_KEY,
	ALL_CONSULTATIONS_KEY,
	CONSULTANT_FEED_KEY,
	COURSES_KEY,
	VIEW_KEY,
	FINANCES_KEY,
	EDIT_PROFILE_KEY,
	TICKETS_KEY,
	HELP_KEY,
	INFO_KEY,
	LEARN_IN_MINDALAY_KEY,
	TEACH_IN_MINDALAY_KEY,
	INSTRUCTOR_KEY,
	BECOME_CONSULTANT_KEY,
	CONSULTANT_INFORMATION_KEY,
	PURE_KEY,
	MINDALAY_FOR_INSTITUTIONS_KEY,
	STUDENT_KEY,
	ALL_MESSAGES_KEY,
	CREATE_ONE_TIME_WEBINAR_KEY,
} from "../../../../Constants/urlKeys";
import { setPureLayoutBackUrl } from "../../../../Store/Actions/main";
import ReurnUrl from "../../../../Services/returnUrl";
import { showOrHideGeneralDashboardLeftMenu } from "../../../../Store/Actions/generalLeftMenu";
import { setSettings } from "../../../../Store/Actions/settings";

const ProfileDropdown = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const potentialconsultationsCount = useSelector(
		state => state.consultation.potentialconsultationsCount,
	);
	const unreadMessgesCount = useSelector(
		state => state.signalR.unreadMessgesCount,
	);
	const globalConnection = useSelector(state => state.signalR.globalConnection);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const onLogout = () => {
		logoutHandler();
		dispatch(changeShoppingCartOrWishListCount(0, 0));
		dispatch(showOrHideWebinarScreenNotification(null));
		dispatch(setSettings(null));
		document.documentElement.style.setProperty(
			"--mindalay--brand-color",
			"#32064A",
		);
		document.documentElement.style.setProperty(
			"--mindalay--brand-color-darken-35",
			"#220432",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color",
			"#E56B1F",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color-lightened-50",
			"#ffbf9f",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color-lightened-minus-5",
			"#e26418",
		);
	};

	const setBackUrl = url => {
		dispatch(setPureLayoutBackUrl(url));
	};

	const returnUrlWithoutRedirect = url => {
		ReurnUrl.setUrl(url);
	};

	const logoutHandler = useCallback(() => {
		if (globalConnection && globalConnection.state === "Connected") {
			globalConnection.stop();
		}
		props.history.push(`/${language}`);
		dispatch(logout());
	});

	return translationService ? (
		<Dropdown>
			<div className="navbar-profile-dropdown">
				<Dropdown.Toggle variant="transparent">
					<div
						className="navbar-profile-image"
						style={{ backgroundImage: `url(${user.avatarFilePath})` }}
						onClick={() =>
							dispatch(showOrHideGeneralDashboardLeftMenu(false))
						}></div>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						title={translationService.translate("TR_EDIT_PROFILE")}
						className="profile-nav-link w-100"
						as={Link}
						to={`/${language}/${EDIT_PROFILE_KEY}`}>
						<div className="d-flex align-items-center">
							<div
								className="navbar-profile-image"
								style={{
									backgroundImage: `url(${user.avatarFilePath})`,
								}}></div>
							<div className="ml-3 user-profil-info-wrapper">
								<h2 className="profile-user-fullname">
									{user.firstname} {user.lastname}
								</h2>
								<small>{user.email}</small>
							</div>
						</div>
					</Dropdown.Item>
					<ul className="header-popup">
						{props.location.pathname.includes(`/${INSTRUCTOR_KEY}/`) ||
						props.location.pathname.includes(`/${CONSULTANT_KEY}/`) ? (
							<Auxiliary>
								<li>
									<hr className="d-none" />
								</li>
								<li className="nav--item important-action">
									<Dropdown.Item
										to={`/${language}/${LEARN_IN_MINDALAY_KEY}/${INFO_KEY}`}
										as={Link}
										className="profile-nav-link">
										{translationService.translate("TR_LEARN_IN_MINDALAY")}
									</Dropdown.Item>
								</li>
								<li className="nav--item important-action">
									{user &&
									(user.userTypeId === INSTRUCTOR_USER_TYPE ||
										user.userTypeId === CONSULTANT_USER_TYPE) ? (
										<Dropdown.Item
											to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`}
											as={Link}
											className="profile-nav-link">
											{translationService.translate("TR_INSTRUCTOR")}
										</Dropdown.Item>
									) : (
										<Dropdown.Item
											to={`/${language}/${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`}
											as={Link}
											className="profile-nav-link"
											onClick={() => setBackUrl(`/${language}`)}>
											{translationService.translate("TR_TEACH_IN_MINDALAY")}
										</Dropdown.Item>
									)}
								</li>
								<li
									className="nav--item important-action position-relative"
									title={
										user.userTypeId === CONSULTANT_USER_TYPE &&
										!user.dontDisturb
											? translationService.translate(
													"TR_ACTIVE_CONSULTANT_INFO",
											  )
											: user.userTypeId === CONSULTANT_USER_TYPE &&
											  user.dontDisturb
											? translationService.translate(
													"TR_INACTIVE_CONSULTANT_INFO",
											  )
											: ""
									}>
									{user ? (
										<Dropdown.Item
											to={
												user.userTypeId === CONSULTANT_USER_TYPE
													? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`
													: `/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`
											}
											as={Link}
											className="profile-nav-link"
											onClick={() => setBackUrl(`/${language}`)}>
											{user.userTypeId === CONSULTANT_USER_TYPE
												? translationService.translate("TR_CONSULTANT")
												: translationService.translate(
														"TR_BECOME_A_CONSULTANT",
												  )}
											{user.userTypeId === CONSULTANT_USER_TYPE &&
											user.dontDisturb ? (
												<p
													className={`d-inline dont-disturb-point is-passive`}
												/>
											) : null}
											{user.userTypeId === CONSULTANT_USER_TYPE &&
											!user.dontDisturb ? (
												<p
													className={`d-inline dont-disturb-point is-active`}
												/>
											) : null}
										</Dropdown.Item>
									) : (
										<Dropdown.Item
											to={`/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`}
											className="profile-nav-link"
											as={Link}
											onClick={() =>
												returnUrlWithoutRedirect(
													`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`,
												)
											}>
											{translationService.translate("TR_BECOME_A_CONSULTANT")}
										</Dropdown.Item>
									)}
								</li>
								<li className="nav--item important-action">
									<Dropdown.Item
										to={`/${language}/${MINDALAY_FOR_INSTITUTIONS_KEY}/${INFO_KEY}`}
										as={Link}
										className="profile-nav-link">
										{translationService.translate(
											"TR_MINDALAY_FOR_INSTITUTIONS",
										)}
									</Dropdown.Item>
								</li>
								<li>
									<hr />
								</li>
							</Auxiliary>
						) : null}
						<hr className="d-none" />
						<li className="nav--item mt-2 position-relative">
							<Dropdown.Item
								className={`profile-nav-link w-100 ${
									unreadMessgesCount ? "has-value" : ""
								} `}
								as={Link}
								to={`/${language}/${ALL_MESSAGES_KEY}`}
								rel={unreadMessgesCount ? unreadMessgesCount : null}>
								{translationService.translate("TR_MESSAGES")}
							</Dropdown.Item>
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${TICKETS_KEY}`}>
								{translationService.translate("TR_TICKETS")}
							</Dropdown.Item>
						</li>
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${EDIT_PROFILE_KEY}`}>
								{translationService.translate("TR_EDIT_PROFILE")}
							</Dropdown.Item>
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${PROFILE_KEY}`}>
								{translationService.translate("TR_MY_COURSES")}
							</Dropdown.Item>
						</li>
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${ALL_CONSULTATIONS_KEY}`}>
								{translationService.translate("TR_CONSULTATIONS")}
							</Dropdown.Item>
						</li>
						{user && user.userTypeId === CONSULTANT_USER_TYPE ? (
							<li className="nav--item position-relative">
								<Dropdown.Item
									className={`profile-nav-link w-100 ${
										potentialconsultationsCount ? "has-value" : ""
									} `}
									as={Link}
									to={`/${language}/${CONSULTANT_FEED_KEY}`}
									rel={
										potentialconsultationsCount
											? potentialconsultationsCount
											: null
									}>
									{translationService.translate("TR_CONSULTANT_FEED")}
								</Dropdown.Item>
							</li>
						) : null}
						{user.userTypeId === CONSULTANT_USER_TYPE ||
						user.userTypeId === INSTRUCTOR_USER_TYPE ? (
							<li className="nav--item">
								<Dropdown.Item
									className="profile-nav-link w-100"
									as={Link}
									to={
										user.userTypeId === CONSULTANT_USER_TYPE
											? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`
											: user.userTypeId === INSTRUCTOR_USER_TYPE
											? `/${language}/${INSTRUCTOR_PATH_KEY}/${COURSES_KEY}/${VIEW_KEY}`
											: null
									}>
									{translationService.translate("TR_DASHBOARD")}
								</Dropdown.Item>
							</li>
						) : null}
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}`}>
								{translationService.translate("TR_VIDEO_CONFERENCES")}
							</Dropdown.Item>
						</li>
						{user && user.userTypeId === STUDENT_USER_TYPE ? (
							<Auxiliary>
								<li>
									<hr />
								</li>
								<li className="nav--item">
									<Dropdown.Item
										className="profile-nav-link w-100"
										as={Link}
										to={`/${language}/${STUDENT_KEY}/${FINANCES_KEY}`}>
										{translationService.translate("TR_FINANCES")}
									</Dropdown.Item>
								</li>
								<li className="nav--item">
									<Dropdown.Item
										className="profile-nav-link w-100"
										as={Link}
										to={`/${language}/${STUDENT_KEY}/${OVERVIEW_KEY}`}>
										{translationService.translate("TR_PAYMENT_HISTORY")}
									</Dropdown.Item>
								</li>
							</Auxiliary>
						) : null}
						<li>
							<hr />
						</li>
						<li className="nav--item">
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to={`/${language}/${HELP_KEY}`}>
								{translationService.translate("TR_HELP")}
							</Dropdown.Item>
						</li>
						<li className="nav--item" onClick={onLogout}>
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to="#">
								{translationService.translate("TR_LOG_OUT")}
							</Dropdown.Item>
						</li>
					</ul>
				</Dropdown.Menu>
			</div>
		</Dropdown>
	) : null;
};

export default withRouter(ProfileDropdown);

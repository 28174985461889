import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ActionButton from "../../Components/ActionButton/actionButton";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Input from "../../Components/Inputs/input";
import { addButtonSpinner, removeButtonSpinner, addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../Constants/translationKeys";

class SectionForm extends Component {
  mainService = new MainService();
  state = {
    form: {
      name: '',
      courseId: +this.props.match.params.courseId
    },
    section: this.props.section,
    sectionId: null,
    isInvalidSubmit: false,
    spinnerId: uuid(),
    translationService: null,
    nameLength: 200,
    failedFields: [],
    changes: false
  };

  componentDidMount() {
    this.setTranslations();
    this.state.section && this.setUpdateSection(this.state.section);
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
  }

  componentDidUpdate() {
    this.setTranslations();
    this.state.section && !this.state.sectionId && this.setUpdateSection(this.state.section);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  setUpdateSection = section => {
    section && this.setState(prevState => ({
      ...prevState,
      sectionId: section.id,
      form: {
        ...prevState.form,
        name: section.name,
      }
    }));
  }

  onChange = (event, fieldLength) => {
    if (event.target.value.length <= fieldLength) {
      this.setState(prevState => ({ ...prevState, form: { ...prevState.form, [event.target.name]: event.target.value } }));
    }
  };

  onSubmit = event => {
    event && event.preventDefault();
    const { user, buttonSpinners } = this.props;
    const { sectionId, translationService, spinnerId, form } = this.state;
    if (buttonSpinners.length) {
      return false;
    }
    this.setState({ changes: false });
    if (!form.name || !form.name.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addButtonSpinner(spinnerId);
      this.setState({ isDisabled: true });

      if (sectionId) { form.id = +sectionId };
      if (user && user.isOrganizationUser) {
        (sectionId
          ? ApiService.sectionUpdateByOrgUser(form)
          : ApiService.sectionCreateByOrgUser(form)).then(() => {
            AlertService.alert(SUCCESS_KEY, translationService.translate(`${sectionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
            this.setState({ isDisabled: false });
            this.props.removeButtonSpinner(spinnerId);
            this.cancel();
            this.props.getCourseById();
          }).catch(error => this.submitFail(spinnerId, error));
      }
      if (user && !user.isOrganizationUser) {
        (sectionId
          ? ApiService.sectionUpdate(form)
          : ApiService.sectionCreate(form)).then(() => {
            AlertService.alert(SUCCESS_KEY, translationService.translate(`${sectionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
            this.setState({ isDisabled: false });
            this.props.removeButtonSpinner(spinnerId);
            this.cancel();
            this.props.getCourseById();
          }).catch(error => this.submitFail(spinnerId, error));
      }
    }
  }

  cancel = () => {
    this.setState(prevState => ({
      ...prevState,
      sectionId: null,
      section: null,
      form: {
        ...prevState.form,
        name: '',
      }
    }));
    this.props.cancel();
  }

  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    this.setState({ isDisabled: false });
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  render() {
    const { isInvalidSubmit, spinnerId, translationService, sectionId, nameLength, form, failedFields } = this.state;

    return translationService ? <div className="toggle-content mt-3">
      <form onSubmit={this.onSubmit} onChange={() => this.setState({ changes: true })}>
        <div>
          <Input
            type="text"
            name="name"
            inputClassName="pr--5 my-4"
            fieldLength={nameLength}
            placeholder={`${translationService.translate("TR_SECTION_NAME")} *`}
            value={form.name}
            isInvalidSubmit={isInvalidSubmit}
            isInvalidField={isInvalidSubmit && !form.name.trim()}
            onChange={event => this.onChange(event, nameLength)}
            autoFocus={true}
            failedFields={failedFields}
          />
          <div className="mt-2 mt-md-0 d-flex justify-content-end">
            <ActionButton
              type="submit"
              spinnerId={spinnerId}
              className="mindalay--btn-default position-relative"
              // name={translationService.translate(sectionId ? "TR_UPDATE_SECTION" : "TR_CREATE_SECTION")}
              name={translationService.translate("TR_SAVE")}
            />
            <button type="button" className="ml-1 mindalay--btn-default" onClick={this.cancel}>
              {translationService.translate("TR_CANCEL")}
            </button>
          </div>
        </div>
      </form>
      {
        this.props.sections && this.props.sections.length ?
          <hr />
          : null
      }
    </div> : null;
  }
}

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SectionForm);
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TranslationService from './../../Services/translationService';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { ENTITY_TYPES_WITH_PAYMENT_PACKAGES, ERROR_KEY, ORGANIZATION_ENTITY_TYPE } from '../../Constants/mainKeys';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import ReactPaginate from 'react-paginate';
import uuid from 'react-uuid';
import { addPartialViewSpinner, removePartialViewSpinner } from './../../Store/Actions/spinner';
import ApiService from './../../Services/apiService';
import AlertService from '../../Services/alertService';
import MainService from '../../Services/mainService';
import moment from 'moment';
import { withRouter } from 'react-router';
import ModalComponentWithoutActionButton from '../../Components/ModalComponent/modalComponentWithoutActionButton';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import CloseSvg from './../../Components/Svg/closeSvg';

const PaymentHistory = (props) => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const pageSize = 10;
  const [translationService, setTranslationService] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [entityType, setEntityType] = useState(-1);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [currentPaymentPackage, setCurrentPaymentPackage] = useState(null);
  const [zoomImagePath, setZoomImagePath] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPaymentHistory(entityType, 1);
    window.addEventListener('keydown', zoomOut);
    return () => {
      window.removeEventListener('keydown', zoomOut);
    }
  }, []);

  const handlePageClick = (event) => {
    getPaymentHistory(entityType, event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getPaymentHistory = (entityType, currentPage) => {
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getPaymentHistoryForMindalayAdmin(currentPage, pageSize, entityType).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      response.data && setPaymentHistory(response.data);
      dispatch(removePartialViewSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
  }

  const onSeleceOptionChange = (selectedCategory) => {
    if (!selectedCategory) { return; }
    setEntityType(selectedCategory.value);
    getPaymentHistory(selectedCategory.value, 1)
  }

  const getPaymentPackageById = (currentPaymentPackageEntityId) => {
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getPaymentPackageById(currentPaymentPackageEntityId).then(response => {
      dispatch(removePartialViewSpinner(spinnerId));
      response.data && setCurrentPaymentPackage(response.data);
    }).catch(error => getFail(error, spinnerId));
  }

  const cancel = () => {
    setCurrentPaymentPackage(null);
  }

  const zoomOut = (event) => {
    if (event.key === "Escape") {
      hideZoomImage();
    }
  }

  const hideZoomImage = () => {
    setZoomImagePath(null);
  }

  return translationService ? <div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="content-title p-0">{translationService.translate("TR_PAYMENT_HISTORY")}</h2>
        <hr />
      </div>
    </div>
    {
      zoomImagePath ?
        <div className="zoom-image-container" onClick={hideZoomImage}>
          <div className="close-svg"><CloseSvg /></div>
          <img src={zoomImagePath} alt="/" onClick={(event) => event.stopPropagation()} />
        </div>
        : null
    }
    {
      currentPaymentPackage ?
        <ModalComponentWithoutActionButton
          title={currentPaymentPackage.paymentPackageName ? currentPaymentPackage.paymentPackageName : ""}
          contentClassName="resource-modal"
          isLarge={true}
          cancel={cancel}
        >
          <div className="row">
            <div className="col-12 px-4">
              <div className="org-student-info-block">
                {
                  currentPaymentPackage.chequePath ?
                    <img
                      src={currentPaymentPackage.chequePath}
                      className='m-3 cursor-pointer'
                      alt="/"
                      onClick={() => setZoomImagePath(currentPaymentPackage.chequePath)}
                    />
                    : null
                }
                <hr />
              </div>
              <div className="org-student-info-block-item">
                {
                  currentPaymentPackage.totalPrice ?
                    <Auxiliary>
                      <div className="d-flex justify-content-between mx-1">
                        <b>{`${translationService.translate("TR_TOTAL_PRICE")}:`}</b>
                        <span>{currentPaymentPackage.totalPrice}</span>
                      </div>
                      <hr />
                    </Auxiliary>
                    : null
                }
                {
                  currentPaymentPackage.monthCount ?
                    <Auxiliary>
                      <div className="d-flex justify-content-between mx-1">
                        <b>{`${translationService.translate("TR_MONTH_COUNT")}:`}</b>
                        <span>{currentPaymentPackage.monthCount}</span>
                      </div>
                      <hr />
                    </Auxiliary>
                    : null
                }
                <Auxiliary>
                  <div className="d-flex justify-content-between mx-1">
                    <b>{`${translationService.translate("TR_ACCEPTED")}:`}</b>
                    {
                      currentPaymentPackage.accepted ?
                        <span><i className="fas fa-check transaction-successful fs-30"></i></span>
                        : <span><i className="fas fa-times transaction-unsuccessful fs-30"></i></span>
                    }
                  </div>
                  <hr />
                </Auxiliary>
                <Auxiliary>
                  <div className="d-flex justify-content-between mx-1">
                    <b>{`${translationService.translate("TR_REJECTED")}:`}</b>
                    <span>{currentPaymentPackage.rejected}</span>
                    {
                      currentPaymentPackage.rejected ?
                        <span><i className="fas fa-check transaction-successful fs-30"></i></span>
                        : <span><i className="fas fa-times transaction-unsuccessful fs-30"></i></span>
                    }
                  </div>
                  <hr />
                </Auxiliary>
                <Auxiliary>
                  <div className="d-flex justify-content-between mx-1">
                    <b>{`${translationService.translate("TR_IS_PAID")}:`}</b>
                    {
                      currentPaymentPackage.isPaid ?
                        <span><i className="fas fa-check transaction-successful fs-30"></i></span>
                        : <span><i className="fas fa-times transaction-unsuccessful fs-30"></i></span>
                    }
                  </div>
                  <hr />
                </Auxiliary>
              </div>
            </div>
          </div>
        </ModalComponentWithoutActionButton>
        : null
    }
    <div className="row">
      <div className="col-md-6 col-12">
        <label>{translationService.translate("TR_FILTER_BY_ENTITY_TYPE")}</label>
        <ReactSelectOption
          value={entityType}
          selectedValue={(() => {
            const selectedValue = { ...ENTITY_TYPES_WITH_PAYMENT_PACKAGES.find(data => data.value === entityType) };
            if (selectedValue) {
              selectedValue.label = translationService.translate(selectedValue.label);
              selectedValue.value = selectedValue.value;
            }
            return selectedValue;
          })()}
          items={ENTITY_TYPES_WITH_PAYMENT_PACKAGES.map(data => ({ label: translationService.translate(data.label), value: data.value }))}
          onChange={(item) => onSeleceOptionChange(item)}
        />
      </div>

      <div className='col-12'>
        {
          paymentHistory && paymentHistory.length ?
            <table className="table table-responsive mt-3">
              <thead>
                <tr>
                  <th>{translationService.translate("TR_INCOME")}</th>
                  <th>{translationService.translate("TR_PERCENTAGE")}</th>
                  <th>{translationService.translate("TR_SERVICE_TYPE")}</th>
                  <th>{translationService.translate("TR_SERVICE_NAME")}</th>
                  <th>{translationService.translate("TR_FROM_USERNAME")}</th>
                  <th>{translationService.translate("TR_FROM_AMOUNT")}</th>
                  <th>{translationService.translate("TR_TRANSACTION_DATE")}</th>
                  <th>{translationService.translate("TR_TO_USERNAME")}</th>
                  <th>{translationService.translate("TR_TO_AMOUNT")}</th>
                  <th>{translationService.translate("TR_UNFREEZE_DATE")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  paymentHistory.map((history, index) => {
                    return <tr className={history.entityType === ORGANIZATION_ENTITY_TYPE ? "cursor-pointer" : ""} key={index} onClick={() => {
                      if (history.entityType === ORGANIZATION_ENTITY_TYPE) {
                        getPaymentPackageById(history.entityId)
                      }
                    }}>
                      <td>{history.amountWithCurrency}</td>
                      <td>{history.percentage}</td>
                      <td>{history.entityTypeName}</td>
                      <td>{history.entityName}</td>
                      <td>{history.fromUserName}</td>
                      <td>{history.fromAmountWithCurrency}</td>
                      <td>{moment(MainService.convertUTCDateToLocalDate(new Date(history.createDate))).format("ll HH:mm")}</td>
                      <td>{history.toUserName}</td>
                      <td>{history.toAmountWithCurrency}</td>
                      <td>{moment(MainService.convertUTCDateToLocalDate(new Date(history.freezInfo))).format("ll HH:mm")}</td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            : <div className="no-data-container mt-5">
              <div className="no-data-wrapper">
                <p>{translationService.translate("TR_NO_DATA")}</p>
                <img src={NoDataImage} alt="/" />
              </div>
            </div>
        }
      </div>
      {
        pagination ?
          <div className="mt-4">
            <div className="col-12">
              <div>
                <ReactPaginate
                  nextLabel={translationService.translate("TR_NEXT")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pagination.TotalPages}
                  previousLabel={translationService.translate("TR_PREVIOUS")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={activePageNumber}
                />
              </div>
            </div>
          </div>
          : null
      }
    </div>
  </div> : null
}

export default withRouter(PaymentHistory);

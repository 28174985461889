import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import uuid from 'react-uuid';
import ApiService from './../../Services/apiService';
import { addPartialViewSpinner, removePartialViewSpinner } from './../../Store/Actions/spinner';
import AlertService from "../../Services/alertService";
import { ENTITY_TYPE, ERROR_KEY, NUMBER_KEY } from "../../Constants/mainKeys";
import { withRouter } from "react-router-dom";
import { COURSE_KEY, IN_PROCESS_KEY, MINDALAY_ADMIN, RESOLVED_KEY, TICKET_KEY } from "../../Constants/urlKeys";
import { setBackUrl } from './../../Store/Actions/main';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ReactPaginate from 'react-paginate';

const Tickets = (props) => {

  const isProcessTicketsPage = props.location.pathname.includes(`/${TICKET_KEY}/${IN_PROCESS_KEY}`) ? true : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const pageSize = 10;
  const location = useLocation();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);

  const [tickets, setTickets] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setActivePageNumber(0);
    getTickets(1);
  }, [history.location.pathname]);

  const getTickets = (currentPage) => {
    if (props.location.pathname.includes(`/${TICKET_KEY}/${IN_PROCESS_KEY}`)) {
      getInprocessTickets(currentPage);
    }
    if (props.location.pathname.includes(`/${TICKET_KEY}/${RESOLVED_KEY}`)) {
      getResolvedTickets(currentPage);
    }
  }

  const handlePageClick = (event) => {
    getTickets(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  const getInprocessTickets = (currentPage) => {
    setTickets([]);
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getInprocessTickets(currentPage, pageSize).then(response => {
      dispatch(removePartialViewSpinner(spinnerId));
      if (response && response.data && response.pagination) {
        setTickets(response.data);
        setPagination(JSON.parse(response.pagination));
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const getResolvedTickets = (currentPage) => {
    setTickets([]);
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getResolvedTickets(currentPage, pageSize).then(response => {
      dispatch(removePartialViewSpinner(spinnerId));
      if (response && response.data && response.pagination) {
        setTickets(response.data);
        setPagination(JSON.parse(response.pagination));
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const getFail = (error, spinnerId) => {
    spinnerId && dispatch(removePartialViewSpinner(spinnerId))
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  const redirectToCourseOrConsultationPage = (event, ticket, entityType) => {
    event.stopPropagation();
    if (typeof entityType !== NUMBER_KEY) { return; }
    if (entityType === 0) {
      props.history.push(`/${language}/${MINDALAY_ADMIN}/${COURSE_KEY}/${ticket.entityId}`)
    } else {
      return;
    }
  }

  const redirectToTicketPage = (ticket) => {
    props.history.push(`/${language}/${MINDALAY_ADMIN}/${TICKET_KEY}/${ticket.id}`);
    dispatch(setBackUrl(props.location.pathname));
  }

  return (
    translationService ? <div className="container">
      <div className="row">
        <div className="col-12">
          {
            isProcessTicketsPage
              ? <h2 className="content-title p-0">{translationService.translate("TR_IN_PROCESS_TICKETS")}</h2>
              : <h2 className="content-title p-0">{translationService.translate("TR_RESOLVED_TICKETS")}</h2>
          }
          <hr />
        </div>
      </div>
      {
        tickets && tickets.length && pagination ?
          <div className="row">
            <div className="col-12">
              <div className="mindayal-admin-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{translationService.translate("TR_NUMBER")}</th>
                      <th>{translationService.translate("TR_SUBJECT")}</th>
                      <th>{translationService.translate("TR_DESCRIPTION")}</th>
                      {
                        !isProcessTicketsPage ? <th>{translationService.translate("TR_REFUNDED")}</th> : null
                      }
                      <th>{translationService.translate("TR_TYPE")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tickets.map((ticket, index) => {
                        return <tr key={index} onClick={() => redirectToTicketPage(ticket)}>
                          <td><div className="mindalay-admin-table-td-item">{ticket.ticketNumber}</div></td>
                          <td><div className="mindalay-admin-table-td-item">{ticket.subject}</div></td>
                          <td className="word-break-break-word"><div className="mindalay-admin-table-td-item">{ticket.description}</div></td>
                          {
                            !isProcessTicketsPage ?
                              <td>
                                <div className="mindalay-admin-table-td-item">
                                  {
                                    ticket.refunded ?
                                      <span className="payment-modal-status-icon-block">
                                        <i className="fas fa-times transaction-unsuccessful fs-30" />
                                      </span> :
                                      <span className="payment-modal-status-icon-block">
                                        <i className="fas fa-check transaction-successful fs-30" />
                                      </span>
                                  }
                                </div>
                              </td>
                              : null
                          }
                          <td>
                            <div className="mindalay-admin-table-td-item">
                              {
                                typeof ticket.entityType === NUMBER_KEY ?
                                  [...ENTITY_TYPE].map((type, index) => {
                                    if (+type.value === +ticket.entityType) {
                                      return <button
                                        key={index}
                                        className="mindalay--btn mindalay--btn-default"
                                        onClick={(event) => redirectToCourseOrConsultationPage(event, ticket, type.value)}
                                      >
                                        {translationService.translate(type.label)}
                                      </button>
                                    } else { return false; }
                                  })
                                  : null
                              }
                            </div>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          : <div className="no-data-container">
            <div className="no-data-wrapper">
              <p>{translationService.translate("TR_NO_DATA")}</p>
              <img src={NoDataImage} alt="/" />
            </div>
          </div>
      }
      {
        pagination ?
          <div className="row mt-4">
            <div className="col-12">
              <div>
                <ReactPaginate
                  nextLabel={translationService.translate("TR_NEXT")}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pagination.TotalPages}
                  previousLabel={translationService.translate("TR_PREVIOUS")}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                  forcePage={activePageNumber}
                />
              </div>
            </div>
          </div>
          : null
      }
    </div> : null
  );
}

export default withRouter(Tickets)




import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "./../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "./../../Components/PageInfoBlock/PageInfoBlock";
import { Tab, Tabs } from "react-bootstrap";
import MessagesForm from "./Components/MessagesForm";
import ImagesForm from "./Components/ImagesForm";
import ColorForm from "./Components/ColorForm";

const Settings = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const { settings } = useSelector(state => state.settings);
	const [key, setKey] = useState("1");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_SETTINGS_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Settings"
						pageName="admin_settings"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-12">
					<Tabs
						activeKey={key}
						className={`setting-tabs`}
						onSelect={newKey => {
							if (
								settings &&
								settings.organizationShortName &&
								key !== newKey
							) {
								setKey(newKey);
							}
						}}>
						<Tab
							eventKey="1"
							title={translationService.translate("TR_MESSAGES")}>
							<MessagesForm eventKey={2} />
						</Tab>
						<Tab eventKey="2" title={translationService.translate("TR_IMAGES")}>
							<ImagesForm eventKey={3} />
						</Tab>
						<Tab eventKey="3" title={translationService.translate("TR_COLOR")}>
							<ColorForm />
						</Tab>
					</Tabs>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(Settings);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from '../../Components/Inputs/input';
import AlertService from '../../Services/alertService';
import MainService from '../../Services/mainService';
import TranslationService from '../../Services/translationService';
import moment from 'moment';
import ApiService from '../../Services/apiService';
import uuid from 'react-uuid';
import ActionButton from '../../Components/ActionButton/actionButton';
import { WEBINAR_SCREEN_URL_KEY } from '../../Constants/apiKeys';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import SettingsSvg from '../../Components/Svg/settingsSvg';
import { addButtonSpinner, removeButtonSpinner, addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { removeWebinarData } from "./../../Store/Actions/main";
import { CREATE_ONE_TIME_WEBINAR_KEY } from '../../Constants/urlKeys';
import { ERROR_KEY, NUMBER_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES, WEBINAR_DATA_KEY } from '../../Constants/mainKeys';

class CreateOneTimeWebinar extends Component {
  _isMounted = false;

  state = {
    form: {
      name: '',
      startDate: '',
      webinarId: this.props.match.params.webinarId ? this.props.match.params.webinarId : null
    },
    isInvalidSubmit: false,
    failedFields: null,
    translationService: null,
    spinnerId: uuid(),
    currentUserWebinars: [],
    isInvalidDate: false,
    webinarData: null,
    withoutSpinner: false,
    nameMaxLength: 120,
    pageNumber: 1,
    pageSize: 5,
    pagination: null,
    buttonSpinnerId: uuid(),
  }

  componentDidMount() {
    const { pageNumber, pageSize } = this.state;
    this._isMounted = true;
    this.setTranslations();
    this.getCurrentUserWebinars(pageNumber, pageSize);
    this.interval = setInterval(this.getInProcessWebinar, 10000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  getInProcessWebinar = () => {
    var { currentUserWebinars } = this.state;
    var webinarData = localStorage.getItem(WEBINAR_DATA_KEY);
    if (webinarData && JSON.parse(webinarData)) {
      this.setState({ webinarData: JSON.parse(webinarData) });
    }
    ApiService.getInProcessWebinar().then(response => {
      if (response.data) {
        const data = { ...response.data };
        var index = currentUserWebinars.findIndex(function (o) {
          return o.id === data.id;
        });
        if (index !== -1) {
          currentUserWebinars.splice(index, 1, data);
          this.setState({ currentUserWebinars });
        }
        // this.cancelUpdateWebinar()
      }
    }).catch(error => this.getFail(error));
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getCurrentUserWebinars = (pageNumber, pageSize, isShowButtonSpinner = null, callAfterUpdateWebinar) => {
    const spinnerId = uuid();
    const { buttonSpinnerId } = this.state;
    if (isShowButtonSpinner) {
      this.props.addButtonSpinner(buttonSpinnerId);
    } else this.props.addPageSpinner(spinnerId);
    var webinarData = localStorage.getItem(WEBINAR_DATA_KEY);
    if (webinarData && JSON.parse(webinarData)) {
      this.setState({ webinarData: JSON.parse(webinarData) });
    }
    ApiService.getCurrentUserWebinars(pageNumber, pageSize).then(response => {
      if (response && response.data && response.pagination) {
        var currentUserWebinars = [...response.data];
        currentUserWebinars.forEach(currentUserWebinar => {
          currentUserWebinar.isShowCopiedMessage = false;
        })
        if (this.state.form.webinarId && currentUserWebinars && currentUserWebinars.length) {
          const currentWebinar = currentUserWebinars.find(webinar => webinar.id === +this.state.form.webinarId);
          if (currentWebinar) {
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,
                name: currentWebinar.name,
                startDate: moment(MainService.convertUTCDateToLocalDate(new Date(currentWebinar.webinarCalendar.startTime))).format("YYYY-MM-DDTHH:mm:ss")
              }
            }));
          }
        }
        if (this.state.currentUserWebinars.length && !callAfterUpdateWebinar) {
          currentUserWebinars = [...this.state.currentUserWebinars, ...response.data];
        }
        this._isMounted && this.setState({
          currentUserWebinars,
          pagination: JSON.parse(response.pagination)
        });
      }
      this.props.removePageSpinner(spinnerId);
      this.props.removeButtonSpinner(buttonSpinnerId);
    }).catch(error => this.getFail(error, spinnerId, buttonSpinnerId));
  }

  getNextPageWebinars = () => {
    let { pageNumber } = this.state;
    pageNumber += 1;
    this.setState({
      pageNumber,
    }, () => {
      this.getCurrentUserWebinars(this.state.pageNumber, this.state.pageSize, true);
    })
  }

  onChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    if (typeof event.target.value)
      this.setState(prevState => ({
        ...prevState,
        failedFields,
        form: {
          ...prevState.form,
          [event.target.name]: event.target.value,
        }
      }));
  };

  onDateChange = event => {
    const failedFields = this.removeFailedFields(event.target.name);
    this.setState(prevState => ({
      ...prevState,
      failedFields,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  };

  checkDateFormatAndIsValid = (date) => {
    const isInvalidDateFormat = MainService.isValidDateTime(date);
    const isInvalidDate = MainService.validateDate(date);
    if (!isInvalidDateFormat || !isInvalidDate) {
      this.setState({ isInvalidDate: true });
    } else this.setState({ isInvalidDate: false });
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  copyInvitationLink = (index, link) => {
    if (typeof index !== NUMBER_KEY || !link) { return; }
    navigator.clipboard.writeText(link);
    const currentUserWebinars = [...this.state.currentUserWebinars];
    const currentWebinar = { ...currentUserWebinars[index] };
    currentWebinar.isShowCopiedMessage = true;
    currentUserWebinars[index] = currentWebinar;
    this.setState({ currentUserWebinars });
    setTimeout(() => {
      currentWebinar.isShowCopiedMessage = false;
      currentUserWebinars[index] = currentWebinar;
      this.setState({ currentUserWebinars });
    }, 1000);
  }

  deleteWebinar = (webinar) => {
    if (!webinar) { return; }
    const { translationService, currentUserWebinars } = this.state;
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${webinar.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      ApiService.deleteOneTimeWebinar(webinar.webinarCalendar.id).then(() => {
        this.props.removePageSpinner(spinnerId);
        const updatedWebinars = currentUserWebinars.filter(currentUserWebinar => currentUserWebinar.id !== webinar.id);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_VIDEO_CONFERENCE_SUCCESSFULLY_DELETED"));
        this.setState({ currentUserWebinars: updatedWebinars });
        this.cancelUpdateWebinar();
      }).catch(error => this.getFail(error, spinnerId));
    });
  }

  updateWebinar = (webinar) => {
    const { language } = this.props;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        webinarId: webinar.id,
        name: webinar.name,
        startDate: moment(MainService.convertUTCDateToLocalDate(new Date(webinar.webinarCalendar.startTime))).format("YYYY-MM-DDTHH:mm:ss")
      }
    }));
    this.props.history.push(`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}/${webinar.id}`);
  }

  cancelUpdateWebinar = () => {
    const { language } = this.props;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        webinarId: null,
        name: "",
        startDate: ""
      }
    }));
    this.props.history.push(`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}`);
  }

  finishWebinar = (currentWebinar) => {
    const { translationService, currentUserWebinars } = this.state;
    const { globalConnection } = this.props;
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_END_WEBINAR_CONFIRM_MESSAGE_KEY")} "${currentWebinar.name}" ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      this.props.removeWebinarData();
      ApiService.finishWebinar(currentWebinar.webinarCalendar.id).then(() => {
        if (globalConnection) {
          globalConnection.invoke("ChangeUserIsNotifiedStatus").then(() => {
            const currentUserWebinarsCopy = [...currentUserWebinars];
            currentUserWebinarsCopy.forEach(webinar => {
              if (webinar.id === currentWebinar.id) {
                webinar.webinarCalendar.isFinished = true;
                webinar.webinarCalendar.inProcess = false;
              }
            })
            AlertService.alert(SUCCESS_KEY, translationService.translate("TR_WEBINAR_SUCCESSFULLY_FINISHED_MSG"));
            this.setState({ currentUserWebinars: currentUserWebinarsCopy });
            localStorage.removeItem(WEBINAR_DATA_KEY);
          });
        }
        this.props.removePageSpinner(spinnerId);
      }).catch(error => this.getFail(error, spinnerId));
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { language } = this.props;
    const { spinnerId, currentUserWebinars, isInvalidDate, translationService, pageSize } = this.state;
    const form = { ...this.state.form };
    if (!form.name || !form.startDate || isInvalidDate) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addButtonSpinner(spinnerId);
      form.timeZoneOffset = new Date().getTimezoneOffset() / 60;
      form.id = form.webinarId;
      const formData = new FormData();
      for (let i in form) {
        if (i === "webinarId") delete form[i];
        !form[i] ? delete form[i] : formData.append(`${i}`, form[i]);
      }
      (this.state.form.webinarId ? ApiService.updateOneTimeWebinar(formData) : ApiService.createOneTimeWebinar(formData)).then(response => {
        if (response && response.data) {
          const currentUserWebinar = { ...response.data };
          currentUserWebinar.isShowCopiedMessage = false;
          currentUserWebinars.unshift(currentUserWebinar);
          this.setState({ currentUserWebinars });
        }
        if (this.state.form.webinarId) {
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_VIDEO_CONFERENCE_SUCCESSFULLY_UPDATED"));
          this.getCurrentUserWebinars(1, pageSize, false, true);
        }
        this.setState(prevState => ({
          ...prevState,
          isInvalidSubmit: false,
          form: {
            ...prevState.form,
            webinarId: null,
            name: '',
            startDate: ''
          }
        }))
        this.props.history.push(`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}`);
        this.props.removeButtonSpinner(spinnerId);
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  goToWebinar = () => {
    window.location.href = WEBINAR_SCREEN_URL_KEY;
  }

  getFail = (error, spinnerId, buttonSpinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
    buttonSpinnerId && this.props.removeButtonSpinner(buttonSpinnerId);
  };

  render() {
    const { name, startDate, webinarId } = this.state.form;
    const {
      isInvalidSubmit,
      failedFields,
      translationService,
      spinnerId,
      currentUserWebinars,
      isInvalidDate,
      webinarData,
      nameMaxLength,
      pagination,
      buttonSpinnerId
    } = this.state;

    return (
      translationService ? <section className="m-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12">
              {
                webinarId
                  ? <strong className="section-title">{translationService.translate("TR_UPDATE_VIDEO_CONFERENCE")}</strong>
                  : <strong className="section-title">{translationService.translate("TR_CREATE_VIDEO_CONFERENCE")}</strong>
              }
              <hr />
              <p className="mt-3 mb-3">
                {translationService.translate("TR_VIDEO_CONFERENCE_INFO")}
              </p>
              <form onSubmit={this.onSubmit}>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  inputClassName="pr--4"
                  value={name}
                  fieldLength={nameMaxLength}
                  isInvalidSubmit={isInvalidSubmit}
                  labelValue={`${translationService.translate("TR_VIDEO_CONFERENCE_NAME")} *`}
                  onChange={(event) => this.onChange(event, nameMaxLength)}
                  failedFields={failedFields}
                />
                <Input
                  type="datetime-local"
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  labelValue={`${translationService.translate("TR_VIDEO_CONFERENCE_START_DATE")} *`}
                  inputClassName={isInvalidDate ? "is-invalid error-bordered" : ""}
                  onChange={this.onDateChange}
                  onBlur={() => this.checkDateFormatAndIsValid(startDate)}
                  failedFields={failedFields}
                  isInvalidSubmit={isInvalidSubmit}
                />
                {
                  isInvalidDate ?
                    <small className="red-color">{translationService.translate("TR_INVALID_DATE")}</small>
                    : null
                }
                <div className="d-block d-lg-flex justify-content-between">
                  <ActionButton
                    type="submit"
                    spinnerId={spinnerId}
                    className="mindalay--btn-default w-100 m-1"
                    name={webinarId ? translationService.translate("TR_UPDATE_VIDEO_CONFERENCE") : translationService.translate("TR_CREATE_VIDEO_CONFERENCE")}
                  />
                  {
                    webinarId
                      ? <button className="mindalay--btn-default w-100 m-1"
                        onClick={this.cancelUpdateWebinar}
                      >
                        {translationService.translate("TR_CANCEL")}
                      </button>
                      : null
                  }
                </div>
              </form>
            </div>
            <div className="col-md-8 col-12">
              <div className="row">
                <div className="col-12">
                  <hr className="d-md-none" />
                  <strong className="section-title">{translationService.translate("TR_VIDEO_CONFERENCES")}</strong>
                  <hr />
                </div>
                {
                  currentUserWebinars.length ?
                    currentUserWebinars.map((currentUserWebinar, index) => {
                      return <div key={currentUserWebinar.id} className="col-12 my-2">
                        <div className="card mindalay--card">
                          <div className="card-header d-sm-flex justify-content-between align-items-center">
                            {
                              (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.startTime))).format("YYYY-MM-DD HH:mm")).getTime() > new Date().getTime()) ?
                                <p className="mindalay--some-process-status text-info">{translationService.translate("TR_UPCOMING")}</p>
                                : currentUserWebinar.webinarCalendar.inProcess ?
                                  (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.endTime))).format("YYYY-MM-DD HH:mm")).getTime() > new Date().getTime()) ?
                                    webinarData && webinarData.webinarId === currentUserWebinar.webinarCalendar.webinarId ?
                                      <p className="mindalay--some-process-status text-success">{translationService.translate("TR_IN_PROCESS")}</p>
                                      : <p className="mindalay--some-process-status text-info">{translationService.translate("TR_UPCOMING")}</p>
                                    : null
                                  : null
                            }
                            {
                              currentUserWebinar.webinarCalendar.isFinished || (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.endTime))).format("YYYY-MM-DD HH:mm")).getTime() < new Date().getTime()) ?
                                <p className="mindalay--some-process-status text-danger">{translationService.translate("TR_FINISHED")}</p>
                                : null
                            }
                            {
                              (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.startTime))).format("YYYY-MM-DD HH:mm")).getTime() > new Date().getTime())
                                && !currentUserWebinar.webinarCalendar.inProcess ?
                                <div className="dropleft setting-dropdown top-5">
                                  <div className="setting-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <SettingsSvg />
                                  </div>
                                  <div className="dropdown-menu">
                                    <Link to="#" className="dropdown-item" onClick={() => this.updateWebinar(currentUserWebinar)}>
                                      {translationService.translate("TR_EDIT")}
                                    </Link>
                                    <Link to="#" className="dropdown-item" onClick={() => this.deleteWebinar(currentUserWebinar)}>
                                      {translationService.translate("TR_DELETE")}
                                    </Link>
                                  </div>
                                </div>
                                : null
                            }
                            {
                              currentUserWebinar.webinarCalendar.inProcess && webinarData && webinarData.webinarId === currentUserWebinar.webinarCalendar.webinarId &&
                                (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.endTime))).format("YYYY-MM-DD HH:mm")).getTime() > new Date().getTime())
                                ?
                                <div>
                                  <button className="mindalay--btn-small mindalay--btn-red-outline mr-sm-1 my-sm-0 my-1" type="button" onClick={() => this.finishWebinar(currentUserWebinar)}>
                                    {translationService.translate("TR_FINISH_WEBINAR")}
                                  </button>
                                  <button className="mindalay--btn-small mindalay--btn-dark ml-sm-1 my-sm-0 my-1" type="button" onClick={this.goToWebinar}>
                                    {translationService.translate("TR_START_VIDEO_CONFERENCE")}
                                  </button>
                                </div>
                                : null
                            }
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">{currentUserWebinar.name}</h5>
                            {
                              !currentUserWebinar.webinarCalendar.inProcess && (currentUserWebinar.webinarCalendar.isFinished
                                || (new Date(moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.endTime))).format("YYYY-MM-DD HH:mm")).getTime() < new Date().getTime())) ?
                                null
                                : <div className="card-text invitation-link position-relative">
                                  <p>
                                    {currentUserWebinar.ivitationLink}
                                  </p>
                                  <span
                                    className={`mindalay--paragrap-iner-item ${currentUserWebinar.isShowCopiedMessage ? "show-copy-message" : ""}`}
                                    rel={translationService.translate("TR_COPIED")}
                                    onClick={() => this.copyInvitationLink(index, currentUserWebinar.ivitationLink)}
                                  >
                                    {translationService.translate("TR_COPY")}
                                  </span>
                                </div>
                            }
                            {
                              currentUserWebinar.lecturerName ?
                                <p className="webinar-creator-name d-block my-1"><span>{`${translationService.translate("TR_CREATOR")}: `}</span><b>{currentUserWebinar.lecturerName}</b></p>
                                : null
                            }
                            <hr />
                            <div className="mindalay--card-span-group m-0">
                              <div className="webinar-start-time">
                                <fieldset>
                                  <legend className="d-block">{translationService.translate("TR_START_TIME")}</legend>
                                  <span>{moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.startTime))).format("YYYY-MM-DD HH:mm")}</span>
                                </fieldset>
                              </div>
                              <div className="webinar-end-time">
                                <fieldset>
                                  <legend className="d-block">{translationService.translate("TR_END_TIME")}</legend>
                                  {
                                    currentUserWebinar.webinarCalendar.realEndTime ?
                                      <span>{moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.realEndTime))).format("YYYY-MM-DD HH:mm")}</span>
                                      : <span>{moment(MainService.convertUTCDateToLocalDate(new Date(currentUserWebinar.webinarCalendar.endTime))).format("YYYY-MM-DD HH:mm")}</span>
                                  }
                                </fieldset>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                    :
                    <div className="no-data-container">
                      <div className="no-data-wrapper">
                        <p>{translationService.translate("TR_YOU_HAVE_NOT_CREATED_WEBINARS")}</p>
                        <img src={NoDataImage} alt="/" />
                      </div>
                    </div>
                }
                <div className="col-12">
                  {
                    pagination && pagination.HasNext ?
                      <ActionButton
                        type="button"
                        spinnerId={buttonSpinnerId}
                        className="mindalay--btn-dark w-100 mt-3"
                        name={translationService.translate("TR_SHOW_MORE")}
                        clicked={this.getNextPageWebinars}
                      />
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  hasUser: state.user.hasUser,
  user: state.user.user,
  language: state.language.language,
  pageSpinners: state.spinner.pageSpinners,
  globalConnection: state.signalR.globalConnection
})

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  removeWebinarData
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOneTimeWebinar)




import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { CONSULTANT_USER_TYPE } from "../../../../Constants/mainKeys";
import { CONSULTANT_DASHBOARD_KEY, CONSULTANT_INFORMATION_KEY, CONSULTANT_KEY } from "../../../../Constants/urlKeys";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../../Store/Actions/generalLeftMenu";

const ConsultantSubMenu = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  const isConsultant = user.userTypeId === CONSULTANT_USER_TYPE ? true : false;

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        {
          isConsultant ?
            <Auxiliary>
              <li className="side-left-submenu-item">
                <NavLink
                  to={
                    isConsultant ? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}` : "#"
                  }
                  activeClassName={
                    props.location.pathname === `/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`
                      ? "active-text" : ""}
                  onClick={() => showOrHideLeftMenu(false)}
                >
                  {translationService.translate("TR_CONTRACTS")}
                </NavLink>
              </li>
              <li className="side-left-submenu-item">
                <NavLink
                  to={
                    isConsultant ? `/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}` : "#"
                  }
                  activeClassName={
                    props.location.pathname === `/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`
                      ? "active-text" : ""}
                  onClick={() => showOrHideLeftMenu(false)}
                >
                  {translationService.translate("TR_SETTINGS")}
                </NavLink>
              </li>
            </Auxiliary>
            : null
        }
      </ul>
    </div>
  </aside> : null;
}

export default withRouter(ConsultantSubMenu);


export const USER_REGISTRATION_REQUEST = 'USER_REGISTRATION_REQUEST';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const GET_TRANSLATIONS_REQUEST = 'GET_TRANSLATIONS_REQUEST';
export const CURRENT_USER_UPDATE_REQUEST = 'CURRENT_USER_UPDATE_REQUEST';
export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_UNAUTH_COURSES_REQUEST = 'GET_UNAUTH_COURSES_REQUEST';
export const GET_COURSES_REQUEST = 'GET_COURSES_REQUEST';
export const GET_VERIFIED_COURSES_REQUEST = 'GET_VERIFIED_COURSES_REQUEST';
export const GET_UNVERIFIED_COURSES_REQUEST = 'GET_UNVERIFIED_COURSES_REQUEST';
export const DELETE_COURSE_REQUEST = 'DELETE_COURSE_REQUEST';
export const GET_CATEGORIES_BY_COURSE_REQUEST = 'GET_CATEGORIES_BY_COURSE_REQUEST';
export const REMOVE_LOGIN_ERROR_MESSAGE_REQUEST = 'REMOVE_LOGIN_ERROR_MESSAGE_REQUEST';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const GET_STUDENTCOURSE_BY_STUDENTCOURSE_ID_REQUEST = 'GET_STUDENTCOURSE_BY_STUDENTCOURSE_ID_REQUEST';
export const GET_POTENTIAL_CONSULTATIONS_COUNT_REQUEST = 'GET_POTENTIAL_CONSULTATIONS_COUNT_REQUEST';
export const GET_PAGES_FROM_ORGANIZATION_USER_REQUEST = 'GET_PAGES_FROM_ORGANIZATION_USER_REQUEST';



import { CURRENT_COURSE } from "../../Constants/mainKeys";
import { GET_CATEGORIES_BY_COURSE_SUCCESS, SET_CURRENT_COURSE } from "../../Constants/reduxKeys";

let initialState = {
  courseCategories: [],
  currentCourse: localStorage.getItem(CURRENT_COURSE) ? JSON.parse(localStorage.getItem(CURRENT_COURSE)) : null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_BY_COURSE_SUCCESS:
      return {
        ...state,
        courseCategories: action.payload?.data,
      };
    case SET_CURRENT_COURSE:
      var currentCourseWithStorage = localStorage.getItem(CURRENT_COURSE);
      currentCourseWithStorage = currentCourseWithStorage ? JSON.parse(currentCourseWithStorage) : null;
      const updatedFields = action.payload;
      var updatedCourse = Object.assign({}, currentCourseWithStorage, updatedFields);
      localStorage.setItem(CURRENT_COURSE, JSON.stringify(updatedCourse));
      return {
        ...state,
        currentCourse: updatedCourse
      };
    default:
      break;
  }
  return state;
};

export default reducer;
// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import TranslationService from "../../../Services/translationService";
// import { withRouter } from "react-router";
// import _InfoSvg from "../../../Components/Svg/_infoSvg";
// import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
// import { Card } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
// import GamifyApiService from "./../../../Services/gamifyApiService";
// import uuid from "react-uuid";
// import AlertService from "./../../../Services/alertService";
// import {
// 	ERROR_KEY,
// 	GAMIFY_COURSE_ENTITY_TYPE_ID,
// 	GAMIFY_QUIZ_ENTITY_TYPE_ID,
// 	GAMIFY_TOPIC_ENTITY_TYPE_ID,
// 	STUDENT_USER_TYPE,
// } from "./../../../Constants/mainKeys";
// import {
// 	addPartialViewSpinner,
// 	removePartialViewSpinner,
// } from "./../../../Store/Actions/spinner";
// import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
// import ApiService from "../../../Services/apiService";

// const Badge = props => {
// 	const dispatch = useDispatch();
// 	let parentEntities = [];
// 	const { history } = props;
// 	const { badgeId, studentId } = props.match.params;
// 	const { translations } = useSelector(state => state.translation);
// 	const { language } = useSelector(state => state.language);
// 	const [translationService, setTranslationService] = useState(null);
// 	const [badge, setBadge] = useState(null);
// 	const { user } = useSelector(state => state.user);

// 	useEffect(() => {
// 		getBadgeById();
// 		return () => {
// 			setBadge([]);
// 		};
// 	}, []);

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	const getBadgeById = () => {
// 		if (!badgeId) {
// 			return false;
// 		}
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		const data = {
// 			id: badgeId,
// 			badgeImageSize: 3,
// 			questImageSize: 2,
// 			resourceImageSize: 2,
// 			fragmentImageSize: 2,
// 			userId: studentId
// 				? studentId.toString()
// 				: user.studentId
// 				? user.studentId.toString()
// 				: null,
// 		};
// 		GamifyApiService.getBadgeById(data)
// 			.then(response => {
// 				if (response && response.data) {
// 					setBadge(response.data);
// 				}
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			})
// 			.catch(error => getFail(error, spinnerId));
// 	};

// 	const getParents = parent => {
// 		if (!parent) {
// 			return false;
// 		}
// 		parentEntities.push({
// 			entityTypeId: parent.entityTypeId,
// 			organizationEntityId: parent.organizationEntityId,
// 		});
// 		getParents(parent.parent);
// 	};

// 	const getEntityPath = badge => {
// 		if (!badge) {
// 			return false;
// 		}
// 		getParents(badge.parent);
// 		switch (badge.entityTypeId) {
// 			case GAMIFY_COURSE_ENTITY_TYPE_ID:
// 				return history.push(
// 					`/${language}/course/${badge.organizationEntityId}`,
// 				);

// 			case GAMIFY_TOPIC_ENTITY_TYPE_ID:
// 				if (parentEntities && parentEntities.length) {
// 					let courseId = parentEntities.find(
// 						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
// 					)?.organizationEntityId;
// 					if (courseId) {
// 						return ApiService.getPublishedCourseByIdByOrgUser(courseId)
// 							.then(response => {
// 								if (response && response.data) {
// 									let data = { ...response.data };
// 									return history.push(
// 										`/${language}/passcourse/video/${courseId}/${data.studentCourseId}/${badge.organizationEntityId}`,
// 									);
// 								}
// 							})
// 							.catch(error => getFail(error));
// 					}
// 				}

// 			case GAMIFY_QUIZ_ENTITY_TYPE_ID:
// 				if (parentEntities && parentEntities.length) {
// 					let courseId = parentEntities.find(
// 						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
// 					)?.organizationEntityId;
// 					if (courseId)
// 						return history.push(
// 							`/${language}/pure/quiz-attempt/view/${courseId}/${badge.organizationEntityId}`,
// 						);
// 				}

// 			default:
// 				break;
// 		}
// 	};

// 	const getFail = (error, spinnerId) => {
// 		error &&
// 			AlertService.alert(
// 				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 				error,
// 			);
// 		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
// 	};

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	return translationService ? (
// 		<div className="container">
// 			<div className="row mt-4">
// 				<div className="col-12">
// 					<div className="content-title p-0">
// 						<div className="d-flex align-items-center">
// 							<Link
// 								to={
// 									studentId
// 										? `/${language}/gamification/badges/${studentId}`
// 										: `/${language}/gamification/badges`
// 								}
// 								title={translationService.translate("TR_BACK")}>
// 								<ArrowBackSvg />
// 							</Link>
// 							<h2 className="title">
// 								{badge ? badge.name : translationService.translate("TR_BACK")}
// 							</h2>
// 						</div>
// 					</div>
// 					<PageInfoBlock
// 						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
// 						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
// 						translationService={translationService}
// 					/>
// 				</div>
// 			</div>
// 			<hr />
// 			{badge ? (
// 				<div className="row mt-3">
// 					<div className="col-12 col-lg-4 px-3">
// 						<div className="my-2">
// 							<Card className="w-100 border-0">
// 								<div
// 									className="w-100"
// 									style={{
// 										backgroundImage: `url(${badge.imagePath})`,
// 										height: "250px",
// 										backgroundPosition: "center",
// 										backgroundSize: "contain",
// 										backgroundRepeat: "no-repeat",
// 									}}
// 								/>
// 								<Card.Body>
// 									<h4 className="text-center mb-2">{badge.name}</h4>
// 									<p className="word-break-break-word text-center">
// 										{badge.description}
// 									</p>
// 									{user && user.userTypeId === STUDENT_USER_TYPE ? (
// 										<button
// 											type="button"
// 											className="mindalay--btn-link mindalay--btn-dark mt-3 w-100"
// 											onClick={() => getEntityPath(badge)}>
// 											View entity
// 										</button>
// 									) : null}
// 								</Card.Body>
// 							</Card>
// 						</div>
// 					</div>

// 					<div className="col-12 col-lg-8 px-3">
// 						{badge.badgeResources && badge.badgeResources.length ? (
// 							<div className="row align-self-stretch  mt-3">
// 								<div className="col-12">
// 									{" "}
// 									<h2 className="section-title">Badge Resources</h2>
// 								</div>
// 								{badge.badgeResources.map((item, index) => {
// 									return (
// 										<div key={index} className="col-12 col-md-4 p-2">
// 											<div className="border rounded h-100">
// 												<Card className="w-100 border-none">
// 													<div
// 														className="w-100"
// 														style={{
// 															backgroundImage: `url(${item.resource?.imagePath})`,
// 															height: "100px",
// 															backgroundPosition: "center",
// 															backgroundSize: "contain",
// 															backgroundRepeat: "no-repeat",
// 														}}
// 													/>
// 													<Card.Body className="p-2">
// 														<h5 className="text-center">
// 															{item.resource?.name}
// 														</h5>
// 														<hr />
// 														<p className="d-flex justify-content-between mt-2">
// 															<b>
// 																{translationService.translate("TR_AMOUNT")}{" "}
// 															</b>
// 															<b>{item.resourceAmount}</b>
// 														</p>
// 													</Card.Body>
// 												</Card>
// 											</div>
// 										</div>
// 									);
// 								})}
// 							</div>
// 						) : null}
// 						{badge.badgeFragments && badge.badgeFragments.length ? (
// 							<div className="row align-self-stretch  mt-3">
// 								<div className="col-12">
// 									{" "}
// 									<h2 className="section-title">Badge Fragments</h2>
// 								</div>
// 								{badge.badgeFragments.map((item, index) => {
// 									return (
// 										<div key={index} className="col-12 col-md-4 p-2">
// 											<div className="border rounded h-100">
// 												<Card className="w-100 border-none">
// 													<div
// 														className="w-100"
// 														style={{
// 															backgroundImage: `url(${item.fragment?.imagePath})`,
// 															height: "100px",
// 															backgroundPosition: "center",
// 															backgroundSize: "contain",
// 															backgroundRepeat: "no-repeat",
// 														}}
// 													/>
// 													<Card.Body className="p-2">
// 														<h5 className="text-center">
// 															{item.fragment?.name}
// 														</h5>
// 													</Card.Body>
// 												</Card>
// 											</div>
// 										</div>
// 									);
// 								})}
// 							</div>
// 						) : null}
// 						{badge.badgeQuests && badge.badgeQuests.length ? (
// 							<div className="row align-self-stretch  mt-3">
// 								<div className="col-12">
// 									{" "}
// 									<h2 className="section-title">Badge Quests</h2>
// 								</div>
// 								{badge.badgeQuests.map((item, index) => {
// 									return (
// 										<div key={index} className="col-12 col-md-4 p-2">
// 											<div className="border rounded h-100">
// 												<Card className="w-100 border-none">
// 													<div
// 														className="w-100"
// 														style={{
// 															backgroundImage: `url(${item.quest?.imagePath})`,
// 															height: "100px",
// 															backgroundPosition: "center",
// 															backgroundSize: "contain",
// 															backgroundRepeat: "no-repeat",
// 														}}
// 													/>
// 													<Card.Body className="p-2">
// 														<h5 className="text-center">{item.quest?.name}</h5>
// 														<hr />
// 														<p className="d-flex justify-content-between align-items-center">
// 															<b>Is Required</b>
// 															{item.isRequired ? (
// 																<i
// 																	className="fa-regular fa-circle-check text-success"
// 																	style={{ fontSize: "20px" }}></i>
// 															) : (
// 																<i
// 																	className="fa-regular fa-circle-xmark text-danger"
// 																	style={{ fontSize: "20px" }}></i>
// 															)}
// 														</p>
// 														<p className="d-flex justify-content-between my-2">
// 															<b>Received</b>
// 															{item?.quest.isQuestCompleted ? (
// 																<i
// 																	className="fa-regular fa-circle-check text-success"
// 																	style={{ fontSize: "20px" }}></i>
// 															) : (
// 																<i
// 																	className="fa-regular fa-circle-xmark text-danger"
// 																	style={{ fontSize: "20px" }}></i>
// 															)}
// 														</p>
// 														<Link
// 															className="mindalay--btn-link mindalay--btn-dark mt-3 w-100"
// 															to={`/${language}/gamification/quest/${item.quest?.id}`}>
// 															{translationService.translate("TR_SHOW_MORE")}
// 														</Link>
// 													</Card.Body>
// 												</Card>
// 											</div>
// 										</div>
// 									);
// 								})}
// 							</div>
// 						) : null}
// 					</div>
// 				</div>
// 			) : (
// 				<div className="no-data-container">
// 					<div className="no-data-wrapper">
// 						<p>{translationService.translate("TR_NO_DATA")}</p>
// 						<img src={NoDataImage} alt="/" />
// 					</div>
// 				</div>
// 			)}
// 		</div>
// 	) : null;
// };

// export default withRouter(Badge);
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "./../../../Services/gamifyApiService";
import uuid from "react-uuid";
import AlertService from "./../../../Services/alertService";
import {
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	GAMIFY_QUIZ_ENTITY_TYPE_ID,
	GAMIFY_TOPIC_ENTITY_TYPE_ID,
	STUDENT_USER_TYPE,
} from "./../../../Constants/mainKeys";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "./../../../Store/Actions/spinner";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import ApiService from "../../../Services/apiService";

let parentEntities = [];
const Badge = props => {
	const dispatch = useDispatch();
	const { history } = props;
	const { badgeId, studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [badge, setBadge] = useState(null);
	const { user } = useSelector(state => state.user);

	useEffect(() => {
		getBadgeById();
		return () => {
			setBadge([]);
		};
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getBadgeById = () => {
		if (!badgeId) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			id: badgeId,
			badgeImageSize: 3,
			questImageSize: 2,
			resourceImageSize: 2,
			fragmentImageSize: 2,
			userId: studentId
				? studentId.toString()
				: user.studentId
				? user.studentId.toString()
				: null,
		};
		GamifyApiService.getBadgeById(data)
			.then(response => {
				if (response && response.data) {
					setBadge(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getParents = parent => {
		if (!parent) {
			return false;
		}
		parentEntities.push({
			entityTypeId: parent.entityTypeId,
			organizationEntityId: parent.organizationEntityId,
		});
		getParents(parent.parent);
	};

	const getEntityPath = badge => {
		if (!badge) {
			return false;
		}
		getParents(badge.parent || badge.parentEntity);
		switch (badge.entityTypeId) {
			case GAMIFY_COURSE_ENTITY_TYPE_ID:
				return history.push(
					`/${language}/course/${badge.organizationEntityId}`,
				);

			case GAMIFY_TOPIC_ENTITY_TYPE_ID:
				if (parentEntities && parentEntities.length) {
					let courseId = parentEntities.find(
						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
					)?.organizationEntityId;
					if (courseId) {
						return ApiService.getPublishedCourseByIdByOrgUser(courseId)
							.then(response => {
								if (response && response.data) {
									let data = { ...response.data };
									return props.history.push(
										`/${language}/passcourse/video/${courseId}/${data.studentCourseId}/${badge.organizationEntityId}`,
									);
								}
							})
							.catch(error => getFail(error));
					}
				}

			case GAMIFY_QUIZ_ENTITY_TYPE_ID:
				if (parentEntities && parentEntities.length) {
					let courseId = parentEntities.find(
						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
					)?.organizationEntityId;
					if (courseId)
						return history.push(
							`/${language}/pure/quiz-attempt/view/${courseId}/${badge.organizationEntityId}`,
						);
				}

			default:
				break;
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={
									studentId
										? `/${language}/gamification/badges/${studentId}`
										: `/${language}/gamification/badges`
								}
								title={translationService.translate("TR_BACK")}>
								<ArrowBackSvg />
							</Link>
							<h2 className="title">
								{badge ? badge.name : translationService.translate("TR_BACK")}
							</h2>
						</div>
					</div>
					<PageInfoBlock
						pageTitle="Badge"
						pageName="student_badge_view"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{badge ? (
				<div className="row mt-3">
					<div className="col-12 col-lg-4 px-3">
						<div className="my-2">
							<Card className="w-100 border-0">
								<div
									className="w-100"
									style={{
										backgroundImage: `url(${badge.imagePath})`,
										height: "250px",
										backgroundPosition: "center",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								/>
								<Card.Body>
									<h4 className="text-center mb-2">{badge.name}</h4>
									<p className="word-break-break-word text-center">
										{badge.description}
									</p>
									{user && user.userTypeId === STUDENT_USER_TYPE ? (
										<button
											type="button"
											className="mindalay--btn-link mindalay--btn-dark mt-3 w-100"
											onClick={() => getEntityPath(badge)}>
											{translationService.translate("TR_VIEW_ENTITY")}
										</button>
									) : null}
								</Card.Body>
							</Card>
						</div>
					</div>

					<div className="col-12 col-lg-8 px-3">
						{badge.badgeResources && badge.badgeResources.length ? (
							<div className="row align-self-stretch  mt-3">
								<div className="col-12">
									{" "}
									<h2 className="section-title">
										{translationService.translate("TR_BADGE_RESOURCES")}
									</h2>
								</div>
								{badge.badgeResources.map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-4 p-2">
											<div className="border rounded h-100">
												<Card className="w-100 border-none">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.resource?.imagePath})`,
															height: "100px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h5 className="text-center">
															{item.resource?.name}
														</h5>
														<hr />
														<p className="d-flex justify-content-between mt-2">
															<b>
																{translationService.translate("TR_AMOUNT")}{" "}
															</b>
															<b>{item.resourceAmount}</b>
														</p>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								})}
							</div>
						) : null}
						{badge.badgeFragments && badge.badgeFragments.length ? (
							<div className="row align-self-stretch  mt-3">
								<div className="col-12">
									{" "}
									<h2 className="section-title">
										{translationService.translate("TR_BADGE_FRAGMENTS")}
									</h2>
								</div>
								{badge.badgeFragments.map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-4 p-2">
											<div className="border rounded h-100">
												<Card className="w-100 border-none">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.fragment?.imagePath})`,
															height: "100px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h5 className="text-center">
															{item.fragment?.name}
														</h5>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								})}
							</div>
						) : null}
						{badge.badgeQuests && badge.badgeQuests.length ? (
							<div className="row align-self-stretch  mt-3">
								<div className="col-12">
									{" "}
									<h2 className="section-title">
										{translationService.translate("TR_BADGE_QUESTS")}
									</h2>
								</div>
								{badge.badgeQuests.map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-4 p-2">
											<div className="border rounded h-100">
												<Card className="w-100 border-none">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.quest?.imagePath})`,
															height: "100px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h5 className="text-center">{item.quest?.name}</h5>
														<hr />
														<p className="d-flex justify-content-between align-items-center">
															<b>
																{translationService.translate("TR_IS_REQUIRED")}
															</b>
															{item.isRequired ? (
																<i
																	className="fa-regular fa-circle-check text-success"
																	style={{ fontSize: "20px" }}></i>
															) : (
																<i
																	className="fa-regular fa-circle-xmark text-danger"
																	style={{ fontSize: "20px" }}></i>
															)}
														</p>
														<p className="d-flex justify-content-between my-2">
															<b>
																{translationService.translate("TR_RECEIVED")}
															</b>
															{item?.quest.isQuestCompleted ? (
																<i
																	className="fa-regular fa-circle-check text-success"
																	style={{ fontSize: "20px" }}></i>
															) : (
																<i
																	className="fa-regular fa-circle-xmark text-danger"
																	style={{ fontSize: "20px" }}></i>
															)}
														</p>
														<Link
															className="mindalay--btn-link mindalay--btn-dark mt-3 w-100"
															to={`/${language}/gamification/quest/${item.quest?.id}`}>
															{translationService.translate("TR_SHOW_MORE")}
														</Link>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								})}
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Badge);

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import Input from "../Components/Inputs/input";
import ModalComponentWithoutActionButton from "../Components/ModalComponent/modalComponentWithoutActionButton";

function Languages(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [languages, setLanguages] = useState([]);
	const [values, setValues] = useState({
		name: "",
		isocode: "",
		isocode2: "",
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [defaultlanguages, setDefaultlanguages] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getLanguages(null);
	}, []);

	const getLanguages = orgId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getLanguages()
			.then(response => {
				if (response && response.data) {
					setLanguages(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteLanguage = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteLanguage(item.id)
				.then(() => {
					setLanguages(languages.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const onClose = (close = true) => {
		setValues({
			name: "",
			defaultUserTypeIds: [],
		});
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
	};

	const getModalData = item => {
		setOpenModal(true);
		if (item) setValues(item);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (
			!values.name.trim().length ||
			!values.isocode.trim.length ||
			!values.isocode2.trim().length
		) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.languageId
			? ApiService.createLanguage(values)
			: ApiService.updateLanguage(values)
		)
			.then(response => {
				if (response.data) {
					setLanguages([...languages, response.data]);
				} else if (values.languageId) {
					setLanguages(
						languages.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									name: values.name,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	const onModalCheckBoxChange = (event, id) => {
		setIsChange(true);
		if (!event.target.checked) {
			const selectedValue = values.defaultUserTypeIds.filter(el => el !== id);
			setValues(values => ({ ...values, defaultUserTypeIds: selectedValue }));
		} else {
			setValues(values => ({
				...values,
				defaultUserTypeIds: [...values.defaultUserTypeIds, id],
			}));
		}
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<Input
								type="text"
								id="name"
								name="name"
								inputClassName="pr--5"
								value={values.name}
								fieldLength={40}
								isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue="Name*"
								onChange={event => onChange(event, "name", 40)}
							/>
						</div>
						<div className="col-12">
							<Input
								type="text"
								id="isocode"
								name="isocode"
								inputClassName="pr--5"
								value={values.isocode}
								fieldLength={3}
								isInvalidSubmit={
									isInvalidSubmit && !values.isocode.trim().length
								}
								labelValue="Name*"
								onChange={event => onChange(event, "isocode", 3)}
							/>
						</div>
						<div className="col-12">
							<Input
								type="text"
								id="isocode2"
								name="isocode2"
								inputClassName="pr--5"
								value={values.isocode2}
								fieldLength={2}
								isInvalidSubmit={
									isInvalidSubmit && !values.isocode2.trim().length
								}
								labelValue="Name*"
								onChange={event => onChange(event, "isocode2", 2)}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_LANGUAGES")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default"
						onClick={() => {
							getModalData();
						}}>
						Create language
					</button>
				</div>
			</div>
			{languages && languages.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ISO_CODE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ISO_CODE_TWO")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{languages.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.isocode}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.isocode2}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	getModalData(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															{/* <Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deleteLanguage(item);
																}}>
																<DeleteSvg />
															</Link> */}
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(Languages);

import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import ApiService from '../../Services/apiService';
import { CURRENCY_KEY, DEFAULT_CURRENCY_KEY, CURRENCY_SYMBOL_KEY, DEFAULT_CURRENCY_SYMBOL_KEY, ERROR_KEY, MENU_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY } from '../../Constants/mainKeys';
import { useSelector } from 'react-redux';
import AlertService from '../../Services/alertService';
import { useDispatch } from 'react-redux';
import { userUpdateSuccess } from '../../Store/Actions/user';

const AuthenticationByRefreshToken = (props) => {

  const { language } = useSelector(state => state.language);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.refreshToken) {
      authenticateUser(props.match.params.refreshToken)
    }
  }, [])

  const authenticateUser = (refreshToken) => {
    const formData = new FormData();
    formData.append(`refreshToken`, refreshToken);
    ApiService.getUserDataByRefreshToken(formData).then(response => {
      if (response.data && response.data.accessToken) {
        const data = { ...response.data };
        localStorage.setItem(TOKEN_KEY, data?.accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
        getUserData();
      } else {
        logout();
      }
    }).catch(logout);
  }

  const getUserData = () => {
    ApiService.currentUser().then(response => {
      if (response && response.data) {
        const user = { ...response.data }
        dispatch(userUpdateSuccess(user));
        if (user.isoCode) {
          localStorage.setItem(CURRENCY_KEY, user.isoCode);
        } else {
          localStorage.setItem(CURRENCY_KEY, DEFAULT_CURRENCY_KEY);
        }
        if (user.currencySymbol) {
          localStorage.setItem(CURRENCY_SYMBOL_KEY, user.currencySymbol);
        } else {
          localStorage.setItem(CURRENCY_SYMBOL_KEY, DEFAULT_CURRENCY_SYMBOL_KEY);
        }

        if (user && user.isOrganizationUser) {
          if (!user.defaultUserTypeIds) {
            ApiService.getPagesFromOrgUser().then(res => {
              if (res && res.data) {
                localStorage.setItem(MENU_KEY, JSON.stringify(res.data));
              }
            }).catch(error => getFail(error))
          }
        }
      }
      props.history.push(`/${language}`)
    }).catch(error => getFail(error))
  }

  const logout = () => {
    props.history.push(`/${language}`)
    localStorage.clear();
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    props.history.push(`/${language}`)
  }

  return <></>
}
export default withRouter(AuthenticationByRefreshToken);

import React from "react";
import { withRouter } from "react-router";
import { PASS_COURSE_VIDEO_KEY } from "../../Constants/urlKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import PassCourseRoutes from "../../Routing/passCourseRoutes";
import PassCourseHeader from "../LayoutSections/Header/passCourseHeader";

const PassCourseLayout = props => {
	return (
		<Auxiliary>
			{props.location.pathname.includes(PASS_COURSE_VIDEO_KEY) &&
			!props.location.pathname.includes("/assignment") ? null : (
				<PassCourseHeader />
			)}
			<div className="wrapper">
				{props.children}
				<PassCourseRoutes />
			</div>
		</Auxiliary>
	);
};

export default withRouter(PassCourseLayout);

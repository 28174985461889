import { SET_CURRENT_COURSE } from "../../Constants/reduxKeys";
import { GET_CATEGORIES_BY_COURSE_REQUEST } from "../../Constants/reduxRequestKeys";

export function getCategoriesByType(type) {
  return {
    type: GET_CATEGORIES_BY_COURSE_REQUEST,
    payload: type,
  };
};

export function setCurrentCourse(course) {
  return {
    type: SET_CURRENT_COURSE,
    payload: course,
  };
};

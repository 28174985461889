import {
  ADD_PAGE_SPINNER,
  REMOVE_PAGE_SPINNER,
  ADD_BUTTON_SPINNER,
  REMOVE_BUTTON_SPINNER,
  ADD_PARTIAL_VIEW_SPINNER,
  REMOVE_PARTIAL_VIEW_SPINNER,
  ADD_MODAL_SPINNER,
  REMOVE_MODAL_SPINNER,
  ADD_TEXT_SPINNER,
  REMOVE_TEXT_SPINNER,
  ADD_TOPIC_SPINNER,
  REMOVE_TOPIC_SPINNER
} from "../../Constants/reduxKeys";

const initialState = {
  pageSpinners: [],
  buttonSpinners: [],
  textSpinners: [],
  patrialViewSpinners: [],
  modalSpinners: [],
  topicSpinners: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAGE_SPINNER:
      return {
        ...state,
        pageSpinners: [...state.pageSpinners, action.payload]
      };
    case REMOVE_PAGE_SPINNER:
      return {
        ...state,
        pageSpinners: state.pageSpinners.filter(data => data !== action.payload)
      };
    case ADD_TEXT_SPINNER:
      return {
        ...state,
        textSpinners: [...state.textSpinners, action.payload],
      };
    case REMOVE_TEXT_SPINNER:
      return {
        ...state,
        textSpinners: state.textSpinners.filter(data => data !== action.payload)
      };
    case ADD_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: [...state.buttonSpinners, action.payload],
      };
    case REMOVE_BUTTON_SPINNER:
      return {
        ...state,
        buttonSpinners: state.buttonSpinners.filter(data => data !== action.payload)
      };
    case ADD_PARTIAL_VIEW_SPINNER:
      return {
        ...state,
        patrialViewSpinners: [...state.patrialViewSpinners, action.payload],
      };
    case REMOVE_PARTIAL_VIEW_SPINNER:
      return {
        ...state,
        patrialViewSpinners: state.patrialViewSpinners.filter(data => data !== action.payload)
      };
    case ADD_MODAL_SPINNER:
      return {
        ...state,
        modalSpinners: [...state.modalSpinners, action.payload],
      };
    case REMOVE_MODAL_SPINNER:
      return {
        ...state,
        modalSpinners: state.modalSpinners.filter(data => data !== action.payload)
      };
    case ADD_TOPIC_SPINNER:
      return {
        ...state,
        topicSpinners: [...state.topicSpinners, action.payload],
      };
    case REMOVE_TOPIC_SPINNER:
      return {
        ...state,
        topicSpinners: state.topicSpinners.filter(data => data !== action.payload)
      };
    default:
      break;
  }
  return state;
};

export default reducer;
import { combineReducers } from "redux";
import translation from "./translation";
import user from "./user";
import language from "./language";
import spinner from "./spinner";
import course from "./course";
import signalR from "./signalR";
import main from "./main";
import wishListOrShoppingCart from "./wishListOrShoppingCart";
import search from "./search";
import consultation from "./consultation";
import generalLeftMenu from "./generalLeftMenu";
import help from "./help";
import settings from "./settings";

export default combineReducers({
	translation,
	user,
	language,
	spinner,
	course,
	signalR,
	main,
	wishListOrShoppingCart,
	search,
	consultation,
	generalLeftMenu,
	help,
	settings,
});

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import QuizView from "./Components/quizView";
import AlertService from "../../Services/alertService";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { PASS_COURSE_KEY, VIDEO_KEY } from "../../Constants/urlKeys";
import { ERROR_KEY, NUMBER_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import Fiels from "./Components/files";
import Sections from "./Components/sections";
import TranslationService from "../../Services/translationService";
import { Helmet } from "react-helmet";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import PosterImage from "../../assets/images/video-poster.png";
import CloseSvg from "./../../Components/Svg/closeSvg";
import FullScreen from "../../Components/Svg/fullScreenSvg";
import MinimizeSvg from "./../../Components/Svg/minimizeSvg";
import LeftArrowSvg from "./../../Components/Svg/leftArrowSvg";
import RightArrowSvg from "./../../Components/Svg/rightArrowSvg";
import Parser from "html-react-parser";
import ReactPlayer from "react-player";
import AssignmentsView from "./Components/assignmentsView";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class PassCourseVideoView extends Component {
	constructor(props) {
		super(props);
		this.player = React.createRef();
		this.state = {
			translationService: null,
			courseId: +this.props.match.params.courseId || null,
			studentCourseId: +this.props.match.params.studentCourseId || null,
			topicId: +this.props.match.params.topicId || null,
			currentSectionId: null,
			showMobileBurgerMenu: false,
			videoCurrentstamp: 0,
			key: "topic-quizzes",
			completionPercentage: null,
			ongoingVideoId: null,
			zoomImagePath: null,
			isFullScreen: false,
			isShowVideo: false,
			isShowImage: false,
			isShowDescription: false,
			isDisabledButtons: false,
			videoProgress: 0,
			vidoe_link: null,
			topicAssignments: [],
		};
		this.child = React.createRef();
	}

	componentDidMount() {
		this.setTranslations();
		this.getQuizzesAssignment();
		this.getStudentCourseAndTopicData();
		window.addEventListener("beforeunload", () => {
			this.onUnload();
		});
		window.addEventListener("keydown", this.zoomOut);
	}

	componentWillUnmount() {
		this.onUnload(this.state.vidoe_link ? true : false);
		window.removeEventListener("beforeunload", () => {
			this.onUnload();
		});
		window.removeEventListener("keydown", this.zoomOut);
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	onUnload = async (isYouTubeLink = false) => {
		let video = document.getElementById("video");
		const { studentCourseId, topicId, videoProgress, topicData } = this.state;
		if (video || videoProgress) {
			await ApiService.updateVideoTimestump(
				studentCourseId,
				topicId,
				topicData?.videoLink
					? Math.floor(videoProgress)
					: Math.floor(video.currentTime),
			);
		}
	};

	getVideoCurrentTime = () => {
		const { ongoingVideoId, topicData } = this.state;
		let video = document.getElementById("video");
		if (
			video &&
			topicData &&
			ongoingVideoId &&
			topicData.id === ongoingVideoId
		) {
			video.pause();
			this.setState({ videoCurrentstamp: Math.floor(video.currentTime) });
		}
	};
	getQuizzesAssignment = (id = null) => {
		const spinnerId = uuid();
		const { topicId } = this.state;
		if (!topicId) return null;
		const { user } = this.props;
		if (user && user.isOrganizationUser) {
			this.props.addPageSpinner(spinnerId);
			ApiService.getQuizzesAssignment(id || topicId)
				.then(response => {
					if (response.data) {
					}
					response.data && this.setState({ topicAssignments: response.data });
					this.props.removePageSpinner(spinnerId);
				})
				.catch(error => this.getFail(error, spinnerId));
		} else {
			return;
		}
	};

	getStudentCourseAndTopicData = async () => {
		const spinnerId = uuid();
		const { studentCourseId, topicId } = this.state;
		const { user } = this.props;
		this.props.addPageSpinner(spinnerId);
		this.setState({ topicData: {}, isDisabledButtons: true });
		(user && user.isOrganizationUser
			? ApiService.getStudentCourseAndTopicDataByOrgStudent(
					+studentCourseId,
					+topicId,
			  )
			: ApiService.getStudentCourseAndTopicData(+studentCourseId, +topicId)
		)
			.then(response => {
				if (response.data) {
					const data = JSON.parse(JSON.stringify(response.data));
					data &&
						this.setState({
							topicData: data.studentTopic,
							studentCourse: data.studentCourse,
							topicQuizData: data.quizResponses,
							topicFileData: data.fileResponses,
							currentSectionId: data.studentTopic.courseSectionId,
							videoCurrentstamp: data.studentTopic.videoTimestamp,
							completionPercentage: data.studentCourse.completionPercentage,
							vidoeLink:
								"https://www.youtube.com/watch?v=YhDN5ef2fr0&list=RDYhDN5ef2fr0&start_radio=1",

							key:
								data.quizResponses && data.quizResponses.length
									? "topic-quizzes"
									: "topic-assignments",
						});
					if (data.studentTopic) {
						if (data.studentTopic.presentationFilePath) {
							this.setState({
								isShowVideo: true,
								isShowImage: false,
								isShowDescription: false,
							});
						}
						if (
							data.studentTopic.imagePath &&
							!data.studentTopic.presentationFilePath
						) {
							this.setState({
								isShowVideo: false,
								isShowImage: true,
								isShowDescription: false,
							});
						}
						if (
							data.studentTopic.description &&
							!data.studentTopic.presentationFilePath &&
							!data.studentTopic.imagePath
						) {
							this.setState({
								isShowVideo: false,
								isShowImage: false,
								isShowDescription: true,
							});
						}
						if (
							(!data.quizResponses ||
								(data.quizResponses && !data.quizResponses.length)) &&
							data.fileResponses &&
							data.fileResponses.length
						) {
							this.setState({ key: "topic-files" });
						}
					}
				}
				this.setState({ isDisabledButtons: false });
				this.props.removePageSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	changeTopicData = async data => {
		if (!data) {
			await this.getStudentCourseAndTopicData();
			return false;
		} else {
			this.setState(
				{
					topicData: {},
					videoCurrentstamp: null,
				},
				() => {
					this.setState(
						{
							topicId: data.studentTopic.id,
							topicData: data.studentTopic,
							topicQuizData: data.quizResponses,
							topicFileData: data.fileResponses,
							videoCurrentstamp: data.studentTopic.videoTimestamp,
						},
						() => {
							this.getStudentCourseAndTopicData(); // last change
						},
					);
				},
			);
		}
	};

	videoRewind = element => {
		if (!element) {
			return false;
		}
		const { videoCurrentstamp } = this.state;
		if (videoCurrentstamp >= 0) element.currentTime = videoCurrentstamp;
	};

	toggleMobileBurgerMenu = show => {
		this.setState({ showMobileBurgerMenu: show });
	};
	handlePlayerReady = () => {
		if (this.player.current) {
			this.player.current.seekTo(
				this.state.videoCurrentstamp ? this.state.videoCurrentstamp : 0,
			);
		}
	};

	getNextOrPreviouseTopicData = async (studentCourse, topicData, event) => {
		const { language } = this.props;
		const allSections = studentCourse?.course?.sections;
		const currentSection = allSections.find(
			section => topicData.courseSectionId === section.id,
		);
		const nextSection = allSections.find(
			section => currentSection.displayOrder + 1 === section.displayOrder,
		);
		const prevSection = allSections.find(
			section => currentSection.displayOrder - 1 === section.displayOrder,
		);
		const nextTopicWithCurrentSection = currentSection
			? currentSection?.topics?.find(
					topic => topicData.displayOrder + 1 === topic.displayOrder,
			  )
			: null;
		const prevTopicWithCurrentSection = currentSection
			? currentSection?.topics?.find(
					topic => topicData.displayOrder - 1 === topic.displayOrder,
			  )
			: null;
		if (
			event === "next" &&
			nextTopicWithCurrentSection &&
			nextTopicWithCurrentSection.isVisible
		) {
			await this.onUnload();
			this.changeCurrentTopic(nextTopicWithCurrentSection);
			this.props.history.push(
				`/${language}/${PASS_COURSE_KEY}/${VIDEO_KEY}/${this.state.courseId}/${this.state.studentCourseId}/${nextTopicWithCurrentSection.id}`,
			);
		}
		// last change
		// if (event === "next" && nextTopicWithCurrentSection && !nextTopicWithCurrentSection.isVisible) {
		//   await this.onUnload();
		//   this.getStudentCourseAndTopicData();
		// }
		if (
			event === "next" &&
			nextTopicWithCurrentSection &&
			!nextTopicWithCurrentSection.isVisible
		) {
			await this.onUnload();
			this.changeCurrentTopic(nextTopicWithCurrentSection);
		}
		//
		if (
			event === "next" &&
			!nextTopicWithCurrentSection &&
			nextSection &&
			nextSection.topics &&
			nextSection.topics[0]?.isVisible
		) {
			await this.onUnload();
			this.changeCurrentTopic(nextSection.topics[0]);
			this.child.current.openSectionWithProps(nextSection.id);
			this.props.history.push(
				`/${language}/${PASS_COURSE_KEY}/${VIDEO_KEY}/${this.state.courseId}/${this.state.studentCourseId}/${nextSection.topics[0].id}`,
			);
		}
		if (event === "next" && !nextTopicWithCurrentSection && !nextSection) {
			await this.onUnload();
			this.getStudentCourseAndTopicData();
		}
		if (
			event === "previouse" &&
			prevTopicWithCurrentSection &&
			prevTopicWithCurrentSection.isVisible
		) {
			await this.onUnload();
			this.changeCurrentTopic(prevTopicWithCurrentSection);
			this.props.history.push(
				`/${language}/${PASS_COURSE_KEY}/${VIDEO_KEY}/${this.state.courseId}/${this.state.studentCourseId}/${prevTopicWithCurrentSection.id}`,
			);
		}
		if (
			event === "previouse" &&
			!prevTopicWithCurrentSection &&
			prevSection &&
			prevSection.topics &&
			prevSection.topics[prevSection.topics.length - 1].isVisible
		) {
			await this.onUnload();
			this.changeCurrentTopic(
				prevSection.topics[prevSection.topics.length - 1],
			);
			this.child.current.openSectionWithProps(prevSection.id);
			this.props.history.push(
				`/${language}/${PASS_COURSE_KEY}/${VIDEO_KEY}/${this.state.courseId}/${
					this.state.studentCourseId
				}/${prevSection.topics[prevSection.topics.length - 1].id}`,
			);
		}

		this.setState({ ongoingVideoId: null });
	};

	setFullScreen = topicData => {
		if (!topicData) {
			return false;
		}
		this.getVideoCurrentTime();
		if (topicData.presentationFilePath) {
			this.setState({
				isShowVideo: true,
				isShowImage: false,
				isShowDescription: false,
				isFullScreen: true,
			});
		}
		if (topicData.imagePath && !topicData.presentationFilePath) {
			this.setState({
				isShowVideo: false,
				isShowImage: true,
				isShowDescription: false,
				isFullScreen: true,
			});
		}
		if (
			topicData.description &&
			!topicData.presentationFilePath &&
			!topicData.imagePath
		) {
			this.setState({
				isShowVideo: false,
				isShowImage: false,
				isShowDescription: true,
				isFullScreen: true,
			});
		}
	};

	changeCurrentTopic = topic => {
		const { language } = this.props;
		this.setState({ topicId: topic.id }, () => {
			this.getStudentCourseAndTopicData();
			this.props.history.push(
				`/${language}/${PASS_COURSE_KEY}/${VIDEO_KEY}/${this.state.courseId}/${this.state.studentCourseId}/${topic.id}`,
			);
		});
	};

	zoomOut = event => {
		if (event.key === "Escape") {
			this.unZoomImage();
			this.closeFullScreen();
		}
	};

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	unZoomImage = () => {
		this.setState({ zoomImagePath: null });
	};

	closeFullScreen = () => {
		this.getVideoCurrentTime();
		this.setState(
			{
				isFullScreen: false,
				isShowDescription: false,
				isShowImage: false,
				isShowVideo: false,
			},
			() => {
				let video = document.getElementById("video");
				if (video) {
					video.pause();
				}
			},
		);
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
		this.setState({ isDisabledButtons: false });
		window.history.back();
	};

	render() {
		const {
			translationService,
			courseId,
			topicData,
			studentCourse,
			studentCourseId,
			showMobileBurgerMenu,
			topicQuizData,
			topicAssignments,
			topicFileData,
			currentSectionId,
			key,
			completionPercentage,
			ongoingVideoId,
			zoomImagePath,
			isFullScreen,
			isShowVideo,
			isShowImage,
			isShowDescription,
			isDisabledButtons,
			topicId,
			vidoe_link,
		} = this.state;
		const { user } = this.props;

		var disabledPreviouseButton = true;
		var disabledNextTopicButton = true;

		const firstTopic =
			studentCourse?.course?.sections[0]?.topics[0]?.id === this.state.topicId;
		const lastTopic =
			studentCourse?.course?.sections[
				studentCourse?.course?.sections.length - 1
			]?.topics[
				studentCourse?.course?.sections[
					studentCourse?.course?.sections.length - 1
				].topics.length - 1
			]?.id === this.state.topicId;
		const allSections = studentCourse?.course?.sections;
		const currentSection =
			allSections &&
			allSections.find(section => topicData.courseSectionId === section.id);
		const nextSection =
			allSections &&
			currentSection &&
			allSections.find(
				section => currentSection.displayOrder + 1 === section?.displayOrder,
			);
		const prevSection =
			allSections &&
			currentSection &&
			allSections.find(
				section => currentSection.displayOrder - 1 === section?.displayOrder,
			);
		const nextTopicWithCurrentSection = currentSection
			? currentSection?.topics?.find(
					topic => topicData.displayOrder + 1 === topic?.displayOrder,
			  )
			: null;
		const prevTopicWithCurrentSection = currentSection
			? currentSection?.topics?.find(
					topic => topicData.displayOrder - 1 === topic?.displayOrder,
			  )
			: null;

		if (
			firstTopic ||
			(prevTopicWithCurrentSection &&
				!prevTopicWithCurrentSection?.isVisible) ||
			(!prevTopicWithCurrentSection &&
				prevSection &&
				prevSection.topics &&
				!prevSection.topics[prevSection.topics.length - 1]?.isVisible)
		)
			disabledPreviouseButton = false;

		if (
			lastTopic ||
			(nextTopicWithCurrentSection &&
				!nextTopicWithCurrentSection?.isVisible) ||
			(!nextTopicWithCurrentSection &&
				nextSection &&
				nextSection.topics &&
				!nextSection.topics[0]?.isVisible)
		)
			disabledNextTopicButton = false;

		return translationService ? (
			studentCourse ? (
				<section>
					{
						<Helmet>
							<title>{`${studentCourse.course.name} | Mindalay`}</title>
						</Helmet>
					}
					{zoomImagePath ? (
						<div className="zoom-image-container" onClick={this.unZoomImage}>
							<div className="close-svg">
								<CloseSvg />
							</div>
							<img
								src={zoomImagePath}
								alt="/"
								onClick={event => event.stopPropagation()}
							/>
						</div>
					) : null}
					{isFullScreen ? (
						<div className="full-screen-container">
							<div className="zoom-image-container">
								<div className="close-svg" onClick={this.closeFullScreen}>
									<MinimizeSvg />
								</div>
								<div className="full-screen-container-item">
									{/* left-arrow */}
									<div
										className={`arrow-block left-arrow-block ${
											!disabledPreviouseButton ? "disabled" : ""
										}`}
										style={{ opacity: !disabledPreviouseButton ? "0.1" : "1" }}
										onClick={() => {
											if (!disabledPreviouseButton) {
												return false;
											} else {
												this.getNextOrPreviouseTopicData(
													studentCourse,
													topicData,
													"previouse",
												);
											}
										}}>
										<LeftArrowSvg
											cursor={!disabledPreviouseButton ? "default" : "pointer"}
										/>
									</div>

									<div className="image-or-video-block">
										{isShowVideo && topicData.presentationFilePath ? (
											<video
												id="video"
												controls
												controlsList="nodownload nopictonpicture"
												disablePictureInPicture={true}
												autoPlay={false}
												ref={this.videoRewind}
												className="cursor-pointer"
												poster={PosterImage}
												onEnded={() =>
													this.getNextOrPreviouseTopicData(
														studentCourse,
														topicData,
														"next",
													)
												}>
												<source
													src={topicData.presentationFilePath}
													type="video/mp4"
												/>
											</video>
										) : null}
										{isShowImage && topicData.imagePath ? (
											<img
												className="cursor-default topic-image-full-screen"
												src={topicData.imagePath}
												alt="/"
											/>
										) : null}
										{isShowDescription && topicData.description ? (
											<span className="topic-description-block">
												<span className="topic-description-block-item">
													<ParserComponent text={topicData.description} />
												</span>
											</span>
										) : null}
										{topicData &&
										topicData.id &&
										!topicData.presentationFilePath &&
										!topicData.imagePath &&
										!topicData.description ? (
											<div className="no-data-container mt-4">
												<div className="no-data-wrapper">
													<p>{translationService.translate("TR_NO_DATA")}</p>
													<img src={NoDataImage} alt="/" />
												</div>
											</div>
										) : null}
									</div>

									{/* right arrow */}
									<div
										className={`arrow-block right-arrow-block ${
											!disabledNextTopicButton ? "disabled" : ""
										}`}
										style={{ opacity: !disabledNextTopicButton ? "0.1" : "1" }}
										onClick={() => {
											if (!disabledNextTopicButton) {
												return false;
											} else {
												this.getNextOrPreviouseTopicData(
													studentCourse,
													topicData,
													"next",
												);
											}
										}}>
										<RightArrowSvg
											cursor={!disabledNextTopicButton ? "default" : "pointer"}
										/>
									</div>
								</div>

								<div className="bottom-block actions-block">
									<div className="d-sm-flex justify-content-evenly">
										{/* {
                        topicData.name ?
                          <div className='actions-block-item'>
                            <h4>{topicData.name}</h4>
                          </div>
                          : null
                      } */}
										<div className="actions-block-item">
											<Auxiliary>
												{topicData.presentationFilePath ? (
													<p
														className="m-2"
														onClick={() => {
															this.setState({
																isShowVideo: true,
																isShowImage: false,
																isShowDescription: false,
															});
														}}>
														{translationService.translate("TR_VIDEO")}
													</p>
												) : null}
												{topicData.imagePath ? (
													<p
														className="m-2"
														onClick={() => {
															this.setState({
																isShowVideo: false,
																isShowImage: true,
																isShowDescription: false,
															});
														}}>
														{translationService.translate("TR_IMAGE")}
													</p>
												) : null}
											</Auxiliary>

											{topicData.description &&
											topicData.description.trim().length ? (
												<p
													className="m-2"
													onClick={() => {
														this.setState({
															isShowVideo: false,
															isShowImage: false,
															isShowDescription: true,
														});
													}}>
													{translationService.translate("TR_DESCRIPTION")}
												</p>
											) : null}
											{topicFileData && topicFileData.length ? (
												<p
													className="m-2"
													onClick={() => {
														this.setState({
															isFullScreen: false,
															key: "topic-files",
														});
													}}>
													{translationService.translate("TR_FILE")}
												</p>
											) : null}
											{topicQuizData && topicQuizData.length ? (
												<p
													className="m-2"
													onClick={() => {
														this.setState({
															isFullScreen: false,
															key: "topic-quizzes",
														});
													}}>
													{translationService.translate("TR_QUIZ")}
												</p>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
					<div className="container-fluid course-pass-container">
						<div className="row">
							<div className="col-12 p-0">
								<div className="d-md-flex d-block">
									<aside className="coures-pass-left-content">
										<div className="course-pass-process-left-header">
											<div className="d-flex justify-content-between align-items-center">
												<div className="nav-item navbar-nav align-items-center">
													<div className="content-title p-0">
														<Link
															to={`/${this.props.language}/${PASS_COURSE_KEY}/${courseId}/${studentCourseId}`}
															className="m-0"
															title={translationService.translate("TR_BACK")}
															onClick={this.onUnload}>
															<ArrowBackSvg />
														</Link>
														<span>{studentCourse.course.name}</span>
													</div>
												</div>
												<Link
													to="#"
													className="burger-btn open-course-content nav-link d-md-none d-block"
													onClick={() => this.toggleMobileBurgerMenu(true)}>
													<span></span>
												</Link>
											</div>
											<div
												className={`burger-menu-container side-menu ${
													showMobileBurgerMenu ? "toggle-burger" : ""
												}`}>
												<div
													className={`burger-menu-body ${
														showMobileBurgerMenu ? "toggle-burger-menu" : ""
													}`}>
													<div
														className={`right-close-btn side-menu-close-btn ${
															showMobileBurgerMenu
																? "burger-close-button-scala"
																: ""
														}`}
														onClick={() => this.toggleMobileBurgerMenu(false)}>
														<span></span>
													</div>
													<div className="accordion-wrapper course-pass-section">
														<div className="card mindalay-card">
															{studentCourse &&
															studentCourse.course.sections ? (
																<Sections
																	sections={studentCourse.course.sections}
																	currentSectionId={currentSectionId}
																	changeTopicData={this.changeTopicData}
																	getQuizzesAssignment={
																		this.getQuizzesAssignment
																	}
																	ref={this.child}
																	topicId={topicId}
																/>
															) : null}
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr className="m-0" />
										<div className="row d-md-block d-none">
											<div className="col-12">
												<div className="course-complited-status p-3">
													{completionPercentage ||
													typeof completionPercentage === NUMBER_KEY ? (
														<div className="progress my-2">
															<div
																className="progress-bar"
																style={{ width: `${completionPercentage}%` }}
																role="progressbar"
																aria-valuenow="75"
																aria-valuemin="0"
																aria-valuemax="100"></div>
														</div>
													) : null}
													<div className="course-pass-percent w-100 text-center">
														{completionPercentage ||
														typeof completionPercentage === NUMBER_KEY ? (
															<p>
																<span>{completionPercentage}</span>
																<span>%</span>
																{translationService.translate("TR_COMPLETED")}
															</p>
														) : null}
													</div>
													{/* <Sections /> */}
												</div>
											</div>
											<div className="col-12">
												{studentCourse && studentCourse.course?.sections ? (
													<Sections
														sections={studentCourse.course.sections}
														currentSectionId={currentSectionId}
														changeTopicData={this.changeTopicData}
														getQuizzesAssignment={this.getQuizzesAssignment}
														ref={this.child}
														topicId={topicId}
													/>
												) : null}
											</div>
										</div>
									</aside>
									<aside className="coures-pass-right-content">
										<div className="row">
											<div className="col-12">
												<ul className="d-flex justify-content-between course-pass-page-navidation-btn text-center">
													<li
														className={`nav-item flex-1 ${
															!disabledPreviouseButton || isDisabledButtons
																? "disabled"
																: ""
														}`}
														onClick={() =>
															this.getNextOrPreviouseTopicData(
																studentCourse,
																topicData,
																"previouse",
															)
														}>
														<Link to="#">
															{translationService.translate(
																"TR_PREVIOUS_TOPIC",
															)}
														</Link>
													</li>
													<li
														className={`nav-item flex-1 ${
															!disabledNextTopicButton || isDisabledButtons
																? "disabled"
																: ""
														}`}
														onClick={() =>
															this.getNextOrPreviouseTopicData(
																studentCourse,
																topicData,
																"next",
															)
														}>
														<Link to="#">
															{translationService.translate("TR_NEXT_TOPIC")}
														</Link>
													</li>
												</ul>
											</div>
											<div className="col-12">
												<p className="course-name">{topicData.name}</p>
												<div className="course-pass-process-video">
													{topicData.presentationFilePath && (
														<div
															className="full-screen-blick"
															onClick={() => this.setFullScreen(topicData)}>
															<FullScreen />
														</div>
													)}
													{(topicData.presentationFilePath ||
														topicData?.videoLink) &&
													!isFullScreen ? (
														<Auxiliary>
															{ongoingVideoId === topicData.id ? (
																<video
																	id="video"
																	controls
																	controlsList="nodownload nopictonpicture"
																	disablePictureInPicture={true}
																	ref={this.videoRewind}
																	// autoPlay={true} // last change
																	className="cursor-pointer"
																	src={
																		ongoingVideoId === topicData.id
																			? topicData.presentationFilePath
																			: ""
																	}
																	onEnded={() =>
																		this.getNextOrPreviouseTopicData(
																			studentCourse,
																			topicData,
																			"next",
																		)
																	}
																	// onPointerDown={() => this.setState({ ongoingVideoId: topicData.id })}
																>
																	{ongoingVideoId === topicData.id ? (
																		<source
																			src={topicData.presentationFilePath}
																			type="video/mp4"
																			// autoPlay // last change
																		/>
																	) : null}
																</video>
															) : topicData?.videoLink ? (
																<div
																	className="course-video-wrapper"
																	style={{ height: "338px" }}>
																	<ReactPlayer
																		ref={this.player}
																		onReady={this.handlePlayerReady}
																		className="course-video-link"
																		onProgress={progress => {
																			this.setState({
																				videoProgress: Math.floor(
																					progress.playedSeconds,
																				),
																			});
																		}}
																		onEnded={() => {
																			this.getNextOrPreviouseTopicData(
																				studentCourse,
																				topicData,
																				"next",
																			);
																		}}
																		url={topicData?.videoLink}
																		config={{
																			youtube: {
																				playerVars: {
																					showinfo: 0,
																					controls: 1,
																				},
																			},
																			facebook: {
																				appId: "12345",
																			},
																		}}
																	/>
																</div>
															) : (
																<img
																	className="cursor-pointer"
																	src={PosterImage}
																	onPointerDown={() =>
																		this.setState({
																			ongoingVideoId: topicData.id,
																		})
																	}
																/>
															)}
														</Auxiliary>
													) : topicData.imagePath ? (
														<img
															className="cursor-pointer"
															src={topicData.imagePath}
															alt="/"
															onClick={() =>
																this.zoomImage(topicData.imagePath)
															}
														/>
													) : null}
												</div>
											</div>
										</div>
										{topicData.description ? (
											<div
												className={`col-12 word-break-break-word ${
													!topicData.presentationFilePath &&
													!topicData.imagePath
														? "my-4"
														: ""
												}`}>
												<span className="topic-description-block">
													<ParserComponent text={topicData.description} />
												</span>
											</div>
										) : null}
										<div className="row d-md-none d-block">
											<div className="col-12">
												<div className="course-complited-status p-3">
													{completionPercentage ||
													typeof completionPercentage === NUMBER_KEY ? (
														<div className="progress my-2">
															<div
																className="progress-bar"
																style={{ width: `${completionPercentage}%` }}
																role="progressbar"
																aria-valuenow="75"
																aria-valuemin="0"
																aria-valuemax="100"></div>
														</div>
													) : null}
													<div className="course-pass-percent w-100 text-center">
														{completionPercentage ||
														typeof completionPercentage === NUMBER_KEY ? (
															<p>
																<span>{completionPercentage}</span>
																<span>%</span>
																{translationService.translate("TR_COMPLETED")}
															</p>
														) : null}
													</div>
												</div>
											</div>
										</div>
										{
											<div className="row">
												<div className="course-pass-process-files">
													<div className="col-12">
														<div className="tab-content tab-title-group">
															<Tabs
																activeKey={key}
																className="pass-course-tab"
																onSelect={key => this.setState({ key })}>
																{topicQuizData && topicQuizData.length ? (
																	<Tab
																		eventKey="topic-quizzes"
																		title={translationService.translate(
																			"TR_QUIZ",
																		)}>
																		<div className="row">
																			<div className="col-12">
																				<div className="mt-4">
																					<div className="course-pass-media-file-container">
																						<div className="row">
																							<QuizView
																								translationService={
																									translationService
																								}
																								quizData={topicQuizData}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Tab>
																) : null}
																{user &&
																user.isOrganizationUser &&
																topicAssignments &&
																topicAssignments.length ? (
																	<Tab
																		eventKey="topic-assignments"
																		title={translationService.translate(
																			"TR_ASSIGNMENTS",
																		)}>
																		{/* translationService.translate(
																			"TR_QUIZ",
																		) */}
																		<div className="row">
																			<div className="col-12">
																				<div className="mt-4">
																					<div className="course-pass-media-file-container">
																						<div className="row">
																							<AssignmentsView
																								translationService={
																									translationService
																								}
																								assignments={topicAssignments}
																							/>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Tab>
																) : null}
																{topicFileData && topicFileData.length ? (
																	<Tab
																		eventKey="topic-files"
																		title={translationService.translate(
																			"TR_FILE",
																		)}>
																		<Fiels topicFileData={topicFileData} />
																	</Tab>
																) : null}
															</Tabs>
														</div>
													</div>
												</div>
											</div>
										}
									</aside>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : null
		) : null;
	}
}

const mapStateToProps = state => ({
	language: state.language.language,
	translations: state.translation.translations,
	user: state.user.user,
	pageSpinners: state.spinner.pageSpinners,
});

const mapDispatchToProps = {
	addPageSpinner,
	removePageSpinner,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PassCourseVideoView);

import React, { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import ApiService from "../../Services/apiService";
import { CONSULTANT_CONSULTATION_CONTRACTS_KEY, STUDENT_CONSULTATION_CONTRACTS_KEY, CONSULTANT_KEY, CREATE_ONE_TIME_WEBINAR_KEY } from "../../Constants/urlKeys";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import callWeitingRingtone from "./../../assets/audio/duooutgoing_b9801c9f5127101.mp3";

const WebinarNotificationBlock = props => {

  let history = useHistory();
  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (props.isShow) {
      audioStart();
    }
  }, [props.isShow, document.getElementById("waiting-ringtone")]);

  const audioStart = () => {
    var audioElement = document.getElementById("waiting-ringtone");
    if (audioElement) {
      audioElement.src = callWeitingRingtone;
      audioElement.autoplay = true;
      audioElement.loop = true;
    }
  }

  const redirectToContractPage = (webinarId) => {
    ApiService.getContractIdByWebinarId(webinarId).then(response => {
      if (+user.id === +props.currentWebinarData.lecturerUserId) {
        if (props.location.pathname.includes("consultant/consultant-consultation-contracts")) {
          window.location.reload()
        }
        response.data && props.history.push(`/${language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${response.data}`)
      } else {
        if (props.location.pathname.includes("student-consultation-contracts")) {
          window.location.reload()
        }
        response.data && props.history.push(`/${language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${response.data}`)
      }
    }).catch(error => getFail(error))
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  return translationService ?
    history.location.pathname.includes(CREATE_ONE_TIME_WEBINAR_KEY) && props.currentWebinarData?.webinarType === 2 ?
      null :
      user && !user.isOrganizationUser ?
        <div className={`webinar-notification ${props.isShow ? "show" : ""}`}>
          {
            props.currentWebinarData ?
              props.currentWebinarData?.webinarType === 1 ?
                <Auxiliary>
                  <p className="text-center d-block">{translationService.translate("TR_YOU_HAVE_ONGOING_CONSULTATION")}</p>
                  <Link
                    to="#"
                    onClick={() => redirectToContractPage(props.currentWebinarData.webinarId)}
                    className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small"
                  >
                    {translationService.translate("TR_GO")}
                  </Link>
                </Auxiliary>
                : props.currentWebinarData?.webinarType === 2 ?
                  <Auxiliary>
                    <p className="text-center d-block">{translationService.translate("TR_YOU_HAVE_ONGOING_VIDEO_CONFERENCE")}</p>
                    <Link
                      to={`/${language}/${CREATE_ONE_TIME_WEBINAR_KEY}`}
                      className="mindalay--btn-dark mindalay--btn-link mindalay--btn-small"
                    >
                      {translationService.translate("TR_GO")}
                    </Link>
                  </Auxiliary>
                  : null
              : null
          }
          {
            <audio id="waiting-ringtone" className="d-none">
              <source src={callWeitingRingtone} type="audio/mp3" />
            </audio>
          }
        </div>
        : null
    : null
}

export default withRouter(WebinarNotificationBlock)
import React from 'react';
import InfoSvg from '../Svg/infoSvg';

const Textarea = (props) => {
  const failedFieldName = props.name && props.name.charAt(0).toUpperCase() + props.name.slice(1);
  return (
    <div className={`${props.blockClassName ? props.blockClassName : ""} form-group`}>
      {
        props.labelValue ? <label htmlFor={props.name} className={props.labelClassName ? props.labelClassName : ""}>{props.labelValue}</label> : null
      }
      {
        props.infoText ?
          <div className="info-title d-inline-block">
            <div>
              <InfoSvg />
              <span style={{ display: "none" }}>
                {props.infoText}
              </span>
            </div>
          </div>
          : null
      }
      <div className="input-wrapper">
        {
          props.max ? <span className="input-lenght">{props.max - props.value.length}</span> : null
        }
        <textarea
          id={props.id}
          type="textarea"
          name={props.name}
          value={props.value}
          rows={props.rows}
          min={props.min}
          max={props.max}
          disabled={props.disabled}
          required={props.required}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          autoFocus={props.autoFocus}
          className={`form-control ${props.textAreaClassName ? props.textAreaClassName : ""}
          ${(props.failedFields && props.failedFields.hasOwnProperty(failedFieldName))
              || (props.isInvalidSubmit && !props.value)
              || (+props.min > props.value?.length && props.isInvalidSubmit)
              || props.isInvalidField
              ? "is-invalid error-bordered" : ""}`}
          onChange={props.onChange}
        />
        {
          props.failedFields && props.failedFields.hasOwnProperty(failedFieldName)
            ? <div className="small-error-msg">{props.failedFields[failedFieldName]}</div>
            : null
        }
      </div>
    </div>
  );
}

export default Textarea;


import React from "react";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import AdminFooter from "../LayoutSections/Footer/adminFooter";
import AdminHeader from "../LayoutSections/Header/adminHeader";
import OrganizationUserHeader from "../LayoutSections/Header/organizationUserHeader";
import DeveloperLeftMenu from "../LayoutSections/LeftMenu/DeveloperLeftMenu";
import DeveloperRoutes from "../../Routing/developerRoutes";

const DeveloperLayout = props => {
	return (
		<Auxiliary>
			<AdminHeader />
			{/* <OrganizationUserHeader /> */}
			{props.location.pathname.includes("/help") ? null : <DeveloperLeftMenu />}
			<section
				className={`${
					props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
				} `}>
				<div className="top-section wrapper pb-5">
					<DeveloperRoutes />
				</div>
				<AdminFooter />
			</section>
		</Auxiliary>
	);
};

export default withRouter(DeveloperLayout);

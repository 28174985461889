import {
  SET_CURICULUMN_BREAD_CRUMB,
  SET_PURE_LAYOUT_BACK_URL,
  GET_HELP_DATA,
  SET_HELP_DATA,
  SET_CONSULTATION_CONTRACT_BACK_URL,
  SET_CURRENT_FAQ_CONTENT,
  SET_FAQ_PROPERTY_ID,
  SET_BACK_URL,
  SHOW_HELP_ALERT,
  GET_CURRENT_QUESTION_MARK_DATA,
  SET_QUESTION_MARK_SPINNER,
  SET_WEBINAR_DATA,
  REMOVE_WEBINAR_DATA,
  SET_B2B_WEBINAR_DATA
} from "../../Constants/reduxKeys";

export function setConsultationContractBackUrl(data) {
  return {
    type: SET_CONSULTATION_CONTRACT_BACK_URL,
    payload: data
  }
}

export function setPureLayoutBackUrl(data) {
  return {
    type: SET_PURE_LAYOUT_BACK_URL,
    payload: data
  }
}

export function setBackUrl(data) {
  return {
    type: SET_BACK_URL,
    payload: data
  }
}

export function setCuriculumBreadCrumb(data) {
  return {
    type: SET_CURICULUMN_BREAD_CRUMB,
    payload: data
  }
}

export function getHelpData(key) {
  return {
    type: GET_HELP_DATA,
    payload: key
  }
}

export function setHelpData(data) {
  return {
    type: SET_HELP_DATA,
    payload: data
  }
}

export function setFaqContent(data) {
  return {
    type: SET_CURRENT_FAQ_CONTENT,
    payload: data
  }
}

export function setFaqPropertyId(id) {
  return {
    type: SET_FAQ_PROPERTY_ID,
    payload: id
  }
}

export function showOrHideHelpAlert(bool) {
  return {
    type: SHOW_HELP_ALERT,
    payload: bool
  }
}

export function getCurrentQuestionMarkData(pageName, key) {
  return {
    type: GET_CURRENT_QUESTION_MARK_DATA,
    payload: { pageName, key }
  }
}

export function setQuestionMarkSpinner(bool) {
  return {
    type: SET_QUESTION_MARK_SPINNER,
    payload: bool
  }
}

export function setWebinarData(webinarData) {
  if (!webinarData) { return false; }
  return {
    type: SET_WEBINAR_DATA,
    payload: webinarData
  }
}
export function setIsShowB2BWebinarNotification(bool) {
  return {
    type: SET_B2B_WEBINAR_DATA,
    payload: bool
  }
}

export function removeWebinarData() {
  return {
    type: REMOVE_WEBINAR_DATA,
  }
}

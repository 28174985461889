import React from "react";

const multiChoiseSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="26.75" height="19" viewBox="0 0 26.75 19">
    <g transform="translate(-0.439 -78.859)">
      <g transform="translate(6.599 87.337)">
        <path d="M143.79,236H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -236)" fill="#2980b9" />
      </g>
      <g transform="translate(6.599 79.372)">
        <path d="M143.79,86H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -86)" fill="#2980b9" />
      </g>
      <g transform="translate(6.599 95.302)">
        <path d="M143.79,386H125.324a1.062,1.062,0,1,0,0,2.124H143.79a1.062,1.062,0,1,0,0-2.124Z" transform="translate(-124.262 -386)" fill="#2980b9" />
      </g>
      <g transform="translate(0 79)">
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 -0.141)" fill="#2980b9" />
      </g>
      <g transform="translate(0 86.488)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.372)" fill="#2980b9" />
        </g>
      </g>
      <g transform="translate(0 94.724)">
        <g transform="translate(0 0)">
          <circle cx="1.5" cy="1.5" r="1.5" transform="translate(0.439 0.136)" fill="#2980b9" />
        </g>
      </g>
    </g>
  </svg>
}

export default multiChoiseSvg;
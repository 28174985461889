import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import AdminProfileDropdown from "./SubComponents/adminProfileDropdown";
import { MINDALAY_ADMIN } from "../../../Constants/urlKeys";
import CloseSvg from "../../../Components/Svg/closeSvg";
import { getHelpData, showOrHideHelpAlert } from "../../../Store/Actions/main";
import HelpService from "../../../Services/helpService";

const AdminHeader = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);
	const language = useSelector(state => state.language.language);
	const isShowHelpAlert = useSelector(state => state.main.isShowHelpAlert);
	// const isShowQuestionMarkSpinner = useSelector(state => state.main.isShowQuestionMarkSpinner);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = () => {
		dispatch(showOrHideGeneralDashboardLeftMenu(!showOrHideGeneralLeftMenu));
	};

	const getHelpDataLocal = () => {
		if (isShowHelpAlert) {
			dispatch(showOrHideHelpAlert(false));
		} else {
			if (HelpService.setPageName(props.location.pathname)) {
				dispatch(getHelpData(HelpService.setPageName(props.location.pathname)));
				dispatch(showOrHideHelpAlert(true));
			}
		}
	};

	return translationService ? (
		<header className="relative-layout admin-dashboard-header header instructor-header-zindex">
			<nav className="navbar navbar-expand-md navbar-light navbar-shadow d-flex justify-content-md-end justify-content-between instructor-consultant-navbar">
				{!showOrHideGeneralLeftMenu ? (
					<Link
						to={`/${language}/${MINDALAY_ADMIN}`}
						className="navbar-brand instructor-consultant-header-logo d-none"></Link>
				) : (
					<div
						className="close-admin-left-menu cursor-pointer"
						onClick={showOrHideLeftMenu}>
						<CloseSvg />
					</div>
				)}
				<Link
					to="#"
					className={`burger-btn open-burger-menu nav-link ${
						showOrHideGeneralLeftMenu ? "disabled" : ""
					}`}
					onClick={showOrHideLeftMenu}>
					<span></span>
				</Link>
				<div className="">
					<ul className="navbar-nav align-items-left align-items-lg-center">
						<li className="nav-item navbar-profile-container header-dropdown">
							<AdminProfileDropdown />
						</li>
					</ul>
				</div>
			</nav>
		</header>
	) : null;
};

export default withRouter(AdminHeader);

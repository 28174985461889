import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import TranslationService from "../../Services/translationService";
import Input from "../../Components/Inputs/input";
import MainService from "../../Services/mainService";
import uuid from "react-uuid";
import { ERROR_KEY, NUMBER_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import {
	addButtonSpinner,
	removeButtonSpinner,
} from "./../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import AlertService from "../../Services/alertService";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import { userLogin } from "./../../Store/Actions/user";
import SmallQuestionMark from "../../Components/SmallQuestionMark/smallQuestionMark";
import HelpService from "../../Services/helpService";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import ActionButton from "../../Components/ActionButton/actionButton";
import createOrganizationImage from "./../../assets/images/createOrganization.svg";

const spinnerId = uuid();
const OrganizationForm = props => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const user = useSelector(state => state.user.user);

	const organizationId = props.match.params.organizationId;
	const nameMaxLength = 120;
	const emailMaxLength = 120;
	const phoneNumberMinLength = 6;
	const phoneNumberMaxLength = 32;

	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isInvalidUserName] = useState(false);
	const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
	const [isInvalidLastName, setIsInvalidLastName] = useState(false);
	const [isInvalidEmail, setIsInvalidEmail] = useState(false);
	const [isInvalidOrganizationEmail, setIsInvalidOrganizationEmail] =
		useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isInvalidPassword, setIsInvalidPassword] = useState(false);
	const [isMismatchPasswords, setIsMismatchPasswords] = useState(null);
	const [isInvalidconfirmPassword, setIsInvalidconfirmPassword] =
		useState(false);
	const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);

	const [organizationName, setOrganizationName] = useState("");
	const [organizationEmail, setOrganizationEmail] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [mobilePhone, setMobilePhone] = useState("+");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const checkFieldValidation = (field, fieldName) => {
		var isValid = MainService.isValidField(field, fieldName);
		if (fieldName === "password") {
			checkIsinvalidFieldName(isValid, setIsInvalidPassword);
		}
		if (fieldName === "confirmPassword") {
			checkIsinvalidFieldName(isValid, setIsInvalidconfirmPassword);
		}
		if (fieldName === "password" || fieldName === "confirmPassword") {
			if (password && confirmPassword && password !== confirmPassword) {
				checkIsinvalidFieldName(false, setIsMismatchPasswords);
			} else {
				checkIsinvalidFieldName(true, setIsMismatchPasswords);
			}
		}
		switch (fieldName) {
			case "firstname":
				checkIsinvalidFieldName(isValid, setIsInvalidFirstName);
				break;
			case "lastname":
				checkIsinvalidFieldName(isValid, setIsInvalidLastName);
				break;
			case "organizationEmail":
				checkIsinvalidFieldName(isValid, setIsInvalidOrganizationEmail);
				break;
			case "email":
				checkIsinvalidFieldName(isValid, setIsInvalidEmail);
				break;
			default:
				break;
		}
	};

	const changeIsinvalidFieldName = (isValid, cb) => {
		cb(!isValid);
	};

	const checkIsinvalidFieldName = (isValid, cb) => {
		cb(!isValid);
	};

	const checkPhoneNumber = phoneNumber => {
		if (
			(phoneNumber &&
				(phoneNumber.length > phoneNumberMaxLength ||
					phoneNumber.length < phoneNumberMinLength)) ||
			!phoneNumber ||
			phoneNumber.includes(".") ||
			phoneNumber.includes("e")
		) {
			setIsInvalidPhoneNumber(true);
			return false;
		} else {
			setIsInvalidPhoneNumber(false);
			return false;
		}
	};
	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (
			!organizationName ||
			!organizationName.trim() ||
			!organizationEmail ||
			!organizationEmail.trim() ||
			!firstname ||
			!firstname.trim() ||
			!lastname ||
			!lastname.trim() ||
			!email ||
			!email.trim() ||
			!mobilePhone ||
			mobilePhone.trim().length <= 1 ||
			!password ||
			!password.trim() ||
			!confirmPassword ||
			!confirmPassword.trim() ||
			isInvalidOrganizationEmail ||
			isInvalidUserName ||
			isInvalidFirstName ||
			isInvalidLastName ||
			isInvalidEmail ||
			isInvalidPhoneNumber ||
			isInvalidPassword ||
			isInvalidconfirmPassword ||
			isMismatchPasswords
		) {
			setIsInvalidSubmit(true);
		} else {
			dispatch(addButtonSpinner(spinnerId));
			const form = {
				organizationName: organizationName.trim(),
				organizationEmail: organizationEmail.trim(),
				firstname: firstname.trim(),
				lastname: lastname.trim(),
				email: email.trim(),
				username: email.trim(),
				mobilePhone,
				password: btoa(password),
				confirmPassword: btoa(confirmPassword),
			};
			ApiService.createOrganization(form)
				.then(() => {
					if (!user) {
						const data = {
							username: email,
							password: password,
						};
						dispatch(userLogin({ ...data }, spinnerId));
					}
					setOrganizationName("");
					setOrganizationEmail("");
					setFirstname("");
					setLastname("");
					setEmail("");
					setMobilePhone("+");
					setPassword("");
					setConfirmPassword("");
					dispatch(removeButtonSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate(TR_DATA_SAVED_KEY),
					);
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-12">
						<h2 className="section-title">
							{organizationId
								? translationService.translate("TR_UPDATE_ORGANIZATION")
								: translationService.translate("TR_CREATE_ORGANIZATION")}
						</h2>
						<hr />
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-lg-6">
						<div className="border rounded p-3">
							<div className="mb-3">
								<h5>
									<b>
										{translationService.translate("TR_ORGANIZATION_DETAILS")}
									</b>
								</h5>
							</div>
							<div>
								<div>
									<label>{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)} *`}</label>
									<SmallQuestionMark
										id="organization_name"
										onMouseEnter={() =>
											getQuestionMarkData("organization_name")
										}
									/>
								</div>
								<Input
									type="text"
									id="organizationName"
									name="organizationName"
									inputClassName="pr--5"
									blockClassName="mb-1"
									value={organizationName}
									fieldLength={nameMaxLength}
									isInvalidField={
										isInvalidSubmit && !organizationName.trim().length
											? true
											: false
									}
									onChange={event => {
										if (event.target.value.length <= nameMaxLength) {
											setOrganizationName(event.target.value);
										} else {
											return;
										}
									}}
								/>
								<Input
									type="email"
									id="organizationEmail"
									name="organizationEmail"
									blockClassName="mb-1 mt-3"
									value={organizationEmail}
									labelValue={`${translationService.translate(
										"TR_ORGANIZATION_EMAIL",
									)} *`}
									onChange={event => {
										if (event.target.value.length <= emailMaxLength) {
											setOrganizationEmail(event.target.value);
										} else {
											return;
										}
									}}
									isInvalidField={
										(isInvalidSubmit && !organizationEmail.trim()) ||
										isInvalidOrganizationEmail
									}
									onBlur={() =>
										checkFieldValidation(organizationEmail, "organizationEmail")
									}
								/>
								{isInvalidOrganizationEmail ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_EMAIL")}
									</small>
								) : null}
							</div>
						</div>

						<div className="border rounded p-3 mt-3">
							<div className="mb-3">
								<h5>
									<b>{translationService.translate("TR_ADMIN_DETAILS")}</b>
								</h5>
							</div>
							<div>
								<Input
									type="text"
									id="firstname"
									name="firstname"
									inputClassName="pr--5"
									value={firstname}
									blockClassName="mb-1 mt-3"
									fieldLength={nameMaxLength}
									isInvalidField={
										(isInvalidSubmit && !firstname.trim().trim()) ||
										isInvalidFirstName
									}
									labelValue={`${translationService.translate(
										"TR_FIRSTNAME",
									)} *`}
									onChange={event => {
										if (event.target.value.length <= nameMaxLength) {
											setFirstname(event.target.value);
										} else {
											return;
										}
									}}
									onBlur={() => checkFieldValidation(firstname, "firstname")}
								/>
								{isInvalidFirstName ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_FIRST_NAME")}
									</small>
								) : null}
								<Input
									type="text"
									id="lastname"
									name="lastname"
									inputClassName="pr--5"
									value={lastname}
									blockClassName="mb-1 mt-3"
									fieldLength={nameMaxLength}
									isInvalidField={
										(isInvalidSubmit && !lastname.trim()) || isInvalidLastName
									}
									labelValue={`${translationService.translate(
										"TR_LASTNAME",
									)} *`}
									onChange={event => {
										if (event.target.value.length <= nameMaxLength) {
											setLastname(event.target.value);
										} else {
											return;
										}
									}}
									onBlur={() => checkFieldValidation(lastname, "lastname")}
								/>
								{isInvalidLastName ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_LAST_NAME")}
									</small>
								) : null}
								<Input
									type="tel"
									id="mobilePhone"
									name="mobilePhone"
									inputClassName="pr--5"
									value={mobilePhone}
									blockClassName="mb-1 mt-3"
									isInvalidField={
										(isInvalidSubmit &&
											(!mobilePhone.trim() ||
												mobilePhone.trim().length <= 1)) ||
										isInvalidPhoneNumber
									}
									labelValue={`${translationService.translate(
										"TR_MOBILEPHONE",
									)} *`}
									onChange={event => {
										if (event.target.value.length) {
											if (
												(event.target.value.length < phoneNumberMaxLength &&
													typeof +event.target.value === NUMBER_KEY &&
													Number(event.target.value)) ||
												event.target.value === "+"
											) {
												setMobilePhone(event.target.value);
											} else {
												return;
											}
										} else {
											setMobilePhone("+");
										}
									}}
									onBlur={() => checkPhoneNumber(mobilePhone)}
								/>
								{isInvalidPhoneNumber ? (
									<small className="red-color">
										{translationService.translate("TR_INVALID_PHONE_NUMBER")}
									</small>
								) : null}
								<div className="mt-3">
									<div>
										<label>
											{" "}
											{`${translationService.translate("TR_EMAIL")} *`}{" "}
										</label>
										<SmallQuestionMark
											id="email"
											onMouseEnter={() => getQuestionMarkData("email")}
										/>
									</div>
									<Input
										type="email"
										id="email"
										name="email"
										inputClassName="pr--5"
										// infoText={translationService.translate("TR_EMAIL_INFO")}
										value={email}
										blockClassName="mb-1"
										isInvalidField={
											(isInvalidSubmit && !email.trim()) || isInvalidEmail
										}
										// labelValue={`${translationService.translate("TR_EMAIL")} *`}
										onChange={event => {
											if (event.target.value.length <= emailMaxLength) {
												setEmail(event.target.value);
											} else {
												return;
											}
										}}
										onBlur={() => checkFieldValidation(email, "email")}
									/>
									{isInvalidEmail ? (
										<small className="red-color">
											{translationService.translate("TR_INVALID_EMAIL")}
										</small>
									) : null}
								</div>
								<div className="row mt-3">
									<div className="col-lg-6 col-md-6 col-12">
										<div>
											<label>
												{" "}
												{`${translationService.translate(
													"TR_PASSWORD",
												)} *`}{" "}
											</label>
											<SmallQuestionMark
												id="password"
												onMouseEnter={() => getQuestionMarkData("password")}
											/>
										</div>
										<Input
											type="password"
											id="password"
											name="password"
											autoComplete="new-password"
											blockClassName="mb-1"
											value={password}
											isInvalidField={
												isInvalidPassword ||
												isMismatchPasswords ||
												(isInvalidSubmit && !password.trim())
											}
											onChange={event => {
												setPassword(event.target.value);
												event.target.value && setErrorMessage(null);
											}}
											// onBlur={() => checkFieldValidationForPasswords(password, "password")}
											onBlur={() => checkFieldValidation(password, "password")}
										/>
										{isInvalidPassword ? (
											<small className="red-color">
												{translationService.translate("TR_PASSWORD_INFO")}
											</small>
										) : null}
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<Input
											type="password"
											id="confirmPassword"
											name="confirmPassword"
											autoComplete="new-password"
											blockClassName="mb-1"
											value={confirmPassword}
											labelValue={`${translationService.translate(
												"TR_CONFIRM_PASSWORD",
											)} *`}
											isInvalidField={
												isInvalidconfirmPassword ||
												isMismatchPasswords ||
												(isInvalidSubmit && !confirmPassword.trim())
											}
											onChange={event => {
												setConfirmPassword(event.target.value);
												event.target.value && setErrorMessage(null);
											}}
											onBlur={() =>
												checkFieldValidation(confirmPassword, "confirmPassword")
											}
										/>
										{isInvalidconfirmPassword ? (
											<small className="red-color">
												{translationService.translate("TR_PASSWORD_INFO")}
											</small>
										) : null}
									</div>
									{errorMessage ? (
										<div className="col-12">
											<small className="fail">{errorMessage}</small>
										</div>
									) : null}
									{isMismatchPasswords ? (
										<div className="col-12 mb-3">
											<small className="red-color">
												{translationService.translate("TR_PASSWORD_MISMATCH")}
											</small>
										</div>
									) : null}
								</div>
							</div>
						</div>
						<ActionButton
							spinnerId={spinnerId}
							clicked={onSubmit}
							type="submit"
							className="mindalay--btn-default float-right my-3 px-5"
							name={translationService.translate("TR_CREATE")}
						/>
					</div>
					<div className="col-12 col-lg-6 d-lg-block d-none">
						<div>
							{/* <img src="https://assets-global.website-files.com/619dc793cd0b4873e0825490/61ae20883d652b27b6ef3f39_org-design-second-edition.svg" style={{maxWidth:"100%"}} /> */}
							<img src={createOrganizationImage} style={{ maxWidth: "100%" }} />
						</div>
					</div>
				</div>
			</form>
		</div>
	) : null;
};

export default withRouter(OrganizationForm);

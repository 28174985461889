import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../Services/apiService";
import { GET_CATEGORIES_BY_COURSE_REQUEST } from "../../Constants/reduxRequestKeys";
import { GET_CATEGORIES_BY_COURSE_SUCCESS } from "../../Constants/reduxKeys";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";

export default function* watcher() {
  yield takeLatest(GET_CATEGORIES_BY_COURSE_REQUEST, getCategoriesByType);
};

function* getCategoriesByType(action) {
  try {
    const type = action.payload;
    const categories = yield call(ApiService.getCategoriesByType, type)
    yield put({
      type: GET_CATEGORIES_BY_COURSE_SUCCESS,
      payload: categories
    });
  } catch (error) {
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}


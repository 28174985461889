import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

export default function ReportDocument(props) {

  const {
    a1,
    a2,
    a3,
    a4,
    a5,
    a6,
    a7,
    a8,
    a10,
    a12,
  } = props.state;

  const user = useSelector(state => state.user.user);

  useEffect(() => {
    props.setState(prevState => ({
      ...prevState,
      a1: props.organizationName ? props.organizationName : "",
      a2: props.groupName ? props.groupName : "",
      a3: user.fullName ? user.fullName : "",
      a4: "",
      a5: "",
      a6: "",
      a7: "",
      a8: "",
      a15: "",
      a17: "",
    }));

  }, []);

  const addAutoResize = () => {
    document.querySelectorAll('.textarea').forEach((element) => {
      element.style.boxSizing = 'border-box';
      var offset = element.offsetHeight - element.clientHeight;
      element.addEventListener('input', (event) => {
        event.target.style.height = 'auto';
        event.target.style.height = event.target.scrollHeight + offset + 'px';
      });
      element.removeAttribute('data-autoresize');
    });
  }

  return (
    <div className='row'>
      <div className='col-12'>
        {
          <div id='document' className='my-5 d-flex justify-content-center'>
            <div className='document-body'>
              <input
                name="a1"
                className='custom-input text-center pb-1'
                placeholder={props.organizationName}
                value={a1}
                disabled
                onChange={() => { }}
              />
              <h1 className="template-title mt-1">ОТЧЕТ</h1>
              <h3 className="template-sub-title mt-1">о прохождении производственной практики</h3>

              <p className="flex-wrap">
                Обучающегося группы
                <input
                  name="a2"
                  type="text"
                  placeholder={props.groupName}
                  value={a2}
                  className="custom-input template-input bottom-short-mrg fit-height"
                  onChange={() => { }}
                />
              </p>
              <h2 className="center selected-font mt-3">
                <input
                  name="a3"
                  type="text"
                  placeholder="ФАМИЛИЯ ИМЯ ОТЧЕСТВО"
                  className="custom-input template-input bottom-short-mrg text-center"
                  value={a3}
                  disabled={true}
                  onChange={() => { }}
                />
              </h2>
              <p className="flex-wrap">Место прохождения практики<br />
                <input
                  name="a4"
                  type="text"
                  placeholder="полное наименование предприятия, учреждения, организации"
                  className="custom-input fit-height"
                  value={a4}
                  onChange={(event) => props.handleChange(event)}
                />
              </p>
              <p className="flex-wrap">(полное наименование предприятия, учреждения, организации)</p>
              <p className="flex-wrap mt-9">Руководитель практики –
                <input
                  name="a5"
                  type="text"
                  placeholder="Фамилия Имя Отчество – должность"
                  className="custom-input template-input bottom-short-mrg fit-height"
                  value={a5}
                  onChange={(event) => props.handleChange(event)}
                />
              </p>
              <p className="flex-wrap mt-4">Период прохождения практики с
                <input
                  name="a6"
                  type="date"
                  className="custom-input date-input bottom-short-mrg"
                  value={a6}
                  onChange={(event) => props.handleChange(event)}
                />
                <span className="mx-1">до</span>
                <input
                  name="a7"
                  type="date"
                  className="custom-input date-input bottom-short-mrg"
                  value={a7}
                  onChange={(event) => props.handleChange(event)}
                />
              </p>
              <p className="flex-wrap mt-4">За время прохождения практики освоил следующие виды работ:</p>
              <p className="flex-wrap">
                <textarea
                  name="a8"
                  value={a8}
                  className="text-area mt-3 textarea"
                  placeholder="Ввести текст"
                  onChange={(event) => {
                    addAutoResize();
                    props.handleChange(event);
                  }}
                />
              </p>
              <p className="flex-wrap mt-7">Практикант
                <input
                  type="text"
                  className="custom-input max-assignment-width fit-height"
                  disabled
                  onChange={() => { }}
                />
                <input
                  name="a10"
                  value={a10}
                  type="text"
                  placeholder="Фамилия И.О."
                  className="custom-input template-input bottom-short-mrg fit-height"
                  onChange={(event) => props.handleChange(event)}
                />
              </p>
              <p className="flex-wrap mt-3">Содержание и объем выполненных работ подтверждаю.</p>
              <p className="flex-wrap mt-3 mb-3">Руководитель практики
                <input
                  type="text"
                  className="custom-input max-assignment-width fit-height"
                  disabled
                  onChange={() => { }}
                />
                <input
                  name="a12"
                  value={a12}
                  type="text"
                  placeholder="Фамилия И.О."
                  className="custom-input template-input bottom-short-mrg fit-height mt-2"
                  onChange={(event) => props.handleChange(event)}
                />
              </p>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../../Services/translationService";
import ButtonSpinner from "../../../Components/Spinners/buttonSpinner";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import { addButtonSpinner, removeButtonSpinner } from "../../../Store/Actions/spinner";
import { setCurrentCourse } from "../../../Store/Actions/course";
import { ERROR_KEY, TR_NO, TR_YES } from "../../../Constants/mainKeys";
import {
  COURSE_DASHBOARD_KEY,
  CURICULUMN_KEY,
  GENERAL_INFO_KEY,
  MESSAGES_KEY,
  TARGET_STUDENTS_KEY,
  PRICING_KEY,
  PROMOTIONS_KEY,
} from "../../../Constants/urlKeys";

const CourseDashboardLeftMenu = props => {
  const dispatch = useDispatch();

  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const currentCourse = useSelector(state => state.course.currentCourse);
  const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);
  const [translationService, setTranslationService] = useState(null);
  const [isGeneralInfoCompleted, setIsGeneralInfoCompleted] = useState(false);
  const [isMessagesCompleted, setIsMessagesCompleted] = useState(false);
  const [isTargetStudentsCompleted, setIsTargetStudentsCompleted] = useState(false);
  const [isPricingCompleted, setIsPricingCompleted] = useState(false);
  const [isCurriculumCompleted, setIsCurriculumCompleted] = useState(false);

  const spinnerIdForVerification = uuid();
  const spinnerIdForPublish = uuid();
  const spinnerIdForCancelPublish = uuid();

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (
      currentCourse.categories?.length
      && currentCourse.languageId
      && currentCourse.name
      && currentCourse.imagePath
    ) {
      setIsGeneralInfoCompleted(true);
    }
    if (currentCourse.congratulationMessage && currentCourse.welcomeMessage) {
      setIsMessagesCompleted(true);
    } else {
      setIsMessagesCompleted(false);
    }
    if (currentCourse.targetStudents
      && currentCourse.requirements
      && currentCourse.whatYouWillLearn) {
      setIsTargetStudentsCompleted(true);
    }
    if (currentCourse.cost) {
      setIsPricingCompleted(true);
    }
    // if (
    //   currentCourse.cost
    //   || typeof currentCourse.cost === NUMBER_KEY
    //   || currentCourse.costByMainCurrency
    //   || typeof currentCourse.costByMainCurrency === NUMBER_KEY
    // ) {
    //   setIsPricingCompleted(true);
    // }
    const notCompletedSection = [...currentCourse?.sections].find(section => !section.topics.length);
    if (!currentCourse.sections.length) {
      setIsCurriculumCompleted(false);
    }
    if (notCompletedSection) {
      setIsCurriculumCompleted(false);
    }
    if (!notCompletedSection && currentCourse.sections.length) {
      var inCompleteTopic = false;
      currentCourse.sections.forEach(section => {
        if (section.topics.length) {
          section.topics.forEach(topic => {
            if (!topic.presentationFilePath && !topic.hasContent) {
              inCompleteTopic = true;
            }
          })
        } else {
          setIsCurriculumCompleted(false);
        }
      });
      setIsCurriculumCompleted(!inCompleteTopic);
    }
  }, [currentCourse]);

  const submitCourseForVerification = () => {
    addBtnSpinner(spinnerIdForVerification);
    AlertService.alertConfirm(
      `${translationService.translate("TR_SUBMIT_FOR_VERIFICATION_CONFIRM_MSG").replace('{0}', currentCourse.name)}`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      ApiService.submitForVerification(currentCourse.id).then(() => {
        const currentCourseChanges = { ...currentCourse };
        currentCourseChanges.submitedForVerification = true;
        currentCourseChanges.verificationDenialMessage = null;
        currentCourseChanges.verificationDenied = false;
        setCurrentCourseData(currentCourseChanges);
        removeBtnSpinner(spinnerIdForVerification);
      }).catch(error => submitFail(error, spinnerIdForVerification));
    });
  }

  const publishCourse = () => {
    addBtnSpinner(spinnerIdForPublish);
    AlertService.alertConfirm(
      `${translationService.translate("TR_COURSE_PUBLISH_CONFIRM_MSG").replace('{0}', currentCourse.name)}`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      ApiService.coursePublish(currentCourse.id).then(() => {
        const currentCourseChanges = { ...currentCourse };
        currentCourseChanges.published = true;
        setCurrentCourseData(currentCourseChanges);
        removeBtnSpinner(spinnerIdForPublish);
      }).catch(error => submitFail(error, spinnerIdForPublish));
    });
  }

  const cancelPublish = () => {
    addBtnSpinner(spinnerIdForCancelPublish);
    AlertService.alertConfirm(
      translationService.translate("TR_COURSE_CALCEL_PUBLISH_CONFIRM_MSG"),
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      ApiService.coursePublish(currentCourse.id).then(() => {
        const currentCourseChanges = { ...currentCourse };
        currentCourseChanges.published = false;
        setCurrentCourseData(currentCourseChanges);
        removeBtnSpinner(spinnerIdForCancelPublish);
      }).catch(error => submitFail(error, spinnerIdForCancelPublish));
    });
  }

  const submitFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && removeBtnSpinner(spinnerId);
  }

  const setCurrentCourseData = useCallback(currentCourse => {
    dispatch(setCurrentCourse(currentCourse));
  })

  const removeBtnSpinner = useCallback(spinnerId => {
    dispatch(removeButtonSpinner(spinnerId))
  });

  const addBtnSpinner = useCallback(spinnerId => {
    dispatch(addButtonSpinner(spinnerId))
  });

  if (!currentCourse) { props.history.push(`/${language}`); return; }

  const currentSpinnerForVerification = buttonSpinners.find(spinner => spinner === spinnerIdForVerification);
  const currentSpinnerForPublish = buttonSpinners.find(spinner => spinner === spinnerIdForPublish);
  const currentSpinnerForCancelPublish = buttonSpinners.find(spinner => spinner === spinnerIdForCancelPublish);

  const mainPath = `/${language}/${COURSE_DASHBOARD_KEY}/${currentCourse.id}`;
  return currentCourse && translationService ? <div className={`course-left-menu-wrapper ${props.wrapperClasses ? props.wrapperClasses : ""}`}>
    {/* <ul className="left-list-information-list">
      <li>How to</li>
      <li className="default-menu-item"><a href="#">Film & edit</a></li>
      <li className="default-menu-item"><a href="#">Best practics</a></li>
      <li className="default-menu-item"><a href="#">Quiz condions</a></li>
      <li className="default-menu-item"><a href="#">Course structure</a></li>
    </ul> */}
    <ul className="left-list-information-list">
      <li className="cursor-default">{translationService.translate("TR_CREATION_STEPS")}</li>
      <li className={`${isGeneralInfoCompleted ? 'passed-menu-item' : 'default-menu-item'}`} onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
        <NavLink to={`${mainPath}/${GENERAL_INFO_KEY}`} activeClassName="active-menu-item">
          {translationService.translate("TR_COURSE_GENERAL_INFO")}
        </NavLink>
      </li>
      <li className={`${isMessagesCompleted ? 'passed-menu-item' : 'default-menu-item'}`} onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
        <NavLink to={`${mainPath}/${MESSAGES_KEY}`} activeClassName="active-menu-item">
          {translationService.translate("TR_COURSE_MESSAGES")}
        </NavLink>
      </li>
      <li className={`${isTargetStudentsCompleted ? 'passed-menu-item' : 'default-menu-item'}`} onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
        <NavLink to={`${mainPath}/${TARGET_STUDENTS_KEY}`} activeClassName="active-menu-item">
          {translationService.translate("TR_TARGET_YOUR_STUDENTS")}
        </NavLink>
      </li>
      <li className={`${isPricingCompleted ? 'passed-menu-item' : 'default-menu-item'}`} onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
        <NavLink to={`${mainPath}/${PRICING_KEY}`} activeClassName="active-menu-item">
          {translationService.translate("TR_PRICING")}
        </NavLink>
      </li>
      <li className={`${isCurriculumCompleted ? 'passed-menu-item' : 'default-menu-item'}`} onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
        <NavLink to={`${mainPath}/${CURICULUMN_KEY}`} activeClassName="active-menu-item">
          {translationService.translate("TR_CURRICULUM")}
        </NavLink>
      </li>
      <li className="passed-menu-item">
        <NavLink to={`${mainPath}/${PROMOTIONS_KEY}`} activeClassName="active-menu-item" onClick={() => props.setToggleBurgerMenu ? props.setToggleBurgerMenu(false) : null}>
          {translationService.translate("TR_PROMOTIONS")}
        </NavLink>
      </li>
    </ul>
    {
      isGeneralInfoCompleted
        && isMessagesCompleted
        && isTargetStudentsCompleted
        && isCurriculumCompleted
        && isPricingCompleted
        && !currentCourse.submitedForVerification
        && !currentCourse.verified
        ? <div className="mt-4">
          <button type="button" className="mindalay--btn-default w-100" onClick={submitCourseForVerification}>
            {translationService.translate("TR_SUBMIT_FOR_VERIFICATION")}
            {
              currentSpinnerForVerification ? <ButtonSpinner spinner={currentSpinnerForVerification} /> : null
            }
          </button>
        </div> : null
    }
    {
      currentCourse.verified && !currentCourse.published ? <div className="mt-4">
        <button type="button" className="mindalay--btn-default w-100" onClick={publishCourse}>
          {translationService.translate("TR_PUBLISH")}
          {
            currentSpinnerForPublish ? <ButtonSpinner spinner={currentSpinnerForPublish} /> : null
          }
        </button>
      </div> : null
    }
    {
      currentCourse.published ? <div className="mt-4">
        <button type="button" className="mindalay--btn-default w-100" onClick={cancelPublish}>
          {translationService.translate("TR_CANCEL_PUBLISH")}
          {
            currentSpinnerForCancelPublish ? <ButtonSpinner spinner={currentSpinnerForCancelPublish} /> : null
          }
        </button>
      </div> : null
    }
  </div> : null;
}

export default withRouter(CourseDashboardLeftMenu);
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import AlertService from "../../../Services/alertService";
import { ERROR_KEY } from "../../../Constants/mainKeys";
import {
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { Card } from "react-bootstrap";
import GamifyApiService from "../../../Services/gamifyApiService";
import uuid from "react-uuid";
import NukaCarousel from "nuka-carousel";
import NoDataImage from "./../../../assets/images/illustrations/nodata.svg";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import { Link } from "react-router-dom";

const ShopItems = props => {
	const dispatch = useDispatch();
	const { studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [shopItems, setShopItems] = useState([]);
	const [isDisabledFields, setIsDisabledFields] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getStudentShopItems();
	}, []);

	const getStudentShopItems = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			userId: studentId
				? studentId.toString()
				: user.studentId
				? user.studentId.toString()
				: null,
			shopItemImageSize: 3,
		};
		GamifyApiService.getStudentShopItems(data)
			.then(response => {
				if (response && response.data && response.data.length) {
					let data = [...response.data];
					setShopItems(data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
		setIsDisabledFields(false);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							<h2 className="content-title p-0">
								{translationService.translate("TR_SHOP_ITEMS")}
							</h2>
						</div>
					</div>
					<PageInfoBlock
						pageTitle="student_my_items"
						pageName="student_my_items"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{shopItems && shopItems.length ? (
				<div>
					<div className="row">
						{shopItems.map((item, index) => {
							return (
								<div key={index} className="col-12 col-sm-6 col-lg-4 p-3">
									<Card className="w-100 h-100">
										{item.shopItem.imagePaths &&
										item.shopItem.imagePaths.length ? (
											item.shopItem.imagePaths.length > 1 ? (
												<NukaCarousel
													autoplay={
														item.shopItem.imagePaths.length > 1 ? true : false
													}
													wrapAround={true}
													autoplayInterval={80000}
													speed={1000}
													height="200px"
													className="small-carousel"
													renderCenterLeftControls={({ previousSlide }) => (
														<button
															onClick={previousSlide}
															className="arrow left-arrow">
															<i className="fa fa-arrow-left" />
														</button>
													)}
													renderCenterRightControls={({ nextSlide }) => (
														<button
															onClick={nextSlide}
															className="arrow right-arrow">
															<i className="fa fa-arrow-right" />
														</button>
													)}>
													{item.shopItem.imagePaths.map((item, index) => {
														return (
															<div
																key={index}
																className="w-100 pt-3"
																style={{
																	backgroundImage: `url(${item})`,
																	backgroundPosition: "center",
																	backgroundSize: "contain",
																	backgroundRepeat: "no-repeat",
																	height: "200px",
																}}
															/>
														);
													})}
												</NukaCarousel>
											) : (
												<div
													className="w-100 pt-3"
													style={{
														backgroundImage: `url(${item.shopItem.imagePaths[0]})`,
														backgroundPosition: "center",
														backgroundSize: "contain",
														backgroundRepeat: "no-repeat",
														height: "200px",
													}}
												/>
											)
										) : null}
										<Card.Body className="position-relative pt-0 d-flex flex-column justify-content-between p-2">
											<div>
												<h3 className="title text-center">
													{item.shopItem.name}
												</h3>
												<hr />
											</div>
											<div>
												<p className="max-line-3">
													{item.shopItem.description}
												</p>
												<p className="d-flex justify-content-between my-2">
													<b>{translationService.translate("TR_QUANTITY")}</b>
													<b>{item.quantity}</b>
												</p>
											</div>
										</Card.Body>
									</Card>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className="mt-4 w-100">
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" height="200px" />
						</div>
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(ShopItems);

import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import ActionButton from "../../Components/ActionButton/actionButton";
import AuthInput from "../../Components/Inputs/authInput";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { Link } from "react-router-dom";

class ConfirmEmail extends Component {

    state = {
        form: {
            email: '',
        },
        translationService: null,
        isInvalidSubmit: false,
        spinnerId: uuid(),
        forgotPasswordMailSended: false,
        errorMsg: ''
    };

    componentDidMount() {
        this.setTranslations();
    }

    setTranslations = () => {
        if (!this.state.translationService && this.props.translations) {
            this.setState({ translationService: new TranslationService(this.props.translations) });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.translations
            && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
            this.setState({ translationService: new TranslationService(nextProps.translations) });
        }
        return true;
    }

    componentDidUpdate() {
        this.setTranslations();
    }

    onChange = (event, maxLength = null) => {
        const failedFields = this.removeFailedFields(event.target.name);
        if (maxLength && maxLength < event.target.value.length) { return; }
        this.setState(prevState => ({
            ...prevState,
            failedFields,
            form: {
                ...prevState.form,
                [event.target.name]: event.target.value,
            }
        }));
    };

    removeFailedFields = name => {
        const failedFields = this.state.failedFields && { ...this.state.failedFields };
        const fieldName = name.replace(name[0], name[0].toUpperCase());
        failedFields && delete failedFields[fieldName];
        return failedFields;
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { spinnerId, form } = this.state;
        const { email } = form;
        if (email) {
            this.props.addButtonSpinner(spinnerId);
            ApiService.getResetLink(email).then(() => {
                this.props.removeButtonSpinner(spinnerId);
                this.setState({
                    forgotPasswordMailSended: true
                })
            }).catch(error => this.getFail(error, spinnerId));
        } else {
            this.setState({ isInvalidSubmit: true });
            this.props.removeButtonSpinner(spinnerId);
        }
    };

    getFail = (error, spinnerId) => {
        error && this.setState({ errorMsg: error.respmess })
        spinnerId && this.props.removeButtonSpinner(spinnerId);
    }

    render() {
        const { translationService, isInvalidSubmit, failedFields, spinnerId, forgotPasswordMailSended, errorMsg } = this.state;
        const { email } = this.state.form;
        return translationService ? <div className="col-12">
            <div className="authorization-form content-background" style={{ maxWidth: "500px" }}>
                <p className="authorization-form-title mb-2">{translationService.translate("TR_FORGOT_YOUR_PASSWORD")}</p>
                <hr />
                {
                    !forgotPasswordMailSended ?
                        <p>{translationService.translate("TR_FORGOT_PASSWORD_INFO_TEXT")}</p>
                        : <p>
                            {translationService.translate("TR_FORGOT_PASSWORD_EMAIL_SENT")}
                        </p>
                }
                {
                    !forgotPasswordMailSended
                        ? <form onSubmit={this.onSubmit}>
                            <AuthInput
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                isInvalidSubmit={isInvalidSubmit}
                                placeholder={`${translationService.translate("TR_EMAIL")}`}
                                onChange={this.onChange}
                                failedFields={failedFields}
                            />
                            {
                                errorMsg ? <small className="red-color">{errorMsg}</small> : null
                            }
                            <div className="my-3">
                                <ActionButton
                                    spinnerId={spinnerId}
                                    type="submit"
                                    className="mindalay--btn-dark w-100"
                                    name={translationService.translate("TR_REQUEST_PASSWORD_RESET")}
                                />
                            </div>
                        </form> : null
                }
                {
                    forgotPasswordMailSended ?
                        <Link to="#" className="mt-4 d-block" onClick={() => this.setState({ forgotPasswordMailSended: false })}>{translationService.translate("TR_I_DID_NOT_RECEIVE_AN_EMAIL")}</Link>
                        : null
                }

            </div>
        </div> : null;
    }
}

const mapStateToProps = state => ({
    language: state.language.language,
    translations: state.translation.translations,
})

const mapDispatchToProps = {
    addButtonSpinner,
    removeButtonSpinner,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)

import React from 'react';

const AuthInput = (props) => {
  const failedFieldName = props.name && props.name.charAt(0).toUpperCase() + props.name.slice(1);
  return (
    <div className={`${props.blockClassName ? props.blockClassName : ""}`}>
      {
        props.labelValue
          ? <label htmlFor={props.id} className={`${props.labelClassName ? props.labelClassName : ""}`} style={props.labelStyle}>{props.labelValue}</label>
          : null
      }
      <input
        id={props.id}
        type={props.type}
        name={props.name}
        min={props.min}
        value={props.value}
        disabled={props.disabled}
        required={props.required}
        onBlur={props.onBlur}
        pattern={props.pattern}
        autoComplete="off"
        placeholder={props.placeholder || (props.labelValue && props.labelValue.replace("*", " "))}
        className={`authorization-input ${props.inputClassName ? props.inputClassName : ""}
          ${(props.failedFields && props.failedFields.hasOwnProperty(failedFieldName))
            || (props.isInvalidSubmit && !props.value) || props.isInvalidField
            ? "error-border is-invalid" : ""}`}
        onChange={props.onChange}
      />
      {
        props.failedFields && props.failedFields.hasOwnProperty(failedFieldName)
          ? <div className="small-error-msg">{props.failedFields[failedFieldName]}</div>
          : null
      }
    </div>
  );
}

export default AuthInput;

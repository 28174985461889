import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import uuid from 'react-uuid';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import { addPartialViewSpinner, removePartialViewSpinner } from '../../Store/Actions/spinner';
import AlertService from '../../Services/alertService';
import { ERROR_KEY, NUMBER_KEY } from '../../Constants/mainKeys';
import MainCategories from './Components/mainCategories';

const Categories = () => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  var [categories, setCategories] = useState([]);


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getCategoriesByType = (categoryType) => {
    if (typeof categoryType !== NUMBER_KEY) { return; }
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getCategoriesByType(categoryType).then(response => {
      dispatch(removePartialViewSpinner(spinnerId));
      if (response && response.data) {
        setCategories(response.data);
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const updateCategories = (categoryId, name, categoryType) => {
    if (!categories || !categories.length || !categoryId || !name) { return; }
    if (name === "mainCategory") {
      categories = categories.filter(category => category.id !== categoryId);
      setCategories(categories);
    }
    if (name === "subCategory") {
      getCategoriesByType(categoryType);
    }
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
    setCategories([]);
  }

  return (translationService ? <div>
    <MainCategories
      categories={categories}
      updateCategories={updateCategories}
      getCategoriesByType={getCategoriesByType}
    />
  </div>
    : null
  );
}

export default Categories;




import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TranslationService from './../../Services/translationService';

const Logs = () => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="content-title p-0">Logs page</h2>
        <hr />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        Todo
      </div>
    </div>
  </div> : null
}

export default Logs;

import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import UploadButton from "../../Components/UploadButton/uploadButton";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import SelectOption from "../../Components/SelectOption/selectOption";
import Input from "../../Components/Inputs/input";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import FileSvg from "../../Components/Svg/fileSvg";
import { COURSES_KEY, FILELIBRARY_KEY, FILE_LIBRARY_KEY, INSTRUCTOR_KEY } from "../../Constants/urlKeys";
import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
  ERROR_KEY,
  SUCCESS_KEY,
  FILE_LIBRARY_CATEGORY_TYPE_KEY,
  ID_KEY,
  NUMBER_KEY,
  ALL_VALID_FILE_TYPES,
  VALID_IMAGE_TYPES_KEY,
  VALID_VIDEO_TYPES_KEY,
  VALID_AUDIO_TYPES_KEY,
  VALID_FILE_TYPES_KEY,
  IMAGE_KEY,
  VIDEO_KEY,
  FILE_KEY,
  OBJECT_KEY,
  AUDIO_KEY
} from "../../Constants/mainKeys";
import {
  addButtonSpinner,
  removeButtonSpinner,
  removePartialViewSpinner,
  addPartialViewSpinner,
} from "../../Store/Actions/spinner";
import ProgressBar from 'react-bootstrap/ProgressBar';

import InfoSvg from '../../Components/Svg/infoSvg';
class FileLibraryForm extends Component {

  mainService = new MainService();

  state = {
    form: {
      name: '',
      fileEntity: '',
      categoryId: null,
    },
    fileFolderForm: {
      folderName: '',
      categorytype: +FILE_LIBRARY_CATEGORY_TYPE_KEY,
    },
    fileId: this.props.match.params.id,
    fileEntities: [],
    ownerUserId: null,
    isInvalidSubmit: false,
    spinnerId: uuid(),
    categories: [],
    translationService: null,
    failedFields: null,
    nameMaxLength: 200,
    fileReadPercent: 0,
  };

  componentDidMount() {
    this.setTranslations();
    this.getFileCategories();
    this.state.fileId && this.getFileLibraryById(this.state.fileId);
  };

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  setFileReadPercent = (percent) => {
    this.setState({ fileReadPercent: percent })
  }

  getFileCategories = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId)
    ApiService.getCategoriesByType(FILE_LIBRARY_CATEGORY_TYPE_KEY).then(response => {
      const categories = this.getCategories(response.data);
      if (categories && categories.length) {
        this.setState(prevState => ({
          ...prevState,
          form: {
            ...prevState.form,
            categoryId: categories[0].id
          }
        }));
      }
      this.setState({ categories });
      this.props.removePartialViewSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getCategories = (categories) => {
    let newCategories = [];
    categories && categories.forEach(category => {
      newCategories.push(category);
      if (category.children && category.children.length) {
        newCategories = [...newCategories, ...this.getCategories(category.children)];
      }
    });
    return newCategories;
  }

  getFileLibraryById = fileLibraryid => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getLibraryFileById(fileLibraryid).then(response => {
      const fileData = response.data;
      const mimeType = fileData.mimeType;
      let settedMimeType;
      if (mimeType.includes(IMAGE_KEY)) {
        settedMimeType = IMAGE_KEY;
      } else if (mimeType.includes(VIDEO_KEY)) {
        settedMimeType = VIDEO_KEY;
      } else if (mimeType.includes(AUDIO_KEY)) {
        settedMimeType = AUDIO_KEY;
      }
      else if (
        mimeType.includes("pdf") || //pdf
        mimeType.includes("text") || //txt
        mimeType.includes("application")
      ) {
        settedMimeType = FILE_KEY;
      }
      const fileEntities = [
        {
          // uploadedFile: `${fileData.filePath}?${uuid()}`,
          uploadedFile: `${fileData.filePath}`,
          fileType: settedMimeType
        }
      ];
      this.setState(prevState => ({
        ...prevState,
        ownerUserId: fileData.ownerUserId,
        fileEntities,
        form: {
          ...prevState.form,
          name: fileData.name ? fileData.name : "",
          fileEntity: fileData.fileEntity,
          categoryId: fileData.categoryId
        }
      }));
      this.props.removePartialViewSpinner(spinnerId)
    }).catch(error => this.getFail(error, spinnerId));
  }

  uploadFile = async event => {
    const fileEntities = [];
    this.setState({ isInvalidFileFormat: false });
    if (event.target.files) {
      for (let i in event.target.files) {
        const file = event.target.files[i];
        if (file && typeof file === OBJECT_KEY) {
          const fileName = file.name;
          const lastDotIndex = fileName.lastIndexOf('.');
          const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';

          let fileType = null;

          if (VALID_IMAGE_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
            fileType = IMAGE_KEY;
          }
          else if (VALID_VIDEO_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
            fileType = VIDEO_KEY;
          }
          else if (VALID_AUDIO_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
            fileType = AUDIO_KEY;
          }
          else if (VALID_FILE_TYPES_KEY.includes(fileExtention || fileExtention.toUpperCase())) {
            fileType = FILE_KEY;
          }
          await this.mainService.readFile(file, ALL_VALID_FILE_TYPES, this.setFileReadPercent).then(uploadedFile => {
            fileEntities.push({
              fileName,
              fileType,
              file,
              uploadedFile
            });
          }).catch(error => error && this.setState({ isInvalidFileFormat: true, fileReadPercent: 0 })
          );
        }
      }
    }
    this.setState({ fileEntities });
  };

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({ ...prevState, form: { ...prevState.form, [event.target.name]: event.target.value } }));
  };

  onChangeFileFolderName = event => {
    this.setState(prevState => ({ ...prevState, fileFolderForm: { ...prevState.fileFolderForm, [event.target.name]: event.target.value } }))
  }

  onNumberChange = event => {
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0)) {
      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,
          [event.target.name]: event.target.value
        }
      }));
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { translationService, spinnerId, fileId, ownerUserId, fileEntities } = this.state;
    const { name, categoryId } = this.state.form;
    const form = { ...this.state.form };
    if (!name || !categoryId || !fileEntities.length || !name.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addButtonSpinner(spinnerId);
      const formData = new FormData();
      for (let i in form) {
        !form[i] ? delete form[i] : formData.append(`${i}`, form[i]);
      }
      if (fileEntities.length > 1 && !fileId) {
        const files = [];
        fileEntities.forEach(fileEntity => {
          files.push(fileEntity.file);
          formData.append('fileEntities', fileEntity.file);
        });
      } else {
        formData.append('fileEntity', fileEntities[0].file);
      }

      fileId && formData.append(ID_KEY, fileId);
      fileId && ownerUserId && formData.append('ownerUserId', ownerUserId);

      (fileId
        ? ApiService.libraryFileUpdate(formData)
        : ApiService.libraryFileCreate(formData, fileEntities.length > 1 ? 'bulk' : '')).then(() => {
          AlertService.alert(SUCCESS_KEY, translationService.translate(`${fileId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
          this.props.removeButtonSpinner(spinnerId);
          this.goBack();
        }).catch(error => this.submitFail(error, spinnerId));
    }
  };

  createFileFolder = (event) => {
    event.preventDefault();
    const { spinnerId, translationService } = this.state;
    const { folderName, categorytype } = this.state.fileFolderForm;
    if (!folderName) {
      this.setState({ isInvalidSubmit: true });
    } else {
      const form = {
        name: folderName,
        categorytype
      }
      this.props.addButtonSpinner(spinnerId)
      ApiService.categoryCreate(form).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CREATED"));
        this.props.removeButtonSpinner(spinnerId);
        this.getFileCategories();
      }).catch(error => this.submitFail(error, spinnerId));
    }
  }

  submitFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
  };

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removeButtonSpinner(spinnerId);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
  };

  goBack = () => {
    const { user, language } = this.props;
    if (user.isOrganizationUser) {
      this.props.history.push(`/${language}/${FILELIBRARY_KEY}`);
    } else {
      this.props.history.push(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_KEY}`);
    }
  }

  render() {
    const { isInvalidSubmit, spinnerId, categories, translationService, fileId, failedFields, fileEntities, nameMaxLength, isInvalidFileFormat, fileReadPercent } = this.state;
    const { name, categoryId } = this.state.form;
    const { folderName } = this.state.fileFolderForm;

    return translationService ? (categories && categories.length) ?
      <div className={this.props.user.isOrganizationUser ? "m-section" : ""}>
        <div className="col-12">
          <h2 className="content-title p-0">
            <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
            {translationService.translate(fileId ? "TR_UPDATE_FILE_LIBRARY" : "TR_CREATE_FILE_LIBRARY")}
          </h2>
          <hr />
        </div>
        <div className="col-xl-6 col-12 mb-4">
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-12">
                <SelectOption
                  isInvalidSubmit={isInvalidSubmit}
                  label={`${translationService.translate("TR_FOLDER")} *`}
                  name="categoryId"
                  value={categoryId}
                  defaultValue=" "
                  items={categories}
                  changed={this.onNumberChange}
                />
              </div>
              <div className="col-12">
                <Input
                  type="text"
                  id="name"
                  name="name"
                  inputClassName="pr--5"
                  fieldLength={nameMaxLength}
                  value={name}
                  isInvalidSubmit={isInvalidSubmit}
                  isInvalidField={isInvalidSubmit && !name.trim()}
                  labelValue={`${translationService.translate("TR_NAME")} *`}
                  onChange={event => this.onChange(event, nameMaxLength)}
                  failedFields={failedFields}
                />
              </div>
              <div className="col-12">
                <div className="row">
                  {
                    fileEntities && fileEntities.map((fileData, fileDataIndex) => {
                      return <Auxiliary key={fileDataIndex}>
                        {
                          (fileData.uploadedFile) && fileData.fileType === IMAGE_KEY
                            ? <div className="col-lg-6 col-sm-6 col-12 mb-3 mt-1">
                              <div className="uploaded-file-library" style={{ backgroundImage: `url(${fileData.uploadedFile})` }}></div>
                            </div>
                            : null
                        }
                        {
                          (fileData.uploadedFile) && fileData.fileType === VIDEO_KEY
                            ? <div className="col-lg-6 col-sm-6 col-12 mb-3 mt-1">
                              <video src={fileData.uploadedFile} className="uploaded-file-library" controls ></video>
                            </div>
                            : null
                        }
                        {
                          (fileData.uploadedFile) && fileData.fileType === AUDIO_KEY
                            ? <div className="col-lg-6 col-sm-6 col-12 mb-3 mt-1">
                              <audio src={fileData.uploadedFile} controls />
                            </div>
                            : null
                        }
                        {
                          (fileData.uploadedFile) && fileData.fileType === FILE_KEY
                            ? <div className="col-lg-6 col-sm-6 col-12 mb-3 mt-1">
                              <div className="uploaded-type-file-library p-4">
                                <div className="type-file">
                                  <FileSvg />
                                </div>
                                <p>{fileData.fileName}</p>
                              </div>
                            </div> : null
                        }
                      </Auxiliary>;
                    })
                  }
                </div>
              </div>
              <div className="col-12">
                <div className={`form-group position-relative ${!isInvalidFileFormat ? "mb-2" : "mb-0"}`}>
                  <UploadButton
                    isMultiple={fileId ? false : true}
                    id="upload-file-lib"
                    textInputClasses="pl-100"
                    isInvalidSubmit={isInvalidSubmit}
                    value={fileEntities.length}
                    placeholder={fileId ? name : ''}
                    labelValue={`${translationService.translate('TR_UPLOAD_FILE')} *`}
                    infoText={translationService.translate("TR_UPLOAD_FILES_INFO")}
                    text={translationService.translate('TR_UPLOAD_FILE')}
                    clicked={this.uploadFile}
                  />
                </div>
                {
                  isInvalidFileFormat ? <small className="red-color d-block mt-1 mb-3">{translationService.translate("TR_INVALID_FILE_FORMAT_EXC")}</small> : null
                }
              </div>
              {
                fileReadPercent > 0 && fileReadPercent < 100 ?
                  <div className="col-12 my-2">
                    <ProgressBar now={fileReadPercent} />
                  </div>
                  : null
              }

              <div className="col-12 mt-2 mb-2">
                <SubmitButton
                  id={fileId}
                  spinnerId={spinnerId}
                />
              </div>
            </div>
          </form>
        </div>
      </div >
      : <div className={this.props.user.isOrganizationUser ? "m-section" : ""}>
        <div className="col-12">
          <h2 className="content-title p-0">
            <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
            {translationService.translate("TR_CREATE_FOLDER")}
          </h2>
          <hr />
          <p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
            <InfoSvg />
            {translationService.translate("TR_EMPTY_FOLDER_MSG")}
          </p>
        </div>
        <div className="col-12">
          <form onSubmit={this.createFileFolder}>
            <div className="row">
              <div className="col-12">
                <Input
                  type="text"
                  id="folderName"
                  name="folderName"
                  value={folderName}
                  isInvalidSubmit={isInvalidSubmit}
                  labelValue={`${translationService.translate("TR_NAME")} *`}
                  onChange={this.onChangeFileFolderName}
                  failedFields={failedFields}
                />
              </div>
              <div className="col-12 text-left mt-4 mb-2">
                <SubmitButton
                  spinnerId={spinnerId}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      : null;
  };
};

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  removePartialViewSpinner,
  addPartialViewSpinner,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(FileLibraryForm);
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import TranslationService from "../../Services/translationService";

const ScrollTopView = (props) => {

  const { onScroll, onVisbile, visible } = props;

  useEffect(() => {
    if (typeof window === 'object') {
      window.addEventListener('scroll', onVisbile);
    }
    return () => {
      window.removeEventListener('scroll', onVisbile);
    }
  }, []);

  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations))
  }, [translations]);

  return translationService ? <Auxiliary>
    {
      visible ?
        <div className="scroll-top-block"
          title={translationService.translate("TR_SCROLL_TO_TOP")}
          onClick={onScroll}
        >
          <i className="fas fa-chevron-up"></i>
        </div>
        : null
    }
  </Auxiliary> : null
}

export default ScrollTopView
import axios from "axios";
import AlertService from "./alertService";
import { ERROR_KEY, USER_KEY } from "../Constants/mainKeys";

const api = axios.create({
	baseURL: process.env.REACT_APP_GAMIFY_URL,
	// withCredentials: true,
});

api.interceptors.request.use(async config => {
	config.headers = {
		// Server
		OrganizationId:
			localStorage.getItem(USER_KEY) &&
			JSON.parse(localStorage.getItem(USER_KEY)).organizationId
				? JSON.parse(localStorage.getItem(USER_KEY)).organizationId.toString()
				: "2",
		PublicKey:
			"MFkwEwYHKoZIzj0CAQYIKoZIzj0DAQcDQgAE2JppL2mLs8UdUBS0s31a1wUHCL0mY0PS4tkOdji/B2dAVoG7eV3E1gwmPCrjNxwNFFcG5N18j0rwySrLZ+qaDg==",
		// PublicKey:
		// 	"MIIBCgKCAQEAryy35pJM7Trw1tEOSwEq5hei5VNm/ik0V6pMGzxSqwNDaw5eaPEx5gR0EvB8XxRPeEHJE1wclJK3LcLScIfY4lBQ78+U0iXtBXSDMipUj3EDZpp/+QCy6Mi+upm+wgk23EiNmVCsRPl+2lhktTILzbikbHFzsTaB+yyHqRREmK1JahSS3ySIc45h14fgpC6cHMFldQ4dMXy7bRFRMEYaNulJbug5b/jvucDoWKsvgS8PpoH5Kv5M/KGC+Q/t9C0KdkMfApxd3fSzQbXD6OSP64e2ZDo0W+y7A/1A5K76EjyOjd7PBdscxN65m37JxhkqSjw3C1cOKSWoFuX8Yu5OlQIDAQAB",

		//Vagho
		// OrganizationId: "4033",
		// PublicKey:
		// 	"MIIBCgKCAQEAryy35pJM7Trw1tEOSwEq5hei5VNm/ik0V6pMGzxSqwNDaw5eaPEx5gR0EvB8XxRPeEHJE1wclJK3LcLScIfY4lBQ78+U0iXtBXSDMipUj3EDZpp/+QCy6Mi+upm+wgk23EiNmVCsRPl+2lhktTILzbikbHFzsTaB+yyHqRREmK1JahSS3ySIc45h14fgpC6cHMFldQ4dMXy7bRFRMEYaNulJbug5b/jvucDoWKsvgS8PpoH5Kv5M/KGC+Q/t9C0KdkMfApxd3fSzQbXD6OSP64e2ZDo0W+y7A/1A5K76EjyOjd7PBdscxN65m37JxhkqSjw3C1cOKSWoFuX8Yu5OlQIDAQAB",

		// "ngrok-skip-browser-warning": "true",
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message);
			}
			return Promise.reject("Sorry, something went wrong!");
		}
		if (response && response.data) {
			return !response.data.respcode
				? response.data
				: Promise.reject({
						respmess: response.data.respmess || response.data.message,
						respcode: response.data.respcode,
				  });
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			// AlertService.alert(ERROR_KEY, "401 unauthorized");
			return false;
		}
		if (error.response) {
			const errors = error.response.data.errors;
			return Promise.reject(errors || error.response.data.ExcMessage || error);
		}
	},
);

class GamifyApiService {
	// static getCategoryById(categoryId, userId) {
	//   return api.get(`/category/getById?id=${categoryId}&userId=${userId}`);
	// };
	static getCategoryById(data) {
		return api.post(`/shopItem/getOrganizationItemsByCategory`, data);
	}

	static getAllCategories() {
		return api.get(`/category/getAll`);
	}

	static getResources(data) {
		return api.post(`/resource/getAllForOrganization`, data);
	}

	static getStudentResources(data) {
		return api.post(`/resource/getResourcesByUserId`, data);
	}

	// static getBadges() {
	//   return api.get(`/badge/getAllForOrganization`);
	// };

	// static getBadgesAsStudent(studentId) {
	//   return api.get(`/badge/GetAllUserBadges?userId=${studentId}`);
	// };

	static getBadges(data) {
		return api.post(`/badge/getAllOrganizationBadges`, data);
	}

	static getBadgesTree(data) {
		return api.post(`/badge/getBadgesTree`, data);
	}

	static getBadgeById(data) {
		return api.post(`/badge/GetOrganizationBadge`, data);
	}

	static getQuestById(data) {
		return api.post(`/quest/get`, data);
	}

	static getGamifyDataByEntity(data) {
		const newData = {};
		Object.entries(data).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				newData[key] = value;
			} else if (value) {
				newData[key] = +value;
			}
		});
		return api.post(`/entity/gamify`, newData);
	}

	static onGamifyGroup(data) {
		return api.post(`/group/gamify`, data);
	}

	static generateGamifyToken(data) {
		return api.post(`/adminToken/create`, data);
	}

	static getLeaderBoards(userId) {
		return api.get(`/leaderboard/getAll?userId=${userId}`);
	}

	static addShopItemToFavorites(data) {
		return api.post(`/favorite/create`, data);
	}

	static removeShopItemFromFavoritesByUserId(userId, shopItemId) {
		return api.delete(
			`/favorite/deleteByUserId?userId=${userId}&shopItemId=${shopItemId}`,
		);
	}

	static removeShopItemFromFavorites(favoriteId) {
		return api.delete(`/favorite/delete?id=${favoriteId}`);
	}

	static addShopItemToShoppingCartByUserId(data) {
		return api.post(`/shoppingCart/create`, data);
	}

	static updateShoppingCartItem(data) {
		return api.put(`/shoppingCart/update`, data);
	}

	static removeShopItemFromShoppingCartByUserId(userId, shopItemId) {
		return api.delete(
			`/shoppingCart/deleteByUserId?userId=${userId}&shopItemId=${shopItemId}`,
		);
	}

	static removeShopItemFromShoppingCart(itemId) {
		return api.delete(`/shoppingCart/delete?id=${itemId}`);
	}

	static getShoppingCartItems(data) {
		return api.post(`/shoppingCart/getAll`, data);
	}

	static moveToFavorite(itemId) {
		return api.post(`/shoppingCart/moveCartToFavorites?id=${itemId}`);
	}

	static moveToShoppingCart(itemId) {
		return api.post(`/favorite/moveFavoritesToCart?id=${itemId}`);
	}

	static getTotalPrice(userId) {
		return api.get(
			`/shoppingCart/getAllResourceForShopingCarts?userid=${userId}`,
		);
	}

	static moveAllProductsToFavorites(userId) {
		return api.post(`/shoppingCart/moveAllCartToFavorites?userid=${userId}`);
	}

	static moveAllProductsToShoppingCart(userId) {
		return api.post(`/favorite/moveAllFavoritesToCart?userid=${userId}`);
	}

	static getFavorites(data) {
		return api.post(`/favorite/getAll`, data);
	}

	static getStudentShopItems(data) {
		return api.post(`/userShopItem/getUserItems`, data);
	}

	static getLeaderBoardById(id) {
		return api.get(`/leaderboard/get?id=${id}`);
	}

	static buyShopItem(data) {
		return api.post(`/userShopItem/acquireShopItem`, data);
	}

	static buyShopItemOll(userId) {
		return api.post(`/userShopItem/buyUserShoppingCart?userId=${userId}`);
	}

	static onChangeCoin(data) {
		return api.post(`/userResource/ConvertResource`, data);
	}
}

export default GamifyApiService;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from "react-uuid"
import Textarea from '../../Components/Inputs/textArea';
import SubmitButton from '../../Components/SubmitButton/submitButton';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import { setCurrentCourse } from "../../Store/Actions/course";
import { addButtonSpinner, removeButtonSpinner, addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from '../../Constants/translationKeys';
import { ERROR_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { COURSE_DASHBOARD_KEY, TARGET_STUDENTS_KEY } from '../../Constants/urlKeys';

class CourseMessages extends Component {
  state = {
    form: {
      welcomeMessage: "",
      congratulationMessage: "",
    },
    spinnerId: uuid(),
    courseData: this.props.courseData,
    translationService: null,
    messageMaxLength: 1000,
    messageMinLength: 3,
    isInvalidSubmit: false,
    changes: false
  };

  componentDidMount() {
    this.setTranslations();
    this.state.courseData && this.setCourseData();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  setCourseData = () => {
    const courseData = { ...this.state.courseData };
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        welcomeMessage: courseData.welcomeMessage || '',
        congratulationMessage: courseData.congratulationMessage || '',
      }
    }));
  }

  onChange = (event, maxLength = 1000) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      isInvalidSubmit: false,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  };

  onSubmit = (event) => {
    event && event.preventDefault();
    let isInvalidSubmit = false;
    const { spinnerId, translationService, courseData, messageMinLength } = this.state;
    const form = { ...this.state.form };
    let { welcomeMessage, congratulationMessage } = form;
    this.setState({ changes: false });

    welcomeMessage = welcomeMessage.trim();
    congratulationMessage = congratulationMessage.trim();

    if (!welcomeMessage && !congratulationMessage) {
      isInvalidSubmit = true;
      this.setState({ isInvalidSubmit });
      return false;
    }

    if (!welcomeMessage && congratulationMessage.length >= messageMinLength) {
      isInvalidSubmit = false;
      this.setState({ isInvalidSubmit });
    }

    if (!congratulationMessage && welcomeMessage.length >= messageMinLength) {
      isInvalidSubmit = false;
      this.setState({ isInvalidSubmit });
    }

    if (welcomeMessage && welcomeMessage.length >= messageMinLength) {
      isInvalidSubmit = false;
      this.setState({ isInvalidSubmit });
    }

    if (congratulationMessage && congratulationMessage.length >= messageMinLength) {
      isInvalidSubmit = false;
      this.setState({ isInvalidSubmit });
    }

    if ((welcomeMessage && welcomeMessage.length < messageMinLength)) {
      isInvalidSubmit = true;
      this.setState({ isInvalidSubmit });
      return false;
    }

    if ((congratulationMessage && congratulationMessage.length < messageMinLength)) {
      isInvalidSubmit = true;
      this.setState({ isInvalidSubmit });
      return false;
    }

    if (!isInvalidSubmit) {
      this.props.addButtonSpinner(spinnerId);
      form.welcomeMessage = form.welcomeMessage ? form.welcomeMessage.trim() : "";
      form.congratulationMessage = form.congratulationMessage ? form.congratulationMessage.trim() : "";
      const formData = new FormData();
      formData.append('id', courseData.id)
      formData.append('welcomeMessage', form.welcomeMessage);
      formData.append('congratulationMessage', form.congratulationMessage);
      ApiService.courseMessagesUpdate(formData).then(response => {
        AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
        this.props.removeButtonSpinner(spinnerId);
        this.props.setCurrentCourse(response.data);
        this.props.history.push(`/${this.props.language}/${COURSE_DASHBOARD_KEY}/${this.props.courseData.id}/${TARGET_STUDENTS_KEY}`);
      }).catch(errors => this.submitFail(spinnerId, errors));
    }

  };

  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  render() {
    const { spinnerId, messageMaxLength, translationService, courseData, isInvalidSubmit, messageMinLength } = this.state;
    const { welcomeMessage, congratulationMessage } = this.state.form;
    return translationService ? <Auxiliary>
      <div className="content-title d-block">
        <h2 className="section-title">{translationService.translate("TR_COURSE_MESSAGES")}</h2>
      </div>
      <hr />
      <div className="content-body pt-0">
        <p className="mb-3">{translationService.translate("TR_COURSE_MESSAGES_INFO")}</p>
        <form onSubmit={this.onSubmit} onChange={() => this.setState({ changes: true })}>
          <div className="row">
            <div className="col-12">
              <Textarea
                id="welcomeMessage"
                name="welcomeMessage"
                min="0"
                rows="6"
                textAreaClassName="pr--5"
                blockClassName="mb-1"
                max={messageMaxLength}
                value={welcomeMessage}
                labelValue={`${translationService.translate("TR_WELCOME_MESSAGE")}*`}
                onChange={this.onChange}
                isInvalidSubmit={isInvalidSubmit}
                isInvalidField={isInvalidSubmit && welcomeMessage && welcomeMessage.trim().length < messageMinLength}
              />
              <small className="mb-3 d-block"><i>{translationService.translate("TR_MIN_SYMBOL")}</i></small>
            </div>
            <div className="col-12">
              <Textarea
                id="congratulationMessage"
                name="congratulationMessage"
                min="0"
                rows="6"
                infoText={translationService.translate("TR_CONGRATULATION_MESSAGE_INFO")}
                textAreaClassName="pr--5"
                blockClassName="mb-1"
                max={messageMaxLength}
                value={congratulationMessage}
                labelValue={`${translationService.translate("TR_CONGRETULATION_MESSAGE")}*`}
                onChange={this.onChange}
                isInvalidSubmit={isInvalidSubmit}
                isInvalidField={isInvalidSubmit && congratulationMessage && congratulationMessage.trim().length < messageMinLength}
              />
              <small className="mb-3 d-block"><i>{translationService.translate("TR_MIN_SYMBOL")}</i></small>
            </div>
          </div>
          <div className="text-right mt-4">
            <SubmitButton
              id={courseData.id}
              spinnerId={spinnerId}
              className="col-12 col-sm-8 col-md-4"
            />
            
          </div>
        </form>
      </div>
    </Auxiliary> : null;
  }
}

const mapStateToProps = state => ({
  language: state.language.language,
  translations: state.translation.translations,
  courseData: state.course.currentCourse
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPageSpinner,
  removePageSpinner,
  setCurrentCourse
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CourseMessages);


import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import CloseSvg from '../../../Components/Svg/closeSvg';
import TranslationService from './../../../Services/translationService';

const FileLibraryImages = (props) => {

    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const [currentImgUrl, setCurrentImgUrl] = useState(null);
    const { images } = props;

    useEffect(() => {
        function handleKeyDown(event) {
            if (event.key === 'Escape') {
                setCurrentImgUrl(null);
            }
        }
        function handleClickOutside(event) {
            if (currentImgUrl && !event.target.closest('.modal-dialog')) {
                setCurrentImgUrl(null);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [currentImgUrl])

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const zoomImage = (url) => {
        setCurrentImgUrl(url);
    }

    return translationService ? images && images.length ? <div className="mindalay-files-gallery-container">
        <div className="row">
            {
                images.map((image, index) => {
                    return <div key={index} className="col-md-3 col-sm-4 col-6 my-2">
                        <div className="mindalay-file-wrapper mindalay-image cursor-pointer" onClick={() => zoomImage(image.filePath)}>
                            <div className="mindalay-file-wrapper-item">
                                <img src={image.filePath} alt="/" />
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
        {
            currentImgUrl ?
                <div className="zoom-image-container">
                    <div className="close-svg" ><CloseSvg /></div>
                    <img src={currentImgUrl} alt="/" onClick={(event) => event.stopPropagation()} />
                </div>
                : null
        }
    </div>
        : <div className="no-data-container mt-3">
            <div className="no-data-wrapper">
                <p>{translationService.translate("TR_YOU_HAVE_NO_IMAGES")}</p>
                <img src={NoDataImage} alt="/" />
            </div>
        </div> : null
}

export default FileLibraryImages;


import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import TranslationService from '../../../Services/translationService';
import Messages from '../Components/messages';
import ContractInfo from '../Components/contractInfo';
import AlertService from '../../../Services/alertService';
import uuid from 'react-uuid';
import ApiService from '../../../Services/apiService';
import { CONSULTATION_CHAT_TYPE_KEY, CONSULTATION_CONTRACT_BACK_KEY, ERROR_KEY, RETURN_URL_KEY, SUCCESS_KEY } from '../../../Constants/mainKeys';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import moment from 'moment';
import MainService from '../../../Services/mainService';
import FeedbackForConsultant from '../Components/feedbackForConsultant';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { CONSULTANT_DASHBOARD_KEY, CONSULTANT_KEY } from '../../../Constants/urlKeys';
import ArrowBackSvg from '../../../Components/Svg/arrowBackSvg';

class ContractPageForConsultant extends Component {
  consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  state = {
    contractId: +this.props.match.params.contractId || null,
    key: this.props.location.pathname.includes("messages") ? "messages" : "contractInfo",
    translationService: null,
    isInvalidSubmit: false,
    messages: [],
    contract: {},
    studentName: '',
    studentImagePath: '',
    entitytype: 4,
    feedBackData: null,
    messageMaxLength: 2000
  }

  componentDidMount() {
    const { contractId } = this.state;
    if (this.props.location.pathname.includes(RETURN_URL_KEY)) {
      this.props.history.push(this.props.location.pathname.slice(0, this.props.location.pathname.length - 10));
    }
    this.setTranslations();
    this.getContractById(contractId);
    this.interval = setInterval(this.getChat, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.match.params.contractId !== this.props.match.params.contractId) {
      this.setState({ contractId: +this.props.match.params.contractId }, () => {
        this.getContractById(this.state.contractId);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getChat = () => {
    const { studentUserId, contractId } = this.state;
    const spinnerId = uuid();
    studentUserId && ApiService.getConsultationChatFromConsultant(this.consultationChatType, contractId, studentUserId).then(response => {
      if (response) {
        response.data && this.setState({ messages: response.data.chat });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  getContractById = (contractId) => {
    const spinnerId = uuid();
    contractId && ApiService.getContractByIdForConsultant(contractId).then(response => {
      if (response) {
        this.props.removePageSpinner(spinnerId);
        if (response.data) {
          const contract = { ...response.data };
          contract.showMoreDesc = false;
          this.setState({
            contract: response.data,
            studentUserId: contract.consultation.studentUserId,
            studentName: contract.consultation.studentUserName,
            studentImagePath: contract.consultation.studentImagePath
          }, () => {
            this.getFeedBackData(this.state.contract);
          });
          this.getChat(contract.consultation.studentUserId);
        }
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getFeedBackData = (contract) => {
    const spinnerId = uuid();
    const { entitytype } = this.state;
    if (contract && contract.consultantId) {
      const contractId = contract.id;
      this.props.addPageSpinner(spinnerId);
      ApiService.getFeedBackDataForConsultant(entitytype, contractId).then(response => {
        this.props.removePageSpinner(spinnerId);
        const data = { ...response.data };
        data.showMoreStudentDesc = false;
        data.showMoreConsultantDesc = false;
        response.data && this.setState({ feedBackData: data });
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  updateDateByConsultant = (date) => {
    const { contractId, translationService } = this.state;
    if (contractId) {
      if (date) {
        date = new Date(date);
      } else {
        date = null;
      }
      const spinnerId = uuid();
      this.props.addPageSpinner(spinnerId);
      ApiService.updateDateByConsultant(contractId, date).then(response => {
        if (response) {
          this.props.removePageSpinner(spinnerId);
          this.getContractById(contractId);
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_AGREED"));
          response.data && this.setState({ contract: response.data });
        }
      }).catch(error => this.getFail(error, spinnerId));
    } else {
      this.setState({ isInvalidSubmit: true });
    }
  }

  sendMessageFromConsultantOrAdmin = (message) => {
    const { studentUserId, contract } = this.state;
    const receiverId = [];
    const spinnerId = uuid();
    if (studentUserId && message) {
      receiverId.push(studentUserId);
      var data = {
        receiverId,
        message,
        entityId: contract.id,
        chatType: this.consultationChatType
      }
      ApiService.sendMessageFromConsultantOrAdmin(data).then(response => {
        if (response) this.getChat();
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  onChangeShowMoreDesc = () => {
    var contract = { ...this.state.contract };
    contract.showMoreDesc = !contract.showMoreDesc;
    this.setState({ contract });
  }

  onSelect = (key) => {
    this.setState({ key });
  }

  goBack = () => {
    const { consulatationContractBackUrl, language, history } = this.props;
    if (consulatationContractBackUrl) {
      consulatationContractBackUrl && history.push(consulatationContractBackUrl);
      localStorage.removeItem(CONSULTATION_CONTRACT_BACK_KEY);
    } else {
      history.push(`/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`);
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    this.props.history.push(`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`)
  }

  render() {
    const { translationService, key, isInvalidSubmit, contract, messages, studentName, studentImagePath, feedBackData, messageMaxLength } = this.state;
    return (
      translationService ?
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="content-title p-0">
                <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}>
                  <ArrowBackSvg />
                </Link>
                {translationService.translate("TR_CONTRACTS")}
              </h2>
              <hr />
            </div>
            <div className="col-12">
              <div className="box-shadow-4 mindalay--consultation-container flex-col-reverse d-flex">
                <div className="header-right-side d-flex align-items-center">
                  <div className="consultation-user-image" style={{ backgroundImage: `url(${studentImagePath})` }}></div>
                  <div className="consultation-user-info">
                    <h3 className="consultation-user-name gray-color">{studentName}</h3>
                    <small className="gmt-time">
                      {` ${translationService.translate("TR_CREATED")}: ${moment(MainService.convertUTCDateToLocalDate(new Date(contract.consultation?.creationDate))).format("ll (HH:mm)")}`}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <div className="box-shadow-4 mindalay--consultation-tab">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-title-group p-0">
                      <Tabs
                        activeKey={key}
                        onSelect={(key) => this.onSelect(key)}
                      >
                        <Tab eventKey="contractInfo" title={translationService.translate("TR_CONTRACT_INFO")}>
                          <ContractInfo
                            isInvalidSubmit={isInvalidSubmit}
                            updateDate={this.updateDateByConsultant}
                            onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                            contract={contract}
                            isConsultant={true}
                          />
                        </Tab>
                        <Tab eventKey="messages" title={translationService.translate("TR_MESSAGES")}>
                          <Messages
                            sendMessage={this.sendMessageFromConsultantOrAdmin}
                            messages={messages}
                            partnerName={studentName}
                            keyName={this.state.key}
                            messageMaxLength={messageMaxLength}
                          />
                        </Tab>
                        {
                          contract && contract.isCompleted && feedBackData ?
                            <Tab eventKey='feedback' title={translationService.translate("TR_FEEDBACK")}>
                              <FeedbackForConsultant
                                contract={contract}
                                feedBackData={feedBackData}
                                getContractById={this.getContractById}
                              />
                            </Tab>
                            : null
                        }
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user,
  consulatationContractBackUrl: state.main.consulatationContractBackUrl
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ContractPageForConsultant);

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import NodataImage from "./../../assets/images/illustrations/nodata.svg";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import CourseBlock from "./Components/CourseBlock";
import ApiService from "./../../Services/apiService";
import uuid from "react-uuid";
import { useLocation, withRouter } from "react-router-dom";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import {
	COURSES_KEY,
	ORGANIZATION_ADMIN_KEY,
	PROGRAM_KEY,
} from "../../Constants/urlKeys";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const CoursesForOrgAdmin = props => {
	const dispatch = useDispatch();
	let { pathname } = useLocation();
	const { _programId, _subjectId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [numberOfDisplayedCourses, setNumberOfDisplayedCourses] = useState(12);

	const [isDisableFields, setIsDisableFields] = useState(false);

	const [programs, setPrograms] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [courses, setCourses] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPrograms();
	}, []);

	useEffect(() => {
		_subjectId && getCoursesBySubjectId(_subjectId);
	}, [_subjectId]);

	const getPrograms = () => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		setIsDisableFields(true);
		ApiService.getProgramsAsAdmin()
			.then(response => {
				if (response && response.data && response.data.length) {
					const data = [...response.data];
					setPrograms(data);
					if (!_programId && _subjectId) {
					}
					if (data.length) {
						if (!_programId) {
							props.history.push(
								`/${language}/${ORGANIZATION_ADMIN_KEY}/${COURSES_KEY}/${PROGRAM_KEY}/${data[0].id}`,
							);
							if (data[0].subjects && data[0].subjects.length) {
								setSubjects(data[0].subjects);
								props.history.push(
									`/${language}/organization-admin/courses/program/${data[0].id}/${data[0].subjects[0].id}`,
								);
							}
						} else {
							props.history.push(
								`/${language}/${ORGANIZATION_ADMIN_KEY}/${COURSES_KEY}/${PROGRAM_KEY}/${_programId}`,
							);
							if (data.find(item => item.id === +_programId)) {
								let currentItem = data.find(item => item.id === +_programId);
								if (currentItem && currentItem.subjects) {
									setSubjects(currentItem.subjects);
								}
								if (_subjectId) {
									props.history.push(
										`/${language}/organization-admin/courses/program/${_programId}/${_subjectId}`,
									);
								}
							}
						}
					}
				}
				extractPartialViewSpinner(spinnerId);
				setIsDisableFields(false);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCoursesBySubjectId = subjectId => {
		if (!subjectId) {
			return false;
		}
		setIsDisableFields(true);
		ApiService.getCoursesBySubjectId(subjectId)
			.then(response => {
				if (response && response.data) {
					setCourses(response.data);
				} else {
					setCourses([]);
				}
				setIsDisableFields(false);
			})
			.catch(error => getFail(error));
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
		setIsDisableFields(false);
	};

	return translationService ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_COURSES")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_COURSES")}
						pageName="admin_courses"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />

			<div className="row">
				{programs && programs.length ? (
					<div className="col-lg-4 col-sm-6 col-12">
						<div className="form-group">
							<label>{`${translationService.translate(
								"TR_PROGRAM_NAME",
							)} *`}</label>
							<ReactSelectOption
								className={`${isDisableFields ? "disabled" : ""}`}
								selectedValue={(() => {
									const selectedItem = {
										...programs.find(data => data.id === +_programId),
									};
									if (selectedItem) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
									}
									return selectedItem;
								})()}
								items={programs.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => {
									setCourses([]);
									setSubjects(item.subjects || []);
									props.history.push(
										`/${language}/organization-admin/courses/program/${item.id}`,
									);
								}}
							/>
						</div>
					</div>
				) : null}
				{subjects && subjects.length ? (
					<div className="col-lg-4 col-sm-6 col-12">
						<div className="form-group">
							<label>{`${translationService.translate("TR_SUBJECT")} *`}</label>
							<ReactSelectOption
								className={`${isDisableFields ? "disabled" : ""}`}
								selectedValue={(() => {
									const selectedItem = {
										...subjects.find(data => data.id === +_subjectId),
									};
									if (selectedItem) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
									}
									return selectedItem;
								})()}
								items={subjects.map(data => ({
									...data,
									label: data.name,
									value: data.id,
								}))}
								onChange={item => {
									setCourses([]);
									props.history.push(
										`/${language}/organization-admin/courses/program/${_programId}/${item.id}`,
									);
								}}
							/>
						</div>
					</div>
				) : null}

				<div className="col-12 mt-3">
					{courses && courses.length ? (
						<div>
							<div className="row">
								{courses.map((course, index) => {
									return numberOfDisplayedCourses > index ? (
										<div
											key={course.id}
											className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
											<CourseBlock course={course} />
										</div>
									) : null;
								})}
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<p>{translationService.translate("TR_NO_DATA")}</p>
								<img src={NodataImage} alt="/" />
							</div>
						</div>
					)}
				</div>
				<div className="col-12 mt-3">
					{courses && courses.length > numberOfDisplayedCourses ? (
						<button
							type="button"
							className="mindalay--btn-dark w-100 mb-3"
							onClick={() =>
								setNumberOfDisplayedCourses(numberOfDisplayedCourses + 12)
							}>
							{translationService.translate("TR_SHOW_MORE")}
						</button>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(CoursesForOrgAdmin);

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, Route, withRouter } from "react-router-dom";
import MenuPlayerSvg from "../../../Components/Svg/menuPlayerSvg";
import ConsultantSvg from "../../../Components/Svg/consultantSvg";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import FinanceSvg from "../../../Components/Svg/financeSvg";
import TranslationService from "../../../Services/translationService";
import CoursesSubMenu from "./GeneralSubMenus/coursesSubMenu";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import FinancesSubMenu from "./GeneralSubMenus/financesSubMenu";
import ConsultantSubMenu from "./GeneralSubMenus/consultantSubMenu";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import {
	CONSULTANT_USER_TYPE,
	INSTRUCTOR_USER_TYPE,
} from "../../../Constants/mainKeys";
import {
	CONSULTANT_DASHBOARD_KEY,
	CONSULTANT_INFORMATION_KEY,
	CONSULTANT_KEY,
	COURSES_KEY,
	FINANCES_KEY,
	INSTRUCTOR_KEY,
	OVERVIEW_KEY,
} from "../../../Constants/urlKeys";

const GeneralDashboardLeftMenu = props => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const showOrHideGeneralLeftMenu = useSelector(
		state => state.generalLeftMenu.showOrHideGeneralLeftMenu,
	);

	const [translationService, setTranslationService] = useState(null);
	const [hover, setHover] = useState(false);
	const { settings } = useSelector(state => state.settings);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const toggleHover = isShow => {
		setHover(isShow);
	};

	const showOrHideLeftMenu = bool => {
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	const isConsultant = user.userTypeId === CONSULTANT_USER_TYPE ? true : false;
	const isInstructor = user.userTypeId === INSTRUCTOR_USER_TYPE ? true : false;

	return translationService && user ? (
		<section
			className={`side-menu-container ${
				showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
			}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex ${
					hover ? "w-fix-left-menu" : ""
				}`}
				onMouseEnter={() => toggleHover(true)}
				onMouseLeave={() => toggleHover(false)}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}>
								<Link
									to={`/${language}`}
									title={translationService.translate("TR_HOME")}
									className="p-0 d-flex"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img src={settings.mainLogoPath} alt="/" style={{ width: "120px", maxWidth: "150px", maxHeight: "150px", display: "block", margin: "0 auto" }} />
									) : (
										<MindalaySvg />
									)}
									
									{/* <span
										className=" ml-2"
										style={{
											fontSize: 16,
											fontWeight: 400,
											transform: "none",
										}}>
										(Beta)
									</span> */}
								</Link>
							</li>
							{isConsultant ? (
								<Auxiliary>
									<li
										title={translationService.translate("TR_CONSULTANT")}
										className={`side-left-menu-item has-submenu ${
											hover ? "" : "d-inline-flex"
										}`}>
										<NavLink
											to={`/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`}
											className={`${
												props.location.pathname.includes(
													CONSULTANT_INFORMATION_KEY,
												) ||
												props.location.pathname.includes(
													CONSULTANT_DASHBOARD_KEY,
												)
													? "active-side-left-menu-item"
													: null
											}`}>
											<ConsultantSvg />
											<span className={hover ? "tr-x-0" : ""}>
												{translationService.translate("TR_CONSULTANT")}
											</span>
										</NavLink>
									</li>
									<li
										title={translationService.translate("TR_INSTRUCTOR")}
										className={`side-left-menu-item has-submenu ${
											hover ? "" : "d-inline-flex"
										}`}>
										<NavLink
											to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}`}
											activeClassName="active-side-left-menu-item">
											<MenuPlayerSvg />
											<span className={hover ? "tr-x-0" : ""}>
												{translationService.translate("TR_INSTRUCTOR")}
											</span>
										</NavLink>
									</li>
								</Auxiliary>
							) : null}
							{isInstructor ? (
								<li
									title={translationService.translate("TR_INSTRUCTOR")}
									className={`side-left-menu-item has-submenu ${
										hover ? "" : "d-inline-flex"
									}`}>
									<NavLink
										to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}`}
										activeClassName="active-side-left-menu-item">
										<MenuPlayerSvg />
										<span className={hover ? "tr-x-0" : ""}>
											{translationService.translate("TR_INSTRUCTOR")}
										</span>
									</NavLink>
								</li>
							) : null}
							<li
								title={translationService.translate("TR_FINANCES")}
								className={`side-left-menu-item has-submenu ${
									hover ? "" : "d-inline-flex"
								}`}>
								<NavLink
									to={
										isConsultant
											? `/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}`
											: isInstructor
											? `/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`
											: "#"
									}
									activeClassName={
										props.location.pathname ===
											`/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}` ||
										props.location.pathname ===
											`/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}` ||
										props.location.pathname ===
											`/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}` ||
										props.location.pathname ===
											`/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}/${OVERVIEW_KEY}`
											? "active-side-left-menu-item"
											: ""
									}>
									<FinanceSvg />
									<span className={hover ? "tr-x-0" : ""}>
										{translationService.translate("TR_FINANCES")}
									</span>
								</NavLink>
							</li>
							{/* <li className={`side-left-menu-item has-submenu ${hover ? "" : "d-inline-flex"}`}>
                  <NavLink
                    to="#"
                    activeClassName="active-side-left-menu-item"
                  >
                    <MenuPlayerSvg />
                    <span className={hover ? "tr-x-0" : ""}>{translationService.translate("TR_INSTRUCTOR")}</span>
                  </NavLink>
                </li> */}
						</ul>
					</div>
				</div>
			</aside>
			<Route
				path={`/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}`}
				component={FinancesSubMenu}
			/>
			<Route
				path={`/${language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`}
				component={ConsultantSubMenu}
			/>
			<Route
				path={`/${language}/${CONSULTANT_KEY}/${CONSULTANT_INFORMATION_KEY}`}
				component={ConsultantSubMenu}
			/>
			<Route
				path={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}`}
				component={CoursesSubMenu}
			/>
			<Route
				path={`/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`}
				component={FinancesSubMenu}
			/>
		</section>
	) : null;
};

export default withRouter(GeneralDashboardLeftMenu);

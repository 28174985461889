import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import MainService from "../../Services/mainService";
import moment from "moment/moment";

const CourseStudents = props => {
	const { courseId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [students, setStudents] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getCourseStudentsByCourseId();
	}, []);

	const getCourseStudentsByCourseId = () => {
		if (!courseId) {
			return false;
		}
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getCourseStudentsByCourseId(courseId)
			.then(response => {
				if (response && response.data) {
					setStudents(response.data);
				}
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
	};

	return (
		translationService && (
			<div className="container mt-4">
				<div className="row">
					<div className="col-12">
						<h2 className="content-title p-0">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							{translationService.translate("TR_STUDENTS")}
						</h2>
						<hr />
					</div>
				</div>
				{students && students.length ? (
					<div className="row">
						<div className="col-12">
							<div className="mindayal-admin-table">
								<table id="table" className="table table-responsive-xl">
									<thead>
										<tr>
											<th className="text-nowrap">
												{translationService.translate("TR_FULLNAME")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_GROUP_NAME")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_EMAIL")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_PERSONAL_DOC_NUMBER")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_CREATE_DATE")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_IS_ARCHIVED")}
											</th>

											<th>{translationService.translate("TR_ACTION")}</th>
										</tr>
									</thead>
									<tbody>
										{students.map((item, index) => {
											return (
												<tr key={index} className="cursor-default">
													<td>
														<div>{item.studentName}</div>
													</td>
													<td>
														<div>{item.groupName}</div>
													</td>
													<td>
														<div>{item.studentEmail}</div>
													</td>
													<td>
														<div>{item.personalDocumentNumber}</div>
													</td>
													<td>
														<div>
															{" "}
															{moment(
																MainService.convertUTCDateToLocalDate(
																	new Date(item.createDate),
																),
															).format("ll HH:mm")}
														</div>
													</td>
													<td>
														{item.isArchived ? (
															<div className="payment-modal-status-icon-block">
																<i className="fas fa-check transaction-successful fs-30"></i>
															</div>
														) : (
															<div className="payment-modal-status-icon-block">
																<i className="fas fa-times transaction-unsuccessful fs-30"></i>
															</div>
														)}
													</td>

													<td>
														<button
															type="button"
															className={`mindalay--btn-primary-outline mindalay--btn-small`}
															onClick={() =>
																props.history.push(
																	`/${language}/organization-admin/course/students/${courseId}/${item.id}`,
																)
															}>
															{translationService.translate("TR_VIEW")}
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				) : (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
		)
	);
};

export default withRouter(CourseStudents);

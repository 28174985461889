import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import Carousel from "../../Components/Carousel/carousel";
import CourseBlock from '../../Components/Course/courseBlock';
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { ERROR_KEY, NUMBER_KEY, STUDENT_USER_TYPE, WISH_LIST_PRODUCTS_IDS_KEYS } from "../../Constants/mainKeys";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { Helmet } from "react-helmet";
import { MINDALAY_KEY } from './../../Constants/mainKeys';
import MainService from "../../Services/mainService";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3
  },
  smallTablet: {
    breakpoint: { max: 768, min: 425 },
    items: 3,
    slidesToSlide: 3
  },
  mobile: {
    breakpoint: { max: 425, min: 375 },
    items: 2,
    slidesToSlide: 2
  },
  smallMobile: {
    breakpoint: { max: 375, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const PublicProfile = (props) => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [instructorOrConsultantData, setInstructorOrConsultantData] = useState(null);
  const [courses, setCourses] = useState([]);
  const [topCourses, setTopCourses] = useState([]);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [instructorId, setInstructorId] = useState(null);
  const [consultantId, setConsultant] = useState(null);
  const [userId, setUserId] = useState(null);
  const [instructorRating, setInstructorRating] = useState(null);
  const [instructorTotalReviews, setInstructorTotalReviews] = useState(null);
  const [instructorRatingPercent, setInstructorRatingPercent] = useState(null);

  const [consultantRating, setConsultantRating] = useState(null);
  const [consultantTotalReviews, setConsultantTotalReviews] = useState(null);
  const [consultantRatingPercent, setConsultantRatingPercent] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (!props.match.params.userId) {
      const spinnerId = uuid();
      dispatch(addPageSpinner(spinnerId));
      ApiService.getTopCourses().then(response => {
        dispatch(removePageSpinner(spinnerId));
        response.data && setTopCourses(response.data);
      }).catch(error => getFail(error, spinnerId));
    }
    user && getRecommendedCourses();
  }, []);

  const getRecommendedCourses = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getRecommendedCourses().then(response => {
      response.data && setRecommendedCourses(response.data)
      dispatch(removePageSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  };

  useEffect(() => {
    setCourses([]);
    setUserId(props.match.params.userId);
    if (props.match.params.userId) {
      getInstructorOrConsultantData(props.match.params.userId);
    } else {
      user && user.userTypeId !== STUDENT_USER_TYPE && getCurrentInstructorData();
      getMyCourses();
    }
  }, [props.match.params.userId]);

  const getMyCourses = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getMyCoursesAsStudent().then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response.data && response.data.length) setCourses(response.data.reverse());
    }).catch(error => getFail(error, spinnerId));
  }

  const getInstructorCourses = (id) => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getInstructorCourses(id).then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response.data) {
        const data = [...response.data];
        setCourses(data);
        var wishListProductsByIds = localStorage.getItem(WISH_LIST_PRODUCTS_IDS_KEYS);
        wishListProductsByIds = JSON.parse(wishListProductsByIds) ? JSON.parse(wishListProductsByIds) : null;
        if (!user && wishListProductsByIds && wishListProductsByIds.length && data) {
          wishListProductsByIds.forEach(courseId => {
            data.find(course => {
              if (course.id === courseId) {
                course.isInWishlist = true
              }
            })
          });
        }
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const updateCourses = (id) => {
    const filteredCourses = [];
    const filteredTopCourses = [];
    const filteredRecommendedCourses = [];

    courses && courses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
      filteredCourses.push(element);
    })

    recommendedCourses && recommendedCourses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
      filteredRecommendedCourses.push(element);
    })

    topCourses && topCourses.forEach(element => {
      if (element.id === id) {
        if (element.isInWishlist === true) {
          element.isInWishlist = false;
        } else {
          element.isInWishlist = true;
        }
      };
      filteredTopCourses.push(element);
    })

    setCourses(filteredCourses);
    setTopCourses(filteredTopCourses);
    setRecommendedCourses(filteredRecommendedCourses);
  }

  const getCurrentInstructorData = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getCurrentInstructorData().then(response => {
      dispatch(removePageSpinner(spinnerId));
      setInstructorOrConsultantData(response.data);
    }).catch(error => getFail(error, spinnerId))
  }

  const getInstructorOrConsultantData = (id) => {
    if (!id) return;
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getInstructorOrConsultantData(+id).then(response => {
      if (response.data) {
        setInstructorOrConsultantData({ ...response.data });
        if (response.data.instructorId) {
          //consultant
          setConsultant(response.data.instructorId);
          getInstructorCourses(response.data.instructorId);
          //---Ratings
          setInstructorRating(response.data.instructorRating);
          setInstructorTotalReviews(response.data.instructorTotalReviews);
          const instructorRatingPercent = Math.round(response.data.instructorRating * 100) / 5;
          setInstructorRatingPercent(instructorRatingPercent);
          //---
          setConsultantRating(response.data.rating);
          setConsultantTotalReviews(response.data.totalReviews);
          const consultantRatingPercent = Math.round(response.data.rating * 100) / 5;
          setConsultantRatingPercent(consultantRatingPercent);
          //---End Ratings
        } else {
          //instructor
          setInstructorId(response.data.id);
          getInstructorCourses(response.data.id);
          //---Ratings
          setInstructorRating(response.data.rating);
          setInstructorTotalReviews(response.data.totalReviews);
          const instructorRatingPercent = Math.round(response.data.rating * 100) / 5;
          setInstructorRatingPercent(instructorRatingPercent);
          //---End Ratings
        }
      }
      dispatch(removePageSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId))
  }

  const goBack = () => {
    window.history.back();
  }

  const getFail = (error, spinnerId = null) => {
    spinnerId && dispatch(removePageSpinner(spinnerId))
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    goBack()
  }

  return translationService ? <section className="mt-5 mb-5 section m-section">
    {
      userId && instructorOrConsultantData && instructorOrConsultantData.fullName ?
        <Helmet>
          <title>{`${instructorOrConsultantData.fullName} | ${MINDALAY_KEY}`}</title>
        </Helmet>
        : <Helmet>
          {
            user ? <title>{`${user?.fullName} | ${MINDALAY_KEY}`}</title> : <title>{`${MINDALAY_KEY}`}</title>
          }
        </Helmet>
    }
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="profile-wrapper">
            <div className="row">
              <div className="col-md-3 col-12">
                {
                  !userId ?
                    <div className="profile-picture-wrapper">
                      <div style={{ backgroundImage: `url(${user?.avatarFilePath})` }} className="profile-picture"></div>
                      <h2 className="user-type text-md-left text-center">{translationService.translate(user?.userTypeDisplayName)}</h2>
                    </div>
                    :
                    <div className="profile-picture-wrapper">
                      <div style={{ backgroundImage: `url(${instructorOrConsultantData?.imagePath})` }} className="profile-picture"></div>
                      {
                        instructorId ?
                          <h2 className="user-type text-md-left text-center">{translationService.translate("TR_INSTRUCTOR")}</h2>
                          : null
                      }
                      {
                        consultantId ?
                          <h2 className="user-type text-md-left text-center">
                            {`${translationService.translate("TR_INSTRUCTOR")} / ${translationService.translate("TR_CONSULTANT")}`}
                          </h2>
                          : null
                      }
                    </div>
                }
              </div>
              <div className="col-md-9 col-12">
                <div className="profile-info-wrapper">
                  <div className="row">
                    <div className="col-12">
                      {
                        !userId ?
                          <div className="user-name-wrapper text-md-left text-center">
                            <h2 className="mb-2">{user?.fullName}</h2>
                            {
                              instructorOrConsultantData && instructorOrConsultantData.specialty
                                ?
                                !MainService.isJson(instructorOrConsultantData.specialty)
                                  ? <p className="mt-3 mb-0">{instructorOrConsultantData.specialty}</p>
                                  : <div className="d-flex flex-wrap word-wrap-break-word">
                                    {
                                      JSON.parse(instructorOrConsultantData.specialty).map((currentCSpecialty, index) => {
                                        return <p
                                          key={index}
                                          className="mt-3 mb-0 word-break-break-word"
                                        >
                                          {`${currentCSpecialty}${index + 1 !== JSON.parse(instructorOrConsultantData.specialty).length ? `,${'\u00A0'}` : ""}`}
                                        </p>
                                      })
                                    }
                                  </div>
                                : null
                            }
                          </div>
                          :
                          <div className="user-name-wrapper text-md-left text-center">
                            {
                              !userId
                                ? <h2 className="mb-2">{user?.fullName}</h2>
                                : <h2 className="mb-2">{instructorOrConsultantData?.fullName}</h2>
                            }
                            {
                              instructorOrConsultantData && instructorOrConsultantData.specialty
                                ?
                                !MainService.isJson(instructorOrConsultantData.specialty)
                                  ? <p className="mt-3 mb-0">{instructorOrConsultantData.specialty}</p>
                                  : <div className="d-flex flex-wrap word-break-break-word">
                                    {
                                      JSON.parse(instructorOrConsultantData.specialty).map((currentCSpecialty, index) => {
                                        return <p
                                          key={index}
                                          className="mt-3 mb-0"
                                        >
                                          {`${currentCSpecialty}${index + 1 !== JSON.parse(instructorOrConsultantData.specialty).length ? `,${'\u00A0'}` : ""}`}
                                        </p>
                                      })
                                    }
                                  </div>
                                : null
                            }
                          </div>
                      }
                    </div>
                    {
                      instructorOrConsultantData ?
                        <div className="col-12 pt-0">
                          {
                            instructorOrConsultantData.bio ?
                              <Auxiliary>
                                <hr className="my-1" />
                                <div className="info-wrapper">
                                  <div className="py-2">
                                    <strong className="section-title">{translationService.translate("TR_BIOGRAPHY")}</strong>
                                  </div>
                                  <p className="word-wrap-break-word" title={instructorOrConsultantData.bio}>{instructorOrConsultantData.bio}</p>
                                </div>
                              </Auxiliary>
                              : null
                          }
                          {
                            (instructorRating || typeof instructorRating === NUMBER_KEY) && userId ?
                              <Auxiliary>
                                <hr />
                                <div className="info-wrapper">
                                  <div className="py-2">
                                    <strong className="section-title">{translationService.translate("TR_INSTRUCTOR_RATING")}</strong>
                                    <div className="course-review-wrapper d-flex align-items-center mt-2">
                                      <span className="course-review-number text-orange mr-1">{instructorRating}</span>
                                      <div className="rating">
                                        <div style={{ width: `${instructorRatingPercent}%` }}></div>
                                      </div>
                                      {
                                        (instructorTotalReviews || typeof instructorTotalReviews === NUMBER_KEY) ?
                                          <span className="course-review-count"><span>(</span>{instructorTotalReviews}<span>)</span></span>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </Auxiliary>
                              : null
                          }
                          {
                            (consultantRating || typeof consultantRating === NUMBER_KEY) && userId ?
                              <Auxiliary>
                                <hr />
                                <div className="info-wrapper">
                                  <div className="py-2">
                                    <strong className="section-title">{translationService.translate("TR_CONSULTANT_RATING")}</strong>
                                    <div className="course-review-wrapper d-flex align-items-center mt-2">
                                      <span className="course-review-number text-orange mr-1">{consultantRating}</span>
                                      <div className="rating">
                                        <div style={{ width: `${consultantRatingPercent}%` }}></div>
                                      </div>
                                      {
                                        (consultantTotalReviews || typeof consultantTotalReviews === NUMBER_KEY) ?
                                          <span className="course-review-count"><span>(</span>{consultantTotalReviews}<span>)</span></span>
                                          : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </Auxiliary>
                              : null
                          }
                          {
                            instructorOrConsultantData.consultationCategoryResponses ?
                              <Auxiliary>
                                <hr />
                                <div className="info-wrapper">
                                  <div className="py-2">
                                    <strong className="section-title">{translationService.translate("TR_CONSULTATION_CATEGORIES")}</strong>
                                  </div>
                                  <ul>
                                    {instructorOrConsultantData.consultationCategoryResponses.map(consultationCategory => {
                                      return <li key={consultationCategory.id}>
                                        {consultationCategory.categoryName}
                                      </li>
                                    })}
                                  </ul>
                                </div>
                              </Auxiliary>
                              : null
                          }
                        </div> : null
                    }
                    {
                      courses && courses.length ?
                        <div className="col-12">
                          <hr />
                          <div className="py-2 d-flex align-items-center justify-content-between">
                            {
                              !userId
                                ?
                                <strong className="section-title">{translationService.translate("TR_MY_COURSES")}<span> ({courses.length})</span></strong>
                                :
                                <strong className="section-title">{translationService.translate("TR_COURSES")}<span> ({courses.length})</span></strong>
                            }
                          </div>
                          <div className="course-slider-wrapper course-carousel">
                            <div className="course-slider">
                              <Carousel
                                responsive={responsive}
                                arrows={true}
                              >
                                {
                                  courses
                                    ? courses.map(course =>
                                      <CourseBlock
                                        key={course.id}
                                        course={course}
                                        updateCourses={updateCourses}
                                      />)
                                    : null
                                }
                              </Carousel>
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    {
                      courses && !courses.length && !userId ?
                        <Auxiliary>
                          <div className="col-12 my-2">
                            <hr />
                            <strong className="section-title">{translationService.translate("TR_MY_COURSES")}<span> ({courses.length})</span></strong>
                          </div>
                          <div className="col-12">
                            <p className="float-left">{translationService.translate("TR_YOU_DID_NOT_BUY_COURSES_YET")}</p>
                          </div>
                        </Auxiliary>
                        : null
                    }
                    {
                      courses && !courses.length && userId ?
                        <Auxiliary>
                          <div className="col-12">
                            <hr />
                            <strong className="section-title">{translationService.translate("TR_COURSES")}<span> ({courses.length})</span></strong>
                          </div>
                          <div className="col-12">
                            <p className="float-left">{translationService.translate("TR_THERE_IS_NOT_CREATED_COURSES_BY_THIS_INSTRUCTOR")}</p>
                          </div>
                        </Auxiliary>
                        : null
                    }
                    {
                      topCourses && topCourses.length && !userId ?
                        <div className="col-12">
                          <hr />
                          <div className="py-2 d-flex align-items-center justify-content-between">
                            <strong className="section-title">{translationService.translate("TR_TOP_COURSES")}<span> ({topCourses.length})</span></strong>
                          </div>
                          <div className="course-slider-wrapper course-carousel">
                            <div className="course-slider">
                              <Carousel
                                responsive={responsive}
                                arrows={true}
                              >
                                {
                                  topCourses.map(course =>
                                    <CourseBlock
                                      key={course.id}
                                      course={course}
                                      updateCourses={updateCourses}
                                    />)
                                }
                              </Carousel>
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    {
                      recommendedCourses && recommendedCourses.length ?
                        <div className="col-12">
                          <hr />
                          <div className="py-2 d-flex align-items-center justify-content-between">
                            <strong className="section-title">{translationService.translate("TR_RECOMMENDED_COURSES")}<span> ({recommendedCourses.length})</span></strong>
                          </div>
                          <div className="course-slider-wrapper course-carousel">
                            <div className="course-slider">
                              <Carousel
                                responsive={responsive}
                                arrows={true}
                              >
                                {
                                  recommendedCourses.map(course =>
                                    <CourseBlock
                                      key={course.id}
                                      course={course}
                                      updateCourses={updateCourses}
                                    />)
                                }
                              </Carousel>
                            </div>
                          </div>
                        </div>
                        : null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> : null;
}

export default withRouter(PublicProfile);
import { TRANSLATE_KEYS } from "../Constants/mainKeys";

class TranslationService {
  translations = null;

  constructor(translations) {
    this.translations = [...translations];
  }

  translate = (key) => {
    if (key) {
      const selectedTranslation = this.translations.find(data => data.shortKey === key);
      if (window.location.search) {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get(TRANSLATE_KEYS)) {
          return selectedTranslation ? selectedTranslation.shortKey : '';
        }
      }
      return selectedTranslation ? selectedTranslation.translation1 : '';
    }
  }
}

export default TranslationService;
import {
  PURE_LAYOUT_BACK_KEY,
  CURICULUM_BREAD_CRUMB,
  CONSULTATION_CONTRACT_BACK_KEY,
  FAQ_KEY,
  FAQ_PROPERTY_ID_KEY,
  BACK_URL_KEY,
  WEBINAR_DATA_KEY,
} from "../../Constants/mainKeys";
import {
  GET_HELP_DATA_SUCCESS,
  SET_CURICULUMN_BREAD_CRUMB,
  SET_HELP_DATA,
  SET_PURE_LAYOUT_BACK_URL,
  SET_CONSULTATION_CONTRACT_BACK_URL,
  SET_CURRENT_FAQ_CONTENT,
  SET_FAQ_PROPERTY_ID,
  SET_BACK_URL,
  SHOW_HELP_ALERT,
  GET_CURRENT_QUESTION_MARK_DATA_SUCCESS,
  SET_QUESTION_MARK_SPINNER,
  SET_WEBINAR_DATA,
  REMOVE_WEBINAR_DATA,
  SET_B2B_WEBINAR_DATA
} from "../../Constants/reduxKeys";

let initialState = {
  pureLayoutBackUrl: localStorage.getItem(PURE_LAYOUT_BACK_KEY),
  consulatationContractBackUrl: localStorage.getItem(CONSULTATION_CONTRACT_BACK_KEY),
  curiculumBreadCrumb: JSON.parse(localStorage.getItem(CURICULUM_BREAD_CRUMB)),
  currentFaqContent: JSON.parse(localStorage.getItem(FAQ_KEY)) || null,
  faqPropertyId: localStorage.getItem(FAQ_PROPERTY_ID_KEY),
  helpAlerts: null,
  isShowHelpAlert: false,
  currentQuestionMarkData: null,
  isShowQuestionMarkSpinner: false,
  currentWebinarData: null,
  isShowB2BWebinarNotification: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULTATION_CONTRACT_BACK_URL:
      localStorage.setItem(CONSULTATION_CONTRACT_BACK_KEY, action.payload);
      return {
        ...state,
        consulatationContractBackUrl: action.payload,
      };
    case SET_PURE_LAYOUT_BACK_URL:
      localStorage.setItem(PURE_LAYOUT_BACK_KEY, action.payload);
      return {
        ...state,
        pureLayoutBackUrl: action.payload,
      };
    case SET_BACK_URL:
      localStorage.setItem(BACK_URL_KEY, action.payload);
      return {
        ...state,
        backUrl: action.payload,
      };
    case SET_CURICULUMN_BREAD_CRUMB:
      localStorage.setItem(CURICULUM_BREAD_CRUMB, JSON.stringify(action.payload));
      return {
        ...state,
        curiculumBreadCrumb: action.payload
      }
    case GET_HELP_DATA_SUCCESS:
      return {
        ...state,
        helpAlerts: action.payload,
      }
    case SET_HELP_DATA:
      return {
        ...state,
        helpAlerts: action.payload.helpData
      }
    case SET_CURRENT_FAQ_CONTENT:
      return {
        ...state,
        currentFaqContent: action.payload
      }
    case SET_FAQ_PROPERTY_ID:
      return {
        ...state,
        faqPropertyId: action.payload
      }
    case SHOW_HELP_ALERT:
      return {
        ...state,
        isShowHelpAlert: action.payload
      }
    case GET_CURRENT_QUESTION_MARK_DATA_SUCCESS:
      return {
        ...state,
        currentQuestionMarkData: action.payload && action.payload.data ? action.payload.data : null
      }
    case SET_QUESTION_MARK_SPINNER:
      return {
        ...state,
        isShowQuestionMarkSpinner: action.payload
      }
    case SET_WEBINAR_DATA:
      localStorage.setItem(WEBINAR_DATA_KEY, JSON.stringify(action.payload));
      return {
        ...state,
        currentWebinarData: action.payload
      }
    case REMOVE_WEBINAR_DATA:
      localStorage.removeItem(WEBINAR_DATA_KEY)
      return {
        ...state,
        currentWebinarData: null
      }
    case SET_B2B_WEBINAR_DATA:
      return {
        ...state,
        isShowB2BWebinarNotification: action.payload
      }
    default:
      break;
  }
  return state;
};

export default reducer;
import React from 'react';
import WelcomeSvg from "../../../assets/icons/welcome.svg"
import CongratulationSvg from "../../../assets/icons/congrats.svg"


const WelcomeCongratulationMessage = (props) => {
  return (
    props.messageType ?
      <div className="modal-window">
        <div className="content-body">
          <div className="content-title text-center pt-75">
            <h1 className="w-100 brand-color">
              {props.title}
            </h1>
            <img src={props.messageType === "welcome" ? WelcomeSvg : CongratulationSvg} alt="/" />
          </div>
          <hr className="mb-0" />
          <i className="fas fa-times" onClick={props.cancel}></i>
          <div className="p-4 text-center course-messages-block" title={props.message}>
            <p>
              {props.message}
            </p>
          </div>
        </div>
      </div>
      : null
  );
}

export default WelcomeCongratulationMessage;

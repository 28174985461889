import { SET_SERACH_TEXT } from "../../Constants/reduxKeys";

let initialState = {
  serachText: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SERACH_TEXT:
      return {
        ...state,
        serachText: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import InstructorCourseBlock from "../../Components/Course/InstructorCourseBlock";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "./../../Store/Actions/spinner";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import OrganizationStudentCourseBlock from "../../Components/Course/OrganizationStudentCourseBlock";

const MyCourses = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);

	const [courses, setCourses] = useState([]);
	const [translationService, setTranslationService] = useState(null);
	const [numberOfDisplayedCourses, setNumberOfDisplayedCourses] = useState(12);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getMyCourses();
	}, []);

	const getMyCourses = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getMyCoursesAsOrgStudent()
			.then(response => {
				if (response.data && response.data.length) setCourses(response.data);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_MY_COURSES")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_MY_COURSES")}
						pageName="student_courses"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-12">
					<div className="row">
						{courses && courses.length ? (
							courses.map((course, courseIndex) => {
								return numberOfDisplayedCourses > courseIndex ? (
									<div
										key={course.id}
										className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
										<OrganizationStudentCourseBlock
											course={course}
											getCourses={getMyCourses}
										/>
									</div>
								) : null;
							})
						) : (
							<div className="no-data-container">
								<div className="no-data-wrapper">
									<p className="col-12">
										{translationService.translate("TR_YOU_DONT_HAVE_COURSES")}
									</p>
									<img src={NoDataImage} alt="/" />
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="col-12 mt-3">
					{courses && courses.length > numberOfDisplayedCourses ? (
						<button
							type="button"
							className="mindalay--btn-dark w-100 mb-3"
							onClick={() =>
								setNumberOfDisplayedCourses(numberOfDisplayedCourses + 12)
							}>
							{translationService.translate("TR_SHOW_MORE")}
						</button>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};

export default MyCourses;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import EditSvg from "../../../Components/Svg/editSvg";
import DeleteSvg from "../../../Components/Svg/deleteSvg";

const SubCategories = props => {
	const { subCategories } = props;
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<ul className="dropdown-sub-menu second-level-categories-admin">
			{subCategories && subCategories.length
				? subCategories.map(subCategory => {
						return (
							<div
								key={subCategory.id}
								className="accordion-wrapper course-pass-section">
								<div className={`card-header d-flex align-items-center`}>
									<h5 className="mb-0 text-elipsis card-course-name">
										{subCategory.name}
									</h5>
									<div className="mr-4">
										<div className="course-wrapper--instructor ">
											<div className="course-button-group">
												<div className="course-button-group d-flex mr-5">
													<Link
														className="course-edit-button mr-1"
														to="#"
														onClick={event => event.stopPropagation()}>
														<EditSvg />
													</Link>
													<Link
														className="course-delet-button ml-1"
														to="#"
														onClick={event =>
															props.categoryDelete(
																event,
																subCategory,
																"subCategory",
															)
														}>
														<DeleteSvg />
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
				  })
				: null}
		</ul>
	) : null;
};
export default SubCategories;

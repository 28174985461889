import React from 'react';

const FeedbackSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g><g xmlns="http://www.w3.org/2000/svg"><path d="m138.813 388.485-43.294-6.291-19.362-39.231h-10.314l-19.362 39.231-43.294 6.291-3.187 9.809 31.328 30.537-7.396 43.119 8.345 6.063 38.723-20.358 38.723 20.358 8.345-6.063-7.396-43.119 31.328-30.537z" fill="#b53471" data-original="#000000" /><path d="m512 398.294-3.187-9.809-43.294-6.291-19.362-39.231h-10.314l-19.362 39.231-43.294 6.291-3.187 9.809 31.328 30.537-7.396 43.119 8.345 6.063 38.723-20.358 38.723 20.358 8.345-6.063-7.396-43.119z" fill="#b53471" data-original="#000000" /><path d="m323.813 388.485-43.294-6.291-19.362-39.231h-10.314l-19.362 39.231-43.294 6.291-3.187 9.809 31.328 30.537-7.396 43.119 8.345 6.063 38.723-20.358 38.723 20.358 8.345-6.063-7.396-43.119 31.328-30.537z" fill="#b53471" data-original="#000000" /><path d="m256 33.987c-74.715 0-135.5 60.785-135.5 135.5s60.785 135.5 135.5 135.5 135.5-60.785 135.5-135.5-60.785-135.5-135.5-135.5zm-11.592 183.608-53.574-53.574 21.213-21.213 32.361 32.361 55.546-55.546 21.213 21.213z" fill="#b53471" data-original="#000000" /></g></g></svg>
  );
}

export default FeedbackSvg;

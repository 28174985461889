
import { takeLatest, call, put } from 'redux-saga/effects';
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import ApiService from '../../Services/apiService';
import { setQuestionMarkSpinner, showOrHideHelpAlert } from '../Actions/main';
import {
  GET_HELP_DATA,
  GET_HELP_DATA_SUCCESS,
  GET_CURRENT_QUESTION_MARK_DATA,
  GET_CURRENT_QUESTION_MARK_DATA_SUCCESS,
} from '../../Constants/reduxKeys';

export default function* watcher() {
  yield takeLatest(GET_HELP_DATA, getHelpData);
  yield takeLatest(GET_CURRENT_QUESTION_MARK_DATA, getCurrentQuestionMarkData);
};

function* getCurrentQuestionMarkData(action) {
  const { pageName, key } = action.payload;
  try {
    const currentQuestionMarkData = yield call(ApiService.getCurrentQuestionMarkData, pageName, key);
    yield put({
      type: GET_CURRENT_QUESTION_MARK_DATA_SUCCESS,
      payload: currentQuestionMarkData,
    });

  } catch (error) {
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}

function* getHelpData(action) {
  const key = action.payload;
  try {
    yield put(setQuestionMarkSpinner(true));
    var helpData = [];
    var questionMarkData = yield call(ApiService.getHelpData, key);
    var questionMarks = document.querySelectorAll(".info-title");

    if (questionMarkData && questionMarkData.data && questionMarkData.data.length && questionMarks && questionMarks.length) {
      helpData = [...questionMarkData.data];
      helpData.sort((first, second) => first.displayOrder - second.displayOrder);
      helpData.forEach(data => {
        questionMarks.forEach((questionMark, index) => {
          if (questionMark.id === data.name) {
            data.displayOrder = index + 1;
          }
        });
      })
    }
    yield put({
      type: GET_HELP_DATA_SUCCESS,
      payload: helpData,
    });
    yield put(setQuestionMarkSpinner(false));
  } catch (error) {
    yield put(showOrHideHelpAlert(false));
    yield put(setQuestionMarkSpinner(false));
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  };
};


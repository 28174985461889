import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setPureLayoutBackUrl } from "../../../Store/Actions/main";
import file from "./../../../assets/icons/file.png";

const AssignmentsView = props => {
	const { translationService, assignments = [] } = props;
	const { studentCourseId, topicId, courseId } = props.match.params;
	const dispatch = useDispatch();

	const language = useSelector(state => state.language.language);

	const setBackUrl = url => {
		dispatch(setPureLayoutBackUrl(url));
	};

	const getFilPath = item => {
		if (item.files && item.files.length) {
			const imgpath = item.files.find(el => el.mimeType.includes("image"));
			if (imgpath) {
				return <img src={imgpath.filePath} alt="/" />;
			} else return <img src={file} alt="/" />;
		} else {
			return null;
		}
	};

	return translationService && assignments && assignments.length
		? assignments.map(item => {
				return (
					<div key={item.id} className="col-lg-4 col-12 mt-3">
						<div className="course-pass-media-file-wrapper box-shadow-4 cursor-default h-100">
							<div className="d-flex align-items-center">
								{/* {getFilPath(item)} */}
								<div className="ml-3 overflow-hidden">
									<h4 className="my-2">{item.title}</h4>
									{/* <p title={item.description}>{item.description}</p> */}
								</div>
							</div>
							<hr />
							<div className="mt-3">
								<Link
									to={`${
										topicId
											? `/${language}/passcourse/video/${courseId}/${studentCourseId}/${topicId}/assignment/${item.id}`
											: `/${language}/passcourse/${courseId}/${studentCourseId}/assignment/${item.id}`
									}`}
									// onClick={() =>
									// 	setBackUrl(
									// 		topicId
									// 			? `/${language}/passcourse/video/${courseId}/${studentCourseId}/${topicId}`
									// 			: `/${language}/passcourse/${courseId}/${studentCourseId}`,
									// 	)
									// }
									className="mindalay--btn-default w-100 mindalay--btn-link">
									{/* Go to assignment */}
									{translationService.translate("TR_VIEW")}
								</Link>
							</div>
						</div>
					</div>
				);
		  })
		: null;
};

export default withRouter(AssignmentsView);

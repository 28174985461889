import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";

function bytesToMB(bytes) {
	if (!bytes) return 0;
	let mb = bytes / (1024 * 1024);
	return mb + " MB";
}

function Paymentpackages(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { user } = useSelector(state => state.user);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [paymentpackages, setPaymentpackages] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDeveloperPaymentPackages();
	}, []);

	const handlePageClick = event => {
		// getPaymentpackages(event.selected + 1);
		setActivePageNumber(event.selected);
	};
	const getDeveloperPaymentPackages = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDeveloperPaymentPackages()
			.then(response => {
				if (response.data) {
					setPaymentpackages(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deletePaymentpackage = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deletePaymentpackage(item.id)
				.then(() => {
					setPaymentpackages(
						paymentpackages.filter(_item => _item.id !== item.id),
					);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_PAYMENT_PACKAGES")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<Link
						to={`/${language}/developer/payment-package-form`}
						className="mindalay--btn-default">
						{"Create payment package"}
					</Link>
				</div>
			</div>
			{paymentpackages && paymentpackages.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_PACKAGE_TYPE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_DESCRIPTION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_COST")}
										</th>
										<th className="text-nowrap">
											{translationService.translate(
												"TR_WEBINAR_STUDENTS_MAX_COUNT",
											)}
										</th>
										<th className="text-nowrap">
											{translationService.translate(
												"TR_ORGANIZATION_USERS_MAX_COUNT",
											)}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_STORAGE_SIZE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ALLOW_VIDEO_SAVE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_DOCUMENT_SERVICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPE_SERVICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_COURSE_SERVICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_WEBINAR_SERVICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_FILE_LIBRARY_SERVICE")}
										</th>
										<th className="text-nowrap">Inner payment service</th>
										<th className="text-nowrap">Gamification service</th>
										<th className="text-nowrap">White label service</th>

										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{paymentpackages.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div
														className="mindalay-admin-table-td-item text-capitalize"
														style={{ width: "150px" }}>
														{item.packageName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.packageType}
													</div>
												</td>
												<td>
													<div
														className="mindalay-admin-table-td-item text-capitalize"
														style={{ width: "300px" }}>
														{item.description}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.cost}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.webinarStudentsMaxCount}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.userMaxCount}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{bytesToMB(item.storageSize)}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.allowSaveVideo ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.documentService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.userTypeService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.courseService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.webinarService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.fileLibraryService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.innerPaymentService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.gamificationService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.whiteLabelService ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={`/${language}/developer/payment-package-form/${item.id}`}
																title={translationService.translate("TR_EDIT")}
																// onClick={e => {
																// 	e.stopPropagation();
																// 	props.history.push(
																// 		`/${language}/developer/payment-package-form/${item.id}`,
																// 	);
																// }}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															<Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deletePaymentpackage(item);
																}}>
																<DeleteSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}

			{/* {pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null} */}
		</div>
	) : null;
}

export default withRouter(Paymentpackages);

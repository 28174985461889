import React, { Component } from 'react';
import { connect } from 'react-redux';
import Textarea from '../../../Components/Inputs/textArea';
import TranslationService from '../../../Services/translationService';
import { ERROR_KEY, RATINGS } from "../../../Constants/mainKeys";
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import ApiService from '../../../Services/apiService';
import AlertService from '../../../Services/alertService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import MainService from '../../../Services/mainService';
import moment from 'moment';
import ShowMoreText from "react-show-more-text";

class FeedbackForConsultant extends Component {
  ratings = RATINGS

  state = {
    translationService: null,
    isInvalidSubmit: false,
    description: '',
    entitytype: 4,
    descriptionMaxLength: 2000,
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    var { entitytype, description } = this.state;
    const { feedBackData, contract } = this.props;
    const spinnerId = uuid();
    if (!description || !description.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      this.props.addPageSpinner(spinnerId);
      description = description.trim();
      const data = {
        entitytype,
        replyText: description,
        id: feedBackData.id
      };
      ApiService.sendFeedBackFromConsultant(data).then(() => {
        this.props.removePageSpinner(spinnerId);
        this.props.getContractById(contract.id);
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {
    const { translationService, description, isInvalidSubmit, descriptionMaxLength } = this.state;
    const { feedBackData } = this.props;

    return (
      translationService && feedBackData ?
        <Auxiliary>
          <div className="course-feedback p-4">
            <div className="feedback-wrapper">
              <p className="title">{feedBackData.title}</p>
              <div className="d-flex">
                <div style={{ backgroundImage: `url(${feedBackData.reviewerAvatarFilePath})` }} className="image-wrapper" ></div>
                <div className="feedback-body">
                  <h4 className="feedback-usernam">{feedBackData.reviewerName}</h4>
                  <div className="d-flex align-items-center">
                    <div className="rating"><div style={{ width: `${feedBackData.rating * 20}%` }}></div></div>
                    <p className="ml-2">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                  </div>
                  {
                    feedBackData.reviewText ?
                      <div className=" mt-3">
                        <ShowMoreText
                          lines={2}
                          more={translationService.translate("TR_SHOW_MORE")}
                          less={translationService.translate("TR_LESS")}
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <p>{feedBackData.reviewText}</p>
                        </ShowMoreText>
                      </div>
                      : null
                  }
                </div>
              </div>
              {
                feedBackData.replyText ?
                  <div className="feedback-reply-body">
                    <div className="d-flex">
                      <div style={{ backgroundImage: `url(${feedBackData.replierAvatarFilePath})` }} className="image-wrapper" ></div>
                      <div className="feedback-body">
                        <h4 className="feedback-usernam">{feedBackData.replierName}</h4>
                        <div className="d-flex align-items-center">
                          <p className="ml-2">{moment(MainService.convertUTCDateToLocalDate(new Date(feedBackData.createdOn))).format("MMM DD, H:mm")}</p>
                        </div>
                        <ShowMoreText
                          lines={2}
                          more={translationService.translate("TR_SHOW_MORE")}
                          less={translationService.translate("TR_LESS")}
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <p>{feedBackData.replyText}</p>
                        </ShowMoreText>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>
          </div>
          {
            !feedBackData.replyText ?
              <div className="tab-pane" id="consultation-contract--feedback-tab" aria-labelledby="consultation-contract--feedback-tab">
                <div className="consultation-feeback-wrapper">
                  <form >
                    <Textarea
                      id="description"
                      rows="5"
                      name="description"
                      value={description}
                      max={descriptionMaxLength}
                      placeholder={translationService.translate("TR_TYPE_YOUR_ANSWER")}
                      labelValue={translationService.translate("TR_ANSWER")}
                      isInvalidSubmit={isInvalidSubmit}
                      onChange={(event) => this.onChange(event, descriptionMaxLength)}
                    />
                    <div className="text-right">
                      <button type="submit" className="mindalay--btn-default" onClick={this.onSubmit}>{translationService.translate("TR_SUBMIT_ANSWER")}</button>
                    </div>
                  </form>
                </div>
              </div>
              : null
          }
        </Auxiliary>
        : null

    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForConsultant);



import React from "react";

const KeySvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 836.127 836.127" xmlSpace="preserve"><g>
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M827.065,263.7c0-35.6-7-70.1-20.7-102.7c-13.3-31.4-32.3-59.6-56.5-83.8s-52.4-43.2-83.8-56.5   C633.565,6.9,598.965,0,563.365,0s-70.1,7-102.7,20.7c-31.399,13.3-59.6,32.3-83.8,56.5s-43.2,52.4-56.5,83.8   c-13.8,32.5-20.7,67.1-20.7,102.7c0,44.5,11.2,88.1,32.5,126.9L16.465,706c-7.3,7.3-9.6,18.4-5.1,28.9c0.9,2.2,2.4,4.2,4.1,5.899   l70.7,70.7c1.7,1.7,3.7,3.101,5.9,4.101c10.5,4.5,21.6,2.3,28.9-5.101l43.7-43.7l62.9,62.9c1.7,1.7,3.7,3.1,5.899,4.1   c10.5,4.5,21.601,2.301,28.9-5.1l69.7-69.7c1.7-1.7,3.1-3.7,4.1-5.899c4.5-10.5,2.3-21.601-5.1-28.9l-61.9-61.9l167.2-167.3   c38.7,21.3,82.4,32.5,126.9,32.5c35.6,0,70.1-7,102.699-20.7c31.4-13.3,59.601-32.3,83.801-56.5   c24.199-24.199,43.199-52.399,56.5-83.8C820.065,333.8,827.065,299.3,827.065,263.7z M655.766,356.2   c-24.7,24.7-57.601,38.3-92.5,38.3c-34.9,0-67.801-13.6-92.5-38.3c-24.7-24.7-38.4-57.6-38.4-92.5c0-35,13.6-67.8,38.3-92.5   s57.601-38.3,92.5-38.3c34.9,0,67.8,13.6,92.5,38.3s38.3,57.6,38.3,92.5C694.165,298.6,680.565,331.5,655.766,356.2z" fill="#000000" data-original="#000000" />
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
    <g xmlns="http://www.w3.org/2000/svg">
    </g>
  </g></svg>
}

export default KeySvg;
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import TranslationService from '../../../Services/translationService';

const FileLibraryAudio = (props) => {

    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const { audio } = props;

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    return translationService ? audio && audio.length ? <div className="mindalay-files-gallery-container">
        <div className="row">
            {
                audio.map((audioItem, index) => {
                    return <div key={index} className="col-lg-4 col-sm-6 col-12 my-2">
                        <div className="mindalay-file-wrapper mindalay-video">
                            <div className="card-body">
                            <audio src={audioItem.filePath} controls />
                                <p className="card-text">{audioItem.name}</p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
        : <div className="no-data-container mt-3">
            <div className="no-data-wrapper">
                <p>{translationService.translate("TR_YOU_HAVE_NO_AUDIO")}</p>
                <img src={NoDataImage} alt="/" />
            </div>
        </div>
        : null
}

export default FileLibraryAudio;


import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import SubmitButton from "../../Components/SubmitButton/submitButton";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { setCurrentCourse } from "../../Store/Actions/course";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";
import { TR_DATA_SAVED_KEY } from "../../Constants/translationKeys";
import { ERROR_KEY, SUCCESS_KEY } from "../../Constants/mainKeys";
import Textarea from "../../Components/Inputs/textArea";
import { COURSE_DASHBOARD_KEY, PRICING_KEY } from "../../Constants/urlKeys";

class TargetStudents extends Component {
  state = {
    form: {
      id: this.props.courseData.id,
      whatYouWillLearn: [],
      requirements: [],
      targetStudents: [],
    },
    courseData: this.props.courseData,
    spinnerId: uuid(),
    descriptionsMaxLength: 200,
    currentBlockMaxLength: 1500, //2000
    isInvalidSubmit: false,
    failedFields: [],
    changes: false
  };

  componentDidMount() {
    this.setTranslations();
    this.state.courseData && this.setCourseData();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  setCourseData = () => {
    const courseData = { ...this.state.courseData };
    const whatYouWillLearn = courseData.whatYouWillLearn && courseData.whatYouWillLearn.length && courseData.whatYouWillLearn !== "{}" ?
      this.convertStrToArray(courseData.whatYouWillLearn) : [{ 1: "" }];
    const requirements = courseData.requirements && courseData.requirements.length && courseData.requirements !== "{}" ?
      this.convertStrToArray(courseData.requirements) : [{ 1: "" }];
    const targetStudents = courseData.targetStudents && courseData.targetStudents.length && courseData.targetStudents !== "{}" ?
      this.convertStrToArray(courseData.targetStudents) : [{ 1: "" }];
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        whatYouWillLearn,
        requirements,
        targetStudents,
      }
    }));
  }

  addDescription = name => {
    const { currentBlockMaxLength } = this.state;
    if (name) {
      const descriptionArr = [...this.state.form[name]];
      if (currentBlockMaxLength >= JSON.stringify(descriptionArr).length) {
        if (
          descriptionArr &&
          descriptionArr.length &&
          (Object.values(descriptionArr[descriptionArr.length - 1])[0] || !descriptionArr.length) &&
          Object.values(descriptionArr[descriptionArr.length - 1])[0].trim()
        ) {
          // descriptionArr.push({ [descriptionArr.length + 1]: "" });
          var lastObject = descriptionArr[descriptionArr.length - 1];
          descriptionArr.push({[+Object.keys(lastObject)[0] + 1]: "" });
          this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, [name]: descriptionArr } }));
        }
      }
    }
  }

  removeDescription = (array, obj) => {
    const index = array.indexOf(obj);
    if (index > -1) { array.splice(index, 1) }
    this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, array } }));
  };

  onChangeDesc = (event, maxLength = null) => {

    if (maxLength && maxLength < event.target.value.length) { return; }
    if (event.target.value) event.target.value.trim();
    const descriptionArr = [...this.state.form[event.target.name]];
    if (this.state.currentBlockMaxLength >= JSON.stringify(descriptionArr).length) {
      const descriptionArrItem = descriptionArr.find(descriptionArrItem => Object.keys(descriptionArrItem)[0] === event.target.id);
      descriptionArrItem[event.target.id] = event.target.value;
      this.setState(prevState => ({ ...prevState, form: { ...prevState.form } }));
    }
  };

  removeIsEmptyFields = (array) => {
    array.forEach((element, index) => {
      if (!Object.values(element)[0].length) {
        array.splice(index, 1)
      }
    });
    return array;
  }

  trimFields = (array) => {
    array.forEach(elem => {
      for (const i in elem) {
        if (elem[i].trim()) {
          elem[i] = elem[i].trim();
        } else delete elem[i]
      }
    })
    return array
  }

  onSubmit = event => {
    event && event.preventDefault();
    const form = JSON.parse(JSON.stringify(this.state.form));
    const { translationService, spinnerId } = this.state;
    this.setState({ changes: false });
    form.whatYouWillLearn && this.removeIsEmptyFields(form.whatYouWillLearn);
    form.requirements && this.removeIsEmptyFields(form.requirements);
    form.targetStudents && this.removeIsEmptyFields(form.targetStudents);
    if (!form.whatYouWillLearn.length || (2 > form.whatYouWillLearn.length > 1 && !form.whatYouWillLearn[0]["1"])) {
      form.whatYouWillLearn = "";
    }
    if (!form.requirements.length || (2 > form.requirements.length > 1 && !form.requirements[0]["1"])) {
      form.requirements = "";
    }
    if (!form.targetStudents.length || (2 > form.targetStudents.length > 1 && !form.targetStudents[0]["1"])) {
      form.targetStudents = "";
    }
    if (!form.targetStudents && !form.requirements && !form.whatYouWillLearn) {
      this.setState({ isInvalidSubmit: true });
      this.props.removeButtonSpinner(spinnerId);
    } else {
      this.props.addButtonSpinner(spinnerId);
      form.whatYouWillLearn = form.whatYouWillLearn && this.trimFields(form.whatYouWillLearn);
      form.requirements = form.requirements && this.trimFields(form.requirements);
      form.targetStudents = form.targetStudents && this.trimFields(form.targetStudents);
      const formData = new FormData();
      formData.append('id', form.id);
      form.whatYouWillLearn && formData.append('whatYouWillLearn', form.whatYouWillLearn.length ? this.convertArrayToString(form.whatYouWillLearn) : null);
      form.requirements && formData.append('requirements', form.requirements.length ? this.convertArrayToString(form.requirements) : null);
      form.targetStudents && formData.append('targetStudents', form.targetStudents.length ? this.convertArrayToString(form.targetStudents) : null);
      ApiService.courseTargetStudentsUpdate(formData).then(response => {
        AlertService.alert(SUCCESS_KEY, translationService.translate(TR_DATA_SAVED_KEY));
        this.props.setCurrentCourse(response.data);
        this.props.removeButtonSpinner(spinnerId);
        this.props.history.push(`/${this.props.language}/${COURSE_DASHBOARD_KEY}/${this.props.courseData.id}/${PRICING_KEY}`);
      }).catch(errors => this.submitFail(spinnerId, errors));
    }
  }

  submitFail = (spinnerId, errors) => {
    this.props.removeButtonSpinner(spinnerId);
    const failedFields = errors;
    this.setState({ failedFields });
    errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
  }

  convertArrayToString = (array) => {
    return JSON.stringify(array.reduce((obj, item) => Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] }), {}));
  };

  convertStrToArray = (str) => {
    const array = [];
    str = JSON.parse(str);
    const keys = Object.keys(str);
    const values = Object.values(str);
    keys.forEach((key, index) => {
      array.push({
        [key]: values[index]
      });
    });
    return [...array];
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  }

  render() {
    const { translationService, descriptionsMaxLength, spinnerId } = this.state;
    const { id, whatYouWillLearn, requirements, targetStudents } = this.state.form;
    
    return translationService ? <Auxiliary>
      <div className="content-title d-block">
        <h2 className="section-title">{`${translationService.translate("TR_TARGET_YOUR_STUDENTS")}`}</h2>
      </div>
      <hr />
      <div className="content-body pt-0">
        <p className="mb-3">{translationService.translate("TR_TARGET_YOUR_STUDENTS_INFO")}</p>
        <form onSubmit={this.onSubmit} onChange={() => this.setState({ changes: true })}>
          <div className="row mb-4">
            <p className="col-12 mb-2">{`${translationService.translate("TR_WHAT_YOU_WILL_LEARN_DESC")} *`}</p>
            {
              whatYouWillLearn && whatYouWillLearn.map(whatYouWillLearnItem => {
                const itemKey = Object.keys(whatYouWillLearnItem)[0];
                const itemValue = Object.values(whatYouWillLearnItem)[0];
                return <div className="col-12" key={itemKey}>
                  <div className="d-flex align-items-start close-icon">
                    <Textarea
                      id={itemKey}
                      name="whatYouWillLearn"
                      blockClassName="w-100"
                      max={descriptionsMaxLength}
                      textAreaClassName="pr--4"
                      min="0"
                      rows="2"
                      value={itemValue}
                      valueLength="input-lenght"
                      onChange={event => this.onChangeDesc(event, descriptionsMaxLength)}
                    // failedFields={failedFields}
                    />
                    {
                      whatYouWillLearn.length > 1 ?
                        <i
                          className="fas fa-times"
                          onClick={() => this.removeDescription(whatYouWillLearn, whatYouWillLearnItem)}
                        />
                        : null
                    }
                  </div>
                </div>
              })
            }
            <div className="col-12 text-right">
              <button
                type="button"
                name="whatYouWillLearn"
                className="mindalay--btn-default position-relative mindalay--btn-small"
                onClick={event => this.addDescription(event.target.name)}
              >
                <i className="fas fa-plus"></i>
                {translationService.translate("TR_ADD_ANSWER")}
              </button>
            </div>
          </div>
          <div className="row"><div className="col-12"><hr /></div></div>
          <div className="row mb-4">
            <p className="col-12 mb-2">{`${translationService.translate("TR_REQUIREMENTS")} *`}</p>
            {
              requirements && requirements.map((requirement) => {
                const itemKey = Object.keys(requirement)[0];
                const itemValue = Object.values(requirement)[0];
                return <div className="col-12" key={itemKey}>
                  <div className="d-flex align-items-start close-icon">
                    <Textarea
                      id={itemKey}
                      name="requirements"
                      blockClassName="w-100"
                      max={descriptionsMaxLength}
                      textAreaClassName="pr--5"
                      min="0"
                      rows="2"
                      value={itemValue}
                      valueLength="input-lenght"
                      onChange={event => this.onChangeDesc(event, descriptionsMaxLength)}
                    // failedFields={failedFields}
                    />
                    {
                      requirements.length > 1 ?
                        <i
                          className="fas fa-times"
                          onClick={() => this.removeDescription(requirements, requirement)}
                        />
                        : null
                    }
                  </div>
                </div>
              })
            }
            <div className="col-12 text-right">
              <button
                type="button"
                name="requirements"
                className="mindalay--btn-default position-relative mindalay--btn-small"
                onClick={event => this.addDescription(event.target.name)}
              >
                <i className="fas fa-plus"></i>
                {translationService.translate("TR_ADD_ANSWER")}
              </button>
            </div>
          </div>
          <div className="row"><div className="col-12"><hr /></div></div>
          <div className="row mb-4">
            <p className="col-12 mb-2">{`${translationService.translate("TR_WHO_THIS_COURSE_IS_FOR")} *`}</p>
            {
              targetStudents && targetStudents.map(targetStudentItem => {
                const itemKey = Object.keys(targetStudentItem)[0];
                const itemValue = Object.values(targetStudentItem)[0];
                return <div className="col-12" key={itemKey}>
                  <div className="d-flex align-items-start close-icon">
                    <Textarea
                      id={itemKey}
                      name="targetStudents"
                      blockClassName="w-100"
                      max={descriptionsMaxLength}
                      textAreaClassName="pr--5"
                      min="0"
                      rows="2"
                      value={itemValue}
                      valueLength="input-lenght"
                      onChange={event => this.onChangeDesc(event, descriptionsMaxLength)}
                    // failedFields={failedFields}
                    />
                    {
                      targetStudents.length > 1 ?
                        <i
                          className="fas fa-times"
                          onClick={() => this.removeDescription(targetStudents, targetStudentItem)}
                        />
                        : null
                    }
                  </div>
                </div>
              })
            }
            <div className="col-12 text-right">
              <button
                type="button"
                name="targetStudents"
                className="mindalay--btn-default position-relative mindalay--btn-small"
                onClick={event => this.addDescription(event.target.name)}
              >
                <i className="fas fa-plus"></i>
                {translationService.translate("TR_ADD_ANSWER")}
              </button>
            </div>
          </div>
          <div className="text-right mt-4">
            <SubmitButton
              id={id}
              spinnerId={spinnerId}
              className="col-12 col-sm-8 col-md-4"
            />
          </div>
        </form>
      </div>
    </Auxiliary> : null;
  }
}

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  translations: state.translation.translations,
  language: state.language.language,
  languages: state.language.languages,
  courseData: state.course.currentCourse
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  setCurrentCourse,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(TargetStudents);

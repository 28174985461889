import React, { Component, createRef } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../../Store/Actions/user";
import TranslationService from "../../../Services/translationService";
import AlertService from "../../../Services/alertService";
import SearchSvg from "../../../Components/Svg/searchSvg";
import CardSvg from "../../../Components/Svg/cardSvg";
// import MessageSvg from "../../../Components/Svg/messagesSvg";
import HeartSvg from "../../../Components/Svg/heartSvg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import UserBurgerMenu from "./SubComponents/userBurgerMenu";
import ProfileDropdown from "./SubComponents/profileDropdown";
import Notification from "./SubComponents/notification";
import Messages from "./SubComponents/messages";
import CategoryDropdowns from "./SubComponents/categoryDropdowns";
import HeaderActionsBar from "./SubComponents/headerActionsBar";
import Input from "../../../Components/Inputs/input";
import ApiService from "../../../Services/apiService";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { getPotentialconsultationsCount } from "../../../Store/Actions/consultation";
import { setSearchText } from "../../../Store/Actions/search";
import {
	addPageSpinner,
	removePageSpinner,
} from "../../../Store/Actions/spinner";
import {
	getHelpData,
	setBackUrl,
	setPureLayoutBackUrl,
} from "../../../Store/Actions/main";
import { changeShoppingCartOrWishListCount } from "../../../Store/Actions/wishListOrShoppingCart";
import logo from "../../../assets/logo/logo_214x214.png";
import categoriesLogo from "../../../assets/images/category.svg";
import ReurnUrl from "../../../Services/returnUrl";
import Collapse from "react-bootstrap/Collapse";
import { CATEGORY } from "../../../Constants/requestKeys";
import {
	CONSULTANT_USER_TYPE,
	ERROR_KEY,
	NUMBER_KEY,
	SHOPPING_CART_COUNT_KEY,
	SHOPPING_CART_KEY,
	STRING_KEY,
	WISH_LIST_COUNT_KEY,
	WISH_LIST_KEY,
} from "../../../Constants/mainKeys";
import {
	BECOME_CONSULTANT_KEY,
	CATEGORY_KEY,
	COURSES_KEY,
	HELP_KEY,
	INFO_KEY,
	LEARN_IN_MINDALAY_KEY,
	LOGIN_KEY,
	MINDALAY_FOR_INSTITUTIONS_KEY,
	PROFILE_KEY,
	PURE_KEY,
	REGISTRATION_KEY,
	STUDENT_KEY,
	TEACH_IN_MINDALAY_KEY,
} from "../../../Constants/urlKeys";

const WAIT_INTERVAL = 500;
class Header extends Component {
	activeSearchElementPosition = 0;

	constructor(props) {
		super(props);
		this.state = {
			form: {
				name: "",
			},
			showSearch: "",
			showMobileBurgerMenu: false,
			translationService: null,
			notifications: [],
			shoppingCartCountWithStorage: null,
			wishListCountWithStorage: null,
			searchResult: {},
			cursor: 0,
			showOrHideSearchResultBlock: false,
			allSearchResult: [],
			currentSearchField: null,
			count: 0,
			isShowMobileCategoriesMenu: false,
			searchTextMaxLength: 100,
		};
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.box = createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.timer = null;
	}

	componentDidMount() {
		const { user } = this.props;
		this.setTranslations();
		this.getWishListOrShoppingCartData();
		document.addEventListener("mousedown", this.handleClickOutside);
		document.addEventListener(
			"mousedown",
			this.handleOutsideClickCategoryMobileMenu,
		);
		if (user && user.userTypeId === CONSULTANT_USER_TYPE) {
			this.props.getPotentialconsultationsCount();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.setTranslations();
		if (
			localStorage.getItem(WISH_LIST_COUNT_KEY) &&
			+localStorage.getItem(WISH_LIST_COUNT_KEY) !==
			this.state.wishListCountWithStorage
		) {
			this.getWishListOrShoppingCartData();
		}
		if (
			localStorage.getItem(SHOPPING_CART_COUNT_KEY) &&
			+localStorage.getItem(SHOPPING_CART_COUNT_KEY) !==
			this.state.shoppingCartCountWithStorage
		) {
			this.getWishListOrShoppingCartData();
		}
		if (this.props.location !== prevProps.location) {
			if (!this.props.location.pathname.includes(CATEGORY_KEY)) {
				this.clearSearchField();
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
		document.removeEventListener(
			"mousedown",
			this.handleOutsideClickCategoryMobileMenu,
		);
	}

	clearSearchField = () => {
		this.setState(
			prevState => ({
				...prevState,
				form: {
					...prevState.form,
					name: "",
				},
			}),
			() => {
				this.props.setSearchText("");
			},
		);
	};

	handleOutsideClickCategoryMobileMenu = event => {
		if (this.box && !this.box.current.contains(event.target)) {
			if (this.state.isShowMobileCategoriesMenu)
				this.setState({ isShowMobileCategoriesMenu: false });
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		const { showOrHideSearchResultBlock } = this.state;
		if (
			this.wrapperRef &&
			!this.wrapperRef.contains(event.target) &&
			showOrHideSearchResultBlock
		) {
			this.setState({ showOrHideSearchResultBlock: false, cursor: 0 });
			this.activeSearchElementPosition = 0;
		}
	}

	getWishListOrShoppingCartData = () => {
		if (localStorage.getItem(WISH_LIST_COUNT_KEY)) {
			this.setState({
				wishListCountWithStorage: +localStorage.getItem(WISH_LIST_COUNT_KEY),
			});
		}
		if (localStorage.getItem(SHOPPING_CART_COUNT_KEY)) {
			this.setState({
				shoppingCartCountWithStorage: +localStorage.getItem(
					SHOPPING_CART_COUNT_KEY,
				),
			});
		}
	};

	showHideSearch = () => {
		const { showSearch } = this.state;
		this.setState(prevState => ({
			...prevState,
			showSearch: showSearch ? "" : "show-search-input",
			showOrHideSearchResultBlock: showSearch ? false : true,
			form: {
				...prevState.form,
				name: "",
			},
		}));
	};

	toggleMobileBurgerMenu = show => {
		this.setState({ showMobileBurgerMenu: show });
	};

	onChange = (event, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		const failedFields = this.removeFailedFields(event.target.name);
		if (!event.target.value) {
			this.setState({ showOrHideSearchResultBlock: false, cursor: 0 });
		}
		clearTimeout(this.timer);
		this.setState(prevState => ({
			...prevState,
			failedFields,
			cursor: 0,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
			},
		}));
		this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
	};

	triggerChange = () => {
		var { name } = this.state.form;
		name = name.trim();
		if (name.length > 1) {
			ApiService.globalSearch(name)
				.then(response => {
					response.data &&
						this.setState(
							{
								searchResult: { ...response.data },
								showOrHideSearchResultBlock: true,
							},
							() => {
								const data = {
									courses: this.state.searchResult.courses,
									instructors: this.state.searchResult.instructors,
									keywords: this.state.searchResult.keywords,
								};
								this.concatSearchResults(data);
							},
						);
				})
				.catch(error => this.getFail(error));
		}
		this.props.setSearchText(name);
	};

	concatSearchResults = obj => {
		const { name } = this.state.form;
		const allSearchResult = [];
		for (const i in obj) {
			if (obj[i] && obj[i].length) {
				obj[i].forEach(element => {
					allSearchResult.push(element);
				});
			}
		}
		allSearchResult.push(name);
		this.setState({ allSearchResult: allSearchResult.reverse() });
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	setKeyword = async keyword => {
		const { language } = this.props;
		this.setState(prevState => ({
			...prevState,
			showOrHideSearchResultBlock: false,
			form: {
				...prevState.form,
				name: keyword,
			},
		}));
		await this.props.setSearchText(keyword);
		this.props.history.push(`/${language}/${CATEGORY}`);
	};

	onFocus = () => {
		this.triggerChange();
	};

	handleKeyDown = (event, array) => {
		var { cursor } = this.state;
		var searchBlock = document.getElementById("scrollable");
		var searchBlockHeight = searchBlock?.offsetHeight;
		var currentElementHeight = event.target?.offsetHeight;
		var cursorNewIndex = 0;
		// Up
		if (event.keyCode === 38 && cursor > 0) {
			cursorNewIndex = cursor - 1;
			this.changeCursorPosition(cursorNewIndex, array);
			this.activeSearchElementPosition -= currentElementHeight;
			if (
				this.activeSearchElementPosition + currentElementHeight <
				searchBlockHeight
			) {
				searchBlock.scrollBy({
					top: -currentElementHeight,
					behavior: "smooth",
				});
			}
		}
		// Down
		if (event.keyCode === 40 && cursor < array.length - 1) {
			cursorNewIndex = cursor + 1;
			this.changeCursorPosition(cursorNewIndex, array);
			this.activeSearchElementPosition += currentElementHeight;
			if (
				this.activeSearchElementPosition + currentElementHeight >
				searchBlockHeight
			) {
				searchBlock.scrollBy({ top: currentElementHeight, behavior: "smooth" });
			}
		}
	};

	changeCursorPosition = (cursorNewIndex, array) => {
		this.setState(prevState => ({
			...prevState,
			cursor: cursorNewIndex,
			currentSearchField: array[cursorNewIndex],
			form: {
				name:
					typeof array[cursorNewIndex] === STRING_KEY
						? array[cursorNewIndex]
						: "",
			},
		}));
	};

	redirectToCourseOrInstructorPage = element => {
		if (!element) {
			return;
		}
		const { language } = this.props;
		if (element.courseName) {
			this.props.history.push(`/${language}/${COURSES_KEY}/${element.id}`);
		}
		if (element.instructorNameAndSpecialty) {
			this.props.history.push(`/${language}/${PROFILE_KEY}/${element.id}`);
		}
		this.setState({ showOrHideSearchResultBlock: false });
	};

	returnUrlWithoutRedirect = url => {
		ReurnUrl.setUrl(url);
		this.setState({ showMobileBurgerMenu: false });
	};

	setBackUrl = url => {
		this.props.setPureLayoutBackUrl(url);
		this.setState({ showMobileBurgerMenu: false });
	};

	onSubmit = event => {
		event.preventDefault();
		var { name } = this.state.form;
		const { currentSearchField } = this.state;
		if (name) {
			name = name.trim();
			this.setState({ showOrHideSearchResultBlock: false }, () => {
				if (name.length > 1) {
					this.props.setSearchText(name);
					this.props.history.push(`/${this.props.language}/${CATEGORY}`);
				}
			});
		}
		if (!name && currentSearchField) {
			this.setState({ showOrHideSearchResultBlock: false }, () => {
				this.props.history.push(
					currentSearchField.courseName
						? `/${this.props.language}/${COURSES_KEY}/${currentSearchField.id}`
						: currentSearchField.instructorNameAndSpecialty
							? `/${this.props.language}/${PROFILE_KEY}/${currentSearchField.id}`
							: "#",
				);
			});
		}
	};

	logout = async () => {
		this.props.changeShoppingCartOrWishListCount(0, 0);
		const { globalConnection } = this.props;
		if (globalConnection && globalConnection.state === "Connected") {
			globalConnection.stop();
		}
		await this.props.logout();
		this.props.history.push(`/${this.props.language}`);
	};

	onLogoClick = () => {
		this.props.history.push(`/${this.props.language}`);
		this.setState(prevState => ({
			...prevState,
			allSearchResult: [],
			showSearch: "",
			form: { ...prevState.form, name: "" },
		}));
		this.props.setSearchText("");
	};

	getFail = error => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
	};

	showOrHideSecondLevelCategories = categoryId => {
		if (this.state.categoryId === categoryId)
			this.setState({ categoryId: null, secondLevelCategoryId: null });
		else this.setState({ categoryId, secondLevelCategoryId: null });
	};

	showOrHideThirdLevelCategories = secondLevelCategoryId => {
		if (this.state.secondLevelCategoryId === secondLevelCategoryId)
			this.setState({ secondLevelCategoryId: null });
		else this.setState({ secondLevelCategoryId });
	};

	render() {
		const {
			hasUser,
			language,
			wishListCount,
			shoppingCartCount,
			courseCategories,
			user,
			settings,
		} = this.props;
		
		const { name } = this.state.form;
		const {
			translationService,
			showSearch,
			showMobileBurgerMenu,
			shoppingCartCountWithStorage,
			wishListCountWithStorage,
			showOrHideSearchResultBlock,
			cursor,
			allSearchResult,
			isShowMobileCategoriesMenu,
			categoryId,
			secondLevelCategoryId,
			searchTextMaxLength,
		} = this.state;

		return translationService ? (
			<header className={`relative-layout header header-zindex`}>
				<div
					className={`burger-menu-container ${isShowMobileCategoriesMenu ? "toggle-burger" : ""
						}`}>
					<aside
						id="navbar_main"
						className={`mobile-offcanvas white-background mobile-categories-block ${isShowMobileCategoriesMenu ? "show" : ""
							}`}
						ref={this.box}>
						<div className="offcanvas-header">
							<div
								className="side-menu-close-btn left-close-btn"
								onClick={() =>
									this.setState({ isShowMobileCategoriesMenu: false })
								}>
								<span></span>
							</div>
							<h5 className="p-3 text-white box-shadow-4 gray-color">
								{translationService.translate("TR_CATEGORIES")}
							</h5>
						</div>
						<ul className="d-block category-burger-menu">
							{courseCategories &&
								courseCategories.map(category => {
									const secondLevelCategories =
										category.children || category.themeChildren;
									if (category) {
										return (
											<li key={category.id} className="nav-item">
												{
													<div className="d-flex align-items-center">
														<Link
															to={`/${language}/${CATEGORY_KEY}/${category.id}`}
															className="nav-link category-field"
															onClick={() =>
																this.setState({
																	isShowMobileCategoriesMenu: false,
																})
															}
															title={category.name}>
															{category.name}
														</Link>
														{secondLevelCategories.length ? (
															<div className="px-4">
																<i
																	className="fas fa-chevron-down category-field-item cursor-pointer"
																	onClick={() =>
																		this.showOrHideSecondLevelCategories(
																			category.id,
																		)
																	}></i>
															</div>
														) : null}
													</div>
												}
												{secondLevelCategories &&
													secondLevelCategories.length ? (
													<ul className="dropdown-sub-menu">
														{secondLevelCategories.map(secondLevelCategory => {
															const thirdLevelCategories =
																secondLevelCategory.children ||
																secondLevelCategory.themeChildren;
															return (
																<li
																	key={secondLevelCategory.id}
																	className="nav-item">
																	<Collapse in={categoryId === category.id}>
																		<div className="mr-3 ml-3 content-block">
																			{
																				<div className="d-flex align-items-center">
																					<Link
																						to={`/${language}/${CATEGORY_KEY}/${category.id}`}
																						className="nav-link category-field"
																						title={secondLevelCategory.name}
																						onClick={() =>
																							this.setState({
																								isShowMobileCategoriesMenu: false,
																							})
																						}>
																						{secondLevelCategory.name}
																					</Link>
																					{secondLevelCategories.length ? (
																						<div className="px-4">
																							<i
																								className="fas fa-chevron-down category-field-item cursor-pointer"
																								onClick={() =>
																									this.showOrHideThirdLevelCategories(
																										secondLevelCategory.id,
																									)
																								}></i>
																						</div>
																					) : null}
																				</div>
																			}
																		</div>
																	</Collapse>
																	{thirdLevelCategories &&
																		thirdLevelCategories.length ? (
																		<ul className="dropdown-sub-menu-item">
																			{thirdLevelCategories.map(
																				thriedLevelCategory => {
																					return (
																						<li
																							key={thriedLevelCategory.id}
																							className="nav-item">
																							<Collapse
																								in={
																									secondLevelCategoryId ===
																									secondLevelCategory.id
																								}>
																								<div className="mr-3 ml-3 content-block">
																									<Link
																										className="nav-link category-field"
																										to={`/${language}/${CATEGORY_KEY}/${category.id}/${secondLevelCategory.id}/${thriedLevelCategory.id}`}
																										title={
																											thriedLevelCategory.name
																										}
																										onClick={() =>
																											this.setState({
																												isShowMobileCategoriesMenu: false,
																											})
																										}>
																										{thriedLevelCategory.name}
																									</Link>
																								</div>
																							</Collapse>
																						</li>
																					);
																				},
																			)}
																		</ul>
																	) : null}
																</li>
															);
														})}
													</ul>
												) : null}
											</li>
										);
									}
								})}
						</ul>
					</aside>
				</div>
				<nav className="navbar navbar-expand-lg brand-background">
					<div className="d-lg-none d-block category-burger-btn-wrapper">
						<button
							type="button"
							data-trigger="#navbar_main"
							className="btn-transparent btn"
							onClick={() =>
								this.setState({
									categoryId: null,
									secondLevelCategoryId: null,
									isShowMobileCategoriesMenu: true,
								})
							}>
							<div className="m-0 font-light">
								<img className="category-logo" src={categoriesLogo} alt="/" />
								{/* {translationService.translate("TR_CATEGORIES")} (mobile) */}
							</div>
						</button>
					</div>
					<li
						className="nav-item search-mobile d-lg-none ml-2"
						onClick={this.showHideSearch}>
						<Link to="#" className="nav-link">
							<SearchSvg />
						</Link>
					</li>

					<div className="nav-left-side d-flex align-items-center flex-1">
						<Link
							to="#"
							title={translationService.translate("TR_HOME")}
							className="navbar-brand"
							onClick={this.onLogoClick}>
							{settings?.mainLogoPath ? (
								<img src={settings?.mainLogoPath} alt="/" />
							) : (
								<img src={logo} alt="/" />
							)}
							{/* <h1 className="d-lg-block d-none">
              mindalay
            </h1> */}
							<h1 className="">
								mindalay
							</h1>
						</Link>
						<CategoryDropdowns clearSearchField={this.clearSearchField} />

						<div className="nav-item d-lg-block d-none">
							<Link to={`/${language}/${HELP_KEY}`} className="nav-link">
								{translationService.translate("TR_HELP")}
							</Link>
						</div>
					</div>

					{/* {
          hasUser ?
            <div className="mobil-navbar-wrapper d-lg-none d-block">
              <ul className="navbar-nav align-items-left align-items-lg-center">
                <li className="nav-item search-mobile d-lg-none" onClick={this.showHideSearch}>
                  <Link to="#" className="nav-link">
                    <SearchSvg />
                  </Link>
                </li>
                <li
                  title={translationService.translate("TR_NOTOFICATIONS")}
                  className="nav-item nav-item not-hover position-relative dropdown header-dropdown">
                  <Notification />
                </li>
                <li className="nav-item">
                  <Link
                    to={`/${language}/${SHOPPING_CART_KEY}`}
                    title={translationService.translate("TR_SHOPPING_CART")}
                    className={`nav-link ${(shoppingCartCount || shoppingCartCountWithStorage) ? "has-number" : ""}`}
                    rel={typeof shoppingCartCount === NUMBER_KEY ? shoppingCartCount : shoppingCartCountWithStorage ? shoppingCartCountWithStorage : 0}>
                    <CardSvg />
                  </Link>
                </li>
                <li className="nav-item" onClick={() => this.toggleMobileBurgerMenu(true)}>
                  <Link to="#" className="burger-btn open-burger-menu nav-link">
                    <span></span>
                  </Link>
                </li>
              </ul>
            </div>
            : <div className="mobil-navbar-wrapper d-lg-none d-block">
              <ul className="navbar-nav">
                <li className="nav-item search-mobile d-lg-none" onClick={this.showHideSearch}>
                  <Link to="#" className="nav-link">
                    <SearchSvg />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/${language}/${WISH_LIST_KEY}`}
                    title={translationService.translate("TR_WISH_LIST")}
                    className={`nav-link ${(wishListCount || wishListCountWithStorage) ? "has-number" : ""}`}
                    rel={typeof wishListCount === NUMBER_KEY ? wishListCount : wishListCountWithStorage ? wishListCountWithStorage : 0}
                  >
                    <HeartSvg />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/${language}/${SHOPPING_CART_KEY}`}
                    title={translationService.translate("TR_SHOPPING_CART")}
                    className={`nav-link ${(shoppingCartCount || shoppingCartCountWithStorage) ? "has-number" : ""}`}
                    rel={typeof shoppingCartCount === NUMBER_KEY ? shoppingCartCount : shoppingCartCountWithStorage ? shoppingCartCountWithStorage : 0}>
                    <CardSvg />
                  </Link>
                </li>
                <li className="nav-item" onClick={() => this.toggleMobileBurgerMenu(true)}>
                  <Link to="#" className="burger-btn open-burger-menu nav-link">
                    <span></span>
                  </Link>
                </li>
              </ul>
            </div>
        } */}

					<div className="mobil-navbar-wrapper d-lg-none d-block">
						<ul className="navbar-nav align-items-left align-items-lg-center">
							{/* <li className="nav-item search-mobile d-lg-none" onClick={this.showHideSearch}>
              <Link to="#" className="nav-link">
                <SearchSvg />
              </Link>
            </li> */}
							{hasUser ? (
								<li
									title={translationService.translate("TR_NOTOFICATIONS")}
									className="nav-item nav-item not-hover position-relative dropdown header-dropdown">
									<Notification />
								</li>
							) : null}
							<li
								className="nav-item"
								onClick={() => this.toggleMobileBurgerMenu(true)}>
								<span className="burger-btn open-burger-menu nav-link cursor-pointer">
									<span></span>
								</span>
							</li>
						</ul>
					</div>
					{
						<form
							onSubmit={this.onSubmit}
							className=""
							ref={this.setWrapperRef}>
							<div
								className={`search-wrapper mr-4 ${hasUser ? "" : "d-lg-block d-none"
									} ${showSearch}`}>
								<i className="fas fa-search bottom-4" />
								<Input
									type="text"
									name="name"
									blockClassName="m-0"
									value={name}
									placeholder={translationService.translate("TR_SEARCH")}
									inputClassName={`search-input`}
									onFocus={this.onFocus}
									onKeyDown={event =>
										this.handleKeyDown(event, allSearchResult)
									}
									onChange={event => this.onChange(event, searchTextMaxLength)}
								/>
								<i
									className="fas fa-times d-lg-none close-search-input"
									onClick={this.showHideSearch}
								/>
								{showOrHideSearchResultBlock ? (
									<ul id="scrollable" className="autocomplete-block">
										{allSearchResult && allSearchResult.length
											? allSearchResult.map((element, index) => {
												return (
													<li
														key={index}
														className={`autocomplete-item pr-4 ${cursor === index ? "active-search" : ""
															}`}>
														<Link
															to={
																element.courseName
																	? `/${language}/${COURSES_KEY}/${element.id}`
																	: element.instructorNameAndSpecialty
																		? `/${language}/${PROFILE_KEY}/${element.id}`
																		: "#"
															}
															title={
																element.courseName
																	? element.courseName
																	: element.instructorNameAndSpecialty
																		? element.instructorNameAndSpecialty
																		: typeof element === STRING_KEY
																			? element
																			: ""
															}
															onClick={
																typeof element === STRING_KEY
																	? () => this.setKeyword(element)
																	: () =>
																		this.redirectToCourseOrInstructorPage(
																			element,
																		)
															}>
															{element.courseName ? (
																<span>
																	<i className="far fa-file bottom-4" />
																</span>
															) : element.instructorNameAndSpecialty ? (
																<span>
																	<i className="fas fa-user bottom-4" />
																</span>
															) : typeof element === STRING_KEY ? (
																<span>
																	{index ? (
																		<i className="fas fa-search bottom-4" />
																	) : null}
																</span>
															) : null}
															<span>
																{element.courseName
																	? element.courseName
																	: element.instructorNameAndSpecialty
																		? element.instructorNameAndSpecialty
																		: typeof element === STRING_KEY
																			? element
																			: ""}
															</span>
														</Link>
													</li>
												);
											})
											: null}
									</ul>
								) : null}
							</div>
						</form>
					}
					{hasUser ? (
						<Auxiliary>
							<div className="d-lg-block d-none">
								<ul className="navbar-nav align-items-left align-items-lg-center">
									{/* <li className="nav-item not-hover d-md-block d-none">
                    <Link
                      to="#"
                      onClick={() => this.props.getHelpData()}
                      className="nav-link">
                      <HelpSvg />
                    </Link>
                  </li> */}
									<li
										title={translationService.translate("TR_NOTIFICATIONS")}
										className="nav-item not-hover d-md-block d-none position-relative dropdown header-dropdown">
										<Notification />
									</li>
									<li className="nav-item not-hover d-lg-block d-none">
										<Link
											to={`/${language}/${WISH_LIST_KEY}`}
											title={translationService.translate("TR_WISH_LIST")}
											className={`nav-link ${wishListCount || wishListCountWithStorage
													? "has-number"
													: ""
												}`}
											rel={
												typeof wishListCount === NUMBER_KEY
													? wishListCount
													: wishListCountWithStorage
														? wishListCountWithStorage
														: 0
											}>
											<HeartSvg />
										</Link>
									</li>
									<li className="nav-item not-hover d-lg-block d-none">
										<Link
											to={`/${language}/${SHOPPING_CART_KEY}`}
											title={translationService.translate("TR_SHOPPING_CART")}
											className={`nav-link ${shoppingCartCount || shoppingCartCountWithStorage
													? "has-number"
													: ""
												}`}
											rel={
												typeof shoppingCartCount === NUMBER_KEY
													? shoppingCartCount
													: shoppingCartCountWithStorage
														? shoppingCartCountWithStorage
														: 0
											}>
											<CardSvg />
										</Link>
									</li>
									<li
										title={translationService.translate("TR_MESSAGES")}
										className="nav-item not-hover d-md-block d-none position-relative dropdown header-dropdown">
										<Messages />
									</li>
									<li className="nav-item navbar-profile-container header-dropdown">
										{user && user.isOrganizationUser ? (
											<OrganizationUserDropdown />
										) : (
											<ProfileDropdown />
										)}
									</li>
								</ul>
							</div>
							<UserBurgerMenu
								showMobileBurgerMenu={showMobileBurgerMenu}
								close={() => this.toggleMobileBurgerMenu(false)}
							/>
						</Auxiliary>
					) : (
						<Auxiliary>
							<div className="d-lg-block d-none ml-2">
								<ul className="navbar-nav align-items-left align-items-lg-center">
									{/* <li className="nav-item not-hover d-md-block d-none">
                    <Link
                      to="#"
                      onClick={getHelpData}
                      className="nav-link">
                      <HelpSvg />
                    </Link>
                  </li> */}
									<li className="nav-item not-hover d-md-block d-none">
										<Link
											to={`/${language}/${WISH_LIST_KEY}`}
											title={translationService.translate("TR_WISH_LIST")}
											className={`nav-link ${wishListCount || wishListCountWithStorage
													? "has-number"
													: ""
												}`}
											rel={
												typeof wishListCount === NUMBER_KEY
													? wishListCount
													: wishListCountWithStorage
														? wishListCountWithStorage
														: 0
											}>
											<HeartSvg />
										</Link>
									</li>
									<li className="nav-item not-hover d-md-block d-none">
										<Link
											to={`/${language}/${SHOPPING_CART_KEY}`}
											title={translationService.translate("TR_SHOPPING_CART")}
											className={`nav-link ${shoppingCartCount || shoppingCartCountWithStorage
													? "has-number"
													: ""
												}`}
											rel={
												typeof shoppingCartCount === NUMBER_KEY
													? shoppingCartCount
													: shoppingCartCountWithStorage
														? shoppingCartCountWithStorage
														: 0
											}>
											<CardSvg />
										</Link>
									</li>
									<Link
										to={`/${language}/${LOGIN_KEY}`}
										className="login-btn mx-1">
										{translationService.translate("TR_SIGN_IN")}
										{/* <HelpAlert helpKey="footer"></HelpAlert> */}
									</Link>
									<Link
										to={`/${language}/${REGISTRATION_KEY}`}
										className="mindalay--btn mindalay--btn-outline mx-1">
										{translationService.translate("TR_SIGN_UP")}
									</Link>
								</ul>
							</div>
							<div
								className={`burger-menu-container ${showMobileBurgerMenu ? "toggle-burger" : ""
									}`}>
								<div
									className={`burger-menu-body ${showMobileBurgerMenu ? "toggle-burger-menu" : ""
										}`}>
									<ul className="burger-profile-container">
										<li id="close-burger-menu" className="nav-item">
											<div
												className={`right-close-btn side-menu-close-btn ${showMobileBurgerMenu
														? "burger-close-button-scala"
														: ""
													}`}
												onClick={() => this.toggleMobileBurgerMenu(false)}>
												<span></span>
											</div>
										</li>
										<li className="nav--item important-action mb-2">
											<Link
												to={`/${language}/${LEARN_IN_MINDALAY_KEY}/${INFO_KEY}`}
												className="profile-nav-link"
												onClick={() =>
													this.setState({ showMobileBurgerMenu: false })
												}>
												{translationService.translate("TR_LEARN_IN_MINDALAY")}
											</Link>
										</li>
										<li className="nav--item important-action">
											<Link
												to={`/${language}/${TEACH_IN_MINDALAY_KEY}/${INFO_KEY}`}
												className="profile-nav-link"
												onClick={() => this.setBackUrl(`/${language}`)}>
												{translationService.translate("TR_TEACH_IN_MINDALAY")}
											</Link>
										</li>
										<li className="nav--item important-action">
											<Link
												to={`/${language}/${BECOME_CONSULTANT_KEY}/${INFO_KEY}`}
												className="profile-nav-link"
												onClick={() =>
													this.returnUrlWithoutRedirect(
														`/${language}/${PURE_KEY}/${BECOME_CONSULTANT_KEY}`,
													)
												}>
												{translationService.translate("TR_BECOME_A_CONSULTANT")}
											</Link>
										</li>
										<li className="nav--item important-action mb-2">
											<Link
												to={`/${language}/${MINDALAY_FOR_INSTITUTIONS_KEY}/${INFO_KEY}`}
												className="profile-nav-link"
												onClick={() =>
													this.setState({ showMobileBurgerMenu: false })
												}>
												{translationService.translate(
													"TR_MINDALAY_FOR_INSTITUTIONS",
												)}
											</Link>
										</li>
										<hr />

										<li className="nav--item">
											<Link
												to={`/${language}/${WISH_LIST_KEY}`}
												className={`profile-nav-link ${wishListCount || wishListCountWithStorage
														? "has-number has-number-in-menu"
														: ""
													}`}
												rel={
													typeof wishListCount === NUMBER_KEY
														? wishListCount
														: wishListCountWithStorage
															? wishListCountWithStorage
															: 0
												}
												onClick={() => {
													this.setState({ showMobileBurgerMenu: false });
												}}>
												{translationService.translate("TR_WISH_LIST")}
											</Link>
										</li>
										<li className="nav--item">
											<Link
												to={`/${language}/${SHOPPING_CART_KEY}`}
												className={`profile-nav-link ${shoppingCartCount || shoppingCartCountWithStorage
														? "has-number has-number-in-menu"
														: ""
													}`}
												rel={
													typeof shoppingCartCount === NUMBER_KEY
														? shoppingCartCount
														: shoppingCartCountWithStorage
															? shoppingCartCountWithStorage
															: 0
												}
												onClick={() => {
													this.setState({ showMobileBurgerMenu: false });
												}}>
												{translationService.translate("TR_SHOPPING_CART")}
											</Link>
										</li>
										<li className="nav--item">
											<Link
												to={`/${language}/${HELP_KEY}`}
												className="profile-nav-link"
												onClick={() => {
													this.setState({ showMobileBurgerMenu: false });
													this.props.setBackUrl(this.props.location.pathname);
												}}>
												{translationService.translate("TR_HELP")}
											</Link>
										</li>
										<hr />
										<li className="nav--item">
											<Link
												to={`/${language}/${LOGIN_KEY}`}
												className="profile-nav-link"
												onClick={() =>
													this.setState({ showMobileBurgerMenu: false })
												}>
												{translationService.translate("TR_SIGN_IN")}
											</Link>
										</li>
										<li className="nav--item">
											<Link
												to={`/${language}/${REGISTRATION_KEY}`}
												className="profile-nav-link"
												onClick={() =>
													this.setState({ showMobileBurgerMenu: false })
												}>
												{translationService.translate("TR_SIGN_UP")}
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</Auxiliary>
					)}
				</nav>
				<HeaderActionsBar />
			</header>
		) : null;
	}
}

const mapStateToProps = state => ({
	translations: state.translation.translations,
	language: state.language.language,
	hasUser: state.user.hasUser,
	user: state.user.user,
	wishListCount: state.wishListOrShoppingCart.wishListCount,
	shoppingCartCount: state.wishListOrShoppingCart.shoppingCartCount,
	unreadMessgesCount: state.signalR.unreadMessgesCount,
	courseCategories: state.course.courseCategories,
	serachText: state.search.serachText,
	globalConnection: state.signalR.globalConnection,
	settings: state.settings.settings,
});

const mapDispatchToProps = {
	logout,
	addPageSpinner,
	removePageSpinner,
	getHelpData,
	setSearchText,
	changeShoppingCartOrWishListCount,
	getPotentialconsultationsCount,
	setPureLayoutBackUrl,
	setBackUrl,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Header);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import * as moment from "moment";
import { Link, withRouter } from "react-router-dom";
import MainService from "../../../../Services/mainService";
import TranslationService from "../../../../Services/translationService";
import ApiService from "../../../../Services/apiService";
import AlertService from "../../../../Services/alertService";
import uuid from "react-uuid";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import NotificationSpinner from "../../../../Components/Spinners/notificationSpinner";
import { ERROR_KEY, NUMBER_KEY, TICKET_CHAT_TYPE_KEY, CONSULTATION_CHAT_TYPE_KEY } from "../../../../Constants/mainKeys";
import consultationSvg from "../../../../assets/icons/notification-icons/consultation.svg";
import complaintSvg from "../../../../assets/icons/notification-icons/complaint.svg";
import MessagesSvg from './../../../../Components/Svg/messagesSvg';
import {
  CHAT_KEY,
  CONSULTANT_KEY,
  TICKET_KEY,
  CONSULTANT_CONSULTATION_CONTRACTS_KEY,
  STUDENT_CONSULTATION_CONTRACTS_KEY,
  ALL_MESSAGES_KEY,
  MESSAGES_KEY
} from "../../../../Constants/urlKeys";

const Messages = (props) => {

  var ticketChatType = TICKET_CHAT_TYPE_KEY;
  var consultationChatType = CONSULTATION_CHAT_TYPE_KEY;

  const translations = useSelector(state => state.translation.translations);
  const unreadMessgesCount = useSelector(state => state.signalR.unreadMessgesCount);
  const language = useSelector(state => state.language.language);
  const [spinnerId, setSpinnerId] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getUnreadMessagesData = isOpened => {
    const spinnerId = uuid();
    if (isOpened) {
      setSpinnerId(spinnerId);
      ApiService.getUnreadMessagesData().then(response => {
        setSpinnerId(null);
        setMessages(response.data);
      }).catch(error => getFail(error));
    } else {
      setMessages([]);
    }
  }

  const redirectToCurrentMessagePage = (message) => {
    if (!message) { return; }
    if (message.chatType === ticketChatType) {
      props.history.push(`/${language}/${TICKET_KEY}/${CHAT_KEY}/${message.entityId}`);
    }
    if (message.chatType === consultationChatType) {
      if (message.isStudent) {
        props.history.push(`/${language}/${STUDENT_CONSULTATION_CONTRACTS_KEY}/${message.entityId}/${MESSAGES_KEY}`)
      }
      if (!message.isStudent) props.history.push(`/${language}/${CONSULTANT_KEY}/${CONSULTANT_CONSULTATION_CONTRACTS_KEY}/${message.entityId}/${MESSAGES_KEY}`)
    }
  }

  const getFail = (error) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setSpinnerId(null);
  }

  const redirectToAllMessagesPage = () => {
    props.history.push(`/${language}/${ALL_MESSAGES_KEY}`)
  }

  return translationService ?
    <Auxiliary>
      <Dropdown onToggle={getUnreadMessagesData}>
        <Dropdown.Toggle variant="transparent">
          <Link
            to="#"
            className={`nav-link ${typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount > 0 ? "has-number" : ""}`}
            rel={typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount}
          >
            <MessagesSvg />
          </Link>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="header-popup">
            <strong className="navbar-popup-title">{translationService.translate("TR_MESSAGES")}</strong>
            <hr className="m-0" />
            <div className="position-relative">
              <ul className="navbar-popup-wrapper">
                {
                  messages && messages.length
                    ? messages.map((message, index) =>
                      <div
                        className={`cursor-pointer ${message.isRead ? "" : "light-gray-background"} dropdown-toggle`}
                        onClick={() => redirectToCurrentMessagePage(message)}
                        key={index}
                      >
                        <li className={`nav-item p-relative notification-body ${!message.isRead ? "unread-notification" : "read-notification"}`}>
                          <div className="notification-title">
                            {
                              (() => {
                                switch (message.chatType) {
                                  case ticketChatType: // ticket 
                                    return <img src={complaintSvg} alt="/" />
                                  case consultationChatType: // consultation 
                                    return <img src={consultationSvg} alt="/" />
                                  default:
                                    break
                                }
                              })()
                            }
                            <span className="ml-2 overflow-hidden" title={message.title}>{message.title}</span>
                          </div>
                          <p className="not-subject" title={message.message}>{message.message}</p>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="consultation-date">
                              {moment(MainService.convertUTCDateToLocalDate(new Date(message.time))).startOf('minute').fromNow()}
                            </span>
                          </div>
                        </li>
                      </div>)
                    : <Dropdown.Item>
                      <li className="nav-item">
                        <p className="not-subject-empty">{translationService.translate("TR_EMPTY_UNREAD_MESSAGES")}</p>
                      </li>
                    </Dropdown.Item>
                }
              </ul>
              <Dropdown.Item>
                <div className="text-right">
                  <button
                    type="button"
                    className="mindalay--btn-dark w-100"
                    onClick={redirectToAllMessagesPage}
                  >
                    {translationService.translate("TR_SEE_ALL")}
                  </button>
                </div>
              </Dropdown.Item>
              <NotificationSpinner spinnerId={spinnerId} />
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </Auxiliary>
    : null;
}

export default withRouter(Messages);
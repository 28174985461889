import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import InfoSvg from "../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import ArrowBackSvg from "./../../Components/Svg/arrowBackSvg";
import { Link } from "react-router-dom";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	GRADING_METHODS_ARR_KEY,
	QUIZ_ATTEMPT_STATUS_TYPES,
} from "../../Constants/mainKeys";
import moment from "moment";
import MainService from "../../Services/mainService";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

const QuizReview = props => {
	const { studentId = null, quizId = null } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const dispatch = useDispatch();
	const [translationService, setTranslationService] = useState(null);
	const [quizData, setQuizData] = useState(null);
	const [attemptsData, setAttemptsData] = useState(null);

	const quizAttemptsColumns = useMemo(() => {
		if (translationService && attemptsData) {
			return [
				{
					name: `${translationService.translate("TR_STATUS")}`,
					selector: "status",
				},
				{
					name: `${translationService.translate("TR_COMPLETED")}`,
					selector: "finishDate",
					wrap: true,
				},
				// {
				//   name: translationService.translate("TR_STATE"),
				//   selector: 'cell',
				// },
				{
					name: `${translationService.translate("TR_MARKS")} / ${
						attemptsData.maxMark
					}`,
					selector: "earnedMark",
					sortable: true,
				},
				{
					name: `${translationService.translate("TR_GRADE")} / ${
						attemptsData.maxGrade
					}`,
					selector: "earnedGrade",
					sortable: true,
				},
				{
					name: translationService.translate("TR_REVIEW"),
					selector: "actions",
					sortable: false,
				},
			];
		} else {
			return [];
		}
	}, [translationService, attemptsData]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (translationService) {
			getLectureQuizAtetmpt();
			getLectureQuiz();
		}
	}, [translationService]);

	const getLectureQuiz = () => {
		const spinnerId = uuid();
		if (!studentId || !quizId) return false;

		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getLectureQuiz(quizId, studentId)
			.then(response => {
				if (response && response.data) {
					setQuizData(response.data);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getLectureQuizAtetmpt = () => {
		const spinnerId = uuid();
		if (!studentId || !quizId) return false;

		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getLectureQuizAtetmpt(quizId, studentId)
			.then(response => {
				if (response && response.data) {
					let data = { ...response.data };
					if (data.attemptResponseModels && data.attemptResponseModels.length) {
						data.attemptResponseModels.forEach(attemptRespModel => {
							let reviewPath = `/${language}/student/${studentId}/quiz/${quizId}/attempt/${attemptRespModel.id}?state=${attemptRespModel.state}&attemptId=${attemptRespModel.id}`;
							attemptRespModel.status = (
								<div>
									<p className="text-capitalize">
										{translationService.translate(
											QUIZ_ATTEMPT_STATUS_TYPES[attemptRespModel.state],
										)
											? translationService.translate(
													QUIZ_ATTEMPT_STATUS_TYPES[attemptRespModel.state],
											  )
											: attemptRespModel.state}
									</p>
								</div>
							);
							attemptRespModel.finishDate = (
								<div>
									{attemptRespModel.endTime ? (
										<p>
											{moment(
												MainService.convertUTCDateToLocalDate(
													new Date(attemptRespModel.endTime),
												),
											).format("ll HH:mm")}
										</p>
									) : null}
								</div>
							);
							attemptRespModel.actions =
								attemptRespModel.state === 1 || attemptRespModel.state === 2 ? (
									<Link to={reviewPath}>
										{translationService.translate("TR_REVIEW")}
									</Link>
								) : null;
						});
					}
					setAttemptsData(data);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	return translationService && quizData && attemptsData ? (
		<div className="container">
			<div className="row">
				<div className="col-12 mt-3">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							<h2 className="content-title p-0">
								{translationService.translate("TR_QUIZ_REVIEW")}
							</h2>
						</div>
					</div>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_QUIZ_REVIEW")}
						pageName="user_quiz_review"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-lg-3">
							<div className="background box-shadow-4 mb-lg-0 mb-3">
								<div className="quiz-fildest-information">
									{quizData.timeLimit ? (
										<fieldset>
											<legend>
												{translationService.translate("TR_TIME_LIMIT")}
											</legend>
											<p>
												<span className="mr-1">{quizData.timeLimit}</span>
												{(() => {
													switch (quizData.timeLimitEntity) {
														case 0:
															return translationService.translate("TR_SECONDS");
														case 1:
															return translationService.translate("TR_MINUTES");
														case 2:
															return translationService.translate("TR_HOURS");
														case 3:
															return translationService.translate("TR_DAYS");
														case 4:
															return translationService.translate("TR_WEEKS");
														default:
															break;
													}
												})()}
											</p>
										</fieldset>
									) : null}
									{quizData.openDate ? (
										<fieldset>
											<legend>
												{translationService.translate("TR_START_DATE")}
											</legend>
											<p>
												{moment(
													MainService.convertUTCDateToLocalDate(
														new Date(quizData.openDate),
													),
												).format("YYYY-MM-DD HH:mm:ss")}
											</p>
										</fieldset>
									) : null}
									{quizData.closeDate ? (
										<fieldset>
											<legend>
												{translationService.translate("TR_END_DATE")}
											</legend>
											<p>
												{moment(
													MainService.convertUTCDateToLocalDate(
														new Date(quizData.closeDate),
													),
												).format("YYYY-MM-DD HH:mm:ss")}
											</p>
										</fieldset>
									) : null}
									{quizData.questions ? (
										<fieldset>
											<legend>
												{translationService.translate("TR_QUESTIONS_COUNT")}
											</legend>
											<p>{quizData.questions.length}</p>
										</fieldset>
									) : null}
									{attemptsData.gradingMethod ||
									attemptsData.gradingMethod === 0 ? (
										<fieldset>
											<legend>
												{translationService.translate("TR_GRADING_METHOD")}
											</legend>
											<p>
												{translationService.translate(
													GRADING_METHODS_ARR_KEY[attemptsData?.gradingMethod],
												)}
											</p>
										</fieldset>
									) : null}
								</div>
							</div>
						</div>
						<div className="col-lg-9 col-12">
							<div className="background box-shadow-4 content">
								<h2 className="content-title word-break-break-word">
									{quizData.name}
								</h2>
								<hr />
								<div className="content-body pt-0">
									{quizData.questions && !quizData.questions.length ? (
										<p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
											<InfoSvg />
											{translationService.translate(
												"TR_EMPTY_QUIZ_WARNING_MSG",
											)}
										</p>
									) : null}
									<div className="content-sub-title">
										<h3>{translationService.translate("TR_QUIZ_ATTEMPTS")}</h3>
									</div>
									<div className="data-table-search-right">
										{attemptsData &&
										attemptsData.attemptResponseModels &&
										attemptsData.attemptResponseModels.length ? (
											<DataTableExtensions
												columns={quizAttemptsColumns}
												data={attemptsData.attemptResponseModels}
												export={false}
												print={false}
												filter={false}
												filterHidden={false}
												filterPlaceholder={translationService.translate(
													"TR_SEARCH",
												)}>
												<DataTable
													noHeader
													defaultSortField="earnedMark"
													defaultSortAsc={false}
													highlightOnHover
													responsive={true}
													pagination
													className="data-table-quiz-attempt"
													paginationComponentOptions={{
														rowsPerPageText:
															translationService.translate("TR_ROWS_PER_PAGE"),
													}}>
													{translationService.translate("TR_QUIZ_ATTEMPTS")}
												</DataTable>
											</DataTableExtensions>
										) : attemptsData.attempt ||
										  attemptsData.reAttempt ||
										  attemptsData.continueAttemptId ? (
											<p
												title={quizData.description}
												className="word-break-break-word">
												{quizData.description}
											</p>
										) : null}
									</div>
									{attemptsData.attempt ||
									attemptsData.reAttempt ||
									attemptsData.continueAttemptId ? null : (
										<div className="text-center mb-3">
											<p>
												{translationService.translate("TR_FINAL_GRADE_MSG")}{" "}
												{attemptsData.attemptGrade}/{attemptsData.maxGrade}
											</p>
										</div>
									)}
									<div className="d-flex justify-content-end mt-4"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(QuizReview);

import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import TranslationService from "../../../Services/translationService";
import Topics from "./topics";
import { setCuriculumBreadCrumb } from "../../../Store/Actions/main";
import {
  addButtonSpinner,
  removeButtonSpinner,
  addPartialViewSpinner,
  removePartialViewSpinner
} from "../../../Store/Actions/spinner";

class Sections extends Component {

  state = {
    courseId: +this.props.match.params.courseId,
    deleteSectionId: null,
    selectedSectionId: this.props.curiculumBreadCrumb?.selectedSectionId,
    updateSection: null,
    topics: [],
    courseQuizzes: [],
    translationService: null,
    showAddSection: false,
    dragSectionId: null,
  };

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  openTopics = id => {
    if (id === this.state.selectedSectionId) {
      this.setState({ selectedSectionId: null }, () => this.setCuriculumBreadCrumb());
    } else {
      this.setState({ selectedSectionId: id }, () => this.setCuriculumBreadCrumb());
    }
  };

  setCuriculumBreadCrumb = () => {
    const curiculumBreadCrumb = { ...this.props.curiculumBreadCrumb };
    curiculumBreadCrumb.selectedSectionId = this.state.selectedSectionId;
    this.props.setCuriculumBreadCrumb(curiculumBreadCrumb);
  }

  render() {
    const {
      translationService,
      selectedSectionId,
    } = this.state;
    const { sections, } = this.props;

    return translationService ? <div className="row">
      <div className="col-12">
        {
          sections && sections.map((section, sectionIndex) => {
            if (this.props.sectionsViewCount && sectionIndex >= this.props.sectionsViewCount) { return false; }
            return <div key={section.id} className="accordion-wrapper mt-3">
              <div className="card mindalay-card">
                <div
                  className="card-header"
                  aria-controls={`section_${section.id}`}
                  aria-expanded={selectedSectionId === section.id}
                  onClick={() => this.openTopics(section.id)}
                >
                  <h5 className="mb-2 text-elipsis">
                    {/* <span>{sectionIndex + 1}. </span> */}
                    {section.name}
                  </h5>
                </div>
                <Collapse in={selectedSectionId === section.id}>
                  <div
                    id={`section_${section.id}`}
                    className="light-blue-background mr-3 ml-3 content-block"
                  >
                    <Topics
                      topics={section.topics}
                      sectionId={selectedSectionId}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          })
        }
      </div>
    </div> : null;
  }
}

const mapStateToProps = state => ({
  buttonSpinners: state.spinner.buttonSpinners,
  language: state.language.language,
  translations: state.translation.translations,
  curiculumBreadCrumb: state.main.curiculumBreadCrumb,
});

const mapDispatchToProps = {
  addButtonSpinner,
  removeButtonSpinner,
  addPartialViewSpinner,
  removePartialViewSpinner,
  setCuriculumBreadCrumb,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sections)
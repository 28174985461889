import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from '../../Components/Inputs/input';
import ReactSelectOption from '../../Components/SelectOption/reactSelectOption';
import CloseSvg from "../../Components/Svg/closeSvg";
import TranslationService from '../../Services/translationService';
import MainService from '../../Services/mainService';
import PlusSvg from '../../Components/Svg/plusSvg';
import moment from 'moment';
import InputCheckBox from '../../Components/Inputs/inputCheckBox';
import uuid from 'react-uuid';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import InfoSvg from '../../Components/Svg/infoSvg';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { userUpdateSuccess } from "../../Store/Actions/user";
import { addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
import { compose } from 'redux';
import { CONSULTANT_KEY } from '../../Constants/requestKeys';
import { CONSULTANT_DASHBOARD_KEY, TERMS_KEY } from '../../Constants/urlKeys';
import {
  BOOLEAN_KEY,
  CONSULTANT_USER_TYPE,
  CURRENCY_SYMBOL_KEY,
  DEFAULT_CURRENCY_SYMBOL_KEY,
  ERROR_KEY,
  INSTRUCTOR_USER_TYPE,
  NUMBER_KEY,
  SUCCESS_KEY,
  TR_DELETE_CONFIRM_MESSAGE_KEY,
  TR_NO,
  TR_YES,
  WARNING_KEY
} from '../../Constants/mainKeys';

class BecomeConsultant extends Component {

  userTypeId = this.props.user.userTypeId;

  state = {
    form: {
      id: null,
      availableHours: null,
      preferredStartTime: 0,
      preferredEndTime: 0,
      consultationLanguages: [],
      consultationCategories: [],
      bio: this.props.bio || null,
      specialty: this.props.specialty || null,
      dontDisturb: false,
    },
    languageId: null,
    language: null,
    languageLevel: null,
    languageLabel: null,
    defaultValue: { label: "", value: "" },
    isInvalidSubmit: false,
    translationService: null,
    termsAndConditions: false,
    availableHoursData: [],
    experienceLevels: [],
    consultatantData: null,
    costsData: [],
    languageLevelsData: [],
    changes: false,
    contentLanguages: [],
    currencySymbol: localStorage.getItem(CURRENCY_SYMBOL_KEY) ? localStorage.getItem(CURRENCY_SYMBOL_KEY) : DEFAULT_CURRENCY_SYMBOL_KEY,
  }

  componentDidMount() {
    this.setTranslations();
    this.addCategory();
    this.getConsultantData();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (this.props.specialty !== this.state.specialty) {
      this.setState({ specialty: this.props.specialty })
    }
  }

  componentWillUnmount() {
    const { changes, translationService } = this.state
    if (changes && translationService && window.confirm(`${translationService.translate("TR_CONFIRM_SAVE_CHANGES")} ?`)) {
      this.onSubmit();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    if (!this.state.form.id && this.state.consultatantData && this.props.languages?.length) {
      this.setConsultantData(this.state.consultatantData);
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getConsultantData = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getConsultantData().then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response.data) {
        const data = { ...response.data };
        this.setState({
          availableHoursData: data?.available_hours,
          experienceLevels: data?.experience_level ? data.experience_level.filter(level => +level.name !== 0) : [],
          costsData: this.sortPriceArray(data?.price),
          languageLevelsData: data.language_levels,
          contentLanguages: data?.languages
        }, () => {
          if (+this.userTypeId === CONSULTANT_USER_TYPE) {
            this.setConsultantData(data.consultant);
          }
        })
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  setConsultantData(data) {
    if (!data) { return; }
    const { costsData } = this.state;
    const languages = [];
    const localStartTime = this.convertUtcDayTimeToLocal(data.preferredStartTime?.hours, data.preferredStartTime?.minutes);
    const localEndTime = this.convertUtcDayTimeToLocal(data.preferredEndTime?.hours, data.preferredEndTime?.minutes);
    const allLanguages = [...this.state.contentLanguages];
    const levels = [...this.state.languageLevelsData];
    data.consultationLanguages && data.consultationLanguages.forEach(consLanguage => {
      const currentLanguage = allLanguages.find(language => language.id === consLanguage.languageId);
      const currentLevel = levels.find(level => +level.name === +consLanguage.languageLevel);
      if (currentLanguage && currentLevel) {
        languages.push({
          languageId: consLanguage.languageId,
          language: currentLanguage.name,
          languageLevel: consLanguage.languageLevel,
          languageLabel: currentLevel.displayName
        });
      }
    });
    // data.consultationCategoryResponses.forEach(category => {
    //   category.priceBlock = {
    //     priceId: typeof category.consultationCategoryPriceResponses[0].costByMainCurrency === NUMBER_KEY ? costsData.find(data => +data.name === category.consultationCategoryPriceResponses[0].costByMainCurrency)?.id : null,
    //     currencyId: category.consultationCategoryPriceResponses[0].currencyId ? category.consultationCategoryPriceResponses[0].currencyId : null,
    //   };
    //   category.consultationCategoryPriceResponses[0].priceId = typeof category.consultationCategoryPriceResponses[0].costByMainCurrency === NUMBER_KEY ? costsData.find(data => +data.name === category.consultationCategoryPriceResponses[0].costByMainCurrency)?.id : null
    //   category.consultationCategoryPriceResponses[0].currencyId = category.consultationCategoryPriceResponses[0].currencyId ? category.consultationCategoryPriceResponses[0].currencyId : null
    // });
    data.consultationCategoryResponses.forEach(category => {
      category.priceBlock = {
        price: category.consultationCategoryPriceResponses.length &&
          category.consultationCategoryPriceResponses[0].price ?
          category.consultationCategoryPriceResponses[0].price : '',
        // priceId: typeof category.consultationCategoryPriceResponses[0].costByMainCurrency === NUMBER_KEY ? costsData.find(data => +data.name === category.consultationCategoryPriceResponses[0].costByMainCurrency)?.id : null,
        // currencyId: category.consultationCategoryPriceResponses[0].currencyId ? category.consultationCategoryPriceResponses[0].currencyId : null,
      };
      // category.consultationCategoryPriceResponses[0].priceId = typeof category.consultationCategoryPriceResponses[0].costByMainCurrency === NUMBER_KEY ? costsData.find(data => +data.name === category.consultationCategoryPriceResponses[0].costByMainCurrency)?.id : null
      // category.consultationCategoryPriceResponses[0].currencyId = category.consultationCategoryPriceResponses[0].currencyId ? category.consultationCategoryPriceResponses[0].currencyId : null
    });
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        id: data.id,
        dontDisturb: data.dontDisturb,
        availableHours: data.availableHours,
        preferredStartTime: localStartTime || 0,
        preferredEndTime: localEndTime || 0,
        consultationCategories: JSON.parse(JSON.stringify(data.consultationCategoryResponses)),
        consultationLanguages: languages
      }
    }));
  }

  convertUtcDayTimeToLocal = (hh, mm) => {
    var d = new Date();
    var date = new Date(d.setHours(hh, mm))
    const localDate = MainService.convertUTCDateToLocalDate(date);
    let hours = localDate.getHours();
    let minutes = localDate.getMinutes()
    if (hours.toString().length === 1) {
      hours = "0" + hours
    }
    if (minutes.toString().length === 1) {
      minutes = "0" + minutes
    }
    return `${hours}:${minutes}`
  }

  addCategory = () => {
    const { translationService } = this.state;
    const { consultationCategories } = { ...this.state.form };
    if (!consultationCategories.length) {
      this.addCategoryFields();
    } else {
      const isExist = consultationCategories.find(category =>
        // !category.themeId || (!category.experienceLevel && typeof category.experienceLevel !== NUMBER_KEY) || !category.yearsOfExperience || !category.consultationCategoryPriceResponses.length
        !category.themeId || (!category.experienceLevel && typeof category.experienceLevel !== NUMBER_KEY) || !category.yearsOfExperience
      )
      var valueArr = consultationCategories.map(function (item) { return item.themeId });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      });
      if (!isExist && !isDuplicate) {
        this.addCategoryFields();
      }
      if (isExist) {
        this.setState({ isInvalidSubmit: true })
      }
      if (isDuplicate) {
        AlertService.alert(WARNING_KEY, translationService.translate("TR_CATEGORY_DUPLICATE_MSG"))
      }
    }
  }

  addCategoryFields = () => {
    const { consultationCategories } = { ...this.state.form };
    consultationCategories.push({
      categoryId: null,
      themeId: null,
      firstLevelCategoryId: null,
      secondLevelCategoryId: null,
      experienceLevel: null,
      yearsOfExperience: "",
      consultationCategoryPriceResponses: [],
      secondLevelCategories: [],
      thirdLevelCategories: [],
      priceBlock: {
        // priceId: null,
        // currencyId: 1,
        price: "",
      },
    });
    this.setState(prevState => ({
      ...prevState,
      firstLevelCategoryId: null,
      secondLevelCategoryId: null,
      isInvalidSubmit: false,
      form: {
        ...prevState.form,
        consultationCategories
      },
    }));
  }

  setFirstSecondLevelCategory = (selectedCategory, name, index, id) => {
    if (index === undefined) { return; }
    const consultationCategories = [...this.state.form.consultationCategories];
    const selectedConsultationCategory = { ...consultationCategories[index] };
    if (selectedConsultationCategory && selectedCategory) {
      selectedConsultationCategory[name] = selectedCategory.children || selectedCategory.themeChildren;
      if (name === "secondLevelCategories" || name === "thirdLevelCategories") selectedConsultationCategory[id] = selectedCategory.id;
      if (name === "thirdLevelCategories") selectedConsultationCategory.categoryId = selectedCategory.id;
      selectedConsultationCategory.themeId = null;
      consultationCategories[index] = selectedConsultationCategory;
      this.setState(prevState => ({ ...prevState, form: { ...prevState.form, consultationCategories } }));
    }
  }

  setThirdLevelCategory = (selectedCategory, name, index) => {
    if (index === undefined) { return; }
    const consultationCategories = [...this.state.form.consultationCategories];
    const thirdLevelCategories = { ...consultationCategories[index] };
    if (thirdLevelCategories && selectedCategory) {
      thirdLevelCategories[name] = selectedCategory.id;
      consultationCategories[index] = thirdLevelCategories;
      this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, consultationCategories } }));
    }
  }

  onNumberChange(event, index) {
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes(".") || event.target.value.includes("e")) { return false; }
    const consultationCategories = [...this.state.form.consultationCategories];
    const consultationCategory = { ...consultationCategories[index] };
    consultationCategory[event.target.name] = +event.target.value;
    consultationCategories[index] = consultationCategory;
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && Number(event.target.value) <= 100)) {
      this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, consultationCategories } }));
    }
  }


  onSelectChange = (selectedCategory, name) => {
    if (selectedCategory) {
      this.setState(prevState => ({
        ...prevState,
        changes: true,
        form: {
          ...prevState.form,
          [name]: +selectedCategory.value,
        }
      }))
    }
  }

  onLanguageChange = (selectedCategory, name) => {
    if (selectedCategory) {
      if (name === "languageId" && selectedCategory) {
        this.setState({ [name]: selectedCategory.id, language: selectedCategory.label });
      }
      if (name === "languageLevel" && selectedCategory) {
        this.setState({ [name]: +selectedCategory.name, languageLabel: selectedCategory.label })
      }
    }
  }

  addConsultationLanguages = () => {
    const { languageId, language, languageLevel, languageLabel } = this.state;
    const { consultationLanguages } = this.state.form;
    if (!consultationLanguages.length && languageId >= 0 && language && languageLevel >= 0 && languageLabel) {
      consultationLanguages.push({
        languageId,
        language,
        languageLevel,
        languageLabel
      });
      this.setState(prevState => ({
        ...prevState,
        changes: true,
        form: {
          ...prevState.form,
          consultationLanguages
        }
      }));
    } else {
      if (languageId >= 0 && language && languageLevel >= 0 && languageLabel) {
        const isExist = consultationLanguages.find(lang => lang.languageId === languageId);
        if (!isExist) {
          consultationLanguages.push({
            languageId,
            language,
            languageLevel,
            languageLabel
          });
          this.setState(prevState => ({
            ...prevState,
            changes: true,
            form: {
              ...prevState.form,
              consultationLanguages
            }
          }));
        } else {
          const consultationLanguagesCopy = [...consultationLanguages];
          consultationLanguagesCopy.forEach(consultationLanguage => {
            if (consultationLanguage.languageId === isExist.languageId) {
              consultationLanguage.languageId = languageId;
              consultationLanguage.language = language;
              consultationLanguage.languageLevel = languageLevel;
              consultationLanguage.languageLabel = languageLabel;
            }
          })
          this.setState({ consultationLanguages: consultationLanguagesCopy })
        }
      }
    }
  }

  removeConsultationLanguage = (item, array) => {
    var indexArray = array.findIndex(o => {
      if (item.languageId !== undefined) {
        return o.language === item.language && o.languageId === item.languageId && o.languageLabel === item.languageLabel && o.languageLevel === item.languageLevel;
      } else { return false; }
    })
    if (indexArray !== -1) {
      array.splice(indexArray, 1);
      this.setState(prevState => ({
        ...prevState,
        changes: true,
        form: {
          ...prevState.form,
          consultationLanguages: array
        }
      }))
    }
  }

  experienceLevelChange = (selectedCategory, name, index) => {
    const consultationCategories = [...this.state.form.consultationCategories];
    if (selectedCategory) {
      const consultationCategory = { ...consultationCategories[index] }
      consultationCategory[name] = +selectedCategory.value;
      consultationCategories[index] = consultationCategory;
      this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, consultationCategories } }));
    }
  }

  // onChangeCurrencyIdPriceId = (item, name, index) => {
  //   const consultationCategories = [...this.state.form.consultationCategories];
  //   const consultationCategory = { ...consultationCategories[index] };
  //   if (name === "currencyId") {
  //     consultationCategory.priceBlock.currencyId = item.id;
  //   }
  //   if (name === "priceId") {
  //     consultationCategory.priceBlock.priceId = item.id;
  //   }
  //   if (consultationCategory.priceBlock.priceId && consultationCategory.priceBlock.currencyId && !consultationCategory.consultationCategoryPriceResponses.length) {
  //     consultationCategory.consultationCategoryPriceResponses.push({ ...consultationCategory.priceBlock });
  //   }
  //   if (consultationCategory.priceBlock.priceId && consultationCategory.priceBlock.currencyId && consultationCategory.consultationCategoryPriceResponses.length) {
  //     consultationCategory.consultationCategoryPriceResponses[0].priceId = consultationCategory.priceBlock.priceId;
  //     consultationCategory.consultationCategoryPriceResponses[0].currencyId = consultationCategory.priceBlock.currencyId;
  //   }
  //   consultationCategories[index] = consultationCategory;
  //   this.setState(prevState => ({
  //     ...prevState,
  //     changes: true,
  //     form: {
  //       ...prevState.form,
  //       consultationCategories
  //     }
  //   }))
  // }

  onPriceChange = (event, index, maxLength = null) => {
    const consultationCategories = [...this.state.form.consultationCategories];
    const consultationCategory = { ...consultationCategories[index] };
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-")) { return false };
    if (event.target.name === "price" && typeof +event.target.value === NUMBER_KEY && +event.target.value <= maxLength) {
      consultationCategory.priceBlock.price = +event.target.value;
      if (consultationCategory.consultationCategoryPriceResponses.length) {
        consultationCategory.consultationCategoryPriceResponses[0].price = consultationCategory.priceBlock.price;
      } else {
        consultationCategory.consultationCategoryPriceResponses.push({ price: +event.target.value });
      }
      consultationCategories[index] = consultationCategory;
      this.setState(prevState => ({
        ...prevState,
        changes: true,
        form: {
          ...prevState.form,
          consultationCategories
        }
      }))
    }
  }

  onCheckboxChange = event => {
    if (typeof event.target.checked === BOOLEAN_KEY) {
      this.setState({
        [event.target.name]: event.target.checked,
      })
    }
  }

  onTimeChange = event => {
    MainService.isValidTime(event.target.value) && this.setState(prevState => ({
      ...prevState,
      changes: true,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  }

  sortPriceArray = (array) => {
    if (!array || !array.length) {
      return;
    } else {
      return array.sort((a, b) => +a.name > +b.name ? 1 : -1);
    }
  }

  removeCategory = (category, index) => {
    const { translationService } = this.state;
    if (index === undefined) { return; }
    if (category.categoryId) {
      AlertService.alertConfirm(
        `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${category.categoryName ? category.categoryName : 'this category'} ?`,
        "",
        translationService.translate(TR_YES),
        translationService.translate(TR_NO)).then(() => {
          this.removeCurrentCategory(index);
        })
    } else {
      this.removeCurrentCategory(index);
    }
  }

  removeCurrentCategory = (index) => {
    const consultationCategories = [...this.state.form.consultationCategories];
    consultationCategories.splice(index, 1);
    this.setState(prevState => ({
      ...prevState,
      changes: true,
      form: {
        ...prevState.form,
        consultationCategories
      }
    }), () => {
      if (!consultationCategories.length) {
        this.addCategory();
      }
    });
  }

  removeCategoryName = (index) => {
    if (index === undefined) { return; }
    const consultationCategories = [...this.state.form.consultationCategories];
    const consultationCategory = { ...consultationCategories[index] };
    consultationCategory.categoryName = "";
    consultationCategories[index] = consultationCategory;
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        consultationCategories
      }
    }));
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        [event.target.name]: event.target.value,
      }
    }));
  }

  scrollToInvalidFieldPosition = () => {
    setTimeout(() => {
      let firstInvalidControl =
        document.querySelector(".select-fail") ||
        document.querySelector(".is-invalid") ||
        document.querySelector(".error-bordered") ||
        document.querySelector(".error-bordered-2") ||
        document.querySelector(".fail");
      firstInvalidControl && window.scrollTo({
        top: firstInvalidControl?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
        behavior: "smooth"
      });
    }, 200);
  }

  onSubmit = event => {
    event && event.preventDefault();
    this.setState({ changes: false });
    let { isInvalidSubmit, translationService } = this.state;
    const { user } = this.props;
    const { id } = this.state.form;
    const spinnerId = uuid();
    var form = JSON.parse(JSON.stringify(this.state.form));
    for (const i in form) {
      if (i === "consultationCategories") {
        form[i].forEach(priceBlock => {
          var prices = [...priceBlock.consultationCategoryPriceResponses];
          priceBlock.consultationCategoryPrices = prices;
          delete priceBlock.consultationCategoryPriceResponses;
        })
      }
    }
    if (form.preferredStartTime) {
      const split = form.preferredStartTime.split(':');
      const date = new Date();
      date.setHours(+split[0]);
      date.setMinutes(+split[1]);
      form.preferredStartTime = moment.utc(date).format('HH:mm')
    }
    if (form.preferredEndTime) {
      const split = form.preferredEndTime.split(':');
      const date = new Date();
      date.setHours(+split[0]);
      date.setMinutes(+split[1]);
      form.preferredEndTime = moment.utc(date).format('HH:mm');
    }
    var categoryWithoutCategoryId = form.consultationCategories.find(category => !category.themeId);

    form.consultationCategories.forEach(category => {
      for (const i in category) {
        if (!category.themeId) {
          return false;
        }
        if (i === "priceBlock") delete category[i];
        if (i === "secondLevelCategories") delete category[i];
        if (i === "secondLevelCategoryId") delete category[i];
        if (i === "thirdLevelCategories") delete category[i];
        if (i === "consultationCategoryPrices") {
          category.consultationCategoryPrices.forEach(consultationCategoryPrice => {
            for (const j in consultationCategoryPrice) {
              if (j === "maxHours") delete consultationCategoryPrice[j];
              if (j === "minHours") delete consultationCategoryPrice[j];
              if (j === "pricePerHour") delete consultationCategoryPrice[j];
              if (j === "costByMainCurrency") delete consultationCategoryPrice[j];
            }
          })
        }
      }
    })
    if (
      !form.availableHours ||
      form.preferredStartTime === 0 ||
      form.preferredEndTime === 0 ||
      !form.consultationLanguages.length ||
      categoryWithoutCategoryId
    ) {
      isInvalidSubmit = true;
      this.setState({ isInvalidSubmit });
      this.scrollToInvalidFieldPosition();
    } else {
      isInvalidSubmit = false;
      this.setState({ isInvalidSubmit });
    }

    if (!isInvalidSubmit) {
      let isInvalidCategory = form.consultationCategories.find(category =>
        !category.themeId ||
        typeof category.experienceLevel !== NUMBER_KEY ||
        !category.yearsOfExperience
        // || !category.consultationCategoryPrices.length
      )
      if (isInvalidCategory) {
        isInvalidSubmit = true;
        this.setState({ isInvalidSubmit });
        this.scrollToInvalidFieldPosition();
        return false;
      }
    }

    if (!isInvalidSubmit) {
      this.props.addPartialViewSpinner(spinnerId);
      if (id) {
        ApiService.updateConsultant(form).then(response => {
          this.props.removePartialViewSpinner(spinnerId);
          if (response) {
            AlertService.alert(SUCCESS_KEY, translationService.translate("TR_INFO_SAVED"));
            const userData = { ...user };
            userData.dontDisturb = form.dontDisturb;
            this.props.userUpdateSuccess(userData);
            this.props.history.push(`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`);
          }
        }).catch(error => this.getFail(error, spinnerId));
      } else {
        ApiService.createConsultant(form).then(response => {
          this.props.removePartialViewSpinner(spinnerId);
          if (response) {
            const userData = { ...user };
            if (response.data) {
              userData.userTypeDisplayName = response.data.userTypeDisplayName;
              userData.userTypeId = response.data.userTypeId;
              userData.dontDisturb = form.dontDisturb;
              this.props.userUpdateSuccess(userData);
            }
            this.props.history.push(`/${this.props.language}/${CONSULTANT_KEY}/${CONSULTANT_DASHBOARD_KEY}`);
            AlertService.alert(SUCCESS_KEY, translationService.translate("TR_INFO_SAVED"));
          }
        }).catch(error => this.getFail(error, spinnerId));
      }
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
  }

  render() {

    const { courseCategories, showBecomeConsultantForm, user } = this.props;
    const { preferredStartTime, preferredEndTime, consultationLanguages, id, consultationCategories, availableHours, dontDisturb } = this.state.form;
    const {
      termsAndConditions,
      defaultValue,
      costsData,
      isInvalidSubmit,
      translationService,
      languageId,
      languageLevel,
      availableHoursData,
      experienceLevels,
      languageLevelsData,
      contentLanguages,
      currencySymbol
    } = this.state;
    
    return (translationService && contentLanguages.length ? <section className={`${showBecomeConsultantForm ? "mt-5" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {
              user && (user.userTypeId === CONSULTANT_USER_TYPE || user.userTypeId === INSTRUCTOR_USER_TYPE) ?
                <Auxiliary>
                  <strong className="section-title">{translationService.translate("TR_CONSULTANT_INFORMATION")}</strong>
                  <hr />
                </Auxiliary>
                : null
            }
            <form className="become-a-consultant my-3" onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-5 col-sm-6 col-12">
                      <label htmlFor="">{`${translationService.translate("TR_AVAILABLE_HOURS_PER_WEEK")}*`}</label>
                      <div className="info-title d-inline-block">
                        <div>
                          <InfoSvg />
                          <span style={{ display: "none" }}>
                            {translationService.translate("TR_AVAILABLE_HOURS_INFO")}
                          </span>
                        </div>
                      </div>
                      <ReactSelectOption
                        defaultValue={defaultValue}
                        items={availableHoursData.length && availableHoursData.map(item => ({ label: item.displayName, value: item.name }))}
                        onChange={(item) => this.onSelectChange(item, "availableHours")}
                        value={availableHours}
                        selectedValue={(() => {
                          const selectedValue = { ...availableHoursData.find(data => +data.name === availableHours) };
                          if (selectedValue) {
                            selectedValue.label = selectedValue.displayName;
                            selectedValue.value = selectedValue.name;
                          }
                          return selectedValue;
                        })()}
                        isInvalidSubmit={isInvalidSubmit}
                      />
                    </div>
                    <div className="col-md-4 col-sm-6 col-12">
                      <div className="form-group">
                        <div>
                          <label>{`${translationService.translate("TR_PREFERED_HOURS")}*`}</label>
                          <div className="info-title d-inline-block">
                            <div>
                              <InfoSvg />
                              <span style={{ display: "none" }}>
                                {translationService.translate("TR_PREFERET_HOURSE_INFO")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="w-100 mr-2">
                            <Input
                              type="time"
                              name="preferredStartTime"
                              value={preferredStartTime}
                              onChange={this.onTimeChange}
                              isInvalidSubmit={isInvalidSubmit}
                            />
                          </div>
                          <div className="w-100 ml-2">
                            <Input
                              type="time"
                              name="preferredEndTime"
                              value={preferredEndTime}
                              onChange={this.onTimeChange}
                              isInvalidSubmit={isInvalidSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <label>{`${translationService.translate("TR_LANGUAGES")}*`}</label>
                        <div className="info-title d-inline-block">
                          <div>
                            <InfoSvg />
                            <span style={{ display: "none" }}>
                              {translationService.translate("TR_LANGUAGES_INFO")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <small className="form-text text-muted">{translationService.translate("TR_LANGUAGES_DESC")}</small>
                      <div className="row my-2">
                        <div className="col-lg-4 col-sm-5 col-12 mb-sm-0 my-1">
                          <ReactSelectOption
                            defaultValue={defaultValue}
                            items={contentLanguages.map(data => ({ ...data, label: data.displayName, value: data.id }))}
                            value={consultationLanguages.length ? consultationLanguages : null}
                            selectedValue={(() => {
                              const selectedValue = { ...contentLanguages.find(data => data.id === languageId) };
                              if (selectedValue) {
                                selectedValue.label = selectedValue.displayName;
                                selectedValue.value = selectedValue.languageId;
                              }
                              return selectedValue;
                            })()}
                            onChange={(item) => this.onLanguageChange(item, "languageId")}
                            isInvalidSubmit={isInvalidSubmit}
                          />
                        </div>
                        <div className="col-lg-4 col-sm-5 col-12 mb-sm-0 my-1">
                          {
                            languageLevelsData.length ?
                              <ReactSelectOption
                                defaultValue={defaultValue}
                                value={consultationLanguages.length ? consultationLanguages : null}
                                selectedValue={(() => {
                                  const selectedValue = { ...languageLevelsData.find(data => +data.name === parseInt(languageLevel)) };
                                  if (selectedValue) {
                                    selectedValue.label = selectedValue.displayName;
                                    selectedValue.value = selectedValue.name;
                                  }
                                  return selectedValue;
                                })()}
                                items={languageLevelsData.map(data => ({ ...data, label: data.displayName, value: data.name }))}
                                onChange={(item) => this.onLanguageChange(item, "languageLevel")}
                                isInvalidSubmit={isInvalidSubmit}
                              />
                              : null
                          }
                        </div>
                        <div className="col-lg-1 col-sm-2 col-12 mb-sm-0 my-1">
                          <button
                            type="button"
                            className="mindalay--btn-secondary svg-button add-language-btn"
                            title={translationService.translate("TR_ADD")}
                            onClick={this.addConsultationLanguages}
                          >
                            <PlusSvg />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="selected-item-container">
                        {
                          consultationLanguages.length ?
                            consultationLanguages.map((item, index) => {
                              return <div key={index} className="selected-item-wrapper">
                                <div className="selected-item">
                                  <p>{item.language}</p>
                                  <small>{item.languageLabel}</small>
                                </div>
                                <button
                                  type="button"
                                  className="mindalay--btn-blue"
                                  title={translationService.translate("TR_REMOVE")}
                                  onClick={() => this.removeConsultationLanguage(item, consultationLanguages)}
                                >
                                  <CloseSvg />
                                </button>
                              </div>
                            })
                            : null
                        }
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="custom-control custom-switch mt-2">
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        id="editModeSwitcher"
                        className="custom-control-input"
                        checked={dontDisturb}
                        onChange={(event) => this.setState(prevState => ({ ...prevState, changes: true, form: { ...prevState.form, dontDisturb: event.target.checked } }))}
                      />
                      <label htmlFor="editModeSwitcher" className='custom-control-label'>
                        {translationService.translate("TR_DO_NOT_DISTURB")}
                      </label>
                      <div className="info-title d-inline-block">
                        <div>
                          <InfoSvg />
                          <span style={{ display: "none" }}>
                            {translationService.translate("TR_DO_NOT_DISTURB_INFO")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                      <p className="mt-2 mb-4">
                        {translationService.translate("TR_CONSULTATION_CATEGORY_INFO")}
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        {
                          consultationCategories.length ? consultationCategories.map((category, index) => {
                            return <div key={index} className="col-12 my-2 order-md-0 order-12">
                              <div className="consultant-category-information">
                                <div className="row">
                                  {
                                    consultationCategories.length >= 1 ?
                                      <button
                                        type="button"
                                        className="remove-category"
                                        title={translationService.translate("TR_REMOVE")}
                                        onClick={() => this.removeCategory(category, index)}
                                      >
                                        <CloseSvg />
                                      </button>
                                      : null
                                  }
                                  {
                                    courseCategories && !category.categoryName ?
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                          <label htmlFor="">{`${translationService.translate("TR_CATEGORY")} *`}</label>
                                          <ReactSelectOption
                                            defaultValue={defaultValue}
                                            value={category.firstLevelCategoryId}
                                            selectedValue={(() => {
                                              const selectedValue = { ...courseCategories.find(data => data.id === category.firstLevelCategoryId) };
                                              if (selectedValue) {
                                                selectedValue.label = selectedValue.name;
                                                selectedValue.value = selectedValue.id;
                                              }
                                              return selectedValue;
                                            })()}
                                            items={courseCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                                            onChange={(item) => this.setFirstSecondLevelCategory(item, "secondLevelCategories", index, 'firstLevelCategoryId')}
                                            isInvalidSubmit={isInvalidSubmit}
                                          />
                                        </div>
                                      </div>
                                      : null
                                  }
                                  {
                                    category.secondLevelCategories?.length && !category.categoryName ?
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                          <label htmlFor="">{`${translationService.translate("TR_SUBCATEGORY")} *`}</label>
                                          <ReactSelectOption
                                            defaultValue={defaultValue}
                                            value={category.secondLevelCategoryId}
                                            selectedValue={(() => {
                                              const selectedValue = { ...category.secondLevelCategories.find(data => data.id === category.secondLevelCategoryId) };
                                              if (selectedValue) {
                                                selectedValue.label = selectedValue.name;
                                                selectedValue.value = selectedValue.id;
                                              }
                                              return selectedValue;
                                            })()}
                                            items={category.secondLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                                            onChange={(item) => this.setFirstSecondLevelCategory(item, "thirdLevelCategories", index, 'secondLevelCategoryId')}
                                            isInvalidSubmit={isInvalidSubmit}
                                          />
                                        </div>
                                      </div>
                                      : null
                                  }
                                  {
                                    category.thirdLevelCategories?.length && !category.categoryName ?
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                          <label htmlFor="">{`${translationService.translate("TR_SUBCATEGORY")} *`}</label>
                                          <ReactSelectOption
                                            defaultValue={defaultValue}
                                            value={category.themeId}
                                            selectedValue={(() => {
                                              const selectedValue = { ...category.thirdLevelCategories.find(data => data.id === category.themeId) };
                                              if (selectedValue) {
                                                selectedValue.label = selectedValue.name;
                                                selectedValue.value = selectedValue.id;
                                              }
                                              return selectedValue;
                                            })()}
                                            items={category.thirdLevelCategories.map(data => ({ ...data, label: data.name, value: data.id }))}
                                            onChange={(item) => this.setThirdLevelCategory(item, "themeId", index)}
                                            isInvalidSubmit={isInvalidSubmit}
                                          />
                                        </div>
                                      </div>
                                      : null
                                  }
                                  {
                                    category.categoryName ?
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="selected-item-container">
                                          <div className="selected-item-wrapper">
                                            <div className="selected-item">
                                              <p>{category.categoryName}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      : null
                                  }
                                  <div className="col-lg-12 mt-3">
                                    <div className="row">
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                          <div className="d-flex align-items-start">
                                            <label htmlFor="">{`${translationService.translate("TR_EXPERIENCE_LEVEL")} *`}</label>
                                          </div>
                                          {
                                            experienceLevels.length ?
                                              <ReactSelectOption
                                                defaultValue={defaultValue}
                                                value={category.experienceLevel}
                                                items={experienceLevels.map(data => ({ label: data.displayName, value: data.name }))}
                                                selectedValue={(() => {
                                                  const selectedValue = { ...experienceLevels.find(data => +data.name === category.experienceLevel) };
                                                  if (selectedValue) {
                                                    selectedValue.label = selectedValue.displayName;
                                                    selectedValue.value = selectedValue.name;
                                                  }
                                                  return selectedValue;
                                                })()}
                                                onChange={(item) => this.experienceLevelChange(item, "experienceLevel", index)}
                                                isInvalidSubmit={isInvalidSubmit}
                                              />
                                              : null
                                          }
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className="form-group">
                                          <Input
                                            id="yearsOfExperience"
                                            labelValue={`${translationService.translate("TR_YEARS_OF_EXPERIENCE")} *`}
                                            type="number"
                                            name="yearsOfExperience"
                                            value={category.yearsOfExperience}
                                            onChange={(event) => this.onNumberChange(event, index)}
                                            isInvalidSubmit={isInvalidSubmit}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-lg-12 col-12">
                                        <div className="consultant-rate-wrapper mb-2">
                                          <div className="row">
                                            <div className="col-lg-4 col-sm-6 col-12">
                                              <label>{`${translationService.translate("TR_CURRENCY")} *`}</label>
                                              <ReactSelectOption
                                                defaultValue={defaultValue}
                                                value={category.priceBlock.currencyId ? true : false}
                                                selectedValue={(() => {
                                                  const selectedValue = { ...currencyData?.find(data => data.id === category.priceBlock.currencyId) };
                                                  if (selectedValue) {
                                                    selectedValue.label = selectedValue.isocode;
                                                    selectedValue.value = selectedValue.id;
                                                  }
                                                  return selectedValue;
                                                })()}
                                                isInvalidSubmit={isInvalidSubmit}
                                                items={currencyData?.map(data => ({ ...data, value: data.id, label: data.isocode }))}
                                                onChange={item => this.onChangeCurrencyIdPriceId(item, "currencyId", index)}
                                              />
                                            </div>
                                            <div className="col-lg-4 col-sm-6 col-12">
                                              <label>{`${translationService.translate("TR_BILLING_RATE")} *`}</label>
                                              <ReactSelectOption
                                                defaultValue={defaultValue}
                                                value={category.priceBlock.priceId ? true : false}
                                                isInvalidSubmit={isInvalidSubmit}
                                                selectedValue={(() => {
                                                  const selectedValue = { ...costsData?.find(data => data.id === category.priceBlock.priceId) };
                                                  var currentCurrency = currencyData?.find(currency => currency.id === category.priceBlock.currencyId);
                                                  if (selectedValue.hasOwnProperty("id")) {
                                                    selectedValue.label =
                                                      `${currentCurrency.symbolPosition ? currentCurrency.symbol : ""}
                                                      ${(+costsData?.find(cost => cost.id === category.priceBlock.priceId)?.name / currentCurrency.rate).toFixed(currentCurrency.decimalCount)}
                                                      ${!currentCurrency.symbolPosition ? currentCurrency.symbol : ""}
                                                    `;
                                                    selectedValue.value = selectedValue.id;
                                                  }
                                                  return selectedValue;
                                                })()}
                                                items={costsData.map(data => ({
                                                  ...data,
                                                  value: data.id,
                                                  label:
                                                    `${currencyData?.find(currency => currency.id === category.priceBlock.currencyId).symbolPosition
                                                      ? currencyData?.find(currency => currency.id === category.priceBlock.currencyId).symbol
                                                      : ""}
                                                  ${(+data.name / currencyData?.find(currency => currency.id === category.priceBlock.currencyId)?.rate)
                                                      .toFixed(currencyData?.find(currency => currency.id === category.priceBlock.currencyId).decimalCount)}
                                                  ${!currencyData?.find(currency => currency.id === category.priceBlock.currencyId).symbolPosition
                                                      ? currencyData?.find(currency => currency.id === category.priceBlock.currencyId).symbol
                                                      : ""}
                                                  `
                                                }))}
                                                onChange={item => this.onChangeCurrencyIdPriceId(item, "priceId", index)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div className="col-lg-4 col-sm-6 col-12">
                                        <div className='icon-input'>
                                          <Input
                                            type="number"
                                            id="price"
                                            name="price"
                                            min="0"
                                            value={category.priceBlock.price || ""}
                                            labelValue={`${translationService.translate("TR_PRICE")}`}
                                            onChange={event => this.onPriceChange(event, index, 1000000)}
                                          />
                                          {
                                            currencySymbol ? <span>{currencySymbol}</span> : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }) : null
                        }
                        <div className="col-12">
                          <button
                            type="button"
                            className="mindalay--btn-default w-sm-100"
                            onClick={this.addCategory}
                          >
                            {translationService.translate("TR_ADD_CATEGORY")}
                          </button>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex">
                        <InputCheckBox
                          id="termsAndConditions"
                          name="termsAndConditions"
                          checked={termsAndConditions}
                          labelValue={translationService.translate("TR_TERMS_AND_CONDITIONS")}
                          onChange={this.onCheckboxChange}
                        />
                        <Link to={`/${this.props.language}/${TERMS_KEY}`} className="ml-3" target="_blank">{translationService.translate("TR_LEARN_MORE")}</Link>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="mindalay--btn-secondary btn mt-4"
                        disabled={!this.state.termsAndConditions}
                      >
                        {!id ? translationService.translate("TR_BECOME_A_CONSULTANT") : translationService.translate("TR_UPDATE")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  courseCategories: state.course.courseCategories,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  userUpdateSuccess
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BecomeConsultant);



import React from 'react';

const messageSend = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
      x="0px" y="0px" viewBox="0 0 448 448"
      style={{ fill: 'transparent' }}
      xmlSpace="preserve">
      <g>
        <g>
          <polygon
            points="0.213,32 0,181.333 320,224 0,266.667 0.213,416 448,224   ">
          </polygon>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default messageSend;

import React, { Component } from 'react';
import TranslationService from '../../../Services/translationService';
import { connect } from 'react-redux';
import SettingsSvg from '../../../Components/Svg/settingsSvg';
import Search from '../../../Components/Search/search';
import { ASC_DESC_FILTER_DATA, ERROR_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES } from "../../../Constants/mainKeys";
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import uuid from 'react-uuid';
import ApiService from '../../../Services/apiService';
import AlertService from '../../../Services/alertService';
import moment from 'moment';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { CREATE_CONSULTATION_KEY, STUDENT_CONSULTATION_DASHBOARD_KEY } from '../../../Constants/urlKeys';
import ShowMoreText from "react-show-more-text";
import { Link } from 'react-router-dom';
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import { setConsultationContractBackUrl } from './../../../Store/Actions/main';

class AllConsultations extends Component {

  ascDescFilterData = ASC_DESC_FILTER_DATA;

  state = {
    translationService: null,
    ascDescFilterData: '',
    defaultValue: { label: "", value: "" },
    consultations: [],
    defaultConsultations: [],
    showConsultationsCount: 5
  }

  componentDidMount() {
    this.setTranslations();
    this.getConsultations();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  changeAscDescFilterData = (selectedCategory, name) => {
    if (selectedCategory, name) {
      this.setState({ ascDescFilterData: selectedCategory.value + 1 });
    }
  }

  filterConsultations = (filteredArray, defaultArray, name) => {
    if (filteredArray !== null) {
      this.setState({ [name]: filteredArray });
    } else {
      this.setState({ [name]: this.state[defaultArray] });
    }
  }

  redirectToDashboard = (consultationId, contractState) => {
    if (!consultationId || !contractState) { return; }
    this.props.history.push(`/${this.props.language}/${STUDENT_CONSULTATION_DASHBOARD_KEY}/${contractState}/${consultationId}`);
  }

  getConsultations = () => {
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getConsultations().then(response => {
      if (response) {
        this.props.removePageSpinner(spinnerId);
        if (response.data) {
          var data = [...response.data];
          data.forEach(consultation => {
            consultation.showMoreDesc = false;
            consultation.proposalContracts = [];
            consultation.interviewContracts = [];
            consultation.inProcessContracts = [];
            consultation.completedContracts = [];
            consultation.contracts && consultation.contracts.forEach(contract => {
              if (!contract.studentAccepted || !contract.consultantAccepted) {
                consultation.proposalContracts.push(contract);
              }
              if (contract.studentAccepted && contract.consultantAccepted && !contract.signed) {
                consultation.interviewContracts.push(contract)
              }
              if (contract.signed && !contract.isCompleted) {
                consultation.inProcessContracts.push(contract);
              }
              if (contract.isCompleted) {
                consultation.completedContracts.push(contract);
              }
            })
          })
          data = data.reverse();
          response.data && this.setState({ defaultConsultations: data, consultations: data });
        }
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  removeConsultation = (currentConsultation) => {
    const spinnerId = uuid();
    const { translationService } = this.state;
    var consultations = [...this.state.consultations];
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${currentConsultation.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      ApiService.removeConsultation(currentConsultation.id).then(() => {
        this.props.removePageSpinner(spinnerId);
        consultations = consultations.filter(consultation => consultation.id !== currentConsultation.id);
        const defaultConsultations = consultations;
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CONSULTATION_DELETED"));
        this.setState({ consultations, defaultConsultations });
      }).catch(error => this.getFail(error, spinnerId))
    })
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {

    const { language } = this.props;
    const { translationService, consultations, showConsultationsCount, defaultConsultations } = this.state;

    return (
      translationService ? <section className="m-section"><div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="section-title">{translationService.translate("TR_ALL_CONSULTATIONS")}</h2>
            <form onSubmit={event=> event.preventDefault()}>
              <div className="d-lg-flex align-items-center justify-content-between">
                <Search
                  searchData={defaultConsultations}
                  searchFieldName="name"
                  emitSearchedData={data => this.filterConsultations(data, "defaultConsultations", "consultations")}
                />
                <div className="mindalay--btn-link-wrapper--instructor">
                  <p className="text-right">
                    {`${translationService.translate("TR_CONSULTATION_NUMBERS")}: `}
                    <strong>{defaultConsultations.length}</strong>
                  </p>
                </div>
                <Link
                  className="mindalay--btn-link mindalay--btn-dark mt-md-0 mt-3 m--w-md-100 float-right ml-md-3"
                  onClick={() => this.props.setConsultationContractBackUrl(this.props.location.pathname)}
                  to={`/${language}/${CREATE_CONSULTATION_KEY}`}
                >
                  {translationService.translate("TR_ORDER_CONSULTATION")}
                </Link>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div className="row justify-content-center">
          <div className="col-12">
            {
              consultations.length ? consultations.map((consultation, index) => {
                return (showConsultationsCount > index) ? <div key={consultation.id} className="mindalay--consultation-card mindalay--consultation-container d-lg-flex d-block my-2">
                  <div className="overflow-hidden flex-1">
                    <div className="dropleft setting-dropdown">
                      <div className="setting-dropdown-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <SettingsSvg />
                      </div>
                      <div className="dropdown-menu">
                        <Link
                          className="dropdown-item"
                          to={`/${language}/${CREATE_CONSULTATION_KEY}/${consultation.id}`}
                        >
                          {translationService.translate("TR_EDIT")}
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => this.removeConsultation(consultation)}
                        >
                          {translationService.translate("TR_DELETE")}
                        </Link>
                      </div>
                    </div>
                    <strong className="consultation-header-name">{consultation.name}</strong>
                    <small className="d-block font-weight-bold">
                      {`${translationService.translate("TR_CREATED")} - ${moment(consultation.creationDate).format("LL")}`}
                    </small>
                    <hr />
                    {
                      consultation.description ?
                        <div>
                          <ShowMoreText
                            lines={2}
                            more={translationService.translate("TR_SHOW_MORE")}
                            less={translationService.translate("TR_LESS")}
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                          >
                            <p>{consultation.description}</p>
                          </ShowMoreText>
                        </div>
                        : null
                    }
                    <div>
                      <ul className="d-flex align-items-center flex-wrap mt-2">
                        {
                          consultation.proposalContracts && consultation.proposalContracts.length > 0 ?
                            <li className="consultation-status-item proposal my-sm-0 my-1 cursor-pointer"
                              onClick={() => consultation.proposalContracts.length && this.redirectToDashboard(consultation.id, "proposal")}
                            >
                              <Link to="#" >
                                {translationService.translate("TR_PROPOSAL")}
                              </Link>
                              <span>{` - ${consultation.proposalContracts.length}`}</span></li>
                            : null
                        }
                        {
                          consultation.interviewContracts && consultation.interviewContracts.length > 0 ?
                            <li className="consultation-status-item interview my-sm-0 my-1 cursor-pointer"
                              onClick={() => consultation.interviewContracts.length && this.redirectToDashboard(consultation.id, "interview")}
                            >
                              <Link to="#">
                                {translationService.translate("TR_INTERVIEW")}
                              </Link>
                              <span>{` - ${consultation.interviewContracts.length}`}</span></li>
                            : null
                        }
                        {
                          consultation.inProcessContracts && consultation.inProcessContracts.length > 0 ?
                            <li className="consultation-status-item in-process my-sm-0 my-1 cursor-pointer"
                              onClick={() => consultation.inProcessContracts.length && this.redirectToDashboard(consultation.id, "in-process")}
                            >
                              <Link to="#">
                                {translationService.translate("TR_IN_PROCESS")}
                              </Link>
                              <span>{` - ${consultation.inProcessContracts.length}`}</span></li>
                            : null
                        }
                        {
                          consultation.completedContracts && consultation.completedContracts.length > 0 ?
                            <li className="consultation-status-item completed my-sm-0 my-1 cursor-pointer"
                              onClick={() => consultation.completedContracts.length && this.redirectToDashboard(consultation.id, "completed")}
                            >
                              <Link to="#">
                                {translationService.translate("TR_COMPLETED")}
                              </Link>
                              <span>{` - ${consultation.completedContracts.length}`}</span></li>
                            : null
                        }
                      </ul>
                    </div>
                  </div>
                </div> : null
              }) :
                <div className="no-data-container">
                  <div className="no-data-wrapper">
                    <p>{translationService.translate("TR_YOU_HAVE_NOT_CONSULTATIONS")}</p>
                    <img src={NoDataImage} alt="/" />
                  </div>
                </div>
            }
          </div>
          <div className="col-12">
            {
              consultations && (consultations.length > showConsultationsCount)
                ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => this.setState({ showConsultationsCount: showConsultationsCount + 5 })}>
                  {translationService.translate("TR_SHOW_MORE")}
                </button> : null
            }
          </div>
        </div>
      </div></section>
        : null
    );
  }
}
const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner,
  setConsultationContractBackUrl
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(AllConsultations);


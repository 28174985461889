import React, { useState, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import ReactSelectOption from '../../../Components/SelectOption/reactSelectOption';
import DeleteSvg from '../../../Components/Svg/deleteSvg';
import EditSvg from '../../../Components/Svg/editSvg';
import AlertService from '../../../Services/alertService';
import ApiService from '../../../Services/apiService';
import TranslationService from '../../../Services/translationService';
import SubCategories from './subCategories';
import Input from "../../../Components/Inputs/input";
import { addButtonSpinner, addPageSpinner, removeButtonSpinner, removePageSpinner } from './../../../Store/Actions/spinner';
import ActionButton from '../../../Components/ActionButton/actionButton';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import { CATEGORY_TYPES_KEY, ERROR_KEY, SUCCESS_KEY, TR_DELETE_CONFIRM_MESSAGE_KEY, TR_NO, TR_YES } from '../../../Constants/mainKeys';
import { NUMBER_KEY } from './../../../Constants/mainKeys';

const MainCategories = (props) => {
  const dispatch = useDispatch();

  const translations = useSelector(state => state.translation.translations);

  const { categories } = props;

  const categoryTypes = CATEGORY_TYPES_KEY;
  const mainSpinnerId = uuid();
  const defaultValue = { label: "", value: "" };
  const nameMaxLength = 120;

  const [translationService, setTranslationService] = useState(null);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [currentCategoryId, setCurrentCategoryId] = useState(props.currentCategoryId || null);
  const [name, setName] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [categoryType, setCategoryType] = useState(2);
  const [showCategoriesCount, setShowCategoriesCount] = useState(20);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (typeof categoryType === NUMBER_KEY) {
      props.getCategoriesByType(categoryType)
    }
  }, []);

  const openSectionData = (id) => {
    if (!id) { return; }
    if (id === currentCategoryId) {
      setCurrentCategoryId(null);
    } else {
      setCurrentCategoryId(id);
    }
  }

  const setFirstLevelCategory = (selectedCategory) => {
    if (!selectedCategory) { return; }
    setMainCategoryId(selectedCategory.id);
  }

  const categoryDelete = (event, category, name) => {
    event && event.stopPropagation();
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${category.name} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO),
    ).then(() => {
      dispatch(addPageSpinner(spinnerId));
      ApiService.categoryDelete(category.id).then(() => {
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DELETE_CATEGORY_INFO"));
        dispatch(removePageSpinner(spinnerId));
        props.updateCategories(category.id, name, categoryType);
      }).catch(error => getFail(error, spinnerId));
    });
  }

  const onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setName(event.target.value);
    setIsInvalidSubmit(false);
  };

  const onFilterChange = (selectedCategoryType) => {
    if (!selectedCategoryType) { return; }
    setShowCategoriesCount(20);
    setCategoryType(selectedCategoryType.value);
    props.getCategoriesByType(selectedCategoryType.value);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!name || (name && !name.trim())) {
      setIsInvalidSubmit(true);
      return;
    }
    const data = {
      categoryType,
      name,
      parentId: mainCategoryId
    }
    dispatch(addButtonSpinner(mainSpinnerId));
    ApiService.createCategory(data).then(() => {
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CREATE_CATEGORY_INFO"));
      dispatch(removeButtonSpinner(mainSpinnerId));
      props.getCategoriesByType(categoryType);
      setMainCategoryId(null);
      setName("");
    }).catch(error => getFail(error, mainSpinnerId));
  }

  const cancel = () => {
    setMainCategoryId(null);
    setName("");
    setIsInvalidSubmit(false);
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
    spinnerId && dispatch(removeButtonSpinner(spinnerId));
  }

  return translationService ? <div className="container">
    <div className="row">
      <div className="col-12">
        <h2 className="content-title p-0">
          {translationService.translate("TR_CATEGORIES")}
        </h2>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label>{translationService.translate("TR_SORT_CATEGORIES_BY")}</label>
              <ReactSelectOption
                defaultValue={defaultValue}
                value={mainCategoryId}
                selectedValue={(() => {
                  const selectedValue = { ...categoryTypes.find(data => data.value === categoryType) };
                  if (selectedValue) {
                    selectedValue.label = translationService.translate(selectedValue.label);
                    // selectedValue.value = selectedValue.value;
                  }
                  return selectedValue;
                })()}
                items={categoryTypes.map(data => ({ ...data, label: translationService.translate(data.label), value: data.value }))}
                onChange={(item) => onFilterChange(item)}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
    {
      categories && categories.length ?
        <Auxiliary>
          <div className="row">
            <div className="col-12">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="">{`${translationService.translate("TR_CATEGORY")}`}</label>
                      <ReactSelectOption
                        defaultValue={defaultValue}
                        value={mainCategoryId}
                        selectedValue={(() => {
                          const selectedValue = { ...categories.find(data => data.id === mainCategoryId) };
                          if (selectedValue) {
                            selectedValue.label = selectedValue.name;
                            selectedValue.value = selectedValue.id;
                          }
                          return selectedValue;
                        })()}
                        items={categories.map(data => ({ ...data, label: data.name, value: data.id }))}
                        onChange={(item) => setFirstLevelCategory(item)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <Input
                      type="text"
                      name="name"
                      inputClassName="pr--5"
                      blockClassName="w-100"
                      fieldLength={nameMaxLength}
                      value={name}
                      labelValue={`${translationService.translate("TR_NAME")} *`}
                      isInvalidSubmit={isInvalidSubmit}
                      isInvalidField={isInvalidSubmit && !name.trim()}
                      onChange={event => onChange(event, nameMaxLength)}
                    />
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <label htmlFor="d-none d-sm-block ">{'\u00A0'}</label>
                    <div className="d-flex">
                      <ActionButton
                        type="submit"
                        spinnerId={mainSpinnerId}
                        className="mindalay--btn-default position-relative"
                        name={translationService.translate("TR_CREATE")}
                      />
                      <button type="button" className="ml-1 mindalay--btn-default" onClick={cancel}>
                        {translationService.translate("TR_CANCEL")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <hr />
          {
            categories && categories.map((category, index) => {
              return (showCategoriesCount > index) ? <div key={category.id} className="accordion-wrapper course-pass-section">
                <div className="card mindalay-card">
                  <div
                    className="card-header card-header-background-color d-flex justify-content-between align-items-center"
                    aria-controls={`category_${category.id}`}
                    aria-expanded={currentCategoryId === category.id}
                    onClick={() => openSectionData(category.id)}
                  >
                    <h5 className="mb-0 text-elipsis">{category.name}</h5>
                    <div className="mr-4">
                      <div className="course-wrapper--instructor">
                        <div className="course-button-group">
                          <div className="course-button-group d-flex mr-5">
                            <Link className="course-edit-button mr-1" to='#' onClick={event => event.stopPropagation()}>
                              <EditSvg />
                            </Link>
                            <Link className="course-delet-button ml-1" to="#" onClick={(event) => categoryDelete(event, category, "mainCategory")}>
                              <DeleteSvg />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      category.children && category.children.length ?
                        <i className="fas fa-angle-down"></i>
                        : null
                    }
                  </div>
                  <Collapse in={currentCategoryId === category.id}>
                    <div id={`category_${category.id}`} className="mindalay--collapse-body">
                      <SubCategories
                        subCategories={category.children ? category.children : []}
                        categoryDelete={categoryDelete}
                      />
                    </div>
                  </Collapse>
                </div>
              </div> : null
            })
          }
          <div className="row">
            <div className="col-12 mt-3">
              {
                categories && (categories.length > showCategoriesCount)
                  ? <button type="button" className="mindalay--btn-dark w-100" onClick={() => setShowCategoriesCount(showCategoriesCount + 20)}>
                    {translationService.translate("TR_SHOW_MORE")}
                  </button> : null
              }
            </div>
          </div>
        </Auxiliary>
        : <div className="no-data-container">
          <div className="no-data-wrapper">
            <p>{translationService.translate("TR_THERE_IS_NO_CATEGORIES_OF_THAT_TYPE")}</p>
            <img src={NoDataImage} alt="/" />
          </div>
        </div>
    }
  </div> : null
}

export default MainCategories;

import React from "react";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import PureLayoutRoutes from "../../Routing/pureLayoutRoutes";
import PureFooter from "../LayoutSections/Footer/pureFooter";
import PureHeader from "../LayoutSections/Header/pureHeader";

const PureLayout = props => {

  return <Auxiliary>
    <PureHeader />
    <section className="wrapper top-section m-section">
      {props.children}
      <PureLayoutRoutes />
    </section>
    <PureFooter />
  </Auxiliary>
}

export default PureLayout
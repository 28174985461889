import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import TranslationService from '../../Services/translationService';

export default function DiaryDocument(props) {

  const {
    a1,
    a2,
    a3,
    a4,
    a5,
    a6,
    a7,
    a8,
    a15,
    a17,
  } = props.state;
  const { tableDataCopy } = props;

  const translations = useSelector(state => state.translation.translations);
  const [rowNumber, setRowNumber] = useState(1);
  const [columnNumber, setColumnNumber] = useState(9);
  const [tableData, setTableData] = useState([]);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const user = useSelector(state => state.user.user);

  useEffect(() => {
    props.setState(prevState => ({
      ...prevState,
      a1: props.organizationName ? props.organizationName : "",
      a2: "",
      a3: props.groupName ? props.groupName : "",
      a4: user.fullName ? user.fullName : "",
      a5: "",
      a6: "",
      a7: "",
      a8: "",
      a10: "",
      a12: "",
    }));
  }, []);

  useEffect(() => {
    if (!tableDataCopy || (tableDataCopy && !tableDataCopy.length)) {
      addRowToTableData();
    } else {
      setTableData([...tableDataCopy]);
      var theBiggestRowNumber = Math.max(...tableDataCopy.map(o => o.number));
      theBiggestRowNumber && setRowNumber(+theBiggestRowNumber + 1);
      var theBiggestColumnNumber = Math.max(...tableDataCopy.map(o => o.columnNumber));
      theBiggestColumnNumber && setColumnNumber(+theBiggestColumnNumber + 1);
    }
  }, [tableDataCopy]);

  const addRowToTableData = () => {
    var columnNumberItem = columnNumber;
    var rowNumberItem = rowNumber;
    var tableDataItem = tableData;
    tableDataItem.push({ number: rowNumberItem, columnNumber: columnNumberItem, value: '', timeType: null, field_name: `date_${rowNumberItem}` })
    columnNumberItem += 1;
    setColumnNumber(columnNumberItem);
    tableDataItem.push({ number: rowNumberItem, columnNumber: columnNumberItem, value: '', timeType: 'start', field_name: `start_time_${rowNumberItem}` })
    tableDataItem.push({ number: rowNumberItem, columnNumber: columnNumberItem, value: '', timeType: 'end', field_name: `end_time_${rowNumberItem}` })
    columnNumberItem += 1;
    setColumnNumber(columnNumberItem);
    tableDataItem.push({ number: rowNumberItem, columnNumber: columnNumberItem, value: '', timeType: null, field_name: `short_description_${rowNumberItem}` })
    columnNumberItem += 1;
    setColumnNumber(columnNumberItem);
    tableDataItem.push({ number: rowNumberItem, columnNumber: columnNumberItem, value: '', timeType: null, field_name: `grade_${rowNumberItem}` })
    columnNumberItem += 1;
    setColumnNumber(columnNumberItem);
    setRowNumber(rowNumber + 1);
    setTableData(tableDataItem);
    props.setState(prevState => ({
      ...prevState,
      tableData: tableDataItem
    }));
  }

  const onDateChange = (event, field_name) => {
    const tableDataCopy = [...tableData];
    tableDataCopy.forEach(tableDataItem => {
      if (tableDataItem.field_name === field_name) {
        tableDataItem.value = event.target.value
      }
    })
    setTableData(tableDataCopy);
    props.setState(prevState => ({
      ...prevState,
      tableData: tableDataCopy
    }));
  }

  const onTextChange = (event, field_name) => {
    const tableDataCopy = [...tableData];
    tableDataCopy.forEach(tableDataItem => {
      if (tableDataItem.field_name === field_name) {
        tableDataItem.value = event.target.value
      }
    })
    setTableData(tableDataCopy);
    props.setState(prevState => ({
      ...prevState,
      tableData: tableDataCopy
    }));
  }

  const removeRow = (rowNumber) => {
    var tableDataCopy = [...tableData];
    tableDataCopy = tableDataCopy.filter(tableDataItem => tableDataItem.number !== rowNumber);
    setTableData(tableDataCopy);
    props.setState(prevState => ({
      ...prevState,
      tableData: tableDataCopy
    }));
  }

  return (translationService ? <div className='row'>
    <div className='col-12'>
      {
        <div id='document' className='my-5 d-flex justify-content-center'>
          <div className='document-body'>
            <input
              name="a1"
              className='custom-input text-center pb-1'
              placeholder={props.organizationName}
              value={a1}
              disabled
              onChange={() => { }}
            />
            <h1 className="template-title mt-1">ДНЕВНИК</h1>
            <h3 className="center template-sub-title margin-h3 mt-2">
              <div className='d-flex align-items-baseline justify-content-center'>
                <span className='mr-3'>прохождения</span>
                <input
                  name="a2"
                  type="text"
                  value={a2}
                  className="custom-input template-input bottom-short-mrg fit-height"
                  onChange={(event) => props.handleChange(event)}
                />
                <span className='ml-3'>практики</span>
              </div>
            </h3>

            <div className='d-flex align-items-baseline'>
              <span className='mr-3'>Обучающегося группы </span>
              <input
                name="a3"
                type="text"
                value={a3}
                placeholder={props.groupName}
                className="custom-input template-input bottom-short-mrg fit-height"
                onChange={(event) => props.handleChange(event)}
              />
            </div>
            <h2 className="center selected-font mt-3">
              <input
                name="a4"
                type="text"
                placeholder="ФАМИЛИЯ ИМЯ ОТЧЕСТВО"
                className="custom-input template-input bottom-short-mrg text-center"
                value={a4}
                disabled={true}
                onChange={() => { }}
              />
            </h2>

            <p className="flex-wrap mt-4">Место прохождения практики<br />
              <input
                name="a5"
                type="text"
                placeholder="полное наименование предприятия, учреждения, организации"
                className="custom-input fit-height"
                value={a5}
                onChange={(event) => props.handleChange(event)}
              />
            </p>
            <p className="flex-wrap">(полное наименование предприятия, учреждения, организации)</p>

            <p className="flex-wrap mt-3 mb-3">Руководитель практики
              <input
                id="a6"
                name="a6"
                value={a6}
                type="text"
                placeholder="Фамилия Имя Отчество – должность"
                className="custom-input template-input bottom-short-mrg fit-height"
                onChange={(event) => props.handleChange(event)}
              />
            </p>
            <p className="flex-wrap mt-4">Период прохождения практики с
              <input
                name="a7"
                type="date"
                className="custom-input date-input bottom-short-mrg"
                value={a7}
                onChange={(event) => props.handleChange(event)}
              />
              <span className="mx-1">до</span>
              <input
                name="a8"
                type="date"
                className="custom-input date-input bottom-short-mrg"
                value={a8}
                onChange={(event) => props.handleChange(event)}
              />
            </p>
            <p className="flex-wrap mt-4">
              <button id="add-18" type="button" className="table-row-add ml-0" onClick={addRowToTableData}>
                Add
              </button></p>
            <div className='table-block mt-3'>

              <div className="table-responsive table-bordered border-radius-10">
                <table className="table mb-0" style={{ maxWidth: "100%" }}>
                <thead className="thead-dark">
                    <tr>
                      <td>№</td>
                      <td>Дата</td>
                      <td>Время работы</td>
                      <td>Краткое описание<br />
                        выполненной работы</td>
                      <td>Оценка за<br />
                        выполненную<br />
                        работу
                      </td>
                      <td>Подпись<br />
                        руководителя</td>
                      <td>Действия</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData && tableData.length ?
                        tableData.map((tableDataItem, index, tableData) => {
                          var nextElement = tableData[index + 1];
                          if ((nextElement && nextElement.number !== tableDataItem.number) || !nextElement) {
                            return <tr key={index}>
                              <td className='p-2'>{(index + 1) / 5}</td>
                              <td>
                                <input
                                  type="date"
                                  className="custom-input date-input"
                                  name={`date_${tableDataItem.number}`}
                                  value=
                                  {
                                    tableData.find(element => element.field_name === `date_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `date_${tableDataItem.number}`).value :
                                      ""
                                  }
                                  onChange={(event) => onDateChange(event, `date_${tableDataItem.number}`)}
                                />
                              </td>
                              <td><div className="flexable d-flex">
                                <input
                                  type="time"
                                  className="custom-input compact-width"
                                  name={`start_time_${tableDataItem.number}`}
                                  value=
                                  {
                                    tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`).value :
                                      ""
                                  }
                                  onChange={(event) => onDateChange(event, `start_time_${tableDataItem.number}`)}
                                /> -
                                <input
                                  type="time"
                                  className="custom-input compact-width"
                                  name={`end_time_${tableDataItem.number}`}
                                  value=
                                  {
                                    tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`).value :
                                      ""
                                  }
                                  onChange={(event) => onDateChange(event, `end_time_${tableDataItem.number}`)}
                                />
                              </div>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Вписать текст"
                                  className="custom-input template-table-input"
                                  name={`short_description_${tableDataItem.number}`}
                                  value=
                                  {
                                    tableData.find(element => element.field_name === `short_description_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `short_description_${tableDataItem.number}`).value :
                                      ""
                                  }
                                  onChange={(event) => onTextChange(event, `short_description_${tableDataItem.number}`)}
                                />
                              </td>
                              <td className='px-2'>
                                <input
                                  type="text"
                                  placeholder="Вписать текст"
                                  className="custom-input template-table-input m-0"
                                  name={`grade_${tableDataItem.number}`}
                                  value=
                                  {
                                    tableData.find(element => element.field_name === `grade_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `grade_${tableDataItem.number}`).value :
                                      ""
                                  }
                                  onChange={(event) => onTextChange(event, `grade_${tableDataItem.number}`)}
                                />
                              </td>
                              <td><div></div></td>
                              <td>
                                {
                                  tableData && tableData.length && tableData.length > 5 ?
                                    <button type="button" className="table-row-add" onClick={() => removeRow(tableDataItem.number)}>{translationService.translate("TR_REMOVE")}</button>
                                    : null
                                }
                              </td>
                            </tr>
                          }
                        })
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <p className="flex-wrap mt-7">Практикант
              <input
                type="text"
                className="custom-input max-assignment-width fit-height"
                disabled
                onChange={() => { }}
              />
              <input
                name="a15"
                value={a15}
                type="text"
                placeholder="Фамилия И.О."
                className="custom-input template-input bottom-short-mrg fit-height"
                onChange={(event) => props.handleChange(event)}
              />
            </p>
            <p className="flex-wrap mt-3">Содержание и объем выполненных работ подтверждаю.</p>
            <p className="flex-wrap mt-3 mb-3">Руководитель практики
              <input
                type="text"
                className="custom-input max-assignment-width fit-height"
                disabled
                onChange={() => { }}
              />
              <input
                name="a17"
                value={a17}
                type="text"
                placeholder="Фамилия И.О."
                className="custom-input template-input bottom-short-mrg fit-height mt-2"
                onChange={(event) => props.handleChange(event)}
              />
            </p>

          </div>
        </div>
      }

    </div>
  </div> : null
  )
}

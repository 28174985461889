import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import TranslationService from './../../../Services/translationService';
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import pdfIcon from "../../../assets/icons/pdf.png";
import txtIcon from "../../../assets/icons/txt.png";
import xlsIcon from "../../../assets/icons/xls.png";
import PosterImage from "../../../assets/images/video-poster.png";
import { IMAGE_KEY, OBJECT_KEY, STRING_KEY, VALID_FILE_TYPES_KEY, VIDEO_KEY } from '../../../Constants/mainKeys';
import CloseSvg from './../../../Components/Svg/closeSvg';
import MainService from '../../../Services/mainService';

const includesData = (file, type) => {
    if (typeof type === STRING_KEY && file.mimeType.includes(type)) {
        return file.mimeType
    }
    if (typeof type === OBJECT_KEY) {
        var validType = type.find(element => file.mimeType.includes(element))
        if (validType) return file.mimeType
    }
}

const FileLibraryFiles = (props) => {

    const [zoomVideoPath, setZoomVideoPath] = useState(null);
    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const { textFiles } = props;


    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const hideZoomImageVideo = () => {
        setZoomVideoPath(null);
    }

    return translationService ? textFiles && textFiles.length ? <div className="mindalay-files-gallery-container">
        {
            zoomVideoPath ?
                <div className="zoom-image-container" onClick={hideZoomImageVideo}>
                    <div className="zoom-image-block">
                        <div className="close-svg"><CloseSvg /></div>
                        <video controls={true} onClick={(event) => event.stopPropagation()}>
                            <source src={zoomVideoPath} />
                        </video>
                    </div>
                </div>
                : null
        }
        <div className="row">
            {
                textFiles ? textFiles.map((file, index) =>
                    <div key={index} className="col-lg-4 col-md-6 col-12 my-2">
                        <div className="mindalay-file-wrapper mindalay-file">
                            {
                                (() => {
                                    switch (file.mimeType) {
                                        case includesData(file, IMAGE_KEY):
                                            return <img src={file.filePath} alt={file.filePath} />;
                                        case includesData(file, VIDEO_KEY):
                                            return <video
                                                className="file-video cursor-pointer"
                                                autoPlay={true}
                                                poster={PosterImage}
                                                onPointerDown={() => setZoomVideoPath(file.filePath)}
                                            />
                                        case includesData(file, VALID_FILE_TYPES_KEY):
                                            switch (file.mimeType) {
                                                case includesData(file, "pdf"):
                                                    return <img src={pdfIcon} alt="/" />;
                                                case includesData(file, "txt"):
                                                    return <img src={txtIcon} alt="/" />;
                                                case includesData(file, "doc"):
                                                    return <img src={''} alt="/" />;
                                                case includesData(file, "rtf"):
                                                    return <img src={''} alt="/" />;
                                                case includesData(file, "xls"):
                                                    return <img src={xlsIcon} alt="/" />;
                                                case includesData(file, "xlsx"):
                                                    return <img src={''} alt="/" />;
                                                default:
                                                    break
                                            }
                                        default:
                                            break;
                                    }
                                })()
                            }
                            <div className="card-body">
                                <p className="card-text">{file.name}</p>
                            </div>
                            <a href={MainService.getDownloadUrl(file.downloadPath ? file.downloadPath : file.filePath)} target="blank" className="float-right brand-color">
                                <i className="fas fa-download" />
                            </a>
                        </div>
                    </div>
                ) : null
            }
        </div>
    </div> : <div className="no-data-container mt-3">
        <div className="no-data-wrapper">
            <p>{translationService.translate("TR_YOU_HAVE_NO_FILES")}</p>
            <img src={NoDataImage} alt="/" />
        </div>
    </div>
        : null
}

export default FileLibraryFiles;



import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../UserPages/Login/login";
import Terms from "../UserPages/Policy/terms";
import DataPolicy from "../UserPages/Policy/dataPolicy";
import CookiesPocicy from "../UserPages/Policy/cookiesPolicy";
import ConfirmEmail from "../UserPages/ConfirmEmail/confirmEmail";
import ResetPassword from "../UserPages/ResetPassword/resetPassword";
import Info from "../UserPages/Info/info";
import FooterMenu from "../UserPages/FooterMenu/footerMenu";
import NotFoundPage from "../Pages/404/notFoundPage";
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import Help from "../Pages/Help/help";
import Faq from "../Pages/Help/faq";
import Topic from "../Pages/Help/topic";
import OrganizationForm from "../UserPages/Organization/organizationForm";
import ContactUs from "../UserPages/ContactUs/contactUs";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import CustomPaymentPackage from "../UserPages/CustomPaymentPackage/customPaymentPackage";
import Checkout from "../Pages/Checkout/checkout";
import CoursesForOrgAdmin from "../OrganizationAdminPages/Courses/CoursesForOrgAdmin";
import Course from "./../OrganizationAdminPages/Courses/CoursePriceForm";
import Finances from "../Pages/Overview/finances";
import PaymentSchedule from "../OrganizationPages/PaymentSchedule/paymentSchedule";
import PaymentSchedules from "../OrganizationPages/PaymentSchedule/paymentSchedules";
import JoinOneTimeWebinar from "../Pages/Webinars/joinOneTimeWebinar";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import _Help from "../Pages/Help/_help.js";
import About from "../UserPages/About/about.js";
import TermsAndCondition from "../UserPages/TermsAndCondition/termsAndCondition.js";
import PrivacyPolicy from "../UserPages/PrivacyPolicy/privacyPolicy.js";

const SpecialDomainRoutes = () => {
	return (
		<Switch>
			{/* Home */}
			{/* <Route path="/:language" exact component={Info} /> */}
			{/* <Route path="/:language/mindalay-for-institutions/info" exact component={Info} /> */}

			<Route path="/:language" exact component={Info} />

			{/* Login, Registration */}
			<Route path="/:language/login" exact component={Login} />
			<Route
				path="/:language/registration"
				exact
				component={OrganizationForm}
			/>
			{/* Create organization */}
			<Route
				path="/:language/organization-admin/organization-form"
				exact
				component={OrganizationForm}
			/>

			<Route
				path="/:language/custom-payment-package"
				exact
				component={CustomPaymentPackage}
			/>

			{/* Help */}
			{/* <Route path="/:language/help" exact component={Help} />
    <Route path="/:language/help/:pageName" exact component={Help} />
    <Route path="/:language/help/:pageName/FAQ" exact component={Faq} />
    <Route path="/:language/help/:pageName/topic/:topicId" exact component={Topic} /> */}
			<Route path="/:language/help" exact component={_Help} />
			<Route path="/:language/help/topic/:topicId" exact component={_Help} />
			<Route path="/:language/help/faq/:faqId" exact component={_Help} />

			{/* Change password */}
			<Route path="/:language/forgot-password" exact component={ConfirmEmail} />
			<Route
				path="/:language/user/changecredentials/:guid"
				exact
				component={ResetPassword}
			/>

			{/* Terms and Policy */}
			<Route path="/:language/terms" exact component={Terms} />
			<Route path="/:language/data-policy" exact component={DataPolicy} />

			<Route
				path="/:language/organization-admin/courses"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/program/:_programId"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/program/:_programId/:_subjectId"
				exact
				component={CoursesForOrgAdmin}
			/>
			<Route
				path="/:language/organization-admin/courses/:courseId"
				exact
				component={Course}
			/>

			{/* Checkout */}
			<Route path="/:language/checkout" exact component={Checkout} />
			<Route
				path="/:language/checkout/paypalsuccess/returnurl"
				exact
				component={Checkout}
			/>
			<Route
				path="/:language/checkout/paypalcancel/returnurl"
				exact
				component={Checkout}
			/>
			<Route
				path="/:language/checkout/stripesuccess/returnurl"
				exact
				component={Checkout}
			/>
			<Route
				path="/:language/checkout/stripecancel/returnurl"
				exact
				component={Checkout}
			/>
			<Route
				path="/:language/checkout/cryllexsuccess/returnurl"
				exact
				component={Checkout}
			/>
			<Route
				path="/:language/checkout/cryllexcancel/returnurl"
				exact
				component={Checkout}
			/>
			<Route path="/:language/checkout/:courseId" exact component={Checkout} />

			<Route
				path="/:language/organization-admin/finances"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/stripesuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/stripecancel/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/paypalsuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/paypalcancel/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/cryllexsuccess/returnurl"
				exact
				component={Finances}
			/>
			<Route
				path="/:language/organization-admin/finances/cryllexcancel/returnurl"
				exact
				component={Finances}
			/>

			<Route path="/:language/contact-us" exact component={ContactUs} />
			<Route path="/:language/about/:pageId" exact component={About} />
			<Route
				path="/:language/privacy-policy/:pageId"
				exact
				component={PrivacyPolicy}
			/>
			<Route
				path="/:language/cookies-policy/:pageId"
				exact
				component={CookiesPocicy}
			/>
			<Route
				path="/:language/terms-and-condition/:pageId"
				exact
				component={TermsAndCondition}
			/>
			<Route
				path="/:language/organization/payment/schedule/:innerPaymentId"
				exact
				component={PaymentSchedule}
			/>
			<Route
				path="/:language/organization/payment/schedules"
				exact
				component={PaymentSchedules}
			/>

			{/* About-us info */}
			<Route path="/:language/tariffs/:pageId" exact component={FooterMenu} />
			<Route
				path="/:language/platform-instructions/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/company-policy/:pageId"
				exact
				component={FooterMenu}
			/>
			<Route
				path="/:language/personal-data-processing-policy/:pageId"
				exact
				component={FooterMenu}
			/>

			<Route
				path="/:language/video-conference-room/:webinarId/:webinarCalendarId/:lecturerUserId"
				exact
				component={JoinOneTimeWebinar}
			/>

			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>

			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>

			{/* 404 */}
			<Route path="/:language/not-found/404" exact component={NotFoundPage} />

			<Redirect to={`/${localStorage.getItem(LANGUAGE_KEY)}`} />
			<Route component={NotFoundPage} />
		</Switch>
	);
};
export default SpecialDomainRoutes;

import React from "react";

const shortAnswerSvg = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="26.35" height="21.958" viewBox="0 0 26.35 21.958">
    <path d="M176.823,21.958a.824.824,0,0,1-.823-.823V.823a.823.823,0,1,1,1.647,0V21.135A.824.824,0,0,1,176.823,21.958Zm0,0" transform="translate(-166.942 0)" fill="#2980b9"></path>
    <path d="M18.939,4.392a.824.824,0,0,1-.823-.823V1.647H1.647V3.568A.823.823,0,1,1,0,3.568V.823A.824.824,0,0,1,.823,0H18.939a.824.824,0,0,1,.823.823V3.568A.824.824,0,0,1,18.939,4.392Zm0,0" fill="#2980b9"></path>
    <path d="M133.764,396.315h-4.941a.823.823,0,0,1,0-1.647h4.941a.823.823,0,0,1,0,1.647Zm0,0" transform="translate(-121.413 -374.357)" fill="#2980b9"></path>
    <path d="M299.491,195.568a.824.824,0,0,1-.823-.823v-1.921a.824.824,0,0,1,.823-.823h9.332a.824.824,0,0,1,.823.823v1.647a.823.823,0,1,1-1.647,0v-.823h-7.685v1.1A.824.824,0,0,1,299.491,195.568Zm0,0" transform="translate(-283.297 -182.119)" fill="#2980b9"></path>
    <path d="M390.155,204.077a.824.824,0,0,1-.823-.823v-10.43a.823.823,0,0,1,1.647,0v10.43A.824.824,0,0,1,390.155,204.077Zm0,0" transform="translate(-369.295 -182.119)" fill="#2980b9"></path>
    <path d="M366.236,396.315h-2.745a.823.823,0,0,1,0-1.647h2.745a.823.823,0,0,1,0,1.647Zm0,0" transform="translate(-344.004 -374.357)" fill="#2980b9"></path>
  </svg>
}

export default shortAnswerSvg;
import React from 'react';

const starSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      style={{ width: props.width }}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <path className="st0" d="M114.6,491.6c-5.6,0-11.2-1.8-15.9-5.2c-8.9-6.4-13-17.4-10.6-28.1L121,313.3l-111.7-98   c-8.2-7.2-11.3-18.5-8-28.9c3.4-10.4,12.5-17.7,23.4-18.7l147.8-13.4L231,17.5C235.3,7.5,245.1,1,256,1s20.7,6.5,25,16.5   l58.4,136.8l147.8,13.4c10.9,1,20.1,8.3,23.4,18.7c3.4,10.4,0.3,21.7-8,28.9L391,313.3l32.9,145.1c2.4,10.7-1.7,21.7-10.6,28.1   c-8.8,6.4-20.6,6.9-29.9,1.3L256,411.6l-127.4,76.2C124.2,490.3,119.4,491.6,114.6,491.6z M256,378.8c4.8,0,9.6,1.3,14,3.9   l120.3,71.9l-31.1-136.9c-2.2-9.7,1.1-19.9,8.6-26.5l105.5-92.5l-139.5-12.7c-10-0.9-18.7-7.2-22.6-16.5L256,40.4l-55.1,129.1   c-3.9,9.2-12.5,15.5-22.5,16.4L38.7,198.6l105.5,92.5c7.6,6.6,10.9,16.8,8.6,26.5l-31.1,136.9L242,382.6   C246.4,380.1,251.2,378.8,256,378.8z M171.4,156.9C171.4,156.9,171.4,156.9,171.4,156.9L171.4,156.9z M340.5,156.9L340.5,156.9   C340.6,156.9,340.6,156.9,340.5,156.9z" />
      </g>
    </svg>

  );
}

export default starSvg;

import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const dropdown = props => {
  return (
    <Dropdown className={props.className}>
      <Dropdown.Toggle>
        {props.children} {props.title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          props.items && props.items.length && props.items.map(item => <Dropdown.Item
            key={item.languageId || item.id}
            href={item.href}
            eventKey={item.isocode2 || item.id}
            onSelect={(eventKey, event) => props.selected(eventKey, event, item)}
          >
            {item.name}
          </Dropdown.Item>)
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default dropdown;
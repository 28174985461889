import React from 'react';
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InstructorDashboardRoutes from '../../Routing/instructorDashboardRoutes';
import InstructorFooter from '../LayoutSections/Footer/instructorFooter';
import InstructorHeader from '../LayoutSections/Header/instructorHeader';
import GeneralDashboardLeftMenu from '../LayoutSections/LeftMenu/GeneralDashboardLeftMenu';
import { withRouter } from "react-router-dom"

const InstructorDashboardLayout = props => {
  return <Auxiliary>
    <InstructorHeader />
    <div className="wrapper">
      {props.children}
      <GeneralDashboardLeftMenu />
      <section className={`${props.location.pathname.includes("/help") ? "" : "instructior-dashboard-body--large"} `}>
        <div className="top-section m-section wrapper">
          <div className="container container-dashboard--instructor">
            <div className="row">
                <InstructorDashboardRoutes />
            </div>
          </div>
        </div>
        <InstructorFooter />
      </section>
    </div>
  </Auxiliary>;
}

export default withRouter(InstructorDashboardLayout);


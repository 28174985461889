import { takeLatest, call, put } from 'redux-saga/effects';
import ApiService from '../../Services/apiService';
import AlertService from "../../Services/alertService";
import { addPageSpinner, removePageSpinner } from '../Actions/spinner';
import { ERROR_KEY } from "../../Constants/mainKeys";
import { GET_TRANSLATIONS_REQUEST } from '../../Constants/reduxRequestKeys';
import { GET_TRANSLATIONS_SUCCESS } from '../../Constants/reduxKeys';


export default function* watcher() {
  yield takeLatest(GET_TRANSLATIONS_REQUEST, getTranslations);
}

function* getTranslations(spinnerId) {
  try {
    // yield put(addPageSpinner(spinnerId));
    const allTranslations = yield call(ApiService.getAllTranslations);
    yield put({
      type: GET_TRANSLATIONS_SUCCESS,
      payload: allTranslations && allTranslations.data && allTranslations.data.length ? [...allTranslations.data] : []
    });
    // yield put(removePageSpinner(spinnerId));
  } catch (error) {
    yield put(removePageSpinner(spinnerId));
    error && AlertService.alert((AlertService.checkMessageType(error?.respcode) || ERROR_KEY), error);
  }
}



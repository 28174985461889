import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "./../../Services/translationService";
import ApiService from "./../../Services/apiService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "./../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../Services/alertService";
import { COURSE_ENTITY_TYPE, ERROR_KEY } from "../../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import {
	ORGANIZATION_KEY,
	PAYMENT_KEY,
	SCHEDULE_KEY,
} from "../../Constants/urlKeys";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const PaymentSchedules = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [schedulesData, setSchedulesData] = useState([]);

	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getPaymentSchedule();
	}, []);

	const handlePageClick = event => {
		setActivePageNumber(event.selected);
	};

	const getPaymentSchedule = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentSchedules()
			.then(resposne => {
				if (resposne && resposne.data) {
					setSchedulesData(resposne.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_PAYMENT_SCHEDULES")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_PAYMENT_SCHEDULES")}
						pageName="student_payment_schedules"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				{schedulesData && schedulesData.length ? (
					<div className="col-12">
						{
							<div className="mindayal-admin-table">
								<table className="table-responsive-md table">
									<thead>
										<tr>
											<th className="text-nowrap">
												{translationService.translate("TR_NAME")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_TYPE")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_PRICE")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_PERIOD")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_PAYMENT_COUNT")}
											</th>
											<th className="text-nowrap">
												{translationService.translate("TR_VIEW")}
											</th>
										</tr>
									</thead>
									<tbody>
										{schedulesData.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.entityName}</td>
													<td>
														{translationService.translate(
															item.entityType === COURSE_ENTITY_TYPE
																? "TR_COURSE"
																: "",
														)}
													</td>
													<td>{item.priceAsString}</td>
													<td>
														{item.period ? item.period : 0}{" "}
														{translationService.translate(
															item.period && item.period > 1
																? "TR_DAYS"
																: "TR_DAY",
														)}
													</td>
													<td>{item.paymentCount}</td>
													<td>
														<button
															type="button"
															className={`mindalay--btn-primary-outline mindalay--btn-small`}
															onClick={() =>
																props.history.push(
																	`/${language}/${ORGANIZATION_KEY}/${PAYMENT_KEY}/${SCHEDULE_KEY}/${item.id}`,
																)
															}>
															{translationService.translate("TR_VIEW")}
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						}
					</div>
				) : (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p className="col-12">
								{translationService.translate("TR_NO_DATA")}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
			{pagination ? (
				<div className="my-4">
					<div className="row">
						<div className="col-12">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(PaymentSchedules);

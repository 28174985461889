import React from "react";
import { confirmAlert } from "react-confirm-alert";
import {
	ERROR_KEY,
	OBJECT_KEY,
	SUCCESS_KEY,
	WARNING_KEY,
	INFO_KEY,
} from "../Constants/mainKeys";
import warningSvg from "../assets/icons/warning.svg";

import { toast } from "react-toastify";
import Auxiliary from "../hoc/auxiliary/auxiliary";

const getAlertType = (type, cb, error) => {
	if (!type) {
		cb.error(error);
	}
	switch (type) {
		case "info":
			return cb.info(error, { position: toast.POSITION.TOP_RIGHT });
		case "success":
			return cb.success(error, { position: toast.POSITION.TOP_RIGHT });
		case "warning":
			return cb.warning(error, { position: toast.POSITION.TOP_RIGHT });
		case "error":
			return cb.error(error, { position: toast.POSITION.TOP_RIGHT });
		default:
			break;
	}
};

class AlertService {
	static checkMessageType = respcode => {
		switch (respcode) {
			case 0:
				return SUCCESS_KEY;
			case 1:
				return ERROR_KEY;
			case 2:
				return WARNING_KEY;
			case 3:
				return INFO_KEY;
			case 4:
				return WARNING_KEY;
			default:
				return ERROR_KEY;
		}
	};

	static alert = (type, error) => {
		const respMessage =
			typeof error === OBJECT_KEY ? error.message || error.respmess : error;
		if (!respMessage || (respMessage && respMessage.includes("401"))) { return false; }
		getAlertType(type, toast, respMessage);
	};

	static alertConfirm = (title, message, yes, no) => {
		return new Promise((resolve, reject) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="mindalay--modal-window">
							<div className="mindalay--modal-window-wrapper">
								<div className="mindalay--modal">
									<div className="d-flex align-items-start">
										<img src={warningSvg} alt="/" />
										<div className="d-block">
											{title ? (
												<Auxiliary>
													<strong>{title}</strong>
												</Auxiliary>
											) : null}
											{message ? <p className="mt-3">{message}</p> : null}
										</div>
									</div>
									<hr />
									<div className="d-flex mt-3 justify-content-end">
										<button
											className="mindalay--btn mindalay--btn-secondary-outline mx-1 no"
											onClick={() => {
												reject();
												onClose();
											}}>
											{no}
										</button>
										<button
											className="mindalay--btn mindalay--btn-dark mx-1 yes"
											onClick={() => {
												resolve();
												onClose();
											}}>
											{yes}
										</button>
										
									</div>
								</div>
							</div>
						</div>
					);
				},
			});
		});
	};

	static removeAlertBlock = () => {
		var currentBlock = document.getElementById("alertBlockCloseButton");
		if (currentBlock && currentBlock.parentNode) {
			currentBlock.parentNode.removeChild(currentBlock);
		} else {
			return;
		}
	};
}

export default AlertService;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TranslationService from './../../Services/translationService';
import ReactPaginate from 'react-paginate';
import uuid from 'react-uuid';
import { addPartialViewSpinner, removePartialViewSpinner } from './../../Store/Actions/spinner';
import ApiService from './../../Services/apiService';
import AlertService from '../../Services/alertService';
import { CONSULTATION_ENTITY_TYPE, ERROR_KEY, PAYMENT_PACKAGE_ENTITY_TYPE } from '../../Constants/mainKeys';
import MainService from './../../Services/mainService';
import moment from 'moment';
import ModalComponentWithoutActionButton from '../../Components/ModalComponent/modalComponentWithoutActionButton';
import Auxiliary from './../../hoc/auxiliary/auxiliary';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { useHistory, withRouter } from 'react-router-dom';
import { PAYMENT_LOGS_KEY } from '../../Constants/urlKeys';


const PaymentLogs = (props) => {
  const history = useHistory();
  const pageSize = 10;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pageLogs, setPageLogs] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [currentLog, setCurrentLog] = useState(null);
  const [currentLogEntityName, setCurrentLogEntityName] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [entityType, setEntityType] = useState(null);

  const handlePageClick = (event) => {
    getPage(event.selected + 1);
    setActivePageNumber(event.selected);
  };

  useEffect(() => {
    setActivePageNumber(0);
    getPage(1);
  }, [history.location.pathname]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const getPage = (currentPage) => {
    const spinnerId = uuid();
    setPageLogs([]);
    dispatch(addPartialViewSpinner(spinnerId));
    const localPaymentPage = props.location.pathname.includes(PAYMENT_LOGS_KEY) ? true : false;
    (localPaymentPage ? ApiService.getPaymentLogs(currentPage, pageSize) : ApiService.getBalanceLogs(currentPage, pageSize)).then(response => {
      if (response && response.pagination) setPagination(JSON.parse(response.pagination));
      response.data && setPageLogs(response.data);
      dispatch(removePartialViewSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  }

  const getLogByPaymentId = (currentLog) => {
    // if (!currentLog.paymentId) { return; }
    const spinnerId = uuid();
    setEntityType(currentLog.entityType);
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getLogByPaymentId(currentLog.paymentId).then(response => {
      const data = { ...response.data };
      if (data.historyResponseModels && data.historyResponseModels.length && currentLog.entityType === PAYMENT_PACKAGE_ENTITY_TYPE) {
        //"ORGANIZATION_PAYMENT_PACKAGE"
        data.historyResponseModels.forEach(model => {
          model.packagePeriod = currentLog.packagePeriod;
          model.entityName = currentLog.entityName;
        })
      }
      if (data.historyResponseModels && data.historyResponseModels.length && currentLog.entityType === CONSULTATION_ENTITY_TYPE) {
        // CONSULTATION
        data.historyResponseModels.forEach(model => {
          model.consultationDuration = currentLog.consultationDuration;
        })
      }
      if (data.historyResponseModels && data.historyResponseModels.length && currentLog.entityType === 2) {
        // BALANCE
        data.historyResponseModels.forEach(model => {
          model.actionName = currentLog.actionName;
          model.providerName = currentLog.providerName;
          model.status = currentLog.status;
        })
      }
      if (currentLog.responseBody) {
        data.error = currentLog.responseBody;
      }
      setCurrentLog(data);
      setIsShowModal(true);
      setCurrentLogEntityName(currentLog.entityTypeName);
      dispatch(removePartialViewSpinner(spinnerId));
    }).catch(error => getFail(error, spinnerId));
  }

  const cancel = () => {
    setCurrentLog(null);
    setIsShowModal(false);
    setCurrentLogEntityName("");
  }
  
  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
  }

  let fileLibraryColumns;

  if (translationService && entityType === 14) {
    //ORGANIZATION_PAYMENT_PACKAGE
    fileLibraryColumns = [
      {
        name: translationService.translate("TR_AMOUNT"),
        selector: 'amountWithCurrency',
      },
      {
        name: translationService.translate("TR_CREATE_DATE"),
        selector: 'createDateField',
      },
      {
        name: translationService.translate("TR_TYPE"),
        selector: 'paymentTypeName',
      },
      {
        name: translationService.translate("TR_PERIOD"),
        selector: 'packagePeriodField',
      },
      {
        name: translationService.translate("TR_PACKAGE_NAME"),
        selector: 'entityName',
      },
    ];

    if (currentLog && currentLog.historyResponseModels && currentLog.historyResponseModels.length) {
      currentLog.historyResponseModels.forEach(model => {
        model.createDateField = (() => {
          return <div>
            <span>
              {moment(MainService.convertUTCDateToLocalDate(new Date(model.createDate))).format("ll HH:mm")}
            </span>
          </div>
        })();
        model.packagePeriodField = (() => {
          return <div>
            <span>{`${model.packagePeriod} ${translationService.translate("TR_MONTH")}`} </span>
          </div>
        })();
      })
    }
  }

  if (translationService && entityType === CONSULTATION_ENTITY_TYPE) {
    //CONSULTATION
    fileLibraryColumns = [
      {
        name: translationService.translate("TR_AMOUNT"),
        selector: 'amountWithCurrency',
      },
      {
        name: translationService.translate("TR_CREATE_DATE"),
        selector: 'createDateField',
      },
      {
        name: translationService.translate("TR_TYPE"),
        selector: 'paymentTypeName',
      },
      {
        name: translationService.translate("TR_DURATION"),
        selector: 'durationField',
      },
      {
        name: translationService.translate("TR_UNFREEZE_DATE"),
        selector: 'unFreezeDateField',
      },
    ];
    if (currentLog && currentLog.historyResponseModels && currentLog.historyResponseModels.length) {
      currentLog.historyResponseModels.forEach(model => {
        model.createDateField = (() => {
          return <div>
            <span>
              {moment(MainService.convertUTCDateToLocalDate(new Date(model.createDate))).format("ll HH:mm")}
            </span>
          </div>
        })();
        model.durationField = (() => {
          return <div>
            <span>
              {model.consultationDuration ? `${model.consultationDuration} ${translationService.translate("TR_MINUTES")}` : null}
            </span>
          </div>
        })();
        model.unFreezeDateField = (() => {
          return <div>
            <span>
              {model.unFreezeDate ? moment(MainService.convertUTCDateToLocalDate(new Date(model.unFreezeDate))).format("ll HH:mm") : null}
            </span>
          </div>
        })();
      })
    }
  };

  if (translationService && entityType === 2) {
    //BALANCE
    fileLibraryColumns = [
      {
        name: translationService.translate("TR_AMOUNT"),
        selector: 'amountWithCurrency',
      },
      {
        name: translationService.translate("TR_CREATE_DATE"),
        selector: 'createDateField',
      },
      {
        name: translationService.translate("TR_TYPE"),
        selector: 'paymentTypeName',
      },
      {
        name: translationService.translate("TR_ACTION"),
        selector: 'actionName',
      },
      {
        name: translationService.translate("TR_PROVIDER"),
        selector: 'providerName',
      },
      {
        name: translationService.translate("TR_STATUS"),
        selector: 'status',
      },

    ];
    if (currentLog && currentLog.historyResponseModels && currentLog.historyResponseModels.length) {
      currentLog.historyResponseModels.forEach(model => {
        model.createDateField = (() => {
          return <div>
            <span>
              {moment(MainService.convertUTCDateToLocalDate(new Date(model.createDate))).format("ll HH:mm")}
            </span>
          </div>
        })();
      })
    }
  };

  if (translationService && entityType !== PAYMENT_PACKAGE_ENTITY_TYPE && entityType !== CONSULTATION_ENTITY_TYPE && entityType !== 2) {
    //OTHER
    fileLibraryColumns = [
      {
        name: translationService.translate("TR_AMOUNT"),
        selector: 'amountWithCurrency',
      },
      {
        name: translationService.translate("TR_CREATE_DATE"),
        selector: 'createDateField',
      },
      {
        name: translationService.translate("TR_TYPE"),
        selector: 'paymentTypeName',
      },
      {
        name: translationService.translate("TR_UNFREEZE_DATE"),
        selector: 'unFreezeDateField',
      },
    ];
    if (currentLog && currentLog.historyResponseModels && currentLog.historyResponseModels.length) {
      currentLog.historyResponseModels.forEach(model => {
        model.createDateField = (() => {
          return <div>
            <span>
              {moment(MainService.convertUTCDateToLocalDate(new Date(model.createDate))).format("ll HH:mm")}
            </span>
          </div>
        })();
        model.unFreezeDateField = (() => {
          return <div>
            <span>
              {
                model.unFreezeDate ?
                  moment(MainService.convertUTCDateToLocalDate(new Date(model.unFreezeDate))).format("ll HH:mm")
                  : null
              }
            </span>
          </div>
        })();
      })
    }
  };
  const inInPaymentLogsPage = props.location.pathname.includes(`/${PAYMENT_LOGS_KEY}`) ? true : false;
  return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12">
					{inInPaymentLogsPage ? (
						<h2 className="content-title p-0">
							{translationService.translate("TR_PAYMENT_LOGS")}
						</h2>
					) : (
						<h2 className="content-title p-0">
							{translationService.translate("TR_BALANCE_LOGS")}
						</h2>
					)}
					<hr />
				</div>
			</div>
			{currentLog && isShowModal ? (
				<ModalComponentWithoutActionButton
					isLarge={true}
					title={currentLogEntityName}
					cancel={cancel}>
					<div className="row">
						<div className="col-12">
							{
								// currentLog && currentLog.historyResponseModels && currentLog.historyResponseModels?.length ?
								<Auxiliary>
									{currentLog.error && MainService.isJson(currentLog.error) ? (
										<div>
											{Object.entries(JSON.parse(currentLog.error)).map(
												([key, value]) => {
													return (
														<span key={key} className="mt-1 d-block">
															<p>
																<b>{key}:</b> {value}
															</p>
														</span>
													);
												},
											)}
										</div>
									) : currentLog.error &&
									  !MainService.isJson(currentLog.error) ? (
										<span className="mt-1 d-block">
											<p>
												<b>{translationService.translate("TR_ERROR")}:</b>{" "}
												{currentLog.error}
											</p>
										</span>
									) : null}
									{currentLog.historyResponseModels &&
									currentLog.historyResponseModels.length ? (
										<DataTableExtensions
											columns={fileLibraryColumns}
											data={currentLog.historyResponseModels}
											export={false}
											print={false}
											filterHidden={false}
											filterPlaceholder={translationService.translate(
												"TR_SEARCH",
											)}>
											<DataTable
												noHeader
												defaultSortField="name"
												fixedHeader={false}
												pagination={false}
												highlightOnHover
												paginationComponentOptions={{
													rowsPerPageText:
														translationService.translate("TR_ROWS_PER_PAGE"),
												}}
											/>
										</DataTableExtensions>
									) : null}
								</Auxiliary>
							}
						</div>
					</div>
				</ModalComponentWithoutActionButton>
			) : null}
			<div className="row">
				<div className="col-12">
					<div className="mindayal-admin-table table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th>{translationService.translate("TR_NAME")}</th>
									<th>{translationService.translate("TR_DATE")}</th>
									<th>{translationService.translate("TR_AMOUNT")}</th>
									<th className="text-center">
										{translationService.translate("TR_SUCCESSFUL")}
									</th>
								</tr>
							</thead>
							<tbody>
								{pageLogs && pageLogs.length
									? pageLogs.map((pageLog, index) => {
											return (
												<tr
													key={index}
													onClick={() => getLogByPaymentId(pageLog)}>
													<td>{pageLog.entityTypeName}</td>
													<td>
														{moment(
															MainService.convertUTCDateToLocalDate(
																new Date(pageLog.requestTime),
															),
														).format("ll HH:mm")}
													</td>
													<td>{pageLog.entityPrice}</td>
													<td>
														{pageLog.isError ? (
															<span className="payment-modal-status-icon-block">
																<i className="fas fa-times transaction-unsuccessful fs-30"></i>
															</span>
														) : (
															<span className="payment-modal-status-icon-block">
																<i className="fas fa-check transaction-successful fs-30"></i>
															</span>
														)}
													</td>
												</tr>
											);
									  })
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{pagination ? (
				<div className="row mt-4">
					<div className="col-12">
						<div>
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
}

export default withRouter(PaymentLogs);

import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";

const Currency = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const { language } = useSelector(state => state.language);

	const [currency, setCurrency] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getCurrency();
	}, []);

	const filtercurrency = useMemo(() => {
		if (search.trim().length && currency.length) {
			return currency.filter(
				el =>
					el.name.toLowerCase().includes(search.toLowerCase()) ||
					el.isocode.toLowerCase().includes(search.toLowerCase()),
			);
		} else {
			return currency;
		}
	}, [currency, search]);

	const getCurrency = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCurrency()
			.then(response => {
				if (response && response.data) {
					setCurrency(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteCurrency = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteCurrency(item.id)
				.then(() => {
					setCurrency(currency.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_CURRENCY")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-center justify-content-end">
				<div className="col-12 col-md-6">
					<div className="position-relative">
						<Input
							type="text"
							id="search"
							name="search"
							inputClassName="pr--5"
							value={search}
							// fieldLength={fieldsLength.nameMaxLength}
							// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							onChange={event => {
								if (event.target.value.trim().length) {
									setSearch(event.target.value);
								} else {
									setSearch("");
								}
							}}
						/>
						<div
							style={{
								position: "absolute",
								bottom: "50%",
								transform: "translateY(50%)",
								right: "9px",
								pointerEvents: "none",
							}}>
							<i
								className="fa fa-search"
								style={{ color: "var(--mindalay--gray-color-lightened-75)" }}
								aria-hidden="true"></i>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<Link
						to={`/${language}/developer/currency-form`}
						className="mindalay--btn-default">
						Create currency
					</Link>
				</div>
			</div>
			{filtercurrency && filtercurrency.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPES_PAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ISO_CODE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_SYMBOL")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_RATE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_SYMBOL_POSITION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{filtercurrency.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														{item.isocode}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														{item.symbol}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														{item.rate}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														<span>
															{item.symbolPosition ? (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-check transaction-successful fs-30"></i>
																</div>
															) : (
																<div className="payment-modal-status-icon-block">
																	<i className="fas fa-times transaction-unsuccessful fs-30"></i>
																</div>
															)}
														</span>
													</div>
												</td>

												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={`/${language}/developer/currency-form/${item.id}`}
																title={translationService.translate("TR_EDIT")}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															<Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deleteCurrency(item);
																}}>
																<DeleteSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Currency);

import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Topics from "../CourseTopics/topics";
import SectionForm from "./sectionForm";
import SettingsSvg from "../../Components/Svg/settingsSvg";
import uuid from "react-uuid";
import { setCuriculumBreadCrumb } from "../../Store/Actions/main";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
	COURSES_KEY,
	COURSE_DASHBOARD_KEY,
	QUIZ_FORM_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	GAMIFY_SECTION_ENTITY_TYPE_ID,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import GamifyApiService from "../../Services/gamifyApiService";
import GameSvg from "../../Components/Svg/gameSvg";
import GamificationItemsComponent from "../../OrganizationPages/Gamification/Components/Modals/GamificationItemsComponent";

class Sections extends Component {
	state = {
		courseId: +this.props.match.params.courseId,
		deleteSectionId: null,
		selectedSectionId: this.props.curiculumBreadCrumb?.selectedSectionId,
		updateSection: null,
		topics: [],
		sections: [],
		courseQuizzes: [],
		translationService: null,
		showAddSection: false,
		dragSectionId: null,
		isLoading: false,
		gamifyData: null,
		selectedEntityData: null,
		gamifyEntityTypeId: null,
	};

	componentDidMount() {
		this.setTranslations();
		if (this.props.sections && this.props.sections.length) {
			if (localStorage.getItem("selectedSectionId")) {
				if (
					this.props.sections.find(
						section =>
							section.id === +localStorage.getItem("selectedSectionId"),
					)
				) {
					this.openSection(+localStorage.getItem("selectedSectionId"), true);
				} else {
					this.openSection(this.props.sections[0].id, true);
				}
			} else {
				this.openSection(this.props.sections[0].id, true);
			}
		}
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		if (
			JSON.stringify(this.props.sections) !==
				JSON.stringify(nextProps.sections) &&
			this.state.selectedSectionId
		) {
			const selectedSection = nextProps.sections.find(
				data => data.id === this.state.selectedSectionId,
			);
			selectedSection && this.setState({ topics: selectedSection.topics });
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	deleteSection = section => {
		const { translationService } = this.state;
		const { user } = this.props;
		AlertService.alertConfirm(
			`${translationService.translate("TR_SECTION_DELETE_CONFIRM_MSG")}
       ${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				section.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.cancel()
				.then(() => {
					if (user.isOrganizationUser) {
						ApiService.sectionDeleteByOrgUser(section.id)
							.then(() => {
								AlertService.alert(
									SUCCESS_KEY,
									translationService.translate(TR_DELETE_MESSAGE_KEY),
								);
								this.props.getCourseById();
							})
							.catch(error => this.getFail(error));
					} else {
						ApiService.sectionDelete(section.id)
							.then(() => {
								AlertService.alert(
									SUCCESS_KEY,
									translationService.translate(TR_DELETE_MESSAGE_KEY),
								);
								this.props.getCourseById();
							})
							.catch(error => this.getFail(error));
					}
				})
				.catch(error => this.getFail(error));
		});
	};

	openSection = (id, firstTime) => {
		if (id === this.state.selectedSectionId) {
			if (firstTime) {
				return;
			}
			localStorage.removeItem("selectedSectionId");
			this.setState({ selectedSectionId: null }, () =>
				this.setCuriculumBreadCrumb(),
			);
		} else {
			localStorage.setItem("selectedSectionId", id);
			this.setState({ selectedSectionId: id }, () =>
				this.setCuriculumBreadCrumb(),
			);
		}
	};

	setCuriculumBreadCrumb = () => {
		const curiculumBreadCrumb = { ...this.props.curiculumBreadCrumb };
		curiculumBreadCrumb.selectedSectionId = this.state.selectedSectionId;
		this.props.setCuriculumBreadCrumb(curiculumBreadCrumb);
	};

	showAddSection = async () => {
		this.cancel().then(() => {
			this.setState({ showAddSection: true });
		});
	};

	updateSection = async section => {
		window.scrollTo(0, 0);
		this.cancel().then(() => {
			this.setState({ updateSection: section, showAddSection: true });
		});
	};

	drop(dropSectionId) {
		const { dragSectionId } = this.state;
		const { user } = this.props;
		const spinnerId = uuid();
		if (
			dropSectionId &&
			dragSectionId &&
			dropSectionId !== dragSectionId &&
			this.props.isEditMode
		) {
			this.props.addPartialViewSpinner(spinnerId);
			if (user.isOrganizationUser) {
				ApiService.setSectionOrderByOrgUser(dropSectionId, dragSectionId)
					.then(response => {
						const data = { ...response.data };
						this.changeTopicsData(data);
						this.props.removePartialViewSpinner(spinnerId);
					})
					.catch(error => this.getFail(error, spinnerId));
			} else {
				ApiService.setSectionOrder(dropSectionId, dragSectionId)
					.then(response => {
						const data = { ...response.data };
						this.changeTopicsData(data);
						this.props.removePartialViewSpinner(spinnerId);
					})
					.catch(error => this.getFail(error, spinnerId));
			}
		}
	}

	drag(sectionId) {
		sectionId && this.setState({ dragSectionId: sectionId });
	}

	getGamifyDataByEntity = (entityData, entityType, parentTypeId) => {
		const { isLoading } = this.state;
		if (!entityData || !entityType || isLoading) {
			return false;
		}
		this.setState({ isLoading: true, gamifyEntityTypeId: entityType });
		const data = {
			entityId: entityData.id.toString(),
			entityTypeId: entityType,
			parentId: entityData.courseId
				? +entityData.courseId
				: entityData.entityId
				? +entityData.entityId
				: null,
			parentTypeId,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					this.setState({
						gamifyData: response.data,
						selectedEntityData: entityData,
					});
				}
				this.setState({ isLoading: false });
			})
			.catch(error => this.getFail(error));
	};

	getGamifyDataByEntity = (entityData, entityType, parentTypeId) => {
		const { isLoading } = this.state;
		if (!entityData || !entityType || isLoading) {
			return false;
		}
		this.setState({ isLoading: true, gamifyEntityTypeId: entityType });
		const data = {
			entityId: entityData.id.toString(),
			entityTypeId: entityType,
			parentId: entityData.courseId
				? +entityData.courseId
				: entityData.entityId
				? +entityData.entityId
				: null,
			parentTypeId,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					this.setState({
						gamifyData: response.data,
						selectedEntityData: entityData,
					});
				}
				this.setState({ isLoading: false });
			})
			.catch(error => this.getFail(error));
	};

	closeGamifyMoadl = () => {
		this.setState({
			selectedEntityData: null,
			gamifyData: null,
			isLoading: false,
			gamifyEntityTypeId: null,
		});
	};

	checkCurrentSectionTopicsContent = section => {
		const { translationService } = this.state;
		if (!section || !translationService) {
			return;
		}
		var isEmptyTopic = section?.topics.find(
			topic => !topic.presentationFilePath && !topic.hasContent,
		);
		if (!isEmptyTopic && section.topics.length) {
			return (
				<span className="mindalay--some-process-status text-success">
					{translationService.translate("TR_THIS_SECTION_IS_ALREADY_READY")}
				</span>
			);
		} else {
			return (
				<small className="mindalay--some-process-status text-warning">
					{translationService.translate("TR_NOT_COMPLETE_SECTION_INFO")}
				</small>
			);
		}
	};

	cancel = () => {
		return new Promise((resolve, reject) => {
			this.setState(
				prevState => ({
					...prevState,
					updateSection: null,
					showAddSection: false,
					form: { ...prevState.form, name: "" },
				}),
				() => {
					resolve();
				},
			);
		});
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		this.setState({ isLoading: false, gamifyEntityTypeId: null });
	};

	changeTopicsData = data => {
		this.props.changeSectionsData(data);
	};

	render() {
		const {
			translationService,
			selectedSectionId,
			updateSection,
			showAddSection,
			courseId,
			isLoading,
			selectedEntityData,
			gamifyData,
			gamifyEntityTypeId,
		} = this.state;

		const {
			sections,
			isEditMode,
			getCourseById,
			isBelongToUser,
			language,
			user,
			showTopicForm,
		} = this.props;

		return translationService ? (
			<div className="row">
				<GamificationItemsComponent
					entityData={selectedEntityData}
					gamifyData={gamifyData}
					entityTypeId={gamifyEntityTypeId}
					cancel={this.closeGamifyMoadl}
				/>
				<div className="col-12">
					{isEditMode ? (
						<div className="toggle-content-container">
							<div className="d-sm-flex">
								<button
									type="button"
									className="mindalay--btn-default mindalay--btn-small toggle-content-btn mr-2 my-1"
									onClick={this.showAddSection}>
									<i className="fas fa-plus mr-2" />
									{translationService.translate("TR_ADD_SECTION")}
								</button>
								<Link
									to={`/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_FORM_KEY}`}
									className="mindalay--btn-default mindalay--btn-small mindalay--btn-link mr-2 my-1">
									<i className="fas fa-plus mr-2" />
									{translationService.translate("TR_CREATE_QUIZ_FOR_COURSE")}
								</Link>
								<Link
									to={`/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/assignment-form`}
									className="mindalay--btn-default mindalay--btn-small   mindalay--btn-link my-1">
									<i className="fas fa-plus mr-2" />
									{translationService.translate("TR_CREATE_ASSIGNMENT")}
								</Link>
							</div>
							{showAddSection ? (
								<SectionForm
									section={updateSection}
									cancel={this.cancel}
									getCourseById={getCourseById}
									sections={sections}
								/>
							) : null}
						</div>
					) : null}
					{sections && sections.length
						? sections.map((section, sectionIndex) => {
								if (
									this.props.sectionsViewCount &&
									sectionIndex >= this.props.sectionsViewCount
								) {
									return false;
								}
								return (
									<div
										key={section.id}
										className="accordion-wrapper"
										draggable={true}
										onDrop={() => this.drop(section.id)}
										onDragOver={event => event.preventDefault()}
										onDragStart={() => this.drag(section.id)}>
										<div className="card mindalay-card">
											{isEditMode ? (
												<div className="dropleft setting-dropdown">
													<div
														className="setting-dropdown-btn"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false">
														<SettingsSvg />
													</div>
													<div
														id={`demo-${sectionIndex}`}
														className="dropdown-menu collapse">
														{user && user.isGamifyAvailable ? (
															<Link
																to="#"
																className="dropdown-item"
																onClick={event => {
																	event && event.stopPropagation();
																	this.getGamifyDataByEntity(
																		section,
																		GAMIFY_SECTION_ENTITY_TYPE_ID,
																		GAMIFY_COURSE_ENTITY_TYPE_ID,
																	);
																}}>
																{isLoading ? (
																	<span className=" d-flex justify-content-between align-items-center">
																		{translationService.translate("TR_GAMIFY")}
																		<div
																			className="spinner-border text-success"
																			style={{
																				width: "15px",
																				height: "15px",
																				borderWidth: "1px",
																			}}
																		/>
																	</span>
																) : (
																	<span className=" d-flex justify-content-between align-items-center">
																		{translationService.translate("TR_GAMIFY")}
																		<GameSvg fill="#28a745" width="20px" />
																	</span>
																)}
															</Link>
														) : null}
														<Link
															to="#"
															className="dropdown-item"
															onClick={() => this.updateSection(section)}>
															{translationService.translate("TR_EDIT")}
														</Link>
														<Link
															to="#"
															className="dropdown-item"
															onClick={() => this.deleteSection(section)}>
															{translationService.translate("TR_DELETE")}
														</Link>
													</div>
												</div>
											) : null}
											<div
												className="card-header"
												aria-controls={`section_${section.id}`}
												aria-expanded={selectedSectionId === section.id}
												onClick={() => this.openSection(section.id)}>
												{user && user.isOrganizationUser
													? null
													: !this.props.history.location.pathname.includes(
															COURSES_KEY,
													  )
													? this.checkCurrentSectionTopicsContent(section)
													: null}
												<h5 className="text-elipsis">
													{/* <span>{sectionIndex + 1}.</span> */}
													{section.name}
												</h5>
											</div>
											<Collapse in={selectedSectionId === section.id}>
												<div
													id={`section_${section.id}`}
													className="light-blue-background mr-3 ml-3 content-block">
													<Topics
														topics={section.topics}
														sectionId={selectedSectionId}
														editMode={isEditMode}
														showTopicForm={showTopicForm}
														getCourseById={getCourseById}
														isBelongToUser={isBelongToUser}
														changeTopicsData={this.changeTopicsData}
														topicClassName={this.props.topicClassName}
													/>
												</div>
											</Collapse>
										</div>
									</div>
								);
						  })
						: null}
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	language: state.language.language,
	translations: state.translation.translations,
	curiculumBreadCrumb: state.main.curiculumBreadCrumb,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	setCuriculumBreadCrumb,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(Sections);

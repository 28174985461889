import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	CURATOR_USER_TYPE,
	ERROR_KEY,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import { WEBINAR_KEY, WEBINAR_CALENDAR_KEY } from "../../Constants/urlKeys";
import ReactPaginate from "react-paginate";
import HelpService from "../../Services/helpService";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const Webinars = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const currentQuestionMarkData = useSelector(
		state => state.main.currentQuestionMarkData,
	);
	const { user } = useSelector(state => state.user);
	const language = useSelector(state => state.language.language);
	const [translationService, setTranslationService] = useState(null);
	const [webinars, setWebinars] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getWebinars(1);
	}, []);

	const getWebinars = currentPage => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getWebinarsByOrgUser(currentPage, pageSize, user)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				response.data && setWebinars(response.data);
				dispatch(removePartialViewSpinner(spinnerId));
				window.scrollTo({ top: 0, behavior: "smooth" });
			})
			.catch(error => getFail(error, spinnerId));
	};

	const handlePageClick = event => {
		getWebinars(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		dispatch(removePartialViewSpinner(spinnerId));
	};

	const getQuestionMarkData = key => {
		if (!key) {
			return;
		}
		if (HelpService.setPageName(props.location.pathname)) {
			dispatch(
				getCurrentQuestionMarkData(
					HelpService.setPageName(props.location.pathname),
					key,
				),
			);
		}
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_WEBINARS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_WEBINARS")}
						translationService={translationService}
						pageName={
							user.userTypeId === STUDENT_USER_TYPE
								? "student_webinar"
								: user.userTypeId === LECTURER_USER_TYPE
								? "lecturer_webinar"
								: ""
						}
					/>
				</div>
			</div>
			<hr />
			<div className="row">
				{webinars && webinars.length ? (
					webinars.map(webinar => {
						return (
							<div
								key={webinar.id}
								className="col-lg-3 col-md-4 col-6 my-2"
								onClick={() =>
									props.history.push(
										`/${language}/webinar/webinar-calendar/${webinar.id}`,
									)
								}>
								<div className="card user-webinar-wrapper">
									<div className="card-img-top-wrapper">
										<img
											className="card-img-top"
											src={webinar.avatarFilePath}
											alt="Card image cap"
										/>
									</div>
									<div className="card-body">
										<h5 className="card-title">{webinar.name}</h5>
										<p className="card-text">
											<small className="text-muted secondary-color">
												{webinar.subject}
											</small>
										</p>
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p className="col-12">
								{translationService.translate("TR_YOU_HAVE_NO_WEBINARS")}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}
			</div>
			<div className="row mt-3">
				<div className="col-12">
					{pagination && webinars && webinars.length ? (
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};

export default withRouter(Webinars);

import { RETURN_URL_KEY } from "../Constants/mainKeys"

class ReurnUrl {

  static setUrl = (url) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem(RETURN_URL_KEY, url);
      resolve();
    })
  }

  static removeUrl = () => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem(RETURN_URL_KEY);
      resolve();
    })
  }

}

export default ReurnUrl;
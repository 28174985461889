import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'react-uuid';
import { ERROR_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import MainService from '../../Services/mainService';
import TranslationService from '../../Services/translationService';
import { addPageSpinner, addButtonSpinner, removeButtonSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import feedbackSvg from "../../assets/icons/notification-icons/feedback-icon.svg";
import consultationSvg from "../../assets/icons/notification-icons/consultation.svg";
import courseSvg from "../../assets/icons/notification-icons/course.svg";
import complaintSvg from "../../assets/icons/notification-icons/complaint.svg";
import financesSvg from "../../assets/icons/notification-icons/finances.svg";
import upcomingWebinarkSvg from "../../assets/icons/notification-icons/upcoming-webinar.svg";
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import ActionButton from '../../Components/ActionButton/actionButton';

const buttonSpinnerId = uuid();

const Notifications = (props) => {
  const dispatch = useDispatch();
  const pageSize = 10;
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);

  const [notifications, setNotifications] = useState([]);
  const [translationService, setTranslationService] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getAllNotifications(pageNumber, pageSize);
  }, []);

  const getAllNotifications = (pageNumber, pageSize, isShowButtonSpinner = null) => {
    const spinnerId = uuid();
    if (isShowButtonSpinner) {
      dispatch(addButtonSpinner(buttonSpinnerId))
    } else dispatch(addPageSpinner(spinnerId))
    ApiService.getAllNotifications(pageNumber, pageSize).then(response => {
      if (response.data && response.pagination) {
        const data = [...response.data];
        if (!notifications.length) {
          setNotifications(data)
        } else {
          setNotifications([...notifications, ...response.data])
        }
        setPagination(JSON.parse(response.pagination))
      }
      dispatch(removePageSpinner(spinnerId));
      dispatch(removeButtonSpinner(buttonSpinnerId));
    }).catch(error => getFail(error, spinnerId, buttonSpinnerId));
  }

  const getNextPageNotifications = () => {
    const nextPageNumber = pageNumber + 1;
    getAllNotifications(nextPageNumber, pageSize, true);
    setPageNumber(nextPageNumber);
  }

  const getFail = (error, spinnerId, buttonSpinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && dispatch(removePageSpinner(spinnerId));
    buttonSpinnerId && dispatch(removeButtonSpinner(buttonSpinnerId));
  }

  const getCourseById = (notification) => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getCourseById(notification.objectId).then(response => {
      dispatch(removePageSpinner(spinnerId));
      if (response && response.data) {
        props.history.push(`/${language}/${notification.link}`);
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const redirectToNotificationLinkPage = (notification) => {
    if (!notification || !notification.link) { return; }
    if (notification.objectType === 0) {
      getCourseById(notification);
    } else {
      props.history.push(`/${language}/${notification.link}`);
    }
  }

  return translationService ?
    <section className="m-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <strong className="section-title">{translationService.translate("TR_NOTIFICATIONS")}</strong>
            <hr />
          </div>
          {
            notifications && notifications.length ?
              <Auxiliary>
                {
                  notifications && notifications.length ?
                    notifications.map(notification => <div
                      key={notification.id}
                      className={`col-12 my-2 ${notification.link ? "cursor-pointer" : ""}`}
                      onClick={() => redirectToNotificationLinkPage(notification)}
                    >
                      <div className="card mindalay--card">
                        <div className="card-body">
                          <div className="notification-title">
                            {(() => {
                              switch (notification.objectType) {
                                case 0: // COURSE 
                                  return <img src={courseSvg} alt="/" />;
                                case 5: // WEBINARCALENDAR 
                                  return <img src={upcomingWebinarkSvg} alt="/" />;
                                case 7: // CONSULTATION 
                                  return <img src={consultationSvg} alt="/" />;
                                case 8: // COMPLAINT  
                                  return <img src={complaintSvg} alt="/" />;
                                case 9: // STUDENTCOURSE 
                                  return <img src={courseSvg} alt="/" />;
                                case 10: // PAYMENT HISTORY
                                  return <img src={financesSvg} alt="/" />;
                                case 12: // REVIEW 
                                  return <img src={feedbackSvg} alt="/" />;
                                default:
                                  break;
                              }
                            })()}
                            <span className="ml-2">{notification.title}</span>
                          </div>
                          <p>
                            {notification.text}
                          </p>
                          <span className="consultation-date">{moment(MainService.convertUTCDateToLocalDate(new Date(notification.time))).startOf('minute').fromNow()}</span>
                        </div>
                      </div>
                    </div>)
                    : null
                }
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 mt-3">
                      {
                        pagination && pagination.HasNext ?
                          <ActionButton
                            type="button"
                            spinnerId={buttonSpinnerId}
                            className="mindalay--btn-dark w-100 mt-3"
                            name={translationService.translate("TR_SHOW_MORE")}
                            clicked={getNextPageNotifications}
                          />
                          : null
                      }
                    </div>
                  </div>
                </div>
              </Auxiliary>
              : <div className="no-data-container">
                <div className="no-data-wrapper">
                  <p className="col-12">{translationService.translate("TR_EMPTY_NOTIFICATION_MSG")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
          }
        </div>
      </div>
    </section>
    : null
}

export default withRouter(Notifications);

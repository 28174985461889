
class RolesService {

  roles = null;

  constructor(roles) {
    this.roles = [...roles];
  }

  checkRole = (field) => {
    if (!this.roles) { return false; }
    const isExist = this.roles.find(role => role === field)
    if (isExist) return true
    else return false
  }

}

export default RolesService;
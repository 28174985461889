import React, { Component } from 'react';
import TranslationService from '../../../Services/translationService';
import { connect } from 'react-redux';
import * as moment from "moment";
import Search from '../../../Components/Search/search';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import AlertService from '../../../Services/alertService';
import { ASC_DESC_FILTER_DATA, TR_NO, TR_YES } from "../../../Constants/mainKeys";
import ShowMoreText from "react-show-more-text";
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import MainService from '../../../Services/mainService';

class Proposal extends Component {
  ascDescFilterData = ASC_DESC_FILTER_DATA;

  state = {
    translationService: null,
    proposalContractsStudentAcceptedCount: 4,
    proposalContractsConsultantAcceptedCount: 4,
  }

  componentDidMount() {
    this.setTranslations();
  }

  componentDidUpdate() {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  declineContract = (contract) => {
    const { translationService } = this.state;
    AlertService.alertConfirm(
      `${translationService.translate("TR_DECLINE_CONFIRM_MESSAGE")} ${contract.consultation.name} ${translationService.translate("TR_CONSULTATION")} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.declineContract(contract.id);
    });
  }

  render() {
    const { translationService, proposalContractsConsultantAcceptedCount, proposalContractsStudentAcceptedCount } = this.state;
    const { proposalContracts, contracts, user, proposalContractsConsultantAccepted, proposalContractsStudentAccepted } = this.props;

    return (
      translationService && user ? <Auxiliary>
        <div className="row">
          <div className="col-12">
            <div className="my-3">
              <form onSubmit={event=> event.preventDefault()}>
                <div className="d-lg-flex align-items-center justify-content-between">
                  <div className="d-lg-flex align-items-center">
                    <Search
                      searchData={contracts}
                      searchFieldName="categoryName"
                      // emitSearchedData={data => this.props.filterContract(data, "proposalContracts", "proposalContractsFiltered")}
                      emitSearchedData={data => this.props.filterContract(data)}
                    />
                  </div>
                  <div className="mindalay--btn-link-wrapper--instructor my-2">
                    <p className="text-right">
                      {`${translationService.translate("TR_PROPOSAL")}: `}<strong>{proposalContracts ? proposalContracts.length : 0}</strong>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            <hr />
          </div>
        </div>
        {
          proposalContractsStudentAccepted && proposalContractsStudentAccepted.length ?
            <div className="row">
              <div className="col-12">
                <div className="content-sub-title p-0">
                  <h3 className="course-title">{translationService.translate("TR_INVITATIONS")}</h3>
                </div>
              </div>
              {
                proposalContractsStudentAccepted.map((contract, index) => {
                  return (proposalContractsStudentAcceptedCount > index) ? <div key={contract.id} className="col-lg-6 col-12 mt-3">
                    <div className="box-shadow-4 mindalay--consultation-container">
                      <div className="consultation-container-left-side overflow-hidden">
                        <div className="d-flex justify-content-between">
                          <div>
                            <strong className="consultation-header-name">{contract.consultation.name}</strong>
                            <small
                              className="d-block"
                            >
                              {`${translationService.translate("TR_CREATED")}: ${moment(contract.consultation.creationDate).format("ll (HH:mm)")}`}
                            </small>
                            {
                              //Student
                              contract.consultation.studentUserId === user.id ?
                                <div><small>{translationService.translate("TR_CONSULTANT")}</small><small className="secondary-color font-weight-bold">: {contract.consultantName}</small></div>
                                : null
                            }
                            {
                              //Consultant
                              contract.consultation.studentUserId !== user.id ?
                                <div><small>{translationService.translate("TR_STUDENT")}</small><small className="secondary-color font-weight-bold">: {contract.consultation.studentUserName}</small></div>
                                : null
                            }
                          </div>
                          <div className="card-price-wrapper text-right">
                            <strong>
                              {contract.approximateCost ? contract.approximateCost : 0}
                            </strong>
                            <div className="mb-4">
                              <small>{`${translationService.translate("TR_DURATION")} `}: </small>
                              <b className="blue-color">
                                {contract.consultation?.durationMax ? contract.consultation?.durationMax : 0}
                              </b>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {
                          contract.consultation.description || contract.coverLetter ?
                            <div>
                              {
                                contract.coverLetter ?
                                  <span>
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_COVER_LETTER")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.coverLetter}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                              {
                                contract.consultation.description ?
                                  <span className="flex-column d-block mt-3">
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_DESCRIPTION")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.consultation.description}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                            </div>
                            : null
                        }
                      </div>
                      {/* <hr/> */}
                      <div className="consultation-container-right-side">
                        <hr className={`${contract.consultation.description || contract.coverLetter ? " d-block" : "d-none"}`} />
                        <div className="d-flex flex-column align-items-end">
                          {
                            !this.props.isStudent && !contract.consultantAccepted && contract.studentAccepted ?
                              <div className="btn-group w-100">
                                <button
                                  type="button"
                                  className="mindalay--btn-dark mr-1"
                                  onClick={() => this.props.openOrCloseModal(contract)}
                                >
                                  {translationService.translate("TR_ACCEPT")}
                                </button>
                                <button type="button" className="mindalay--btn-secondary-outline ml-1" onClick={() => this.declineContract(contract)}>
                                  {translationService.translate("TR_DECLINE")}
                                </button>
                              </div>
                              : null
                          }
                          {
                            this.props.isStudent && contract.consultantAccepted && !contract.studentAccepted ?
                              <div className="btn-group w-100">
                                <button
                                  type="button"
                                  className="mindalay--btn-dark mr-1"
                                  onClick={() => this.props.acceptContract(contract)}
                                >
                                  {translationService.translate("TR_ACCEPT")}
                                </button>
                                <button type="button" className="mindalay--btn-secondary-outline ml-1" onClick={() => this.declineContract(contract)}>
                                  {translationService.translate("TR_DECLINE")}
                                </button>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div> : null
                })
              }
              {
                proposalContractsStudentAccepted && (proposalContractsStudentAcceptedCount < proposalContractsStudentAccepted.length)
                  ?
                  <div className="col-12">
                    <button
                      type="button"
                      className="mindalay--btn-default w-100 mt-4"
                      onClick={() => this.setState({ proposalContractsStudentAcceptedCount: proposalContractsStudentAcceptedCount + 4 })}
                    >
                      {translationService.translate("TR_SHOW_MORE")}
                    </button>
                  </div>
                  : null
              }
            </div>
            : null
        }
        {
          proposalContractsStudentAccepted && proposalContractsStudentAccepted.length
            && proposalContractsConsultantAccepted && proposalContractsConsultantAccepted.length
            ? <hr /> : null
        }
        {
          proposalContractsConsultantAccepted && proposalContractsConsultantAccepted.length ?
            <div className="row">
              <div className="col-12">
                <div className="content-sub-title p-0">
                  <h3 className="course-title">
                    {
                      !this.props.isStudent ?
                        translationService.translate("TR_NEW_APPLICATIONS") + " " + "(applied)"
                        : translationService.translate("TR_NEW_APPLICATIONS")
                    }
                  </h3>
                </div>
              </div>
              {
                proposalContractsConsultantAccepted.map((contract, index) => {
                  return (proposalContractsConsultantAcceptedCount > index) ? <div key={contract.id} className="col-lg-6 col-12 mt-3">
                    <div className="box-shadow-4 mindalay--consultation-container">
                      <div className="consultation-container-left-side overflow-hidden">
                        <div className="d-flex justify-content-between">
                          <div>
                            <strong className="consultation-header-name">{contract.consultation.name}</strong>
                            <small
                              className="d-block"
                            >
                              {/* {`${translationService.translate("TR_CREATED")}: ${moment(contract.consultation.creationDate).format("lll")}`} */}
                              {`${translationService.translate("TR_CREATED")}: ${moment(MainService.convertUTCDateToLocalDate(new Date(contract.consultation.creationDate))).format("ll (HH:mm)")}`}
                            </small>
                            {
                              //Student
                              contract.consultation.studentUserId === user.id ?
                                <div><small>{translationService.translate("TR_CONSULTANT")}</small><small className="secondary-color font-weight-bold">: {contract.consultantName}</small></div>
                                : null
                            }
                            {
                              //Consultant
                              contract.consultation.studentUserId !== user.id ?
                                <div><small>{translationService.translate("TR_STUDENT")}</small><small className="secondary-color font-weight-bold">: {contract.consultation.studentUserName}</small></div>
                                : null
                            }
                          </div>
                          <div className="card-price-wrapper text-right">
                            <strong>
                              {contract.approximateCost ? contract.approximateCost : 0}
                            </strong>
                            <div className="mb-4">
                              <small>{`${translationService.translate("TR_DURATION")} `}: </small>
                              <b className="blue-color">
                                {contract.consultation?.durationMax ? contract.consultation?.durationMax : 0}
                              </b>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {
                          contract.consultation.description || contract.coverLetter ?
                            <div>
                              {
                                contract.coverLetter ?
                                  <span>
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_COVER_LETTER")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.coverLetter}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                              {
                                contract.consultation.description ?
                                  <span className="flex-column d-block mt-3">
                                    <span className="d-block font-weight-bold my-2">{translationService.translate("TR_DESCRIPTION")}</span>
                                    <ShowMoreText
                                      lines={2}
                                      more={translationService.translate("TR_SHOW_MORE")}
                                      less={translationService.translate("TR_LESS")}
                                      className="content-css"
                                      anchorClass="my-anchor-css-class"
                                      expanded={false}
                                      truncatedEndingComponent={"... "}
                                    >
                                      <p>{contract.consultation.description}</p>
                                    </ShowMoreText>
                                  </span>
                                  : null
                              }
                              {/* <hr /> */}
                            </div>
                            : null
                        }
                      </div>
                      {/* <hr /> */}
                      <div className="consultation-container-right-side">
                        <hr className="d-lg-none" />
                        <div className="d-flex flex-column align-items-end">
                          {
                            !this.props.isStudent && !contract.consultantAccepted && contract.studentAccepted ?
                              <div className="btn-group w-100">
                                <button
                                  type="button"
                                  className="mindalay--btn-dark mr-1"
                                  onClick={() => this.props.openOrCloseModal(contract)}
                                >
                                  {translationService.translate("TR_ACCEPT")}
                                </button>
                                <button type="button" className="mindalay--btn-secondary-outline ml-1" onClick={() => this.declineContract(contract)}>
                                  {translationService.translate("TR_DECLINE")}
                                </button>
                              </div>
                              : null
                          }
                          {
                            this.props.isStudent && contract.consultantAccepted && !contract.studentAccepted ?
                              <div className="btn-group w-100">
                                <button
                                  type="button"
                                  className="mindalay--btn-dark mr-1"
                                  onClick={() => this.props.acceptContract(contract)}
                                >
                                  {translationService.translate("TR_ACCEPT")}
                                </button>
                                <button type="button" className="mindalay--btn-secondary-outline ml-1" onClick={() => this.declineContract(contract)}>
                                  {translationService.translate("TR_DECLINE")}
                                </button>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                  </div> : null
                })
              }
              {
                proposalContractsConsultantAccepted && (proposalContractsConsultantAcceptedCount < proposalContractsConsultantAccepted.length)
                  ?
                  <div className="col-12">
                    <button
                      type="button"
                      className="mindalay--btn-default w-100 mt-4"
                      onClick={() => this.setState({ proposalContractsConsultantAcceptedCount: proposalContractsConsultantAcceptedCount + 4 })}
                    >
                      {translationService.translate("TR_SHOW_MORE")}
                    </button>
                  </div>
                  : null
              }
            </div>
            : null
        }
        {
          (proposalContracts && !proposalContracts.length) || !proposalContracts ?
            <div className="no-data-container">
              <div className="no-data-wrapper">
                <p>{translationService.translate("TR_NO_PROPOSAL")}</p>
                <img src={NoDataImage} alt="/" />
              </div>
            </div>
            : null
        }
      </Auxiliary > : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  user: state.user.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Proposal);

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Dropdown from "../../../Components/Dropdown/dropdown";
import { setLanguage } from "../../../Store/Actions/language";
import {
	COURSE_CATEGORIES_TYPE_KEY,
	LANGUAGE_KEY,
} from "../../../Constants/mainKeys";
import footerLogo from "../../../assets/logo/footer-logo.png";
import { getCategoriesByType } from "./../../../Store/Actions/course";

class PureFooter extends Component {
	changeLanguage = isocode => {
		if (isocode) {
			localStorage.setItem(LANGUAGE_KEY, isocode);
			this.props.setLanguage(isocode);
			const newPath = this.props.location.pathname
				.toString()
				.replace(this.props.match.path.toString(), `/${isocode}`);
			this.props.history.push(newPath.toString());
			this.props.getCategoriesByType(COURSE_CATEGORIES_TYPE_KEY);
		}
	};

	render() {
		return (
			<footer>
				<div className="container-fluid">
					<div className="footer-bottom-body d-sm-flex">
						<div className="footer-logo-wrapper">
							{this.props?.settings?.footerLogoPath ? (
								<img src={this.props?.settings?.footerLogoPath} alt="/" />
							) : (
								<>
									<img src={footerLogo} alt="/" />
									<span className="footer-brand-name">Mindalay</span>
								</>
							)}
						</div>
						<div className="text-right">
							<div className="dropdown mb-3">
								<Dropdown
									items={this.props.languages}
									title={this.props.language.toUpperCase()}
									selected={item => this.changeLanguage(item)}>
									<i className="fas fa-language mr-1"></i>
								</Dropdown>
							</div>
							{this.props?.settings?.footerText && (
								<div className="my-2 footer-bottom-body ">
									<p className="footer-text">
										{this.props?.settings?.footerText}
									</p>
								</div>
							)}
							<p className="copyright">
								Copyright © <span>{new Date().getFullYear()}</span> Mindalay,
								Inc.
							</p>
						</div>
					</div>	
				</div>
			</footer>
		);
	}
}

const mapStateToProps = state => {
	return {
		languages: state.language.languages,
		language: state.language.language,
		translations: state.translation.translations,
		settings: state.settings.settings,
	};
};

const mapDispatchToProps = {
	setLanguage,
	getCategoriesByType,
};

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(PureFooter);

import React, { Component } from 'react';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import TranslationService from '../../../Services/translationService';
import { CONSULTATION_CHAT_TYPE_KEY, CONSULTATION_CONTRACT_BACK_KEY, ERROR_KEY, RATINGS, RETURN_URL_KEY, SUCCESS_KEY } from "../../../Constants/mainKeys";
import Messages from '../Components/messages';
import FeedbackForStudent from '../Components/feedbackForStudent';
import ContractInfo from '../Components/contractInfo';
import ApiService from '../../../Services/apiService';
import AlertService from '../../../Services/alertService';
import uuid from 'react-uuid';
import { addPageSpinner, removePageSpinner } from "../../../Store/Actions/spinner";
import * as moment from "moment";
import MainService from '../../../Services/mainService';
import { Link, withRouter } from 'react-router-dom';
import ArrowBackSvg from '../../../Components/Svg/arrowBackSvg';
import { compose } from 'redux';
import { ALL_CONSULTATIONS_KEY, PROFILE_KEY } from '../../../Constants/urlKeys';

class ContractPageForStudent extends Component {
  ratings = RATINGS;
  consultationChatType = CONSULTATION_CHAT_TYPE_KEY;
  state = {
    contractId: +this.props.match.params.contractId || null,
    consultantUserId: null,
    studentUserId: null,
    key: this.props.location.pathname.includes("feedback") ? "feedback" : this.props.location.pathname.includes("messages") ? "messages" : "contractInfo",
    rating: 0,
    entitytype: 4,
    translationService: null,
    isInvalidSubmit: false,
    contract: {},
    messages: [],
    consultantName: '',
    consultantImagePath: '',
    feedBackData: null,
    defaultFeedBackData: null,
    complaintData: {},
    feedBackDataId: null,
    messageMaxLength: 2000,
  }

  componentDidMount() {
    const { contractId } = this.state;
    if (this.props.location.pathname.includes(RETURN_URL_KEY)) {
      this.props.history.push(this.props.location.pathname.slice(0, this.props.location.pathname.length - 10));
    }
    this.setTranslations();
    this.interval = setInterval(this.getChat, 10000);
    contractId && this.getContractById(contractId);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.match.params.contractId !== this.props.match.params.contractId) {
      this.setState({ contractId: +this.props.match.params.contractId }, () => {
        this.getContractById(this.state.contractId);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  getComplaintData = (consultationId, spinnerId) => {
    //canRequestRefund
    ApiService.getComplaintData(7, consultationId).then(response => {
      if (response.data) {
        const complaintData = { ...response.data };
        this.setState({ complaintData });
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  changeComplaintData = (data) => {
    this.setState({
      complaintData: data
    })
  }

  getChat = () => {
    const { contractId } = this.state;
    const spinnerId = uuid();
    ApiService.getConsultationChatFromStudent(this.consultationChatType, contractId).then(response => {
      if (response && response.data) {
        this.setState({ messages: response.data.chat });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  updateDateByStudent = (date) => {
    const { contractId, translationService } = this.state;
    if (date && contractId) {
      const spinnerId = uuid();
      var dateToUtc = new Date(date);
      this.props.addPageSpinner(spinnerId);
      ApiService.updateDateByStudent(contractId, dateToUtc).then(response => {
        if (response) {
          this.props.removePageSpinner(spinnerId);
          this.getContractById(contractId);
          const contract = { ...response.data };
          response.data && this.setState({ contract });
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
        }
      }).catch(error => this.getFail(error, spinnerId));
    } else {
      this.setState({ isInvalidSubmit: true });
    }
  }

  getContractById = (contractId) => {
    const spinnerId = uuid();
    contractId && ApiService.getContractByIdForStudent(contractId).then(response => {
      if (response) {
        this.props.removePageSpinner(spinnerId);
        if (response.data) {
          const contract = { ...response.data };
          this.setState({
            contract,
            consultantUserId: contract.consultantUserId,
            studentUserId: contract.consultation.studentUserId,
            consultantName: contract.consultantName,
            consultantImagePath: contract.consultantImagePath,
            consultantId: contract.consultantId
          }, () => {
            this.getFeedBackData(this.state.contract, spinnerId);
          });
          this.getChat(contract.consultantUserId);
        }
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  getFeedBackData = (contract, spinnerId) => {
    const { entitytype } = this.state;
    if (contract && contract.consultantId) {
      const entityid = contract.consultation.id;
      ApiService.getFeedBackDataForStudent(entitytype, entityid).then(response => {
        this.getComplaintData(contract.consultation.id, spinnerId);
        const data = { ...response.data };
        data.showMoreStudentDesc = false;
        data.showMoreConsultantDesc = false;
        response.data && this.setState({ feedBackData: data, defaultFeedBackData: data, feedBackDataId: data.id });
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  sendMessageFromStudent = (message) => {
    const { consultantUserId, studentUserId, contract } = this.state;
    const receiverId = [];
    const spinnerId = uuid();
    if (consultantUserId && studentUserId && message) {
      receiverId.push(consultantUserId);
      const data = {
        receiverId,
        senderId: studentUserId,
        message,
        entityId: contract.id,
        chatType: this.consultationChatType
      }
      ApiService.sendMessageFromStudent(data).then(response => {
        response && this.getChat();
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  onChangeShowMoreDesc = () => {
    var contract = { ...this.state.contract };
    contract.showMoreDesc = !contract.showMoreDesc;
    this.setState({ contract });
  }

  onSelect = (key) => {
    this.setState({ key });
  }

  signContract = (id) => {
    const { translationService } = this.state;
    const spinnerId = uuid();
    const offset = new Date().getTimezoneOffset() / 60;
    if (id && offset) {
      this.props.addPageSpinner(spinnerId);
      ApiService.signContract(id, offset).then(response => {
        if (response) {
          response.data && this.setState({ contract: { ...response.data } });
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CONSULTATION_SIGNED"));
          this.props.removePageSpinner(spinnerId);
        }
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  annulFeedbackData = () => {
    this.setState({ feedBackData: null })
  }

  cancelUpdateFeedback = () => {
    this.setState({ feedBackData: this.state.defaultFeedBackData })
  }

  goBack = () => {
    const { consulatationContractBackUrl, language, history } = this.props;
    if (consulatationContractBackUrl) {
      consulatationContractBackUrl && history.push(consulatationContractBackUrl);
      localStorage.removeItem(CONSULTATION_CONTRACT_BACK_KEY);
    } else {
      history.push(`/${language}/${ALL_CONSULTATIONS_KEY}`);
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {
    const {
      translationService,
      key,
      isInvalidSubmit,
      contract,
      messages,
      consultantName,
      consultantImagePath,
      feedBackData,
      complaintData,
      consultantUserId,
      feedBackDataId,
      messageMaxLength
    } = this.state;

    const { language } = this.props;

    return (
      translationService ? <section className="m-section">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex my-3">
              <h2 className="content-title p-0">
                <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}>
                  <ArrowBackSvg />
                </Link>
                {translationService.translate("TR_CONTRACTS")}
              </h2>
              <hr />
            </div>
            <div className="col-12">
              <Link to="#" className="box-shadow-4 mindalay--consultation-container flex-col-reverse d-flex cursor-default">
                <div className="header-right-side d-flex align-items-center">
                  <div className="consultation-user-image" style={{ backgroundImage: `url(${consultantImagePath})` }}></div>
                  <div className="consultation-user-info">
                    <h3 className="consultation-user-name gray-color cursor-pointer" onClick={() => this.props.history.push(`/${language}/${PROFILE_KEY}/${consultantUserId}`)}>{consultantName}</h3>
                    <small className="gmt-time">
                      {` ${translationService.translate("TR_CREATED")}: ${moment(MainService.convertUTCDateToLocalDate(new Date(contract.consultation?.creationDate))).format("ll (HH:mm)")}`}
                    </small>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <div className="box-shadow-4 mindalay--consultation-tab">
                <div className="row">
                  <div className="col-12">
                    <div className="tab-title-group p-0">
                      <Tabs
                        activeKey={key}
                        onSelect={(key) => this.onSelect(key)}
                      >
                        <Tab eventKey="contractInfo" title={translationService.translate("TR_CONTRACT_INFO")}>
                          <ContractInfo
                            isInvalidSubmit={isInvalidSubmit}
                            updateDate={this.updateDateByStudent}
                            onChangeShowMoreDesc={this.onChangeShowMoreDesc}
                            signContract={this.signContract}
                            complaintData={complaintData}
                            contract={contract}
                            isConsultant={false}
                          />
                        </Tab>
                        <Tab eventKey="messages" title={translationService.translate("TR_MESSAGES")}>
                          <Messages
                            sendMessage={this.sendMessageFromStudent}
                            messages={messages}
                            partnerName={consultantName}
                            keyName={this.state.key}
                            messageMaxLength={messageMaxLength}
                          />
                        </Tab>
                        {
                          contract && contract.isCompleted ?
                            <Tab eventKey='feedback' title={translationService.translate("TR_FEEDBACK")}>
                              <FeedbackForStudent
                                contract={contract}
                                feedBackData={feedBackData}
                                complaintData={complaintData}
                                feedBackDataId={feedBackDataId}
                                getContractById={this.getContractById}
                                annulFeedbackData={this.annulFeedbackData}
                                cancelUpdateFeedback={this.cancelUpdateFeedback}
                                changeComplaintData={this.changeComplaintData}
                              />
                            </Tab>
                            : null
                        }
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  language: state.language.language,
  consulatationContractBackUrl: state.main.consulatationContractBackUrl
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ContractPageForStudent);


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import uuid from 'react-uuid';
import { compose } from "redux";
import { ERROR_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import ApiService from '../../Services/apiService';
import TranslationService from '../../Services/translationService';
import { addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
import FeedBackModalComponent from '../../Components/ModalComponent/feedBackModalComponent';
import { addModalSpinner, removeModalSpinner } from './../../Store/Actions/spinner';
import { CONSULTANTS_KEY, COURSES_KEY, FEEDBACK_KEY } from './../../Constants/urlKeys';
import ModalComponent from '../../Components/ModalComponent/modalComponent';
import Textarea from '../../Components/Inputs/textArea';
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ReactPaginate from 'react-paginate';

class FeedBacks extends Component {

  state = {
    translationService: null,
    feedbacks: [],
    currentFeedback: null,
    showModal: false,
    decline: false,
    declineDescription: "",
    showDeclineDescriptionModal: false,
    isInvalidFeedbackSubmit: false,
    declineDescriptionMaxLength: 2000,
    activePageNumber: 0,
    pageSize: 10,
    pagination: null,
  }

  componentDidMount() {
    this.setTranslations();
    this.getFeedBacks(1);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ activePageNumber: 0 }, () => this.getFeedBacks(1));
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  handlePageClick = (event) => {
    this.getCourses(event.selected + 1);
    this.setState({ activePageNumber: event.selected });
  }

  getFeedBacks = (currentPage) => {
    if (this.props.location.pathname.includes(`/${FEEDBACK_KEY}/${COURSES_KEY}`)) {
      this.getCourseFeedbacks(currentPage);
    }
    if (this.props.location.pathname.includes(`/${FEEDBACK_KEY}/${CONSULTANTS_KEY}`)) {
      this.getConsultantFeedbacks(currentPage);
    }
  }

  getCourseFeedbacks = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getFeedbacksByEntitytype(0, currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        this.setState({ feedbacks: response.data, pagination: JSON.parse(response.pagination) });
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  getConsultantFeedbacks = (currentPage) => {
    const spinnerId = uuid();
    const { pageSize } = this.state;
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getFeedbacksByEntitytype(4, currentPage, pageSize).then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (response && response.data && response.pagination) {
        this.setState({ feedbacks: response.data, pagination: JSON.parse(response.pagination) });
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  showModal = (feedback) => {
    if (!feedback) { return false; }
    this.setState({
      currentFeedback: feedback,
      showModal: true
    })
  }

  closeModal = () => {
    this.setState({
      currentFeedback: null,
      showModal: false
    })
  }

  onAproveFeedback = (id) => {
    var { feedbacks, translationService } = this.state;
    const spinnerId = uuid();
    this.props.addModalSpinner(spinnerId);
    ApiService.approveFeedback(id).then((response) => {
      this.props.removeModalSpinner(spinnerId);
      if (response && response.data) {
        feedbacks = feedbacks.filter(feedback => feedback.id !== id);
        this.setState({ feedbacks });
        this.closeModal();
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  onDeclineFeedback = (id) => {
    var { declineDescription, feedbacks, translationService } = this.state;
    if (!declineDescription || !declineDescription.trim()) {
      this.setState({ isInvalidFeedbackSubmit: true })
    } else {
      const spinnerId = uuid();
      this.props.addModalSpinner(spinnerId);
      const data = { id, declineDescription }
      ApiService.declineFeedback(data).then((response) => {
        this.props.removeModalSpinner(spinnerId);
        if (response) {
          feedbacks = feedbacks.filter(feedback => feedback.id !== id);
          this.setState({
            showDeclineDescriptionModal: false,
            declineDescription: '',
            feedbacks
          })
          AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        }
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  showDeclineDescriptionModal = (bool) => {
    this.setState({ showDeclineDescriptionModal: bool, showModal: false })
  }

  onChange = (event, maxLength = null) => {
    const failedFields = this.removeFailedFields(event.target.name);
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({
      failedFields, [event.target.name]: event.target.value,
    })
  }

  removeFailedFields = name => {
    const failedFields = this.state.failedFields && { ...this.state.failedFields };
    const fieldName = name.replace(name[0], name[0].toUpperCase());
    failedFields && delete failedFields[fieldName];
    return failedFields;
  };

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
    spinnerId && this.props.removeModalSpinner(spinnerId);
  }

  render() {

    const {
      translationService,
      feedbacks,
      currentFeedback,
      showModal,
      showDeclineDescriptionModal,
      declineDescription,
      failedFields,
      isInvalidFeedbackSubmit,
      declineDescriptionMaxLength,
      pagination,
      activePageNumber
    } = this.state;

    const isFeedbackCoursesPage = this.props.location.pathname.includes(`/${FEEDBACK_KEY}/${COURSES_KEY}`) ? true : false;

    return (
      translationService ? <div className="container">
        <div className="row">
          <div className="col-12">
            {
              isFeedbackCoursesPage
                ? <h2 className="content-title p-0">{translationService.translate("TR_FEEDBACK_COURSES")}</h2>
                : <h2 className="content-title p-0">{translationService.translate("TR_FEEDBACK_CONSULTANTS")}</h2>
            }
            <hr />
          </div>
        </div>
        {
          currentFeedback && showModal && !showDeclineDescriptionModal ?
            <FeedBackModalComponent
              title={currentFeedback.title}
              actionButtonTitle={`${translationService.translate("TR_APPROVE")}`}
              isLarge={true}
              onAproveFeedback={() => this.onAproveFeedback(currentFeedback.id)}
              onDeclineFeedback={() => this.showDeclineDescriptionModal(true)}
              cancel={this.closeModal}
            >
              <div>
                <div className="d-flex align-items-center">
                  <p className="title">{`${translationService.translate("TR_RATING")}: `}</p>
                  <span className="ml-2">{currentFeedback.rating}</span>
                </div>
                <div className="cursor-default word-break-break-word">
                  {currentFeedback.reviewText}
                </div>
              </div>
            </FeedBackModalComponent>
            : null
        }
        {
          showDeclineDescriptionModal ?
            <ModalComponent
              isLarge={true}
              title={translationService.translate("TR_DECLINE_FEEDBACK")}
              actionButtonTitle={translationService.translate("TR_SEND")}
              onSubmit={() => this.onDeclineFeedback(currentFeedback.id)}
              cancel={() => this.setState({ showDeclineDescriptionModal: false, isInvalidFeedbackSubmit: false, declineDescription: '' })}
            >
              <form>
                <div className="row">
                  <div className="col-12">
                    <Textarea
                      rows="4"
                      id="declineDescription"
                      name="declineDescription"
                      textAreaClassName="pr--5"
                      value={declineDescription}
                      max={declineDescriptionMaxLength}
                      isInvalidSubmit={isInvalidFeedbackSubmit}
                      labelValue={translationService.translate('TR_DESCRIPTION')}
                      onChange={event => this.onChange(event, declineDescriptionMaxLength)}
                      failedFields={failedFields}
                    />
                  </div>
                </div>
              </form>
            </ModalComponent>
            : null
        }
        {
          feedbacks && feedbacks.length && pagination ?
            <div className="row">
              <div className="col-12">
                <div className="mindayal-admin-table table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{translationService.translate("TR_TITLE")}</th>
                        <th>{translationService.translate("TR_RATING")}</th>
                        <th>{translationService.translate("TR_REVIEW_TEXT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        feedbacks.map((feedback, index) => {
                          return <tr key={index} onClick={() => this.showModal(feedback)} >
                            <td><div className="mindalay-admin-table-td-item">{feedback.title}</div></td>
                            <td><div className="mindalay-admin-table-td-item">{feedback.rating}</div></td>
                            <td className='word-break-break-word'><div className="mindalay-admin-table-td-item">{feedback.reviewText}</div></td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <div className="no-data-container">
              <div className="no-data-wrapper">
                <p>{translationService.translate("TR_NO_DATA")}</p>
                <img src={NoDataImage} alt="/" />
              </div>
            </div>
        }
        {
          pagination ?
            <div className="row mt-4">
              <div className="col-12">
                <div>
                  <ReactPaginate
                    nextLabel={translationService.translate("TR_NEXT")}
                    onPageChange={this.handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pagination.TotalPages}
                    previousLabel={translationService.translate("TR_PREVIOUS")}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                    forcePage={activePageNumber}
                  />
                </div>
              </div>
            </div>
            : null
        }
      </div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  addModalSpinner,
  removeModalSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(FeedBacks);



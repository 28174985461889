import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import quizIcon from "../../../assets/icons/quiz.png";
import * as moment from "moment";
import MainService from '../../../Services/mainService';
import { TIME_TYPES_ARR_KEY } from '../../../Constants/mainKeys';
import { PURE_KEY, QUIZ_ATTEMPT_KEY, VIEW_KEY } from '../../../Constants/urlKeys';
import { setPureLayoutBackUrl } from '../../../Store/Actions/main';

const QuizView = props => {

  const { translationService, quizData } = props;
  const courseId = +props.match.params.courseId || null;
  const dispatch = useDispatch();

  const language = useSelector(state => state.language.language);

  const setBackUrl = useCallback((url) => {
    dispatch(setPureLayoutBackUrl(url));
  });

  return translationService && quizData && quizData.length ? quizData.map(quiz => {
    return <div key={quiz.id} className="col-lg-4 col-12 mt-3">
      <div className="course-pass-media-file-wrapper box-shadow-4 cursor-default h-100">
        <div className="d-flex align-items-center">
          <img src={quizIcon} alt="/" />
          <div className="ml-3 overflow-hidden">
            <h4 className="my-2">{quiz.name}</h4>
            <p title={quiz.description}>{quiz.description}</p>
          </div>
        </div>
        <hr />
        <div className="quiz-information">
          {
            quiz.timeLimitEnabled ?
              <fieldset>
                <legend>{translationService.translate("TR_TIME_LIMIT")}</legend>
                <p>{quiz.timeLimit} {translationService.translate(`${TIME_TYPES_ARR_KEY[quiz.timeLimitEntity]}`)}</p>
              </fieldset>
              : null
          }
          {quiz.openDate ? <fieldset>
            <legend>{translationService.translate("TR_START_DATE")}</legend>
            <p>{moment(MainService.convertUTCDateToLocalDate(new Date(quiz.openDate))).format("lll")}</p>
          </fieldset> : null}
          {quiz.closeDate ? <fieldset>
            <legend>{translationService.translate("TR_END_DATE")}</legend>
            <p>{moment(MainService.convertUTCDateToLocalDate(new Date(quiz.closeDate))).format("lll")}</p>
          </fieldset> : null}
          {quiz.questions ? <fieldset>
            <legend>{translationService.translate("TR_QUESTIONS_PAGE")}</legend>
            <p>{quiz.questions}</p>
          </fieldset> : null}
        </div>
        <div className="d-flex justify-content-center">
          {quiz.maxGrade ? <div className="course-grade">
            <span className="value">{quiz.maxGrade}</span>
            <span className="name">{translationService.translate("TR_MAX_GRADE")}</span>
          </div> : null}
          {quiz.passGrade ? <div className="course-grade">
            <span className="value">{quiz.passGrade}</span>
            <span className="name">{translationService.translate("TR_PASS_GRADE")}</span>
          </div> : null}
        </div>
        <div className="mt-3">
          <Link
            to={`/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${VIEW_KEY}/${courseId}/${quiz.id}`}
            className="mindalay--btn-default w-100 mindalay--btn-link"
            onClick={() => setBackUrl(props.location.pathname)}
          >
            {translationService.translate("TR_GO_TO_QUIZ")}
          </Link>
        </div>
      </div>
    </div>
  })
    : null
}

export default withRouter(QuizView);
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import NotFoundPage from "../Pages/404/notFoundPage";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import _Help from "../Pages/Help/_help";
import RolesService from "../Services/rolesService";
import { useSelector } from "react-redux";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import MainService from "../Services/mainService";
import UserTypes from "../DeveloperPages/userTypes";
import Permissions from "../DeveloperPages/permissions";
import Languages from "../DeveloperPages/languages";
import Paymentpackages from "../DeveloperPages/paymentpackages";
import PaymentpackageForm from "../DeveloperPages/paymentpackageForm";
import Roles from "../DeveloperPages/roles";
import Currency from "../DeveloperPages/currency";
import CurrencyForm from "../DeveloperPages/currencyForm";
import Configs from "../DeveloperPages/configs";
import ConfigForm from "../DeveloperPages/configForm";
import PageContents from "../DeveloperPages/pageContents";
import PageContentForm from "../DeveloperPages/pageContentForm";
import Categorys from "../DeveloperPages/categorys";
import Library from "../DeveloperPages/library";
import TranslationsPage from "../DeveloperPages/TranslationsPage";
import DocumentCategory from "../DeveloperPages/documentCategory";
import TemplatesInfo from "../DeveloperPages/templatesInfo";
import DocumentTemplate from "../DeveloperPages/documentTemplate";
import TemplatesInfoData from "../DeveloperPages/templatesInfoData";
import Documents from "../DeveloperPages/documents";

const DeveloperRoutes = () => {
	const { role } = useSelector(state => state.user);
	const [roleService, setRoleService] = useState(null);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	return roleService ? (
		<Switch>
			<Route
				path="/:language/developer/user-types"
				exact
				component={UserTypes}
			/>
			<Route
				path="/:language/developer/permissions"
				exact
				component={Permissions}
			/>
			<Route
				path="/:language/developer/languages"
				exact
				component={Languages}
			/>
			<Route
				path="/:language/developer/translations"
				exact
				component={TranslationsPage}
			/>
			<Route
				path="/:language/developer/document-category"
				exact
				component={DocumentCategory}
			/>
			<Route path="/:language/developer/document" exact component={Documents} />
			<Route
				path="/:language/developer/templates-info"
				exact
				component={TemplatesInfo}
			/>
			<Route
				path="/:language/developer/document-template"
				exact
				component={DocumentTemplate}
			/>
			<Route
				path="/:language/developer/templates-info-data"
				exact
				component={TemplatesInfoData}
			/>
			<Route
				path="/:language/developer/payment-packages"
				exact
				component={Paymentpackages}
			/>
			<Route
				path="/:language/developer/payment-package-form"
				exact
				component={PaymentpackageForm}
			/>
			<Route
				path="/:language/developer/payment-package-form/:paymentId"
				exact
				component={PaymentpackageForm}
			/>
			<Route path="/:language/developer/rols" exact component={Roles} />
			<Route path="/:language/developer/library" exact component={Library} />
			<Route path="/:language/developer/currency" exact component={Currency} />
			<Route
				path="/:language/developer/currency-form"
				exact
				component={CurrencyForm}
			/>
			<Route
				path="/:language/developer/currency-form/:currencyId"
				exact
				component={CurrencyForm}
			/>
			<Route path="/:language/developer/configs" exact component={Configs} />
			<Route
				path="/:language/developer/config-form"
				exact
				component={ConfigForm}
			/>
			<Route
				path="/:language/developer/config-form/:configId"
				exact
				component={ConfigForm}
			/>
			<Route
				path="/:language/developer/page-contents"
				exact
				component={PageContents}
			/>

			<Route
				path="/:language/developer/page-content-form"
				exact
				component={PageContentForm}
			/>
			<Route
				path="/:language/developer/page-content-form/:languageId/:pageContentId"
				exact
				component={PageContentForm}
			/>
			<Route path="/:language/developer/category" exact component={Categorys} />
			<Route path="/:language/help" exact component={_Help} />
			<Route path="/:language/help/topic/:topicId" exact component={_Help} />
			<Route path="/:language/help/faq/:faqId" exact component={_Help} />
			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>
			{/* 404 */}
			<Route path="/:language/not-found/404" exact component={NotFoundPage} />
			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>
			<Redirect
				to={`/${localStorage.getItem(LANGUAGE_KEY)}/developer/user-types`}
			/>
			{/* <Route component={NotFoundPage} /> */}
		</Switch>
	) : null;
};

export default DeveloperRoutes;

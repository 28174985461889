
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { COURSES_KEY, MINDALAY_ADMIN, VERIFIED_KEY } from '../../Constants/urlKeys';

const Home = (props) => {

  const language = useSelector(state => state.language.language);

  useEffect(() => {
    props.history.push(`/${language}/${MINDALAY_ADMIN}/${COURSES_KEY}/${VERIFIED_KEY}`)
  }, []);

  return (
    <div></div>
  )
}

export default withRouter(Home);


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import ProfileDropdown from "./SubComponents/profileDropdown";
import UserBurgerMenu from "./SubComponents/userBurgerMenu";
import { COURSES_KEY, COURSE_KEY } from "../../../Constants/urlKeys";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { PURE_LAYOUT_BACK_KEY } from "../../../Constants/mainKeys";

const PassCourseHeader = props => {
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [showMobileBurgerMenu, setShowMobileBurgerMenu] = useState(null);
	const { pureLayoutBackUrl } = useSelector(state => state.main);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const goBack = () => {
		// if (pureLayoutBackUrl) {
		// 	props.history.push(pureLayoutBackUrl);
		// 	localStorage.removeItem(PURE_LAYOUT_BACK_KEY);
		// 	return false;
		// }
		if (props.location.pathname.includes("assignment")) {
			window.history.back();
			return false;
		}
		const url = props.location.pathname.split("/");
		const courseId = url ? url[url.length - 2] : null;
		if (user && user.isOrganizationUser && courseId) {
			courseId && props.history.push(`/${language}/${COURSE_KEY}/${courseId}`);
		}
		if (user && !user.isOrganizationUser && courseId) {
			courseId && props.history.push(`/${language}/${COURSES_KEY}/${courseId}`);
		}
	};

	return translationService ? (
		<header className="header relative-layout">
			<nav className="navbar site-navbar-heigth navbar-expand-md navbar-shadow d-flex justify-content-end">
				<div className="mobil-navbar-wrapper d-md-none d-block w-100">
					<div className="d-flex justify-content-between">
						<div className="nav-item navbar-nav align-items-center">
							<h2 className="content-title p-0">
								<Link
									to="#"
									title={translationService.translate("TR_BACK")}
									onClick={goBack}>
									<ArrowBackSvg />
								</Link>
							</h2>
						</div>
						<ul className="navbar-nav">
							<li
								className="nav-item"
								onClick={() => {
									setShowMobileBurgerMenu(true);
								}}>
								{/* <Link to="#" className="burger-btn open-burger-menu nav-link pass-course-burger"><span></span></Link> */}
							</li>
						</ul>
					</div>
				</div>
				<div className="d-md-block d-none w-100">
					<div className="d-flex justify-content-between">
						<div className="nav-item navbar-nav align-items-center">
							<div className="content-title p-0">
								<Link
									to="#"
									title={translationService.translate("TR_BACK")}
									onClick={goBack}>
									<ArrowBackSvg />
								</Link>
							</div>
						</div>
						<div className="header-dropdown navbar-profile-container">
							{user && user.isOrganizationUser ? (
								<OrganizationUserDropdown />
							) : (
								<ProfileDropdown />
							)}
						</div>
					</div>
				</div>
				{user.isOrganizationUser ? null : (
					<UserBurgerMenu
						showMobileBurgerMenu={showMobileBurgerMenu}
						close={() => setShowMobileBurgerMenu(false)}
					/>
				)}
			</nav>
		</header>
	) : null;
};

export default withRouter(PassCourseHeader);

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const buttonSpinner = props => {
  return (
    props.spinner ? <div className={`center ml-2 spinner ${props.buttonClassName ? props.buttonClassName : ""}`}>
      <Spinner className='action-button-spinner' animation="border" variant="secondary" size="sm" color='#fff' />
    </div>
      : null
  );
}

export default buttonSpinner;
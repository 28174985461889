import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	STRING_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import Textarea from "../Components/Inputs/textArea";
import RichTextEditor from "../Pages/Questions/Components/RichTextEditor";

const placeHolderFields = [
	"input-string",
	"inputs-list",
	"input-short-string",
	"input-long-string",
	"sign-row-arr",
	"table-input-string",
	"table-string-and-date",
];

const libraryFields = ["library", "table-library"];

function DocumentTemplate(props) {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [documentTemplat, setDocumentTemplat] = useState([]);
	const [values, setValues] = useState({
		name: "",
		documentCategoryId: null,
		body: null,
		description: "",
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [documentCategory, setDocumentCategory] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDocumentTemplate();
	}, []);

	const getDocumentCategory = () => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getDocumentCategory()
			.then(response => {
				if (response && response.data) {
					setDocumentCategory(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removeModalSpinner(spinnerId));
			});
	};

	const getDocumentTemplate = (templateId = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDocumentTemplate()
			.then(response => {
				if (response && response.data) {
					setDocumentTemplat(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const onSelectOptionChange = (item, fieldName) => {
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
		setIsChange(true);
		setIsInvalidSubmit(false);
	};

	const onClose = (close = true) => {
		setValues({
			name: "",
			documentCategoryId: null,
			body: null,
			description: "",
		});
		setDocumentCategory([]);
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
	};

	const onEditorChange = event => {
		const newRichText = event.editor.getData();
		setIsInvalidSubmit(false);
		setIsChange(true);
		setValues(values => ({
			...values,
			body: newRichText,
		}));
	};

	const getModalData = item => {
		setOpenModal(true);
		getDocumentCategory();
		if (item) {
			setValues(item);
		} else {
			setValues(values => ({ ...values, body: "" }));
		}
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.documentCategoryId || !values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createDocumentTemplate(values)
			: ApiService.updateDocumentTemplate(values)
		)
			.then(response => {
				if (response.data) {
					setDocumentTemplat([...documentTemplat, response.data]);
				} else if (values.id) {
					setDocumentTemplat(
						documentTemplat.map(item => {
							if (item.id === values.id) {
								return {
									...item,
									...values,
								};
							}
							return item;
						}),
					);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					isLarge={true}
					onSubmit={() => {
						onSave();
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<label>
								{translationService.translate("TR_DOCUMENT_CATEGORY")}*
							</label>
							<ReactSelectOption
								value={values.documentCategoryId}
								isInvalidSubmit={isInvalidSubmit && !values.documentCategoryId}
								selectedValue={(() => {
									const selectedItem = {
										...documentCategory.find(
											data => data.id === values.documentCategoryId,
										),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label =
											selectedItem.displayString || selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={documentCategory
									.filter(item => item.id !== values.documentCategoryId)
									.map(data => ({
										...data,
										label: data.name,
										value: data.id,
									}))}
								onChange={item =>
									onSelectOptionChange(item, "documentCategoryId")
								}
							/>
						</div>
						<div className="col-12 mt-1">
							<Input
								type="text"
								id="name"
								name="name"
								value={values.name}
								isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue={`${translationService.translate("TR_NAME")}*`}
								onChange={event => onChange(event, "name")}
							/>
						</div>
						<div className="col-12 mt-1">
							<Textarea
								rows="3"
								id="description"
								name="description"
								value={values.description}
								labelValue={translationService.translate("TR_DESCRIPTION")}
								onChange={event => onChange(event, "description")}
							/>
						</div>
						<div className="col-12 mt-1">
							{typeof values.body === STRING_KEY ? (
								<>
									<label>
										{translationService.translate("TR_DESCRIPTION")}
									</label>
									<RichTextEditor
										value={values.body}
										onEditorChange={event => onEditorChange(event)}
									/>
								</>
							) : null}
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_TEMPLATES")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end justify-content-end">
				<div className="col-12 col-md-6"></div>
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default mt-3"
						onClick={() => {
							getModalData();
						}}>
						Create {translationService.translate("TR_TEMPLATES")}
					</button>
				</div>
			</div>
			{documentTemplat && documentTemplat.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_WL_DESCRIPTION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_CONTENT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{documentTemplat.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item ">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item ">
														{item.description}
													</div>
												</td>
												<td
													style={{
														minHeight: "400px",
														minWidth: 400,
													}}>
													{item.body}
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	getModalData(item);
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															{/* <Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deletedocumentTemplat(item);
																}}>
																<DeleteSvg />
															</Link> */}
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
}

export default withRouter(DocumentTemplate);

import React, { useEffect, useState } from 'react';
import SelectOption from './selectOption';

const DataListOption = props => {
  const failedFieldName = props.name && props.name.charAt(0).toUpperCase() + props.name.slice(1);

  const [items, setItems] = useState([]);
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (props.items && props.items.length) {
      var arr = [];
      props.items.forEach((element, index) => {
        arr.push({
          value: index + 1,
          text: index + 1
        });
      })
      setItems(arr)
    }
  }, [props.items]);

  const onReactSelectChange = (event) => {
    if (!event.target.value) { return; }
    props.changed(+event.target.value)
  }

  return <div className={`form-group ${props.containerClasses ? props.containerClasses : ""}`}>
    {
      props.label ? <label>{props.label}</label> : null
    }
    <SelectOption
      value={props.currentPage}
      items={items}
      changed={(item) => onReactSelectChange(item)}
    />
    {
      props.failedFields && props.failedFields.hasOwnProperty(failedFieldName)
        ? <small className="small-error-msg">{props.failedFields[failedFieldName]}</small>
        : null
    }
  </div>
}
export default DataListOption;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { compose } from 'redux';
import { CHOISE_NUMBERING_TYPES_ARR_KEY, ERROR_KEY, NUMBER_KEY, QUESTION_TYPES_ARR_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner"
import ApiService from './../../Services/apiService';
import { Link } from 'react-router-dom';
import ArrowBackSvg from '../../Components/Svg/arrowBackSvg';
import ModalComponentWithoutActionButton from '../../Components/ModalComponent/modalComponentWithoutActionButton';
import Auxiliary from '../../hoc/auxiliary/auxiliary';

class QuizView extends Component {
  questionTypes = QUESTION_TYPES_ARR_KEY;
  choiseNumbering = CHOISE_NUMBERING_TYPES_ARR_KEY;

  state = {
    translationService: null,
    quizId: parseInt(this.props.match.params.quizId) || null,
    quizData: null,
    currentQuestion: null,
    isShowQuestionModal: false,
  }

  componentDidMount() {
    this.setTranslations();
    this.getQuizData();
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  getQuizData = () => {
    const { quizId } = this.state;
    const spinnerId = uuid();
    if (!quizId) { return; }
    this.props.addPageSpinner(spinnerId);
    ApiService.getQuizById(quizId).then(response => {
      this.props.removePageSpinner(spinnerId);
      if (response && response.data) {
        this.setState({ quizData: response.data });
      }
    }).catch(error => this.getFail(error, spinnerId))
  }
  
  goBack = () => {
    window.history.back();
  }

  showOrHideQuestionModal = (question, bool) => {
    this.setState({
      currentQuestion: question,
      isShowQuestionModal: bool,
    })
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
  }

  render() {

    const { translationService, quizData, currentQuestion, isShowQuestionModal } = this.state;

    return translationService && quizData ?
      <div className="container admin-quiz-view">
        <div className="row">
          <div className="col-12 content-title">
            <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
            <h2 className="content-title p-0">{translationService.translate("TR_QUIZ")}</h2>
          </div>
        </div>
        <hr />
        {
          currentQuestion && isShowQuestionModal ?
            <ModalComponentWithoutActionButton
              title={currentQuestion.name}
              cancel={() => this.showOrHideQuestionModal(null, false)}
              isLarge={true}
            >
              <div className="quiz-question-modal-body">
                {
                  currentQuestion.text ?
                    <span className="d-block my-1"><b>{`${translationService.translate("TR_QUESTION")}: `}</b><span>{currentQuestion.text}</span></span>
                    : null
                }
                {
                  typeof currentQuestion.questionType === NUMBER_KEY ?
                    <span className="d-block my-1">
                      <b>{`${translationService.translate("TR_QUESTION_TYPE")}: `}</b>
                      {
                        this.questionTypes.map((questionType, index) => {
                          if (index === currentQuestion.questionType) {
                            return <span key={index}>{translationService.translate(questionType)}</span>
                          } else { return false; }
                        })
                      }
                    </span>
                    : null
                }
                {
                  currentQuestion.groupName ?
                    <span className="d-block my-1"><b>{`${translationService.translate("TR_QUESTION_GROUP")}: `}</b><span>{currentQuestion.groupName}</span></span>
                    : null
                }
                {
                  currentQuestion.mark || typeof currentQuestion.mark === NUMBER_KEY ?
                    <span className="d-block my-1"><b>{`${translationService.translate("TR_MARK")}: `}</b><span>{currentQuestion.mark}</span></span>
                    : null
                }
                {
                  currentQuestion.penaltyForIncorrectAnswer || typeof currentQuestion.penaltyForIncorrectAnswer === NUMBER_KEY ?
                    <span className="d-block my-1"><b>{`${translationService.translate("TR_PENALTY_PERCENT")}: `}</b><span>{currentQuestion.penaltyForIncorrectAnswer}</span></span>
                    : null
                }
                <span className="d-block my-1">
                  <b>{`${translationService.translate("TR_SHUFFLE_WITHIN_QUESTIONS")}: `}</b>
                  <span>
                    {currentQuestion.isShuffle ? translationService.translate("TR_YES")
                      : translationService.translate("TR_NO")}
                  </span>
                </span>
                {
                  typeof currentQuestion.choiceNumbering === NUMBER_KEY ?
                    <span className="d-block my-1">
                      <b>{`${translationService.translate("TR_CHOICE_NUMBERING")}: `}</b>
                      <span>
                        {
                          this.choiseNumbering.map((quiestionType, index) => {
                            if (index === currentQuestion.choiceNumbering && index === 0) {
                              return <span key={index}>{quiestionType}</span>
                            } else if (index === currentQuestion.choiceNumbering && index !== 0) {
                              return <span key={index}>{translationService.translate(quiestionType)}</span>
                            } else { return false; }
                          })
                        }
                      </span>
                    </span>
                    : null
                }
                {
                  currentQuestion.questionAnswers && currentQuestion.questionAnswers.length ?
                    <Auxiliary>
                      <h5 className="my-4">{translationService.translate("TR_ANSWERS")}</h5>
                      {
                        currentQuestion.questionAnswers.map((answer, index) => {
                          return <div key={index} className="index">
                            <div className="d-flex">
                              {/* <b className="mt-1 mr-2">{`${index + 1}.`}</b> */}
                              <div className="w-100 ">
                                {
                                  answer.markPercent || typeof answer.markPercent === NUMBER_KEY ?
                                    <span className="d-block my-1"><b>{`${translationService.translate("TR_GRADE_PERCENT")}: `}</b><span>{answer.markPercent}</span></span>
                                    : null
                                }
                                <span className="d-block my-1"><b>{`${translationService.translate("TR_FEEDBACK")}: `}</b>
                                  <span>
                                    {
                                      answer.feedback ? answer.feedback : translationService.translate("TR_NONE")
                                    }
                                  </span>
                                </span>
                              </div>
                            </div>
                            <hr className="w-100" />
                          </div>
                        })
                      }
                    </Auxiliary>
                    : null
                }
                {
                  currentQuestion.questionHints && currentQuestion.questionHints.length ?
                    <Auxiliary>
                      <h5 className="my-4">{translationService.translate("TR_HINTS")}</h5>
                      {
                        currentQuestion.questionHints.map((hint, index) => {
                          return <div key={index} className="d-flex">
                            {/* <b className="mt-1 mr-2">{`${index + 1}.`}</b> */}
                            <div className="w-100 ">
                              <span className="d-block my-1"><span>{hint.text}</span></span>
                            </div>
                          </div>
                        })
                      }
                    </Auxiliary>
                    : null
                }
              </div>
            </ModalComponentWithoutActionButton>
            : null
        }
        <div className="ml-2 my-4">
          {
            quizData.name ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_NAME")}: `}</b><span>{quizData.name}</span></span>
              : null
          }
          {
            quizData.maxGrade ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_MAX_GRADE")}: `}</b><span>{quizData.maxGrade}</span></span>
              : null
          }
          {
            quizData.passGrade ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_GRADE_TO_PASS")}: `}</b><span>{quizData.passGrade}</span></span>
              : null
          }
          {
            quizData.timeLimit ?
              <span className="d-block my-1"><b>{`${translationService.translate("TR_TIME_LIMIT")}: `}</b><span>{quizData.timeLimit}</span></span>
              : null
          }
        </div>
        {
          quizData.questions && quizData.questions.length ?
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{translationService.translate("TR_ENTITY_QUESTION_GROUP")}</th>
                  <th scope="col">{translationService.translate("TR_NAME")}</th>
                  <th scope="col">{translationService.translate("TR_QUESTION")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  quizData.questions.map(question => {
                    return <tr
                      key={question.id}
                      className="cursor-pointer question-block"
                      onClick={() => this.showOrHideQuestionModal(question, true)}
                    >
                      <td title={question.groupName}>
                        <p>
                          {question.groupName}
                        </p>
                      </td>
                      <td title={question.name}>
                        <p>
                          {question.name}
                        </p>
                      </td>
                      <td title={question.text}>
                        <p>
                          {question.text}</p>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
            : null
        }
      </div >
      : null;
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(QuizView);

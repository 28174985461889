import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "../Services/translationService";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";

import uuid from "react-uuid";
import AlertService from "../Services/alertService";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../Components/Svg/_infoSvg";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import ReactSelectOption from "../Components/SelectOption/reactSelectOption";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import InputCheckBox from "../Components/Inputs/inputCheckBox";
import { event } from "jquery";

const Permissions = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [userTypes, setUserTypes] = useState([]);
	const [userTypeId, setUserTypeId] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [organizationId, setOrganizationsId] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [pages, setPages] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getUsertypes(null);
		getOrganizations();
	}, []);

	const handlePageClick = event => {
		getUsertypes(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getOrganizations = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizations()
			.then(response => {
				if (response && response.data.length) {
					const organizations = [...response.data];
					setOrganizations(organizations);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getPageusertype = usertypeId => {
		if (!userTypeId) return null;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPageusertype(usertypeId)
			.then(response => {
				if (
					response &&
					response.data &&
					response.data.pages &&
					response.data.pages.length
				) {
					setPages(response.data.pages);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getUsertypes = orgId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getUsertypes(orgId)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setUserTypes(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteType = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")}`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteUsertype(item.id)
				.then(() => {
					setUserTypes(userTypes.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const onSelectOptionChange = item => {
		setOrganizationsId(item.id);
		getUsertypes(item.id);
	};
	const onCheckboxChange = (data, index, childrenIndex = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.postPageusertype(data)
			.then(() => {
				getPageusertype(userTypeId);
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_USER_TYPES_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-end">
				{/* <div className="col-12 col-md-6 mb-2">
					<Link
						to={`/${language}/division-form`}
						className="mindalay--btn-default">
						{translationService.translate("TR_CREATEUSERTYPE")
							? translationService.translate("TR_CREATEUSERTYPE")
							: "Create user type"}
					</Link>
				</div> */}
				<div className="col-12 col-md-6 mb-2">
					{organizations && organizations.length ? (
						<div>
							<ReactSelectOption
								value={organizationId}
								selectedValue={(() => {
									const selectedItem = {
										...[
											{
												name: translationService.translate("TR_ALL"),
												id: null,
											},
											...organizations,
										].find(data => data.id === organizationId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={[
									{
										name: translationService.translate("TR_ALL"),
										id: null,
									},
									...organizations,
								]
									.filter(item => item.id !== organizationId)
									.map(data => ({
										...data,
										label: data.name,
										value: data.id,
									}))}
								onChange={item => onSelectOptionChange(item, "parentId")}
							/>
						</div>
					) : null}
				</div>
			</div>

			{userTypes && userTypes.length ? (
				<div className="row mt-5">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPES_PAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ORGANIZATION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_DEFAULT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{userTypes.map((item, index) => {
										return (
											<tr
												key={index}
												style={
													item.id === userTypeId
														? {
																backgroundColor:
																	"var(--mindalay--gray-color-lightened-80)",
														  }
														: {}
												}
												onClick={() => {
													setUserTypeId(item.id);
													getPageusertype(item.id);
												}}>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.name}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.organizationName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.isDefault
															? translationService.translate("TR_YES")
															: translationService.translate("TR_NO")}
													</div>
												</td>
												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														{item.isDefault ? (
															""
														) : (
															<>
																{/* <Link
																	to={"/"}
																	title={translationService.translate(
																		"TR_EDIT",
																	)}
																	className="table-action-btn edit-btn">
																	<EditSvg />
																</Link> */}
																<Link
																	to="#"
																	title={translationService.translate(
																		"TR_DELETE",
																	)}
																	className="table-action-btn delete-btn"
																	onClick={() => {
																		deleteType(item);
																	}}>
																	<DeleteSvg />
																</Link>
															</>
														)}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pages && pages.length ? (
				<div className="row mt-5">
					<div className="col-12 col-md-6">
						<div className="card mindalay-card">
							<div className="card-header  d-flex justify-content-center align-items-center">
								<h6 className="mb-0 text-elipsis">
									{translationService.translate("TR_PAGES_PAGE")}
								</h6>
							</div>
							{pages.map((item, index) => (
								<div>
									<div key={index} className="py-2 px-3">
										<div className="d-flex justify-content-between">
											<label htmlFor={`pages${item.id}`}>
												{translationService.translate(item.name)}
											</label>
											<InputCheckBox
												id={`pages${item.id}`}
												name="recurringPayment"
												checked={item.isBelongToCurrentUserType}
												onChange={event => {
													const data = {
														create: event.target.checked,
														pageId: item.id,
														usertypeId: userTypeId,
													};
													onCheckboxChange(data, index);
												}}
												labelValue={" "}
											/>
										</div>
									</div>
									{item.children && item.children.length ? (
										<div className="p-3 m-3 border rounded shadow-lg">
											{item.children.map((el, i) => (
												<div key={uuid()} className="py-2 px-3">
													<div className="d-flex justify-content-between">
														<label htmlFor={`pages${el.id}`}>
															{translationService.translate(el.name)}
														</label>
														<InputCheckBox
															id={`pages${el.id}`}
															name="recurringPayment"
															checked={el.isBelongToCurrentUserType}
															onChange={event => {
																const data = {
																	create: event.target.checked,
																	pageId: el.id,
																	usertypeId: userTypeId,
																};
																onCheckboxChange(data, index, i);
															}}
															labelValue={" "}
														/>
													</div>
												</div>
											))}
										</div>
									) : null}
									<hr />
								</div>
							))}
						</div>
					</div>
				</div>
			) : null}
			{pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(Permissions);

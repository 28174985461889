import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from "react-redux";
import AlertService from '../../Services/alertService';
import { ERROR_KEY } from '../../Constants/mainKeys';
import TranslationService from '../../Services/translationService';
import { Tabs } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import FileLibraryImages from './FileLibraryComponents/fileLibraryImages';
import FileLibraryFiles from './FileLibraryComponents/fileLibraryFiles';
import FileLibraryVideos from './FileLibraryComponents/fileLibraryVideos';
import { addPartialViewSpinner, removePartialViewSpinner } from '../../Store/Actions/spinner';
import ApiService from '../../Services/apiService';
import FileLibraryAudio from './FileLibraryComponents/fileLibraryAudio';
import NoDataImage from './../../assets/images/illustrations/nodata.svg';
import FileLibraryDocuments from './FileLibraryComponents/fileLibraryDocuments';
import _InfoSvg from './../../Components/Svg/_infoSvg';
import PageInfoBlock from '../../Components/PageInfoBlock/PageInfoBlock';

const Filelibrary = () => {

  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [key, setKey] = useState("file-library-images");
  const [images, setImages] = useState([]);
  const [textFiles, setTextFiles] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audio, setAudio] = useState([]);
  const [documents, setDocuments] = useState([]);


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getFileLibrary();
  }, []);

  const getFileLibrary = () => {
    const spinnerId = uuid();
    dispatch(addPartialViewSpinner(spinnerId));
    ApiService.getFileLibrary().then(response => {
      if (response.data) {
        const data = { ...response.data };
        data.images && data.images.length && setImages(data.images);
        data.textFiles && data.textFiles.length && setTextFiles(data.textFiles);
        data.videos && data.videos.length && setVideos(data.videos);
        data.audio && data.audio.length && setAudio(data.audio);
        data.documents && data.documents.length && setDocuments(data.documents);
        dispatch(removePartialViewSpinner(spinnerId));
        if (data.images && data.images.length) {
          setKey("file-library-images");
          return false;
        }
        if (data.textFiles && data.textFiles.length) {
          setKey("file-library-files");
          return false;
        }
        if (data.videos && data.videos.length) {
          setKey("file-library-videos");
          return false;
        }
        if (data.audio && data.audio.length) {
          setKey("file-library-audios");
          return false;
        }
        if (data.documents && data.documents.length) {
          setKey("file-library-document");
          return false;
        }
      }

    }).catch(error => getFail(error, spinnerId));
  }

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && dispatch(removePartialViewSpinner(spinnerId));
  }

  return translationService ?
    <div className="container">
      <div className="row mt-4">
        <div className="col-12">
          <h2 className="section-title">{translationService.translate("TR_FILE_LIBRARY")}</h2>
          <PageInfoBlock
            pageTitle={translationService.translate("TR_FILE_LIBRARY")}
            pageName="student_file_library"
            translationService={translationService}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          {
            images && images.length ||
              textFiles && textFiles.length ||
              videos && videos.length ||
              audio && audio.length ||
              documents && documents.length ?
              <div className="tabs-wrapper tab-title-group">
                <Tabs
                  activeKey={key}
                  onSelect={(key) => setKey(key)}
                >
                  {
                    images && images.length ?
                      <Tab eventKey="file-library-images" title={translationService.translate("TR_IMAGES")}>
                        <FileLibraryImages
                          images={images}
                        />
                      </Tab>
                      : null
                  }
                  {
                    textFiles && textFiles.length ?
                      <Tab eventKey="file-library-files" title={translationService.translate("TR_FILES")}>
                        <FileLibraryFiles
                          textFiles={textFiles}
                        />
                      </Tab>
                      : null
                  }
                  {
                    videos && videos.length ?
                      <Tab eventKey="file-library-videos" title={translationService.translate("TR_VIDEOS")}>
                        <FileLibraryVideos
                          videos={videos}
                        />
                      </Tab>
                      : null
                  }
                  {
                    audio && audio.length ?
                      <Tab eventKey="file-library-audios" title={translationService.translate("TR_AUDIO")}>
                        <FileLibraryAudio
                          audio={audio}
                        />
                      </Tab>
                      : null
                  }
                  {
                    documents && documents.length ?
                      <Tab eventKey="file-library-document" title={translationService.translate("TR_DOCUMENTS")}>
                        <FileLibraryDocuments
                          documents={documents}
                        />
                      </Tab>
                      : null
                  }
                  {/* <Tab eventKey="file-library-webinar-recording" title={translationService.translate("TR_WEBINARS_RECORDING")}>
            <FileLibraryWebinarsRecordings />
          </Tab> */}
                </Tabs>
              </div>
              : <div className="no-data-container mt-3">
                <div className="no-data-wrapper">
                  <p>{translationService.translate("TR_YOU_HAVE_NO_FILES")}</p>
                  <img src={NoDataImage} alt="/" />
                </div>
              </div>
          }
        </div>
      </div>
    </div>
    : null
}

export default Filelibrary;


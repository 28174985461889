import React from 'react';

const Categorysvg = () => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 64 64" xmlSpace="preserve">
      <g>
        <path d="M13.9,28.2c-2.8,0-5.5,0.1-8.3,0c-3.6-0.1-5.5-2-5.5-5.6C0,16.9,0,11.2,0.1,5.6c0.1-3.6,2-5.5,5.6-5.5
		C11.3,0,17,0,22.6,0.1c3.6,0.1,5.5,2,5.5,5.6c0.1,5.6,0.1,11.3,0,16.9c-0.1,3.6-2,5.4-5.6,5.5C19.7,28.2,16.8,28.2,13.9,28.2z"/>
        <path d="M50,0c2.8,0,5.5-0.1,8.3,0c3.6,0.1,5.6,2,5.6,5.5c0.1,5.6,0.1,11.3,0,16.9c0,3.6-2,5.6-5.5,5.6c-5.6,0.1-11.3,0.1-16.9,0
		c-3.6,0-5.6-2-5.6-5.5c-0.1-5.6-0.1-11.3,0-16.9c0-3.7,2-5.6,5.8-5.6C44.5,0,47.2,0,50,0z"/>
        <path d="M28.2,50.2c0,2.8,0.1,5.5,0,8.3c-0.1,3.4-2,5.4-5.4,5.4C17,64,11.2,64,5.5,63.9c-3.4-0.1-5.4-2-5.4-5.4
		C0,52.8,0,47,0.1,41.3c0.1-3.4,2-5.4,5.4-5.4c5.8-0.1,11.5-0.1,17.3,0c3.4,0.1,5.3,2,5.4,5.4C28.2,44.2,28.2,47.2,28.2,50.2z"/>
        <path d="M50,35.8c7.7,0,14,6.4,14,14c0,7.7-6.3,14-14,14.1c-7.8,0-14.2-6.4-14.1-14.2C35.9,42.1,42.2,35.8,50,35.8z" />
      </g>
    </svg>

  );
}

export default Categorysvg;

import { SET_FAQ_PAGE_CONTENT, SET_TOPIC_PAGE_CONTENT, SET_PAGE_CONTENT, SET_IS_SHOW_HELP_LEFT_MENU } from "../../Constants/reduxKeys";

export function setIsShowLelpLeftMenu(bool) {
  return {
    type: SET_IS_SHOW_HELP_LEFT_MENU,
    payload: bool,
  };
};

export function setFaqPageContent(data) {
  return {
    type: SET_FAQ_PAGE_CONTENT,
    payload: data,
  };
};

export function setTopicPageContent(data) {
  return {
    type: SET_TOPIC_PAGE_CONTENT,
    payload: data,
  };
};

export function setPageContent(data) {
  return {
    type: SET_PAGE_CONTENT,
    payload: data,
  };
};

import React from 'react';
import Loader from 'react-loader-spinner';

const topicSpinner = props => {
  return (
    props.spinners && props.spinners.length
      ? <div className="topic-spinner-wrapper">
        {
          props.spinners.map(spinner => <div key={spinner} className="center page-loader">
            <Loader type="ThreeDots" color="#32064A" height={80} width={80} />
          </div>)
        }
      </div>
      : null
  );
}

export default topicSpinner;
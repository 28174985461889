import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from "../AdminPages/Home/home";
import { LANGUAGE_KEY } from "../Constants/mainKeys";
import { HOME_KEY, MINDALAY_ADMIN } from "../Constants/urlKeys";
import Courses from "../AdminPages/Courses/courses";
import CurrentCourse from "../AdminPages/Courses/currentCourse";
import QuizView from "../AdminPages/Quiz/quizView";
import CurrentTicket from "../AdminPages/Tickets/currentTicket";
import Feedbacks from "../AdminPages/Feedbacks/feedbacks";
import Tickets from "../AdminPages/Tickets/tickets";
import KeyWords from "../AdminPages/KeyWords/keyWords";
import Theme from "../AdminPages/Categories/themes";
import Category from "../AdminPages/Categories/categories";
import ThemeForm from "../AdminPages/Categories/themeForm";
import NotFoundPage from "../Pages/404/notFoundPage";
import Logs from "../AdminPages/Logs/logs";
import PaymentLogs from "../AdminPages/Logs/paymentLogs";
import PaymentReceipt from "../AdminPages/PaymentPackage/paymentReceipt";
import PaymentHistory from "../AdminPages/PaymentPackage/paymentHistory";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";

const AdminRoutes = () => {
  return <Switch>

    {/* Admin Home Page */}
    <Route path="/:language/mindalay-admin/home" exact component={Home} />

    {/* Courses */}
    <Route path="/:language/mindalay-admin/course/:courseId" exact component={CurrentCourse} />
    <Route path="/:language/mindalay-admin/courses/verified" exact component={Courses} />
    <Route path="/:language/mindalay-admin/courses/unverified" exact component={Courses} />
    <Route path="/:language/mindalay-admin/courses/discount" exact component={Courses} />

    {/* FeedBack */}
    <Route path="/:language/mindalay-admin/feedback/courses" exact component={Feedbacks} />
    <Route path="/:language/mindalay-admin/feedback/consultants" exact component={Feedbacks} />

    {/* Tickets */}
    <Route path="/:language/mindalay-admin/ticket/in-process" exact component={Tickets} />
    <Route path="/:language/mindalay-admin/ticket/resolved" exact component={Tickets} />

    <Route path="/:language/mindalay-admin/ticket/:ticketId" exact component={CurrentTicket} />

    {/* Quiz */}
    <Route path="/:language/mindalay-admin/course/quiz-view/:quizId" exact component={QuizView} />
    <Route path="/:language/mindalay-admin/topic/quiz-view/:quizId" exact component={QuizView} />

    {/* Key words */}
    <Route path="/:language/mindalay-admin/key-word/speciality" exact component={KeyWords} />
    <Route path="/:language/mindalay-admin/key-word/search" exact component={KeyWords} />

    {/* Categories */}
    <Route path="/:language/mindalay-admin/categories/category" exact component={Category} />
    <Route path="/:language/mindalay-admin/categories/theme" exact component={Theme} />
    <Route path="/:language/mindalay-admin/categories/theme-form" exact component={ThemeForm} />
    <Route path="/:language/mindalay-admin/categories/theme-form/:themeId" exact component={ThemeForm} />

    {/* log */}
    <Route path="/:language/mindalay-admin/logs" exact component={Logs} />
    <Route path="/:language/mindalay-admin/payment-logs" exact component={PaymentLogs} />
    <Route path="/:language/mindalay-admin/balance-logs" exact component={PaymentLogs} />

    {/* Payment-package */}
    <Route path="/:language/mindalay-admin/payment-package/payment-receipt" exact component={PaymentReceipt} />
    <Route path="/:language/mindalay-admin/payment-package/payment-receipt" exact component={PaymentReceipt} />
    <Route path="/:language/mindalay-admin/payment-package/payment-history" exact component={PaymentHistory} />

    {/* AuthenticationByRefreshToken */}
    <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

    {/* 404 */}
    <Route path="/:language/not-found/404" exact component={NotFoundPage} />

    {/* Server error page */}
    <Route path="/:language/under-maintenance" exact component={UnderMaintenancePage} />

    <Redirect to={`/${localStorage.getItem(LANGUAGE_KEY)}/${MINDALAY_ADMIN}/${HOME_KEY}`} />
    
  </Switch>;
}

export default AdminRoutes;

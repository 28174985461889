import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import ActionButton from "../../Components/ActionButton/actionButton";
import AuthInput from "../../Components/Inputs/authInput";
import { ERROR_KEY } from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import { addButtonSpinner, removeButtonSpinner } from "../../Store/Actions/spinner";

const JoinMeeting = () => {
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);

  const [translationService, setTranslationService] = useState(null);
  const [name, setName] = useState('');
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [failedFields, setFailedFields] = useState([]);

  const spinnerId = uuid();

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onSubmit = event => {
    event.preventDefault();
    if (name) {
      setButtonSpinner(spinnerId);
      const form = {
        name: name
      };
      ApiService.joinMeeting(form).then(() => {
        extractButtonSpinner(spinnerId);
      }).catch(error => submitFail(spinnerId, error));
    } else {
      setIsInvalidSubmit(true);
    }
  }

  const submitFail = (spinnerId, error) => {
    spinnerId && extractButtonSpinner(spinnerId);
    setFailedFields(failedFields);
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  return translationService ? <div className="col-12">
    <div className="sign-form-wrapper background">
      <p className="join-meeting-title">{translationService.translate("TR_GO_TO_MEETING_TITLE")}</p>
      <form onSubmit={onSubmit}>
        <AuthInput
          type="text"
          id="name"
          name="name"
          value={name}
          isInvalidSubmit={isInvalidSubmit}
          placeholder={`${translationService.translate("TR_NAME")} *`}
          onChange={event => setName(event.target.value)}
          failedFields={failedFields}
        />
        <div className="mb-3">
          <ActionButton
            spinnerId={spinnerId}
            type="submit"
            className="mindalay--btn-default w-100 position-relative"
            name={translationService.translate("TR_SUBMIT")}
          />
        </div>
      </form>
    </div>
  </div> : null;
}

export default JoinMeeting;
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import TranslationService from "../../Services/translationService";
import ModalSpinner from "../../Components/Spinners/modalSpinner";

const LowBalanceModal = (props) => {
  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const modalSpinners = useSelector(state => state.spinner.modalSpinners);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return (
    translationService &&
    <Modal
      size={props.isLarge ? "lg" : ""}
      show={true}
      onHide={props.cancel}
      contentClassName={props.contentClassName}
      aria-labelledby={props.ariaLabelledby}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalSpinner spinners={modalSpinners} />
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="mindalay--btn-default" onClick={props.cancel}>
          {translationService.translate("TR_CANCEL")}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LowBalanceModal;


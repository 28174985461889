import React, { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import CourseHeader from "../LayoutSections/Header/courseHeader";
import CourseDashboardRoutes from "../../Routing/courseDashboardRoutes";
import CourseDashboardLeftMenu from "../LayoutSections/LeftMenu/CourseDashboardLeftMenu";
import PartialViewSpinner from "../../Components/Spinners/partialViewSpinner";
import PureFooter from "../LayoutSections/Footer/pureFooter";
import TranslationService from "../../Services/translationService";
import InfoSvg from "../../Components/Svg/infoSvg";
import { setCurrentCourse } from "../../Store/Actions/course";
import { Link, withRouter } from "react-router-dom";
import { COURSES_KEY, COURSE_DASHBOARD_KEY, GENERAL_INFO_KEY, INSTRUCTOR_KEY, VIEW_KEY } from "../../Constants/urlKeys";
import ApiService from "../../Services/apiService";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from './../../Store/Actions/spinner';
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";

const CourseDashboardLayout = props => {

  const dispatch = useDispatch();
  const patrialViewSpinners = useSelector(state => state.spinner.patrialViewSpinners);
  const courseData = useSelector(state => state.course.currentCourse);
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);

  const [translationService, setTranslationService] = useState(null);
  const [isShowInfoAlert, setIsShowInfoAlert] = useState(
    courseData && (courseData.submitedForVerification || courseData.verified || courseData.published) ? true : false
  );
  
  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (!courseData && props.location.pathname.includes(COURSE_DASHBOARD_KEY) && props.location.pathname.includes(GENERAL_INFO_KEY)) {
      const spinnerId = uuid();
      var courseId = parseInt(props.location.pathname.split('/')[3]);
      if (courseId) {
        dispatch(addPageSpinner(spinnerId));
        ApiService.getCourseById(courseId).then(response => {
          dispatch(removePageSpinner(spinnerId));
          response.data && dispatch(setCurrentCourse(response.data));
        }).catch(error => getFail(error, spinnerId))
      }
    }
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    error && props.history.push(`/${language}`);
    spinnerId && dispatch(removePageSpinner(spinnerId));
  }

  return translationService && courseData ? <Auxiliary>
    <CourseHeader />
    <div className="wrapper">
      {props.children}
      <section className="top-section m-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 d-md-block d-none">
              <CourseDashboardLeftMenu wrapperClasses="d-md-block d-none" />
            </div>
            {
              courseData && (courseData.submitedForVerification || courseData.verified || courseData.published) && isShowInfoAlert ?
                <div className="info-alert-container">
                  <div className="layer" />
                  <Auxiliary>
                    <div className="info-alert-block">
                      <div className="info-description">
                        {
                          courseData?.published ?
                            <p>{translationService.translate("TR_PUBLISHED_WARNING_MSG")}</p>
                            : null
                        }
                        {
                          courseData?.submitedForVerification && !courseData?.published ?
                            <p>{translationService.translate("TR_SUBMITED_FOR_VERIFICATION_WARNING_MSG")}</p>
                            : null
                        }
                        {
                          courseData?.verified && !courseData?.published ?
                            <p>{translationService.translate("TR_VERIFIED_WARNING_MSG")}</p>
                            : null
                        }
                      </div>
                      <hr />
                      <div className="d-flex justify-content-center">
                        <button className="mindalay--btn-default mr-2" onClick={() => setIsShowInfoAlert(false)}>OK</button>
                        <Link to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`} className="mindalay--btn-default ml-2">
                          {translationService.translate("TR_CANCEL")}
                        </Link>
                      </div>
                    </div>
                  </Auxiliary>
                </div>
                : null
            }
            <div className="col-lg-9 col-md-8 col-12">
              {
                courseData?.submitedForVerification && !courseData.published
                  ? <p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
                    <InfoSvg />
                    {translationService.translate("TR_SUBMITED_FOR_VERIFICATION_WARNING_MSG")}
                  </p>
                  : null
              }
              {
                courseData?.published
                  ? <p className="light-blue-background blue-color blue-border my-3 p-3 mindalay--information">
                    <InfoSvg />
                    {translationService.translate("TR_PUBLISHED_WARNING_MSG")}
                  </p>
                  : null
              }
              {
                courseData?.verificationDenied
                  ? <p className="light-red-background red-color red-border my-3 p-3 mindalay--information">
                    <InfoSvg />
                    {translationService.translate("TR_COURSE_DENY_MSG")}<br />
                    {
                      courseData.verificationDenialMessage
                        ? <Auxiliary>{translationService.translate("TR_DENIAL_MESSAGE")} : {courseData.verificationDenialMessage}</Auxiliary>
                        : null
                    }
                  </p>
                  : null
              }
              <div className="background box-shadow-4 content">
                {/* <PartialViewSpinner spinners={patrialViewSpinners} customClasses="course-dashboard-spinner-wrapper" /> */}
                <CourseDashboardRoutes />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <PureFooter />
  </Auxiliary> : null;
}

export default withRouter(CourseDashboardLayout)
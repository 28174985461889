import { GET_LANGUAGES_REQUEST } from "../../Constants/reduxRequestKeys";
import { LANGUAGE_SUCCESS } from "../../Constants/reduxKeys";

export function setLanguage(language) {
  return {
    type: LANGUAGE_SUCCESS,
    payload: language,
  };
};

export function getLanguages() {
  return {
    type: GET_LANGUAGES_REQUEST,
    payload: {},
  };
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import CourseEditView from "../Pages/Courses/courseEditView";
import CourseForm from "../Pages/Courses/courseForm";
import CoursePricing from "../Pages/Courses/coursePricing";
import CourseMessages from "../Pages/Courses/courseMessages";
import TargetStudents from "../Pages/Courses/targetStudents";
import TopicMediaForm from "../Pages/CourseTopics/topicMediaForm";
import QuizForm from "../Pages/Quiz/quizForm";
import QuizView from "../Pages/Quiz/quizView";
import CoursePromotions from "../Pages/Courses/coursePromotions";
import NotFoundPage from "../Pages/404/notFoundPage";
import UnderMaintenancePage from "../Pages/UnderMaintenancePage/underMaintenancePage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import AssignmentForm from "../Pages/Assignment/assignmentForm";

const CourseDashboardRoutes = () => {
	return (
		<Switch>
			{/* Course Menu routes */}
			<Route
				path="/:language/coursedashboard/:courseId/curiculumn"
				exact
				component={CourseEditView}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/general-info"
				exact
				component={CourseForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/general-info/returnurl"
				exact
				component={CourseForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/target-students"
				exact
				component={TargetStudents}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/messages"
				exact
				component={CourseMessages}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/pricing"
				exact
				component={CoursePricing}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/promotions"
				exact
				component={CoursePromotions}
			/>
			{/* Course Quiz Form */}
			<Route
				path="/:language/coursedashboard/:courseId/quiz-form/:quizId"
				exact
				component={QuizForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/quiz-form"
				exact
				component={QuizForm}
			/>
			{/* Quiz Form */}
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/quiz-form/:quizId"
				exact
				component={QuizForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/quiz-form"
				exact
				component={QuizForm}
			/>
			{/* Quiz view */}
			<Route
				path="/:language/coursedashboard/:courseId/quiz-view/:quizId"
				exact
				component={QuizView}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/assignment-form/:assignmentId"
				exact
				component={AssignmentForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/assignment-form"
				exact
				component={AssignmentForm}
			/>
			{/* assignment Form */}
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/assignment-form/:assignmentId"
				exact
				component={AssignmentForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/assignment-form"
				exact
				component={AssignmentForm}
			/>
			{/* assignmentId view */}
			<Route
				path="/:language/coursedashboard/:courseId/assignment-view/:assignmentId"
				exact
				component={QuizView}
			/>
			{/* Topic Attach File(Media) Form */}
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/media-form/:mediaId"
				exact
				component={TopicMediaForm}
			/>
			<Route
				path="/:language/coursedashboard/:courseId/:topicId/media-form"
				exact
				component={TopicMediaForm}
			/>
			{/* AuthenticationByRefreshToken */}
			<Route
				path="/:language/authentication/:refreshToken"
				exact
				component={AuthenticationByRefreshToken}
			/>
			{/* Server error page */}
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>
			<Route component={NotFoundPage} />
		</Switch>
	);
};

export default CourseDashboardRoutes;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import TranslationService from '../../Services/translationService';
import { Link } from 'react-router-dom';
import ArrowBackSvg from '../../Components/Svg/arrowBackSvg';
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import uuid from 'react-uuid';
import ApiService from './../../Services/apiService';
import AlertService from '../../Services/alertService';
import { ENTITY_TYPE, ERROR_KEY, NUMBER_KEY, SUCCESS_KEY, TR_NO, TR_YES, TICKET_CHAT_TYPE_KEY, BACK_URL_KEY } from '../../Constants/mainKeys';
import * as moment from "moment";
import MainService from './../../Services/mainService';
import MessageSend from '../../Components/Svg/messageSend';
import Auxiliary from '../../hoc/auxiliary/auxiliary';

class CurrentTicket extends Component {
  _isMounted = false;
  entityType = ENTITY_TYPE;
  ticketChatType = TICKET_CHAT_TYPE_KEY;

  state = {
    translationService: null,
    ticketId: parseInt(this.props.match.params.ticketId),
    ticketData: null,
    studentUserId: null, //ticket owner studentId
    messages: [],
    isInvalidSubmit: false,
    message: '',
    messageMaxLength: 2000,
  }

  componentDidMount() {
    this._isMounted = true;
    const { ticketId } = this.state;
    this.setTranslations();
    if (ticketId) {
      this.getTicketByIdFromAdmin();
    } else { this.goBack(); }
    this.interval = setInterval(this.getTicketChatFromAdmin, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevState.messages.length !== this.state.messages.length) {
      this.chatScrolToBottom();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this._isMounted = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    }
    return true;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    }
  }

  goBack = () => {
    if (localStorage.getItem(BACK_URL_KEY)) {
      this.props.history.push(localStorage.getItem(BACK_URL_KEY));
    } else {
      window.history.back();
    }
    localStorage.removeItem(BACK_URL_KEY);
  }

  getTicketByIdFromAdmin = () => {
    const { ticketId } = this.state;
    const spinnerId = uuid();
    this.props.addPageSpinner(spinnerId);
    ApiService.getTicketByIdFromAdmin(ticketId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        this._isMounted && this.setState({
          ticketData: data,
          studentUserId: data.studentUserId,
          partnerName: data.studentName
        }, () => {
          this.getTicketChatFromAdmin();
        })
      }
      this.props.removePageSpinner(spinnerId);
    }).catch(error => this.getFail(error, spinnerId));
  }

  getTicketChatFromAdmin = () => {
    const { ticketId, studentUserId } = this.state;
    if (!studentUserId) { return; }
    const spinnerId = uuid();
    studentUserId && ticketId && ApiService.getTicketChatFromAdmin(this.ticketChatType, ticketId, studentUserId).then(response => {
      if (response && response.data) {
        this._isMounted && this.setState({ messages: response.data.chat });
      }
    }).catch(error => this.getFail(error, spinnerId));
  }

  chatScrolToBottom = () => {
    var element = document.getElementById("mindalay--consultation-chat");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  sendMessageFromConsultantOrAdmin = (event) => {
    event.preventDefault();
    var { message, studentUserId, ticketId } = this.state;
    const spinnerId = uuid();
    const receiverId = [];
    if (!studentUserId) { return };
    if (!message || !message.trim()) {
      this.setState({ isInvalidSubmit: true });
    } else {
      message = message.trim();
      receiverId.push(studentUserId);
      var data = {
        receiverId,
        message,
        entityId: ticketId,
        chatType: this.ticketChatType
      }
      ApiService.sendMessageFromConsultantOrAdmin(data).then(response => {
        response && this.getTicketChatFromAdmin();
        this.setState({ message: '' });
      }).catch(error => this.getFail(error, spinnerId));
    }
  }

  refundTicket = () => {
    const { translationService, ticketId } = this.state;
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_REFUND_CONFIRM_MSG")} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      ApiService.refundTicket(ticketId).then(() => {
        this.props.removePageSpinner(spinnerId);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        this.goBack();
      }).catch(error => this.getFail(error, spinnerId));
    })
  }

  rejectTicket = () => {
    const { translationService, ticketId } = this.state;
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_REJECT_CONFIRM_MSG")} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      ApiService.rejectTicket(ticketId).then(() => {
        this.props.removePageSpinner(spinnerId);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        this.goBack();
      }).catch(error => this.getFail(error, spinnerId));
    })
  }

  resolve = () => {
    const { translationService, ticketId } = this.state;
    const spinnerId = uuid();
    AlertService.alertConfirm(
      `${translationService.translate("TR_RESOLVE_CONFIRM_MSG")} ?`,
      "",
      translationService.translate(TR_YES),
      translationService.translate(TR_NO)
    ).then(() => {
      this.props.addPageSpinner(spinnerId);
      ApiService.resolveTicket(ticketId).then(() => {
        this.props.removePageSpinner(spinnerId);
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_SAVED"));
        this.goBack();
      }).catch(error => this.getFail(error, spinnerId));
    })
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePageSpinner(spinnerId);
    this.goBack();
  }

  render() {
    const { translationService, ticketData, messages, partnerName, isInvalidSubmit, message, messageMaxLength } = this.state;

    return (translationService ? <div className="container">
      <div className="row">
        <div className="col-12 content-title">
          <div className="d-flex align-items-center">
            <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
            <h2 className="content-title p-0">{translationService.translate("TR_TICKET")}</h2>
          </div>
        </div>
      </div>
      <hr />
      {
        ticketData ?
          <div className="col-12">
            <div className="row my-4">
              <div className="col-12">
                <div className="box-shadow-4 mindalay--consultation-container flex-col-reverse d-flex">
                  <div className="header-right-side d-flex align-items-center">
                    <div className="consultation-user-image" style={{ backgroundImage: `url(${ticketData.studentAvatarFilePath})` }}></div>
                    <div className="consultation-user-info">
                      <h3 className="consultation-user-name gray-color">{ticketData.studentName}</h3>
                      <small className="gmt-time mt-2">{translationService.translate("TR_TICKET_OWNER")}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              ticketData.ticketNumber ?
                <span className="d-block my-1"><b>{`${translationService.translate("TR_TICKET_NUMBER")}: `}</b><span>{ticketData.ticketNumber}</span></span>
                : null
            }
            {
              ticketData.description ?
                <span className="d-block my-1"><b>{`${translationService.translate("TR_DESCRIPTION")}: `}</b><span>{ticketData.description}</span></span>
                : null
            }
            {
              ticketData.subject ?
                <span className="d-block my-1"><b>{`${translationService.translate("TR_SUBJECT")}: `}</b><span>{ticketData.subject}</span></span>
                : null
            }
            {
              typeof ticketData.entityType === NUMBER_KEY ?
                this.entityType.map((type, index) => {
                  if (type.value === ticketData.entityType) {
                    return <span key={index} className="d-block my-1">
                      <b>{`${translationService.translate("TR_TYPE")}: `}</b>
                      <span>{translationService.translate(type.label)}</span>
                    </span>
                  } else { return false; }
                })
                : null
            }
            {
              ticketData.createDate ?
                <span className="d-block my-1">
                  <b>{`${translationService.translate("TR_CREATED")}: `}</b>
                  <span>{moment(MainService.convertUTCDateToLocalDate(new Date(ticketData.createDate))).format("lll")}</span>
                </span>
                : null
            }
            <hr />
            <Auxiliary>
              <h2 className="section-title">{translationService.translate("TR_CHAT")}</h2>
              <div className="admin-message-container my-4">
                <div className="mindalay--consultation-tab">
                  <hr className="d-md-none" />
                  <div className="tab-pane " id="consultation-contract--messages-tab" aria-labelledby="consultation-contract--messages-tab">
                    {
                      partnerName ?
                        <strong className="consultation-chat-username">{`${partnerName}`}</strong>
                        : null
                    }
                    <div className="consultation-chat-content-wrapper">
                      <div id="mindalay--consultation-chat" className="consultation-chat-message-container">
                        {
                          messages.length ?
                            messages.map((mess, index) => {
                              return mess.receiver ?
                                <div key={index} className="consultation-chat-message-wrapper consultation-chat-currnet-user">
                                  <div className="consultation-chat-message">
                                    <p className="consultation-chat-message-text">{mess.message}</p>
                                    <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                  </div>
                                </div>
                                :
                                <div key={index} className="consultation-chat-message-wrapper consultant-chat-sender">
                                  <div className="consultation-chat-sender-wrapper">
                                  </div>
                                  <div className="consultation-chat-message">
                                    <p className="consultation-chat-message-text">{mess.message}</p>
                                    <span className="consultation-chat-message-date">{moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")}</span>
                                  </div>
                                </div>
                            })
                            : null
                        }
                      </div>
                      <form onSubmit={this.sendMessageFromConsultantOrAdmin}>
                        <div className="consultation-chat-input-wrapper">
                          <div className="consultation-chat-send-button bg-transparent" id="send-message-button" onClick={this.sendMessageFromConsultantOrAdmin}>
                            <button className="message-send-button" title={translationService.translate("TR_SEND")}>
                              <MessageSend />
                            </button>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${isInvalidSubmit && (!message || !message.trim()) ? "is-invalid error-bordered" : ""}`}
                            value={message}
                            autoComplete="off"
                            name="message"
                            placeholder={translationService.translate("TR_TYPE_TEXT")}
                            onChange={(event) => this.onChange(event, messageMaxLength)}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Auxiliary>
            {
              ticketData.withRefund ?
                <Auxiliary>
                  <hr />
                  <form>
                    <button type="button" className="mindalay--btn-default mr-2" onClick={this.refundTicket}>
                      {translationService.translate("TR_REFUND")}
                    </button>
                    <button type="button" className="mindalay--btn-default ml-2" onClick={this.rejectTicket}>
                      {translationService.translate("TR_REJECT")}
                    </button>
                  </form>
                </Auxiliary>
                : <Auxiliary>
                  <hr />
                  <form>
                    <button type="button" className="mindalay--btn-default ml-2" onClick={this.resolve}>
                      {translationService.translate("TR_RESOLVE")}
                    </button>
                  </form>
                </Auxiliary>
            }
          </div>
          : null
      }
    </div> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  languages: state.language.languages,
  language: state.language.language,
  user: state.user.user
})

const mapDispatchToProps = {
  addPageSpinner,
  removePageSpinner
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CurrentTicket);


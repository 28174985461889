import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import NoDataImage from '../../../assets/images/illustrations/nodata.svg';
import TranslationService from '../../../Services/translationService';
import FileSvg from './../../../Components/Svg/fileSvg';


const FileLibraryDocuments = (props) => {

    const translations = useSelector(state => state.translation.translations);
    const [translationService, setTranslationService] = useState(null);
    const { documents } = props;

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const openDocument = (url) => {
        window.open(url, "_blank")
    }

    return translationService ? documents && documents.length ? <div className="mindalay-files-gallery-container">
        <div className="row">
            {
                documents.map((document, index) => {
                    return <div key={index} className="col-lg-2 col-md-3 col-sm-2 col-4 my-2">
                        <div className="mindalay-file-wrapper mindalay-video p-1 cursor-pointer" onClick={() => openDocument(document.filePath)}>
                            <FileSvg />
                            <Link to='#' className="file-item text-secondary px-3 py-2 d-block word-break-break-word">
                                {document.name}
                            </Link>
                        </div>
                    </div>
                })
            }
        </div>
    </div>
        : <div className="no-data-container mt-3">
            <div className="no-data-wrapper">
                <p>{translationService.translate("TR_YOU_HAVE_NO_AUDIO")}</p>
                <img src={NoDataImage} alt="/" />
            </div>
        </div>
        : null
}

export default FileLibraryDocuments;


import React, { useEffect } from "react";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Footer from "../LayoutSections/Footer/footer";
import { withRouter } from "react-router";
import OrganizationUserHeader from "../LayoutSections/Header/organizationUserHeader";
import OrganizationUserLeftMenu from "../LayoutSections/LeftMenu/OrganizationUserLeftMenu";
import OrganizationUserRoutes from "./../../Routing/organizationUserRoutes";
import { useSelector } from "react-redux";
import StudentRoute from "../../Routing/studentRoute";
import {
	CURATOR_USER_TYPE,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import LecturerRoute from "../../Routing/lecturerRoute";
import CuratorRoute from "../../Routing/curatorRoute";

const OrganizationUserLayout = props => {
	const { role, user } = useSelector(state => state.user);

	if (
		user &&
		user.defaultUserTypeIds &&
		user.defaultUserTypeIds.includes(user.userTypeId)
	) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${
						props.location.pathname.includes("/help")
							? ""
							: "instructior-dashboard-body--large"
					} `}>
					<div className="top-section wrapper pb-5">
						<OrganizationUserRoutes />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === STUDENT_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${
						props.location.pathname.includes("/help")
							? ""
							: "instructior-dashboard-body--large"
					} `}>
					<div className="top-section wrapper pb-5">
						<StudentRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === LECTURER_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${
						props.location.pathname.includes("/help")
							? ""
							: "instructior-dashboard-body--large"
					} `}>
					<div className="top-section wrapper pb-5">
						<LecturerRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === CURATOR_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${
						props.location.pathname.includes("/help")
							? ""
							: "instructior-dashboard-body--large"
					} `}>
					<div className="top-section wrapper pb-5">
						<CuratorRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}
	return (
		<Auxiliary>
			<OrganizationUserHeader />
			{props.location.pathname.includes("/help") ? null : (
				<OrganizationUserLeftMenu />
			)}
			<section
				className={`${
					props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
				} `}>
				<div className="top-section wrapper pb-5">
					<OrganizationUserRoutes />
				</div>
				<Footer />
			</section>
		</Auxiliary>
	);
};

export default withRouter(OrganizationUserLayout);

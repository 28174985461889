import React from "react";
import { useSelector } from "react-redux";
import { OBJECT_KEY } from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import TranslationService from "../../Services/translationService";
import InfoSvg from "../Svg/infoSvg";

const SelectOption = props => {
	const translationReduxState = useSelector(state => state.translation);
	const translationService = new TranslationService(
		translationReduxState.translations,
	);
	const failedFieldName =
		props.name && props.name.charAt(0).toUpperCase() + props.name.slice(1);
	return (
		<div
			className={`form-group ${
				props.containerClasses ? props.containerClasses : ""
			}`}>
			{props.label ? <label>{props.label}</label> : null}
			{props.infoText ? (
				<div className="info-title d-inline-block">
					<div>
						<InfoSvg />
						<span style={{ display: "none" }}>{props.infoText}</span>
					</div>
				</div>
			) : null}
			<select
				type="select"
				value={props.value || props.value === 0 ? props.value : ""}
				name={props.name}
				className={`form-control ${
					props.selectClasses ? props.selectClasses : ""
				}
        ${
					(props.failedFields &&
						props.failedFields.hasOwnProperty(failedFieldName)) ||
					(props.isInvalidSubmit && !props.value) ||
					props.error ||
					props.isInvalidField
						? "is-invalid error-bordered"
						: ""
				}`}
				onChange={props.changed}
				disabled={props.isDisabled}>
				{props.defaultValue ? (
					<option hidden value={props.defaultValue}>
						{props.defaultValue}
					</option>
				) : null}
				{props.items && props.items.length
					? props.items.map((item, index) => {
							return (
								<Auxiliary
									key={item.value || item.id || item.languageId || item}>
									<option
										value={
											item.value ||
											item.id ||
											item.languageId ||
											item.question ||
											(props.returnItem ? item : index)
										}>
										{translationService.translate(item.name) ||
											translationService.translate(item) ||
											item.name ||
											item.text ||
											item.title ||
											(typeof item === OBJECT_KEY ? "" : item)}
										{props.hasPercentSign ? "%" : null}
									</option>
								</Auxiliary>
							);
					  })
					: null}
			</select>
			{props.failedFields &&
			props.failedFields.hasOwnProperty(failedFieldName) ? (
				<small className="small-error-msg">
					{props.failedFields[failedFieldName]}
				</small>
			) : null}
		</div>
	);
};
export default SelectOption;

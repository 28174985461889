import axios from "axios";
import AlertService from "./alertService";
import { CURRENCY_KEY, ERROR_KEY, LANGUAGE_KEY } from "../Constants/mainKeys";
import { COURSE_PATH_KEY, PUBLISHED_PATH_KEY } from "../Constants/requestKeys";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true,
});

api.interceptors.request.use(async config => {
	config.headers = {
		language: localStorage.getItem(LANGUAGE_KEY),
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data) {
			return !response.data.respcode
				? response.data
				: Promise.reject(response.data.respmess);
		}
	},
	error => {
		if (error.response) {
			const errors = error.response.data.errors;
			for (let err in errors) {
				AlertService.alert(ERROR_KEY, errors[err][0]);
			}
			return Promise.reject();
		} else return Promise.reject(error);
	},
);

api.interceptors.request.use(async config => {
	config.headers = {
		language: localStorage.getItem(LANGUAGE_KEY),
	};
	return config;
});

class GuestApiService {
	//Courses
	static getPublishedCourses() {
		return api.get(
			`/${COURSE_PATH_KEY}/${PUBLISHED_PATH_KEY}/${localStorage.getItem(
				CURRENCY_KEY,
			)}`,
		);
	}

	//Pages
	static getFrontBottomPages() {
		return api.get(`/site/struct?menuGroupId=2`);
	}

	static getIpLocationData() {
		return axios.get("https://ipapi.co/json/");
	}
}

export default GuestApiService;

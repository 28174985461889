import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import Sections from "../CourseSections/sections";
import SettingsSvg from "../../Components/Svg/settingsSvg";
import QuizSvg from "../../Components/Svg/quizSvg";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import InfoSvg from "../../Components/Svg/infoSvg";
import ShowMoreText from "react-show-more-text";
import Parser from "html-react-parser";
import { setPureLayoutBackUrl } from "./../../Store/Actions/main";
import { setCurrentCourse } from "../../Store/Actions/course";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../Constants/translationKeys";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import {
	COURSES_KEY,
	SETTINGS_KEY,
	QUIZ_FORM_KEY,
	QUIZ_ATTEMPT_KEY,
	QUIZ_VIEW_KEY,
	COURSE_DASHBOARD_KEY,
	VIEW_KEY,
	PURE_KEY,
} from "../../Constants/urlKeys";
import TopicForm from "../CourseTopics/topicForm";
import ParserService from "../../Services/parserService";
import ParserComponent from "../../Components/ParserComponent/ParserComponent";

class CourseEditView extends Component {
	state = {
		courseId: +this.props.match.params.courseId,
		courseData: this.props.courseData,
		isInvalidSubmit: false,
		showAddSection: false,
		isEditMode: true,
		sections: this.props.courseData && this.props.courseData.sections,
		translationService: null,

		isShowTopicForm: false,
		sectionId: null,
		topics: [],
		updateTopic: null,
	};

	componentDidMount() {
		const { courseId } = this.state;
		const { history, language } = this.props;
		if (history.location.hash) {
			history.push(`/${language}/${SETTINGS_KEY}/${COURSES_KEY}/${courseId}`);
		}
		this.setTranslations();
		if (courseId) {
			this.getQuizzesByCourseId(courseId);
			this.getCourseById(courseId);
		}
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		if (
			JSON.stringify(this.props.courseData) !==
			JSON.stringify(nextProps.courseData)
		) {
			this.setState({ courseData: nextProps.courseData });
		}
		return true;
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	getCourseById = courseId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getCourseById(courseId)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					// if (data && data.sections && data.sections.length) {
					//   data.sections.forEach(section => {
					//     if (section.topics && section.topics.length) {
					//       section.topics.forEach(topic => {
					//         topic.imagePath = topic.imagePath ? `${topic.imagePath}?${uuid()}` : null;
					//       })
					//     }
					//   })
					// }
					this.props.setCurrentCourse(data);
					this.props.removePageSpinner(spinnerId);
					this.setState({
						courseData: data,
						sections: data.sections,
					});
				}
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	getQuizzesByCourseId = courseId => {
		const spinnerId = uuid();
		this.props.addPageSpinner(spinnerId);
		ApiService.getQuizzesByCourseId(courseId)
			.then(response => {
				this.setState({ courseQuizzes: response.data });
				this.props.removePageSpinner(spinnerId);
			})
			.catch(error => this.getFail(error, spinnerId));
	};

	onChange = event => {
		this.setState(prevState => ({
			...prevState,
			form: { ...prevState.form, [event.target.name]: event.target.value },
		}));
	};

	deleteQuiz = quiz => {
		const { translationService, courseQuizzes } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${
				quiz.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				ApiService.quizDelete(quiz.id)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						const index = courseQuizzes.indexOf(quiz);
						if (index > -1) {
							courseQuizzes.splice(index, 1);
						}
						this.setState({ courseQuizzes });
					})
					.catch(e => this.deleteFail(null, e));
			})
			.catch(e => this.deleteFail(null, e));
	};

	showTopicForm = data => {
		if (!data) {
			return false;
		}
		this.setState({
			sectionId: data.sectionId,
			topics: data.topics,
			isShowTopicForm: data.isShow,
			updateTopic: data.updateTopic,
		});
	};

	topicSubmitted = () => {
		const { courseId } = this.state;
		if (!courseId) {
			return false;
		}
		this.setState({
			sectionId: null,
			isShowTopicForm: null,
			updateTopic: null,
		});
		this.getCourseById(courseId);
	};

	cancel = () => {
		this.setState({ isShowTopicForm: false, updateTopic: null });
	};

	changeSectionsData = data => {
		data &&
			this.setState({
				courseData: data,
				sections: data.sections,
			});
	};

	deleteFail = (spinnerId, message) => {
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		message &&
			AlertService.alert(
				AlertService.checkMessageType(message.respcode) || ERROR_KEY,
				message,
			);
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
	};

	goBack = () => {
		this.props.history.push(
			`/${this.props.language}/${SETTINGS_KEY}/${COURSES_KEY}`,
		);
	};

	render() {
		const {
			isEditMode,
			courseQuizzes,
			courseData,
			courseId,
			sections,
			translationService,

			isShowTopicForm,
			topics,
			sectionId,
			updateTopic,
		} = this.state;

		const { language } = this.props;

		return translationService && courseData ? (
			<Auxiliary>
				<div className="d-sm-flex d-block curriculum-title">
					<div className="w-100">
						<h2
							title={courseData.name}
							className="section-title word-wrap-break-word">
							{courseData.name}
						</h2>
						<div className="custom-control custom-switch mt-2">
							<input
								type="checkbox"
								id="editModeSwitcher"
								className="custom-control-input"
								checked={isEditMode}
								onChange={event =>
									this.setState({ isEditMode: event.target.checked })
								}
							/>
							<label
								htmlFor="editModeSwitcher"
								className="custom-control-label">
								{translationService.translate("TR_EDIT_MODE")}
							</label>
						</div>
						<p className="mt-2">
							{" "}
							{translationService.translate("TR_CURICULUMN_INFO")}
						</p>
					</div>
				</div>
				<hr />
				{isShowTopicForm && sectionId && courseId && isEditMode ? (
					<div className="row  scale-up-hor-right">
						<div className="col-12">
							<div className="topic-form-container">
								<TopicForm
									show={true}
									courseId={courseId}
									sectionId={sectionId}
									topic={updateTopic}
									topics={topics}
									cancel={this.cancel}
									submitted={this.topicSubmitted}
								/>
							</div>
						</div>
					</div>
				) : (
					<div className="content-body pt-0">
						{courseData.description ? (
							<div className="mb-3 overflow-hidden">
								<ShowMoreText
									lines={2}
									more={translationService.translate("TR_SHOW_MORE")}
									less={translationService.translate("TR_LESS")}
									className="content-css"
									anchorClass="my-anchor-css-class"
									expanded={false}
									truncatedEndingComponent={"... "}>
									<span className="course-description-block">
										<ParserComponent text={courseData.description} />
									</span>
								</ShowMoreText>
							</div>
						) : null}
						<Sections
							sections={sections ? sections : []}
							isEditMode={isEditMode}
							showTopicForm={this.showTopicForm}
							isBelongToUser={true}
							getCourseById={() => this.getCourseById(courseId)}
							changeSectionsData={this.changeSectionsData}
						/>
						{courseQuizzes && courseQuizzes.length ? (
							<Auxiliary>
								<hr />
								<div className="accordion-wrapper mt-3">
									<div className="card mindalay-card">
										<div className="border-0 m-0 p-0">
											<div className="card-body p-0">
												<div className="d-flex align-items-center">
													<h5 className="mb-2 text-elipsis">
														{translationService.translate("TR_COURSE_QUIZZES")}
													</h5>
													<div className="info-title d-inline-block mb-1">
														<div>
															<InfoSvg />
															<span style={{ display: "none" }}>
																{translationService.translate(
																	"TR_COURSE_QUIZ_INFO",
																)}
															</span>
														</div>
													</div>
												</div>
												<div className="section-content-wrapper">
													{courseQuizzes.map(courseQuiz => {
														const quizAttemptViewPath = `/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${VIEW_KEY}/${courseId}/${courseQuiz.id}`;
														const editPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_FORM_KEY}/${courseQuiz.id}`;
														const attachQuestionsPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_VIEW_KEY}/${courseQuiz.id}`;
														return (
															<div
																key={courseQuiz.id}
																className="section-content">
																{isEditMode ? (
																	<div className="dropleft setting-dropdown">
																		<div
																			className="setting-dropdown-btn"
																			data-toggle="dropdown"
																			aria-haspopup="true"
																			aria-expanded="false">
																			<SettingsSvg />
																		</div>
																		<div className="dropdown-menu">
																			<Link
																				to={editPath}
																				className="dropdown-item">
																				{translationService.translate(
																					"TR_EDIT",
																				)}
																			</Link>
																			<Link
																				to={attachQuestionsPath}
																				className="dropdown-item">
																				{translationService.translate(
																					"TR_ATTACH_QUESTIONS",
																				)}
																			</Link>
																			<Link
																				to="#"
																				className="dropdown-item"
																				onClick={() =>
																					this.deleteQuiz(courseQuiz)
																				}>
																				{translationService.translate(
																					"TR_DELETE",
																				)}
																			</Link>
																		</div>
																	</div>
																) : null}
																<QuizSvg />
																<Link
																	onClick={() =>
																		this.props.setPureLayoutBackUrl(
																			this.props.location.pathname,
																		)
																	}
																	to={quizAttemptViewPath}>
																	<p className="mb-0 text-elipsis">
																		{courseQuiz.name}
																	</p>
																</Link>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Auxiliary>
						) : null}
					</div>
				)}
			</Auxiliary>
		) : null;
	}
}

const mapStateToProps = state => ({
	language: state.language.language,
	translations: state.translation.translations,
	buttonSpinners: state.spinner.buttonSpinners,
	courseData: state.course.currentCourse,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	setCurrentCourse,
	setPureLayoutBackUrl,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(CourseEditView);

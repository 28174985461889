import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoDataImage from "../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY, SUCCESS_KEY, TR_NO, TR_YES } from "../Constants/mainKeys";
import _InfoSvg from "../Components/Svg/_infoSvg";
import EditSvg from "../Components/Svg/editSvg";
import DeleteSvg from "../Components/Svg/deleteSvg";
import TranslationService from "../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../Store/Actions/spinner";
import ApiService from "../Services/apiService";
import AlertService from "../Services/alertService";
import ModalComponent from "../Components/ModalComponent/modalComponent";
import PageInfoBlock from "../Components/PageInfoBlock/PageInfoBlock";
import Input from "../Components/Inputs/input";

const Rols = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [userTypes, setUserTypes] = useState([]);
	const [roles, setRoles] = useState([]);
	const [values, setValues] = useState({
		name: "",
	});
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [defaultUserTypes, setDefaultUserTypes] = useState(null);
	const [search, setSearch] = useState("");

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getRoles();
	}, []);

	const filterRoles = useMemo(() => {
		if (search.trim().length && roles.length) {
			return roles.filter(el => el.name.includes(search));
		} else {
			return roles;
		}
	}, [roles, search]);

	const getRoles = orgId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getRoles()
			.then(response => {
				if (response && response.data) {
					setRoles(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const deleteRole = item => {
		if (!item) return;
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.deleteRole(item.id)
				.then(() => {
					setRoles(roles.filter(_item => _item.id !== item.id));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error =>
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					),
				)
				.finally(() => {
					dispatch(removePartialViewSpinner(spinnerId));
				});
		});
	};

	const onClose = (close = true) => {
		setValues({
			name: "",
		});
		setIsChange(false);
		setIsInvalidSubmit(false);
		setOpenModal(false);
	};

	const onSave = () => {
		const spinerID = uuid();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addModalSpinner(spinerID));
		(!values?.id
			? ApiService.createRole(values)
			: ApiService.updateRole(values)
		)
			.then(response => {
				if (response.data) {
					setRoles([...roles, response.data]);
				}
				if (values?.id) {
					setRoles(
						roles.map(el => {
							if (el.id === values.id) {
								return { ...el, name: values.name };
							}
							return el;
						}),
					);
					// setUserTypes([...userTypes, response.data]);
				}
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				onClose(false);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerID));
			});
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
		setIsChange(true);
	};

	const onModalCheckBoxChange = (event, id) => {
		setIsChange(true);
		if (!event.target.checked) {
			const selectedValue = values.defaultrolss.filter(el => el !== id);
			setValues(values => ({ ...values, defaultrolss: selectedValue }));
		} else {
			setValues(values => ({
				...values,
				defaultrolss: [...values.defaultrolss, id],
			}));
		}
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave(openModal);
					}}
					addButtonIsDisabled={!isChange}
					title={
						!values?.id
							? translationService.translate("TR_CREATE")
							: translationService.translate("TR_UPDATE")
					}
					actionButtonTitle={translationService.translate("TR_SAVE")}>
					<div className="row">
						<div className="col-12">
							<Input
								type="text"
								id="name"
								name="name"
								inputClassName="pr--5"
								value={values.name}
								fieldLength={40}
								isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
								labelValue="Name*"
								onChange={event => onChange(event, "name", 40)}
							/>
						</div>
					</div>
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_USER_TYPES_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
						infoText="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-items-center justify-content-end">
				<div className="col-12 col-md-6">
					<div className="position-relative">
						<Input
							type="text"
							id="search"
							name="search"
							inputClassName="pr--5"
							value={search}
							// fieldLength={fieldsLength.nameMaxLength}
							// isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							onChange={event => {
								if (event.target.value.trim().length) {
									setSearch(event.target.value);
								} else {
									setSearch("");
								}
							}}
						/>
						<div
							style={{
								position: "absolute",
								bottom: "50%",
								transform: "translateY(50%)",
								right: "9px",
								pointerEvents: "none",
							}}>
							<i
								class="fa fa-search"
								style={{ color: "var(--mindalay--gray-color-lightened-75)" }}
								aria-hidden="true"></i>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-6  d-flex justify-content-end">
					<button
						className="mindalay--btn-default"
						onClick={() => {
							setOpenModal(true);
						}}>
						Create user role
					</button>
				</div>
			</div>
			{filterRoles && filterRoles.length ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_USER_TYPES_PAGE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{filterRoles.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<div className="mindalay-admin-table-td-item w-100">
														{item.name}
													</div>
												</td>

												<td>
													<div className="table-action-btn-group d-flex align-items-center">
														<>
															<Link
																to={"#"}
																title={translationService.translate("TR_EDIT")}
																onClick={e => {
																	e.stopPropagation();
																	setOpenModal(true);
																	setValues({ id: item.id, name: item.name });
																}}
																className="table-action-btn edit-btn">
																<EditSvg />
															</Link>
															<Link
																to="#"
																title={translationService.translate(
																	"TR_DELETE",
																)}
																className="table-action-btn delete-btn"
																onClick={e => {
																	e.stopPropagation();
																	deleteRole(item);
																}}>
																<DeleteSvg />
															</Link>
														</>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Rols);

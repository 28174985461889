import React from "react";
import { Route, Switch } from "react-router";
import CreateCourseForm from "../Pages/Courses/createCourseForm";
import BecomeInstructor from "../Pages/BecomeInstructor/becomeInstructor";
import QuizAttempt from "../Pages/Quiz/quizAttempt";
import QuizAttemptView from "../Pages/Quiz/quizAttemptView";
import BecomeConsultantView from "../Pages/BecomeConsultant/becomeConsultantView";
import NotFoundPage from "../Pages/404/notFoundPage";
import AuthenticationByRefreshToken from "../Pages/AuthenticationByRefreshToken/AuthenticationByRefreshToken";
import StudentAssignment from "../Pages/Assignment/StudentAssignment";

const PureLayoutRoutes = () => {
  return <Switch>
    {/* Create Course */}
    <Route path="/:language/pure/createcourse" exact component={CreateCourseForm} />

    {/* Become Instructor */}
    <Route path="/:language/pure/become-instructor" exact component={BecomeInstructor} />

    {/* Become Instructor */}
    <Route path="/:language/pure/become-consultant" exact component={BecomeConsultantView} />

    {/* AuthenticationByRefreshToken */}
    <Route path="/:language/authentication/:refreshToken" exact component={AuthenticationByRefreshToken} />

    {/* Quiz attempt */}
    <Route path="/:language/pure/quiz-attempt/:courseId/:quizId" exact component={QuizAttempt} />
    <Route path="/:language/pure/quiz-attempt/view/:courseId/:quizId" exact component={QuizAttemptView} />

    <Route
      path="/:language/passcourse/:courseId/:studentCourseId/student-assignment/:assignmentId"
      exact
      component={StudentAssignment}
    />

    <Route
      path="/:language/passcourse/video/:courseId/:studentCourseId/:topicId/student-assignment/:assignmentId"
      exact
      component={StudentAssignment}
    />

    <Route component={NotFoundPage} />

  </Switch>;
}

export default PureLayoutRoutes;
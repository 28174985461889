import React from 'react';

const TicketSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="512"
      height="512"
      x="0"
      y="0"
      viewBox="0 0 423.055 423.055"
      xmlSpace="preserve"
    ><g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M362.021,10.869c-6.431-2.963-14.009-1.81-19.269,2.93l-27.755,24.575c-0.755,0.672-1.894,0.668-2.645-0.008L274.588,4.59    c-6.83-6.12-17.17-6.12-24,0l-37.73,33.745c-0.759,0.678-1.906,0.678-2.665,0L172.459,4.59c-6.83-6.119-17.17-6.119-24,0    L110.69,38.366c-0.756,0.676-1.898,0.679-2.658,0.007l-27.78-24.574c-7.37-6.554-18.658-5.893-25.212,1.477    c-2.939,3.305-4.547,7.583-4.513,12.005v368.494c-0.066,9.878,7.888,17.939,17.766,18.005c4.425,0.03,8.703-1.582,12.009-4.523    l27.755-24.575c0.755-0.672,1.894-0.668,2.645,0.008l37.764,33.776c6.83,6.12,17.17,6.12,24,0l37.734-33.745    c0.759-0.678,1.906-0.678,2.665,0l37.734,33.744c6.831,6.117,17.17,6.117,24,0l37.771-33.776c0.756-0.676,1.898-0.679,2.658-0.007    l27.78,24.574c7.373,6.551,18.66,5.885,25.211-1.488c2.934-3.302,4.54-7.575,4.508-11.993V27.281    C372.621,20.202,368.489,13.747,362.021,10.869z M116.734,143.528h99.586c4.418,0,8,3.582,8,8s-3.582,8-8,8h-99.586    c-4.418,0-8-3.582-8-8S112.316,143.528,116.734,143.528z M306.32,279.528H116.734c-4.418,0-8-3.582-8-8s3.582-8,8-8H306.32    c4.418,0,8,3.582,8,8S310.738,279.528,306.32,279.528z M306.32,219.528H116.734c-4.418,0-8-3.582-8-8s3.582-8,8-8H306.32    c4.418,0,8,3.582,8,8S310.738,219.528,306.32,219.528z" fill="#b53471" data-original="#000000" />
          </g>
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
        <g xmlns="http://www.w3.org/2000/svg">
        </g>
      </g></svg>

  );
}

export default TicketSvg;

import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../../Services/translationService";
import { showOrHideWebinarScreenNotification } from "../../../../Store/Actions/signalR";
import { logout } from "../../../../Store/Actions/user";
import { changeShoppingCartOrWishListCount } from "../../../../Store/Actions/wishListOrShoppingCart";
import {
	EDIT_PROFILE_KEY,
	ORGANIZATION_KEY,
} from "../../../../Constants/urlKeys";
import { showOrHideGeneralDashboardLeftMenu } from "../../../../Store/Actions/generalLeftMenu";
import { REFRESH_TOKEN_KEY } from "../../../../Constants/mainKeys";
import Auxiliary from "../../../../hoc/auxiliary/auxiliary";
import { setSettings } from "../../../../Store/Actions/settings";

const OrganizationStudentDropdown = props => {
	const dispatch = useDispatch();

	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const globalConnection = useSelector(state => state.signalR.globalConnection);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const onLogout = () => {
		logoutHandler();
		dispatch(changeShoppingCartOrWishListCount(0, 0));
		dispatch(showOrHideWebinarScreenNotification(null));
		dispatch(setSettings(null));
		document.documentElement.style.setProperty(
			"--mindalay--brand-color",
			"#32064A",
		);
		document.documentElement.style.setProperty(
			"--mindalay--brand-color-darken-35",
			"#220432",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color",
			"#E56B1F",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color-lightened-50",
			"#ffbf9f",
		);
		document.documentElement.style.setProperty(
			"--mindalay--secondary-color-lightened-minus-5",
			"#e26418",
		);
		props.history.push(`/${language}`);
	};

	const logoutHandler = useCallback(() => {
		if (globalConnection && globalConnection.state === "Connected") {
			globalConnection.stop();
		}
		dispatch(logout());
	});

	return translationService ? (
		<Dropdown>
			<div className="navbar-profile-dropdown">
				<Dropdown.Toggle variant="transparent">
					<div
						className="navbar-profile-image"
						style={{ backgroundImage: `url(${user.avatarFilePath})` }}
						onClick={() => dispatch(showOrHideGeneralDashboardLeftMenu(false))}
					/>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						className="profile-nav-link w-100"
						as={Link}
						to={`/${language}/${ORGANIZATION_KEY}/${EDIT_PROFILE_KEY}`}>
						<div className="d-flex align-items-center">
							<div
								className="navbar-profile-image"
								style={{
									backgroundImage: `url(${user.avatarFilePath})`,
								}}></div>
							<div className="ml-3 user-profil-info-wrapper">
								<h2 className="profile-user-fullname">
									{user.firstname} {user.lastname}
								</h2>
								<small>{user.email}</small>
							</div>
						</div>
					</Dropdown.Item>
					<ul className="header-popup">
						<li className="nav--item" onClick={onLogout}>
							<Dropdown.Item
								className="profile-nav-link w-100"
								as={Link}
								to="#">
								{translationService.translate("TR_LOG_OUT")}
							</Dropdown.Item>
						</li>
					</ul>
				</Dropdown.Menu>
			</div>
		</Dropdown>
	) : null;
};

export default withRouter(OrganizationStudentDropdown);

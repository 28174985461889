import React from 'react';
import Loader from 'react-loader-spinner';

const notificationSpinner = props => {
  return (
    props.spinnerId ?
      <div className="notification-spinner-wrapper">
        <div className="center page-loader">
          <Loader type="Oval" color="#32064A" height={40} width={40} />
        </div>
      </div>
      : null
  );
}

export default notificationSpinner;
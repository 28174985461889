import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import ActionButton from '../../Components/ActionButton/actionButton';
import Input from '../../Components/Inputs/input';
import { ERROR_KEY, NUMBER_KEY, SUCCESS_KEY } from '../../Constants/mainKeys';
import AlertService from '../../Services/alertService';
import TranslationService from '../../Services/translationService';
import { addButtonSpinner, removeButtonSpinner } from '../../Store/Actions/spinner';
import ApiService from './../../Services/apiService';
import ArrowBackSvg from './../../Components/Svg/arrowBackSvg';
import InputCheckBox from './../../Components/Inputs/inputCheckBox';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import { useCallback } from 'react';
import { FORM_KEY, ORGANIZATION_ADMIN_KEY, PAYMENT_PACKAGE_KEY } from '../../Constants/urlKeys';


const WAIT_INTERVAL = 500;
const CustomPaymentPackage = (props) => {

  const buttonSpinnerId = uuid();
  const maxInputValue = 10000;
  const dispatch = useDispatch();

  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);

  const [translationService, setTranslationService] = useState(null);
  const [packageProperties, setPackageProperties] = useState([]);
  const [totalPriceAsString, setTotalPriceAsString] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [paymentPackagePropertiesModel, setPaymentPackagePropertiesModel] = useState([]);
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPackageProperties();
  }, []);

  const getPackageProperties = () => {
    const spinnerId = uuid();
    ApiService.getPackageProperties().then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        const paymentPackagePropertiesModelCopy = [...paymentPackagePropertiesModel];
        data.totalPriceAsString && setTotalPriceAsString(data.totalPriceAsString);
        data.totalPrice && setTotalPrice(data.totalPrice);
        const properties = [...data.properties];
        properties.forEach(propertyItem => {
          if (!propertyItem.isPricePerUnit) {
            propertyItem.isSelected = false;
          } else {
            if (!propertyItem.dependedPropertyId) {
              let obj = {
                propertyId: propertyItem.id,
                unitCount: propertyItem.selectedUnitCount
              }
              paymentPackagePropertiesModelCopy.push(obj);
            }
          }
        })
        setPackageProperties(properties);
        setPaymentPackagePropertiesModel(paymentPackagePropertiesModelCopy);
        setTimeout(() => setIsDisabledFields(false), 500);
      }
    }).catch(error => getFail(error, spinnerId))
  }

  const onCheckboxChange = (event, packagePropertyId) => {
    let packagePropertiesCopy = [...packageProperties];
    let paymentPackagePropertiesModelCopy = [...paymentPackagePropertiesModel];
    var obj = {
      propertyId: packagePropertyId,
      unitCount: event.target.checked ? 1 : 0
    }

    if (event.target.checked) {
      if (!paymentPackagePropertiesModelCopy.length) {
        paymentPackagePropertiesModelCopy.push(obj);
      } else {
        const isExist = paymentPackagePropertiesModelCopy.find(property => property.propertyId === packagePropertyId);
        if (!isExist) {
          paymentPackagePropertiesModelCopy.push(obj);
        } else {
          var foundIndex = paymentPackagePropertiesModelCopy.findIndex(x => x.propertyId === packagePropertyId);
          if (foundIndex !== -1) {
            paymentPackagePropertiesModelCopy[foundIndex] = obj;
          }
        }
      }
      packagePropertiesCopy.forEach(packageProperty => {
        if (packageProperty.dependedPropertyId && packageProperty.dependedPropertyId === packagePropertyId) {
          let objItem = {
            propertyId: packageProperty.id,
            unitCount: packageProperty.selectedUnitCount
          }
          const isExist = paymentPackagePropertiesModelCopy.find(property => property.propertyId === packageProperty.id);
          if (!isExist) {
            paymentPackagePropertiesModelCopy.push(objItem);
          } else {
            var foundIndex = paymentPackagePropertiesModelCopy.findIndex(x => x.propertyId === packageProperty.id);
            if (foundIndex !== -1) {
              paymentPackagePropertiesModelCopy[foundIndex] = objItem;
            }
          }
        }
      })
    } else {

      // let findElementByDependedPropertyId = packagePropertiesCopy.find(packagePropertiesCopyItem => packagePropertiesCopyItem.dependedPropertyId);
      let findElementByDependedPropertyId = packagePropertiesCopy.find(packagePropertiesCopyItem => packagePropertiesCopyItem.dependedPropertyId === packagePropertyId);
      if (findElementByDependedPropertyId) {
        let removeIndex = paymentPackagePropertiesModelCopy.findIndex(item => item.propertyId === findElementByDependedPropertyId.id);
        if (removeIndex > -1) {
          paymentPackagePropertiesModelCopy.splice(removeIndex, 1);
        }
      }
      let removeIndexItem = paymentPackagePropertiesModelCopy.findIndex(item => item.propertyId === packagePropertyId);
      if (removeIndexItem > -1) {
        paymentPackagePropertiesModelCopy.splice(removeIndexItem, 1);
      }
    }
    var cb = () => {
      setPaymentPackagePropertiesModel(paymentPackagePropertiesModelCopy);
      setTimeout(() => setIsDisabledFields(false), 500);
    }

    calculatePrice(paymentPackagePropertiesModelCopy, cb);
  }

  const calculatePrice = (paymentPackageProperties, cb) => {
    const form = {
      paymentPackageProperties,
    }
    setIsDisabledFields(true);
    ApiService.calculateCustomPaymentPackagePrice(form).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        data.totalPriceAsString && setTotalPriceAsString(data.totalPriceAsString);
        data.totalPrice && setTotalPrice(data.totalPrice);
        const properties = [...data.properties];
        setPackageProperties(properties);
        cb();
      }
    }).catch(error => getFail(error))
  }

  const onChangeValue = (event, minValue, maxValue, packagePropertyId) => {
    if (+event.target.value.charAt(0) === 0) {
      event.target.value = event.target.value.substring(1);
    }
    if (event.target.value.includes("e") || event.target.value.includes(".")) { return false };
    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) >= 0 && Number(event.target.value) <= (maxValue || maxInputValue))) {
      clearTimeout(timer);
      var num = event.target.value;
      setValue(num, packagePropertyId, minValue, maxValue);
    }
  }

  const setValue = (num, packagePropertyId, minValue, maxValue) => {
    const paymentPackagePropertiesModelCopy = [...paymentPackagePropertiesModel];
    const packagePropertiesCopy = [...packageProperties];
    var obj = {
      propertyId: packagePropertyId,
      unitCount: +num,
    }
    if (!paymentPackagePropertiesModelCopy.length) {
      paymentPackagePropertiesModelCopy.push(obj);
    } else {
      const isExist = paymentPackagePropertiesModelCopy.find(property => property.propertyId === packagePropertyId);
      if (!isExist) {
        paymentPackagePropertiesModelCopy.push(obj);
      } else {
        var foundIndex = paymentPackagePropertiesModelCopy.findIndex(x => x.propertyId === packagePropertyId);
        if (foundIndex !== -1) {
          paymentPackagePropertiesModelCopy[foundIndex] = obj;
        }
      }
    }
    packagePropertiesCopy.forEach(packagePropertiesCopyItem => {
      if (packagePropertiesCopyItem.id === packagePropertyId) {
        packagePropertiesCopyItem.selectedUnitCount = +num;
      }
    })
    setPackageProperties(packagePropertiesCopy);

    var cb = () => {
      setPaymentPackagePropertiesModel(paymentPackagePropertiesModelCopy);
      setTimeout(() => setIsDisabledFields(false), WAIT_INTERVAL);
    }
    if (+num >= minValue && +num <= maxValue) {
      const newTimer = setTimeout(() => calculatePrice(paymentPackagePropertiesModelCopy, cb), WAIT_INTERVAL);
      setTimer(newTimer);
    }
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (isDisabledFields) { return false; }
    setButtonSpinner(buttonSpinnerId);
    const form = {
      paymentPackageProperties: paymentPackagePropertiesModel,
      totalPrice,
    }
    ApiService.createCustomPaymentPackage(form).then(() => {
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PP_SUCCESSFULLY_CREATED"));
      props.history.push(`/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}`)
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const setButtonSpinner = useCallback(spinnerId => {
    dispatch(addButtonSpinner(spinnerId));
  });

  const extractButtonSpinner = useCallback(spinnerId => {
    dispatch(removeButtonSpinner(spinnerId));
  });

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    setTimeout(() => setIsDisabledFields(false), 500);
  }

  return translationService ? <div className="container mt-4">
    <div className="row">
      <div className="col-12">
        <h2 className="content-title p-0">
          <Link
            to={user && user.isOrganizationUser ? `/${language}/${ORGANIZATION_ADMIN_KEY}/${PAYMENT_PACKAGE_KEY}` : `#`}
            onClick={() => {
              if (!user || (user && !user.isOrganizationUser)) {
                window.history.back()
              }
            }}
            title={translationService.translate("TR_BACK")}
          ><ArrowBackSvg /></Link>
          {translationService.translate("TR_CUSTOM_PP")}
        </h2>
        <hr />
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        {
          totalPriceAsString ?
            <div className='mt-2 mb-3 text-right'>
              <p className='h4'><b>{translationService.translate("TR_TOTAL_PRICE")}:&nbsp;</b>{totalPriceAsString}</p>
            </div>
            : null
        }
        <form onSubmit={onSubmit} className="p-4 mb-4 border rounded">
          {
            packageProperties && packageProperties.length ?
              <Auxiliary>

                <div className='row'>
                  {
                    packageProperties.map((packageProperty, index) => {
                      if (!packageProperty.isPricePerUnit) {
                        return <div key={index} className="m-2 border rounded p-3">
                          <div className={`d-flex ${isDisabledFields ? "disabled" : ""}`}>
                            <InputCheckBox
                              id={packageProperty.id}
                              checked={packageProperty.isSelected}
                              blockClassName="mb-0"
                              labelValue={translationService.translate(packageProperty.displayName) ? translationService.translate(packageProperty.displayName) : packageProperty.displayName}
                              onChange={(event) => onCheckboxChange(event, packageProperty.id)}
                            />
                            <p className='mx-2'>{packageProperty.priceAsString}</p>
                          </div>
                        </div>
                      }
                    })
                  }
                </div>
                <div className='row'>
                  <div className='col-12'><hr /></div>
                </div>
                <div className='row'>
                  {
                    packageProperties.map((packageProperty, index) => {
                      if (packageProperty.isPricePerUnit) {
                        return <Auxiliary key={index}>
                          {
                            packageProperty.dependedPropertyId && !paymentPackagePropertiesModel.find(property => property.propertyId === packageProperty.dependedPropertyId) ? null
                              : <div className='m-2 border rounded p-3'>
                                <div className={`d-flex flex-column`}>
                                  <Input
                                    type="number"
                                    name="maxCost"
                                    blockClassName="mb-0"
                                    labelValue={translationService.translate(packageProperty.displayName) ? translationService.translate(packageProperty.displayName) : packageProperty.displayName}
                                    value={packageProperty.selectedUnitCount}
                                    disabled={isDisabledFields}
                                    isInvalidField={packageProperty.selectedUnitCount < packageProperty.minValue || packageProperty.selectedUnitCount > packageProperty.maxValue}
                                    onChange={(event) => {
                                      if (isDisabledFields) { return false; }
                                      onChangeValue(event, packageProperty.minValue, packageProperty.maxValue, packageProperty.id);
                                    }}
                                    onBlur={() => clearTimeout(timer)}
                                  />
                                  {
                                    typeof packageProperty.minValue === NUMBER_KEY && typeof packageProperty.maxValue === NUMBER_KEY ?
                                      <small className='mt-1'>{`${translationService.translate("TR_MIN_VALUE")}: ${packageProperty.minValue}`}, {`${translationService.translate("TR_MAX_VALUE")}: ${packageProperty.maxValue} `}</small>
                                      : null
                                  }
                                  <p className='my-2'>
                                    {`
                                        ${packageProperty.priceAsString} /
                                        ${translationService.translate(packageProperty.unitName) ? translationService.translate(packageProperty.unitName) : packageProperty.unitName}
                                        (${packageProperty.propertyTotalPriceAsString})
                                      `}
                                  </p>
                                </div>

                                <div className='d-flex flex-column'>
                                  {
                                    packageProperty.packagePropertyDiscounts && packageProperty.packagePropertyDiscounts.length ?
                                      <div>
                                        <p>{translationService.translate("TR_DISCOUNTS")}</p>
                                        {
                                          packageProperty.packagePropertyDiscounts.map((discount, index) => {
                                            return typeof discount.minValue === NUMBER_KEY && typeof discount.maxValue === NUMBER_KEY ?
                                              <small className='d-block' key={index}>
                                                {`${discount.minValue} - ${discount.maxValue} (${discount.discountPercent}%)`}
                                              </small>
                                              : null
                                          })
                                        }
                                      </div>
                                      : null
                                  }
                                </div>
                              </div>
                          }
                        </Auxiliary>
                      }
                    })
                  }
                </div>
              </Auxiliary>
              : null
          }


          {
            props.location.pathname.includes(FORM_KEY) ?
              <Auxiliary>
                <hr />
                <ActionButton
                  type="submit"
                  spinnerId={buttonSpinnerId}
                  name={translationService.translate("TR_CONTINUE")}
                  className="btn mindalay--btn-dark"
                />
              </Auxiliary>
              : null
          }
        </form>
      </div>
    </div>
  </div> : null
}

export default withRouter(CustomPaymentPackage)
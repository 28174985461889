import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { Link, withRouter } from "react-router-dom";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import {
	ADMIN_USER_TYPE,
	BOOLEAN_KEY,
	CURATOR_USER_TYPE,
	ERROR_KEY,
	INFO_KEY,
	SUCCESS_KEY,
	TR_YES,
	WARNING_KEY,
} from "../../../Constants/mainKeys";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import Input from "../../../Components/Inputs/input";
import ReactSelectOption from "../../../Components/SelectOption/reactSelectOption";
import ArrowBackSvg from "./../../../Components/Svg/arrowBackSvg";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import Textarea from "../../../Components/Inputs/textArea";
import ActionButton from "../../../Components/ActionButton/actionButton";
import ApiService from "../../../Services/apiService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const buttonSpinnerId = uuid();
const DivisionForm = props => {
	const { state } = useLocation();
	const { divisionId } = props.match.params;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [divisions, setDivisions] = useState([]);
	const [curators, setCurators] = useState([]);
	const [isDisabledDivision, setIsDisabledDivision] = useState(false);

	const fieldsLength = {
		nameMaxLength: 255,
		descMaxLength: 1000,
	};

	const [values, setValues] = useState({
		parentId: null,
		name: "",
		description: "",
		isStudentDivision: false,
		ownerUserId:
			(user && user.userTypeId === CURATOR_USER_TYPE) ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.length &&
				user.defaultUserTypeIds.includes(CURATOR_USER_TYPE))
				? user.id
				: null,
	});

	useEffect(() => {
		if (localStorage.getItem("parent")) {
			const parent = JSON.parse(localStorage.getItem("parent"));
			getAllDivisions(parent.ownerUserId);
			setValues(values => ({
				...values,
				ownerUserId: parent.ownerUserId,
				parentId: parent.parentId,
			}));
			setTimeout(() => {
				localStorage.removeItem("parent");
			}, 1);
		}
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (divisionId) {
			getDivisionsById(divisionId);
		}
		if (
			user.userTypeId === ADMIN_USER_TYPE ||
			(user.defaultUserTypeIds &&
				user.defaultUserTypeIds.length &&
				user.defaultUserTypeIds.includes(ADMIN_USER_TYPE))
		) {
			getCurators();
		}
	}, [divisionId]);

	useEffect(() => {
		values.ownerUserId && getAllDivisions(values.ownerUserId);
	}, [values.ownerUserId]);

	const getCurators = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getCurators()
			.then(response => {
				if (response && response.data && response.data.length) {
					setCurators(response.data);
				} else {
					AlertService.alert(INFO_KEY, "You don't have curators");
					setIsDisabledDivision(true);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getAllDivisions = ownerUserId => {
		const spinnerId = uuid();
		// dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getAllDivisions(ownerUserId)
			.then(response => {
				if (response && response.data) {
					setDivisions(response.data);
				}
				// dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getDivisionsById = divisionId => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getDivisionById(divisionId)
			.then(response => {
				if (response && response.data) {
					setValues(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onChange = (event, fieldname, maxLength = Infinity) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldname]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSelectOptionChange = (item, fieldName) => {
		if (!item) {
			return false;
		}
		setValues(values => ({
			...values,
			[fieldName]: item.value,
		}));
	};

	const onCheckboxChange = event => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			setValues(values => ({
				...values,
				[event.target.name]: event.target.checked,
			}));
			setIsInvalidSubmit(false);
		}
	};

	const onSubmit = event => {
		event && event.preventDefault();
		if (!values.name.trim().length) {
			setIsInvalidSubmit(true);
			return false;
		}
		if (!values.ownerUserId) {
			setIsInvalidSubmit(true);
			return false;
		}
		dispatch(addButtonSpinner(buttonSpinnerId));
		(divisionId
			? ApiService.updateDivision(values)
			: ApiService.createDivisions(values)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate("TR_DATA_SAVED"),
				);
				props.history.push(`/${language}/division`);
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row">
				<div className="col-12 content-title">
					<div className="d-flex align-items-center">
						<Link
							to={`/${language}/division`}
							title={translationService.translate("TR_BACK")}>
							<ArrowBackSvg />
						</Link>
						<h2 className="title">
							{!divisionId
								? translationService.translate("TR_CREATE_A_DIVISION")
								: translationService.translate("TR_UPDATE_DIVISION")}
						</h2>
					</div>
				</div>
			</div>
			<hr />
			<form onSubmit={onSubmit}>
				<div className="row">
					<div className="col-md-6 col-12 my-1">
						<Input
							type="text"
							id="name"
							name="name"
							inputClassName="pr--5"
							value={values.name}
							fieldLength={fieldsLength.nameMaxLength}
							isInvalidSubmit={isInvalidSubmit && !values.name.trim().length}
							labelValue={`${translationService.translate("TR_NAME")}*`}
							onChange={event =>
								onChange(event, "name", fieldsLength.nameMaxLength)
							}
						/>
					</div>

					{curators && curators.length ? (
						<div className="col-md-6 col-12 my-1">
							<label>{translationService.translate("TR_CHOOSE_CURATOR")}</label>
							<ReactSelectOption
								value={values.ownerUserId}
								isInvalidSubmit={isInvalidSubmit && !values.ownerUserId}
								isDisabled={divisionId}
								selectedValue={(() => {
									const selectedItem = {
										...curators.find(data => data.id === values.ownerUserId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.fullName;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={curators.map(data => ({
									...data,
									label: data.fullName,
									value: data.id,
								}))}
								onChange={item => onSelectOptionChange(item, "ownerUserId")}
							/>
						</div>
					) : null}
					{divisions && divisions.length ? (
						<div className="col-md-6 col-12 my-1">
							<label>
								{translationService.translate("TR_CHOOSE_PARENT_DIVISION")}
							</label>
							<ReactSelectOption
								value={values.parentId}
								isDisabled={divisionId}
								selectedValue={(() => {
									const selectedItem = {
										...divisions.find(data => data.id === values.parentId),
									};
									if (Object.keys(selectedItem).length) {
										selectedItem.label = selectedItem.name;
										selectedItem.value = selectedItem.id;
										return selectedItem;
									} else {
										return {
											value: null,
											label: translationService.translate("TR_CHOOSE"),
										};
									}
								})()}
								items={divisions
									.filter(item => item.id !== +divisionId)
									.map(data => ({ ...data, label: data.name, value: data.id }))}
								onChange={item => onSelectOptionChange(item, "parentId")}
							/>
						</div>
					) : null}
					{(!state || !state.length) && (
						<div className="col-md-6 col-12 mt-1">
							<label htmlFor="isStudentDivision" className="cursor-pointer">
								{translationService.translate("TR_STUDENT_DIVISION")}
							</label>
							<InputCheckBox
								id="isStudentDivision"
								name="isStudentDivision"
								checked={values.isStudentDivision}
								labelValue={translationService.translate(TR_YES)}
								onChange={onCheckboxChange}
							/>
						</div>
					)}

					<div className="col-12 mt-3">
						<Textarea
							rows="3"
							id="description"
							name="description"
							textAreaClassName="pr--5"
							value={values.description}
							max={fieldsLength.descMaxLength}
							labelValue={translationService.translate("TR_DESCRIPTION")}
							onChange={event =>
								onChange(event, "description", fieldsLength.descMaxLength)
							}
						/>
					</div>

					<div className="col-12">
						<div className="mt-3">
							<ActionButton
								spinnerId={buttonSpinnerId}
								type="submit"
								disabled={isDisabledDivision}
								className="mindalay--btn-dark position-relative"
								name={translationService.translate(
									!divisionId ? "TR_CREATE" : "TR_UPDATE",
								)}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	) : null;
};

export default withRouter(DivisionForm);

import React from 'react'

export default function cryllex(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 188.87 53.85"
      style={{ width: props.width ? props.width : "" }}
    >
      <title>cryllex</title>
      <path style={{ fill: props.fill ? props.fill : "" }} d="M29.16,32.25a12.31,12.31,0,0,1-8.86,3.66c-7.21,0-11.7-5.1-11.7-13.3S13.17,9.48,20.51,9.48a11,11,0,0,1,7.65,3l1.07,1L34.3,8.36,33.17,7.3A18.84,18.84,0,0,0,20.42,2.43C8.21,2.43,0,10.59,0,22.73S8.07,43,20.08,43A19.16,19.16,0,0,0,34.21,37.3l1.09-1.07-5.1-5Z" />
      <path style={{ fill: props.fill ? props.fill : "" }} d="M77.75,28.32c-.41,1.13-.83,2.35-1.26,3.58l-.2.59c-.59-1.47-1.18-2.92-1.75-4.26L67.71,12.48H59a9.83,9.83,0,0,0-3.93-.66,11.44,11.44,0,0,0-8,3.46l-.26-2.8H39.57V42.3h8.34V23.92c2.25-4.51,5.28-5,6.49-5a9.1,9.1,0,0,1,2.68.39l1.47.4,1.28-5.13L72.27,40.93l-.5,1.35c-1.25,3.19-3.17,4.81-5.72,4.81a8.1,8.1,0,0,1-2-.34l-1.44-.42-1.87,6.45,1.38.45a13.65,13.65,0,0,0,4.17.62c8,0,11.36-5.86,13.21-10.27l12.75-31.1H83.75Z" />
      <path style={{ fill: props.fill ? props.fill : "" }} d="M104.38,36.1a3.75,3.75,0,0,1-.84.08,1.67,1.67,0,0,1-.05-.44V0H95.14V35.41C95.14,41.65,99,43,102.3,43a11.35,11.35,0,0,0,3.51-.49l1.31-.4-1.3-6.24Z" />
      <path style={{ fill: props.fill ? props.fill : "" }} d="M121.46,36.1a3.67,3.67,0,0,1-.83.08,1.25,1.25,0,0,1-.06-.44V0h-8.34V35.41c0,6.24,3.89,7.55,7.15,7.55a11.25,11.25,0,0,0,3.51-.49l1.31-.4-1.3-6.24Z" />
      <path style={{ fill: props.fill ? props.fill : "" }} d="M152.59,33.7l-1.23.69a14,14,0,0,1-7.12,2c-4.63,0-7.86-2.4-8.77-6.37H156.2l.27-1.16a13.86,13.86,0,0,0,.29-2.92c0-8.68-5.4-14.08-14.1-14.08A15.51,15.51,0,0,0,127,27.43C127,36.57,133.8,43,143.58,43a22.46,22.46,0,0,0,11.16-3l1.39-.78Zm-3.64-10h-13.4a7.4,7.4,0,0,1,7.2-5.29C146.43,18.44,148.51,20.22,149,23.73Z" />
      <path style={{ fill: props.fill ? props.fill : "" }} d="M177.38,27.25,188,12.48h-9.3l-4.31,6.57q-.7,1.1-1.41,2.22l-.37.57c-.67-.95-1.32-1.9-1.94-2.85L166,12.48h-9.55L167.1,26.81,155.68,42.3H165l4.74-6.9,1.07-1.64,1.05-1.6c.86,1.17,1.65,2.33,2.32,3.3l5.1,6.84h9.58Z" /></svg>
  )
}

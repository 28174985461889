import React from 'react'

export default function ResourceCount({ count = 0, size = "md" }) {
  return count ?
      <div className={`gamify-resource-count-block gamify-resource-count-success gamify-resource-count-block-${size}`}>
        <div className='gamify-resource-count-sub-block'>
          <span>{count}</span>
        </div>
      </div>
      : <div className={`gamify-resource-count-block gamify-resource-count-danger gamify-resource-count-block-${size}`}>
        <div className='gamify-resource-count-sub-block'>
          <span>{count}</span>
        </div>
      </div>
}

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../Services/translationService";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { setCurrentCourse } from "../../Store/Actions/course";
import { ERROR_KEY, TR_NO, TR_YES } from "../../Constants/mainKeys";
import { addPageSpinner, removePageSpinner } from "../../Store/Actions/spinner";
import { COURSES_KEY, COURSE_KEY } from "../../Constants/urlKeys";
import uuid from "react-uuid";
import EditSvg from "../Svg/editSvg";
import DeleteSvg from "../Svg/deleteSvg";
import ViewSvg from "../Svg/viewSvg";
import CopySvg from "./../Svg/copy";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import { SUCCESS_KEY } from "../../Constants/requestKeys";

const InstructorCourseBlock = props => {
	const dispatch = useDispatch();
	const courseData = props.course;

	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const editCourseHandler = () => {
		getCourseById();
	};

	const getCourseById = () => {
		const spinnerId = uuid();
		dispatch(addPageSpinner(spinnerId));
		ApiService.getCourseById(courseData.id)
			.then(response => {
				if (response && response.data) {
					const data = { ...response.data };
					// if (data && data.sections && data.sections.length) {
					//   data.sections.forEach(section => {
					//     if (section.topics && section.topics.length) {
					//       section.topics.forEach(topic => {
					//         topic.imagePath = topic.imagePath ? `${topic.imagePath}?${uuid()}` : null;
					//       })
					//     }
					//   })
					// }
					setCurrentCourseHandler(data);
					props.history.push(props.courseDashboardLink);
				}
				dispatch(removePageSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const courseCopy = courseData => {
		if (!courseData) {
			return false;
		}
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService
				.translate("TR_COURSE_COPY_CONFIRM_MSG")
				.replace("{0}", `"${courseData.name}"`)} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPageSpinner(spinnerId));
			ApiService.courseCopy(+courseData.id)
				.then(() => {
					dispatch(removePageSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_COURSE_COPIED_SUCCESSFULLY"),
					);
					props.getCourses && props.getCourses();
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const setCurrentCourseHandler = useCallback(course => {
		dispatch(setCurrentCourse(course));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePageSpinner(spinnerId));
	};

	const ratingPercent = Math.round(courseData.rating * 100) / 5;
	return translationService && courseData ? (
		<div
			className={`course-wrapper--instructor d-flex flex-column justify-content-between ${
				user && user.isOrganizationUser ? "cursor-pointer" : ""
			}`}
			onClick={
				user && user.isOrganizationUser
					? () =>
							props.history.push(`/${language}/${COURSE_KEY}/${courseData.id}`)
					: null
			}>
			<div className="course-image-wrapper--instructor d-flex flex-column justify-content-between">
				<div className="course-button-group">
					{user && user.isOrganizationUser ? null : (
						<Auxiliary>
							<Link
								className="course-view-button"
								to="#"
								title={translationService.translate("TR_VIEW")}
								onClick={() => {
									props.history.push(
										`/${language}/${COURSES_KEY}/${courseData.id}`,
									);
								}}>
								<ViewSvg />
							</Link>
							<Link
								className="course-edit-button"
								to="#"
								title={translationService.translate("TR_EDIT")}
								onClick={() => editCourseHandler(courseData)}>
								<EditSvg />
							</Link>
							<Link
								className="course-edit-button"
								to="#"
								title={translationService.translate("TR_COPY_COURSE")}
								onClick={() => courseCopy(courseData)}>
								<CopySvg />
							</Link>
							<Link
								className="course-delet-button"
								to="#"
								title={translationService.translate("TR_DELETE")}
								onClick={() => props.delete(courseData)}>
								<DeleteSvg />
							</Link>
						</Auxiliary>
					)}
				</div>
				{user && user.isOrganizationUser ? (
					<Auxiliary>
						{courseData.isBlocked ? (
							<span className="course-status--instructor course-denied--instructor">
								{translationService.translate("TR_BLOCKED")}
							</span>
						) : null}
						{courseData.isPaused ? (
							<span className="course-status--instructor course-submit-verified--instructor">
								{translationService.translate("TR_PAUSED")}
							</span>
						) : null}
						{courseData.isPaused ? (
							<span className="course-status--instructor course-live--instructor">
								{translationService.translate("TR_PAID_COURSE")}
							</span>
						) : null}
					</Auxiliary>
				) : (
					<Auxiliary>
						{courseData.published ? (
							<span className="course-status--instructor course-live--instructor">
								{translationService.translate("TR_LIVE")}
							</span>
						) : null}
						{courseData.submitedForVerification ? (
							<span className="course-status--instructor course-submit-verified--instructor">
								{translationService.translate("TR_SUBMIT_FOR_VERIFICATION")}
							</span>
						) : null}
						{courseData.verificationDenied ? (
							<span className="course-status--instructor course-denied--instructor">
								{translationService.translate("TR_DENIED")}
							</span>
						) : null}
						{courseData.verified && !courseData.published ? (
							<span className="course-status--instructor course-verified--instructor">
								{translationService.translate("TR_VERIFIED")}
							</span>
						) : null}
						{!courseData.published &&
						!courseData.submitedForVerification &&
						!courseData.verificationDenied &&
						!courseData.verified ? (
							<span className="course-status--instructor course-inprocess--instructor">
								{translationService.translate("TR_IN_PROCESS")}
							</span>
						) : null}
					</Auxiliary>
				)}
				<div
					style={{ backgroundImage: `url(${courseData.imagePath})` }}
					className="course-image"></div>
			</div>
			<div className="course-body-wrapper--instructor">
				<div className="p-2 border-bottom">
					<h6
						className="course-name--instructor"
						title={courseData.name}
						onClick={() =>
							props.history.push(`/${language}/${COURSES_KEY}/${courseData.id}`)
						}>
						{courseData.name}
					</h6>
					{user && user.isOrganizationUser ? (
						<Auxiliary>
							<hr />
							<div className="progress my-3">
								<div
									className="progress-bar"
									style={{ width: `${courseData.completionPercentage}%` }}
									role="progressbar"
									aria-valuenow="75"
									aria-valuemin="0"
									aria-valuemax="100"></div>
							</div>
							<div className="text-right">
								<p className="red-color font-default">
									{translationService.translate("TR_COMPLETED")}:{" "}
									<span className="font-bold">
										{courseData.completionPercentage} %
									</span>
								</p>
							</div>
						</Auxiliary>
					) : null}
					{user && user.isOrganizationUser ? null : (
						<div className="course-price-wrapper--instructor">
							<div className="d-flex align-items-baseline">
								<p>
									{!courseData.cost || courseData.discount === 100
										? translationService.translate("TR_FREE")
										: courseData.cost}
								</p>
								{courseData.oldCost ? (
									<p className="course-old-price--instructor">
										{courseData.oldCost}
									</p>
								) : null}
							</div>
							<p className="small-text text-capitalize">
								{translationService.translate("TR_COURSE_PRICE")}
							</p>
						</div>
					)}
				</div>
				{user && user.isOrganizationUser ? null : (
					<div className="course-month-infor--instructor course-info--instructor border-bottom">
						{courseData.earnedThisMonth ? (
							<Auxiliary>
								<span className="course-review-number--instructor mr-1">
									{courseData.earnedThisMonth}
								</span>
								<p className="small-text text-capitalize">
									{translationService.translate("TR_EARNED_THIS_MONTH")}
								</p>
							</Auxiliary>
						) : (
							<Auxiliary>
								<span className="course-review-number--instructor mr-1">
									{translationService.translate("TR_FREE")}
								</span>
								<p className="small-text text-capitalize">{"\u00A0"}</p>
							</Auxiliary>
						)}
					</div>
				)}
				{user && user.isOrganizationUser ? null : (
					<div className="course-review-wrapper--instructor course-info--instructor border-bottom">
						<div className="d-flex align-items-center">
							<span className="course-review-number--instructor mr-1">
								{courseData.rating}
							</span>
							<div className="rating">
								<div style={{ width: `${ratingPercent}%` }}></div>
							</div>
							<span className="course-review-count--instructor">
								({courseData.approvedTotalReviews || 0})
							</span>
						</div>
						<p className="small-text text-capitalize">
							{translationService.translate("TR_COURSE_RATING")}
						</p>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default withRouter(InstructorCourseBlock);

import React from 'react'

export default function DiaryDocumentView(props) {

  const { documentData } = props;

  if (documentData.data && JSON.parse(documentData.data)) {
    var {
      a1,
      a2,
      a3,
      a4,
      a5,
      a6,
      a7,
      a8,
      a15,
      a17,
      tableData
    } = JSON.parse(documentData.data);
  }
  
  return (documentData.signFilePath || documentData.filePath ?
    <div className='col-12'>
      <div id='document' className='my-5'>
        <img src={documentData.signFilePath ? documentData.signFilePath : documentData.filePath} alt="/" style={{ width: "100%", maxWidth: "100%", height: "auto" }} />
      </div>
    </div>
    : <div className='row'>
      <div className='col-12'>
        <div id='document' className='my-5 d-flex justify-content-center'>
          <div className='document-body'>
            <p className='text-center'>{a1}</p>
            <h1 className="template-title mt-1">ДНЕВНИК</h1>
            <h3 className="center template-sub-title margin-h3 mt-2">
              <div className='d-flex align-items-baseline justify-content-center'>
                <span className='mr-3'>прохождения</span>
                <span className='word-break-break-word'>{a2}</span>
                <span className='ml-3'>практики</span>
              </div>
            </h3>
            <div className='d-flex align-items-baseline'>
              <span className='mr-3'>Обучающегося группы </span>
              <span>{a3}</span>
            </div>
            <h2 className="center selected-font mt-3">
              {a4}
            </h2>
            <p className="flex-wrap mt-4">Место прохождения практики<br />
              <span>{a5}</span>
            </p>
            <p className="flex-wrap">(полное наименование предприятия, учреждения, организации)</p>

            <p className="flex-wrap mt-3 mb-3">Руководитель практики
              <span className='ml-3'>{a6}</span>
            </p>
            <p className="d-flex mt-4">Период прохождения практики с
              
              {
                a7 ? <span className='mx-3'>{a7}</span> : <span className="empty-row d-block"></span>
              }
              <span className="mx-1">до</span>
              {
                a8 ? <span className='mx-3'>{a8}</span> : <span className="empty-row d-block"></span>
              }
              
            </p>
            <div className='table-block mt-3'>
              <div className="table-responsive table-bordered border-radius-10">
                <table className="table mb-0" style={{ maxWidth: "100%" }}>
                  <thead className="thead-dark">
                    <tr>
                      <td>№</td>
                      <td>Дата</td>
                      <td>Время работы</td>
                      <td>Краткое описание<br />
                        выполненной работы</td>
                      <td>Оценка за<br />
                        выполненную<br />
                        работу
                      </td>
                      <td>Подпись<br />
                        руководителя</td>
                        <td>Действия</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData && tableData.length ?
                        tableData.map((tableDataItem, index, tableData) => {
                          var nextElement = tableData[index + 1];
                          if ((nextElement && nextElement.number !== tableDataItem.number) || !nextElement) {
                            return <tr key={index}>
                              <td className='py-2'>{(index + 1) / 5}</td>
                              <td>
                                <span>
                                  {
                                    tableData.find(element => element.field_name === `date_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `date_${tableDataItem.number}`).value :
                                      ""
                                  }
                                </span>
                              </td>
                              <td>
                                {
                                  tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`) &&
                                    tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`).value &&
                                    tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`) &&
                                    tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`).value ?
                                    <div className="flexable d-flex">
                                      <span>
                                        {
                                          tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`) ?
                                            tableData.find(element => element.field_name === `start_time_${tableDataItem.number}`).value :
                                            ""
                                        }
                                      </span> -
                                      <span>
                                        {
                                          tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`) ?
                                            tableData.find(element => element.field_name === `end_time_${tableDataItem.number}`).value :
                                            ""
                                        }
                                      </span>
                                    </div>
                                    : null
                                }

                              </td>
                              <td className='word-break-break-word'>
                                <span>
                                  {
                                    tableData.find(element => element.field_name === `short_description_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `short_description_${tableDataItem.number}`).value :
                                      ""
                                  }
                                </span>
                              </td>
                              <td className='px-2 word-break-break-word'>
                                <span>
                                  {
                                    tableData.find(element => element.field_name === `grade_${tableDataItem.number}`) ?
                                      tableData.find(element => element.field_name === `grade_${tableDataItem.number}`).value :
                                      ""
                                  }
                                </span>
                              </td>
                              <td><div></div></td>
                              <td><div></div></td>
                            </tr>
                          }
                        })
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex-wrap mt-7 d-flex">
              <span>Практикант</span>
              <span className="empty-row d-block ml-2"></span>
              <span className='ml-2'>{a15}</span>
            </div>
            <p className="flex-wrap mt-3">Содержание и объем выполненных работ подтверждаю.</p>
            <div className="flex-wrap mt-3 mb-3 d-flex">
              <span>Руководитель практики</span>
              <span className="empty-row d-block ml-2"></span>
              <span className='ml-2'>{a17}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

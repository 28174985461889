import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import FragmenetsModal from "../Components/Modals/FragmenetsModal";
import { Link } from "react-router-dom";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "../../../Services/gamifyApiService";
import AlertService from "../../../Services/alertService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import { ERROR_KEY } from "../../../Constants/mainKeys";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import ResourceCount from "./Components/ResourceCount";

const buttonSpinnerId = uuid();
const ResourcesAsStudent = props => {
	const dispatch = useDispatch();
	const { studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [resources, setResources] = useState([]);
	const [selectedResource, setSelectedResource] = useState(null);
	const [isDisabledFields, setIsDisabledFields] = useState(false);

	useEffect(() => {
		getStudentResources();
		return () => {
			setResources([]);
		};
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getStudentResources = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			userId: studentId
				? studentId.toString()
				: user.studentId
				? user.studentId.toString()
				: null,
			resourceImageSize: 3,
			fragmentImageSize: 2,
		};
		GamifyApiService.getStudentResources(data)
			.then(response => {
				if (response && response.data) {
					setResources(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		setIsDisabledFields(false);
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="content-title p-0">
						<Link
							to={`#`}
							title={translationService.translate("TR_BACK")}
							onClick={() => window.history.back()}>
							<ArrowBackSvg />
						</Link>
						{translationService.translate("TR_RESOURCES")}
					</h2>
					<PageInfoBlock
						pageTitle="Student resources"
						pageName="student_resources"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-self-stretch">
				{resources && resources.length ? (
					resources.map((item, index) => {
						return (
							<div key={index} className="col-12 col-md-6 col-lg-3 p-2">
								<Card className="w-100 h-100 position-relative overflow-hidden">
									<ResourceCount count={item.resourceAmount} />
									<div
										className="w-100"
										style={{
											backgroundImage: `url(${item.resource?.imagePath})`,
											height: "200px",
											backgroundPosition: "center",
											backgroundSize: "contain",
											backgroundRepeat: "no-repeat",
										}}
									/>
									<Card.Body className="d-flex flex-column justify-content-between p-2">
										<div>
											<h3
												className="title max-line-2"
												title={item.resource?.name}>
												{item.resource?.name}
											</h3>
											<p
												className="max-line-3"
												title={item.resource?.description}>
												{item.resource?.description}
											</p>
											<hr />
											{!item.isFragmental ? (
												<p className="d-flex justify-content-between mb-2">
													<b>{translationService.translate("TR_RATE")}</b>
													<b>{item.resource?.rate}</b>
												</p>
											) : null}
											<p className="d-flex justify-content-between mb-2">
												<b>{translationService.translate("TR_ISDEFAULT")}</b>
												{item.resource?.isDefault ? (
													<i
														className="fa-regular fa-circle-check text-success"
														style={{ fontSize: "20px" }}></i>
												) : (
													<i
														className="fa-regular fa-circle-xmark text-danger"
														style={{ fontSize: "20px" }}></i>
												)}
											</p>
											<p className="d-flex justify-content-between mb-2">
												<b>{translationService.translate("TR_FRAGMENTAL")}</b>
												{item.resource?.isFragmental ? (
													<i
														className="fa-regular fa-circle-check text-success"
														style={{ fontSize: "20px" }}></i>
												) : (
													<i
														className="fa-regular fa-circle-xmark text-danger"
														style={{ fontSize: "20px" }}></i>
												)}
											</p>
										</div>
										<div>
											{item.resource?.isFragmental ? (
												<button
													className="btn mindalay--btn-dark w-100 py-2 mt-3"
													to={`#`}
													onClick={() => setSelectedResource(item)}>
													{translationService.translate("TR_SHOW_FRAGMENTS")}
												</button>
											) : null}
										</div>
									</Card.Body>
								</Card>
							</div>
						);
					})
				) : (
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				)}

				{selectedResource ? (
					<FragmenetsModal
						selectedResource={selectedResource}
						setSelectedResource={setSelectedResource}
						translationService={translationService}
					/>
				) : null}
			</div>
		</div>
	) : null;
};

export default withRouter(ResourcesAsStudent);

import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
	addModalSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	CURATOR_USER_TYPE,
	IMAGE_KEY,
	FILE_KEY,
} from "../../Constants/mainKeys";
import TranslationService from "../../Services/translationService";
import { withRouter } from "react-router";
import {
	STUDENT_KEY,
	COURSES_KEY,
	DOCUMENTS_KEY,
} from "../../Constants/urlKeys";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ModalComponentWithoutActionButton from "../../Components/ModalComponent/modalComponentWithoutActionButton";
import MainService from "../../Services/mainService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import FileSvg from "./../../Components/Svg/fileSvg";
import ImageSvg from "./../../Components/Svg/imageSvg";
import CloseSvg from "./../../Components/Svg/closeSvg";
import DownloadSvg from "./../../Components/Svg/downloadSvg";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import GamifyApiService from "../../Services/gamifyApiService";

const Students = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const [translationService, setTranslationService] = useState(null);
	const [students, setStudents] = useState([]);
	const [groups, setGroups] = useState([]);
	const [groupId, setGroupId] = useState(null);
	const [isShowModal, setIsShowModal] = useState(false);
	const [studentData, setStudentData] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [userDocuments, setUserDocuments] = useState([]);
	const [zoomImagePath, setZoomImagePath] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getStudents(1);
		window.addEventListener("keydown", zoomOut);
		return () => {
			window.removeEventListener("keydown", zoomOut);
		};
	}, []);

	const handlePageClick = event => {
		getStudents(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getStudents = currentPage => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		// currentPage, pageSize
		(user.userTypeId === CURATOR_USER_TYPE
			? ApiService.getStudentsByCurator()
			: ApiService.getStudentsByLecturer()
		)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				response.data && setStudents(response.data);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const sortStudentsByGroupId = selectedValue => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		setGroupId(selectedValue.id);
		ApiService.getStudentsByOrganizationId(selectedValue.id)
			.then(response => {
				setStudents(response?.data);
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getUserInformation = student => {
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		ApiService.getStudentDataById(student.id)
			.then(response => {
				if (response.data) {
					response.data.student && setStudentData(response.data.student);
					response.data.documents && setUserDocuments(response.data.documents);
					setIsShowModal(true);
				}
				dispatch(removeModalSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const cancel = () => {
		setStudentData(null);
		setIsShowModal(false);
	};

	const zoomImage = filePath => {
		setZoomImagePath(filePath);
	};

	const zoomOut = event => {
		if (event.key === "Escape") {
			hideZoomImage();
		}
	};

	const hideZoomImage = () => {
		setZoomImagePath(null);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		cancel();
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_STUDENTS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_STUDENTS")}
						pageName="curator_lecturer_selection_committee_studens"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{zoomImagePath ? (
				<div className="zoom-image-container">
					<div className="close-svg d-flex">
						<span className="mr-4 d-block">
							<a
								href={MainService.getDownloadUrl(zoomImagePath)}
								target="blank"
								className="float-right brand-color">
								<DownloadSvg width={"35px"} />
							</a>
						</span>
						<CloseSvg onClick={hideZoomImage} />
					</div>
					<img
						src={zoomImagePath}
						alt="/"
						onClick={event => event.stopPropagation()}
					/>
				</div>
			) : null}
			{studentData && isShowModal ? (
				<ModalComponentWithoutActionButton
					title={studentData.studentName}
					cancel={cancel}>
					<div className="row">
						<div className="col-12">
							{studentData.avatarFilePath ? (
								<div className="org-student-info-block">
									<img src={studentData.avatarFilePath} alt="/" />
									<hr />
								</div>
							) : null}
							<div className="org-student-info-block-item">
								{studentData.personalDocumentNumber ? (
									<Auxiliary>
										<div className="d-flex justify-content-between mx-1">
											<b className="capitalize">{`${translationService.translate(
												"TR_PERSONAL_DOC_NUMBER",
											)}:`}</b>
											<span className="word-break-break-word">
												{studentData.personalDocumentNumber}
											</span>
										</div>
										<hr />
									</Auxiliary>
								) : null}
								{studentData.studentName ? (
									<Auxiliary>
										<div className="d-flex justify-content-between mx-1">
											<b className="capitalize">{`${translationService.translate(
												"TR_USERNAME",
											)}:`}</b>
											<span className="word-break-break-word">
												{studentData.username}
											</span>
										</div>
										<hr />
									</Auxiliary>
								) : null}
								{studentData.groupName ? (
									<Auxiliary>
										<div className="d-flex justify-content-between mx-1">
											<b className="capitalize">{`${translationService.translate(
												"TR_GROUP_NAME",
											)}:`}</b>
											<span className="word-break-break-word">
												{studentData.groupName}
											</span>
										</div>
										<hr />
									</Auxiliary>
								) : null}
								{studentData.studentEmail ? (
									<Auxiliary>
										<div className="d-flex justify-content-between mx-1">
											<b className="capitalize">{`${translationService.translate(
												"TR_EMAIL",
											)}:`}</b>
											<span className="word-break-break-word">
												{studentData.studentEmail}
											</span>
										</div>
										<hr />
									</Auxiliary>
								) : null}
								{studentData.createDate ? (
									<Auxiliary>
										<div className="d-flex justify-content-between mx-1">
											<b className="capitalize">{`${translationService.translate(
												"TR_CREATE_DATE",
											)}:`}</b>
											<span className="word-break-break-word">
												{moment(
													MainService.convertUTCDateToLocalDate(
														new Date(studentData.createDate),
													),
												).format("LL")}
											</span>
										</div>
										<hr />
									</Auxiliary>
								) : null}
								<Auxiliary>
									<div className="d-flex justify-content-between mx-1">
										<b className="capitalize">
											{translationService.translate("TR_IS_ARCHIVED")}
										</b>
										<span className="word-break-break-word">
											{studentData.isArchived
												? translationService.translate("TR_YES")
												: translationService.translate("TR_NO")}
										</span>
									</div>
									<hr />
								</Auxiliary>
								{/* 
								<Auxiliary>
									<div className="d-flex justify-content-between mx-1">
										<b className="capitalize">Is Belong To Current Group</b>
										<span className="word-break-break-word">
											{studentData.isBelongToCurrentGroup
												? translationService.translate("TR_YES")
												: translationService.translate("TR_NO")}
										</span>
									</div>
									<hr />
								</Auxiliary> */}
							</div>
						</div>
					</div>
					<div className="d-flex">
						{userDocuments.map((document, index) => {
							let fileType = "";
							if (document.mimeType && document.mimeType.includes(IMAGE_KEY)) {
								fileType = IMAGE_KEY;
							} else if (
								document.mimeType &&
								(document.mimeType.includes("pdf") || //pdf
									document.mimeType.includes("text") || //txt
									document.mimeType.includes("application"))
							) {
								fileType = FILE_KEY;
							}
							return (
								<div key={index} className="section-content flex-1">
									{(() => {
										switch (fileType) {
											case IMAGE_KEY:
												return (
													<div>
														<div className="mindalay-file-wrapper mindalay-video p-1">
															<ImageSvg width="60px" />
															<p
																className="cursor-pointer"
																onClick={() => zoomImage(document.filePath)}>
																{" "}
																{document.name}
															</p>
														</div>
													</div>
												);
											case FILE_KEY:
												return (
													<div>
														<div className="mindalay-file-wrapper mindalay-video p-1">
															<FileSvg width="60px" />
															<a
																target="blank"
																href={document.filePath}
																className="file-item text-secondary px-1 pt-1 d-block word-break-break-word">
																{document.name}
															</a>
														</div>
													</div>
												);
											default:
												break;
										}
									})()}
								</div>
							);
						})}
					</div>
				</ModalComponentWithoutActionButton>
			) : null}
			{groups && groups.length ? (
				<div className="row">
					<div className="col-lg-4 col-12">
						<div className="form-group">
							<label>{`${translationService.translate(
								"TR_SORT_STUDENTS_BY_GROUP",
							)}`}</label>
							<ReactSelectOption
								placeholder={translationService.translate("TR_GROUPS")}
								items={groups.map(data => ({
									...data,
									label: data.groupName,
									value: data.id,
								}))}
								onChange={item => sortStudentsByGroupId(item)}
							/>
						</div>
					</div>
				</div>
			) : null}
			{students && students.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th>{translationService.translate("TR_NAME")}</th>
										<th>{translationService.translate("TR_EMAIL")}</th>
										<th>{translationService.translate("TR_GROUP_NAME")}</th>
										{/* <th>{translationService.translate("TR_PERSONAL_DOC_NUMBER")}</th> */}
										<th>{translationService.translate("TR_ACTIONS")}</th>
									</tr>
								</thead>
								<tbody>
									{students.map((student, index) => {
										return (
											<tr key={index} className="cursor-default">
												<td>{student.studentName}</td>
												<td>{student.studentEmail}</td>
												<td>{student.groupName}</td>
												{/* <td>{student.personalDocumentNumber}</td> */}
												<td>
													<div className="d-flex">
														<button
															className="mindalay--btn mindalay--btn-small mindalay--btn-default mr-1"
															onClick={() =>
																props.history.push(
																	`/${language}/${STUDENT_KEY}/${DOCUMENTS_KEY}/${student.userId}`,
																)
															}>
															{translationService.translate("TR_DOCUMENTS")}
														</button>
														<button
															className="mindalay--btn mindalay--btn-small mindalay--btn-default mr-1"
															onClick={() =>
																props.history.push(
																	`/${language}/${STUDENT_KEY}/${COURSES_KEY}/${student.id}`,
																)
															}>
															{translationService.translate("TR_COURSES")}
														</button>
														<button
															className="mindalay--btn mindalay--btn-small mindalay--btn-default ml-1"
															onClick={() => getUserInformation(student)}>
															{translationService.translate("TR_DETAILS")}
														</button>
														{user.isGamifyAvailable ? (
															<button
																className="mindalay--btn mindalay--btn-small mindalay--btn-default ml-1"
																onClick={() =>
																	props.history.push(
																		`/${language}/gamification/dashboard/${student.id}`,
																	)
																}>
																{translationService.translate("TR_GAMIFY_DATA")}
															</button>
														) : null}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div>
					<div className="no-data-container">
						<div className="no-data-wrapper">
							<p>
								{translationService.translate(
									!groupId
										? "TR_NO_STUDENTS_IN_GROUP"
										: "TR_NO_STUDENTS_IN_THIS_GROUP",
								)}
							</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				</div>
			)}

			{pagination ? (
				<div className="row my-4">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(Students);

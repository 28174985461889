import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModalComponentWithoutActionButton from "../../../../Components/ModalComponent/modalComponentWithoutActionButton";
import { Card } from "react-bootstrap";
import TranslationService from "../../../../Services/translationService";
import { useSelector } from "react-redux";
import NoDataImage from "./../../../../assets/images/illustrations/nodata.svg";
import AlertService from "../../../../Services/alertService";
import {
	ERROR_KEY,
	GAMIFY_GROUP_ENTITY_TYPE_ID,
	GAMIFY_SECTION_ENTITY_TYPE_ID,
	STUDENT_USER_TYPE,
} from "../../../../Constants/mainKeys";
import GamifyApiService from "../../../../Services/gamifyApiService";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";
import {
	addModalSpinner,
	removeModalSpinner,
} from "../../../../Store/Actions/spinner";
import MainService from "./../../../../Services/mainService";
import moment from "moment";

export default function GamificationItemsComponent(props) {
	const dispatch = useDispatch();
	const { entityData, cancel, gamifyData, entityTypeId } = props;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const generateGamifyToken = (scopeType, type, itemId) => {
		if (!scopeType) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		let data = null;
		if (entityTypeId === GAMIFY_GROUP_ENTITY_TYPE_ID) {
			data = {
				entityTypeId,
				groupId: +entityData.id,
				scopeType,
			};
		} else {
			data = {
				entityTypeId,
				groupId: null,
				entityId: +entityData.id,
				scopeType,
			};
		}
		GamifyApiService.generateGamifyToken(data)
			.then(response => {
				if (response && response.data) {
					let redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/${type}/${response.data}`;
					if (itemId && (type === "badges" || type === "leaderboard")) {
						redirectUrl = `${process.env.REACT_APP_GAMIFICATION_URL}/${type}/${response.data}/form/${itemId}`;
					}
					window.open(redirectUrl, "_blank");
				}
				dispatch(removeModalSpinner(spinnerId));
				cancel();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removeModalSpinner(spinnerId));
	};
	return entityData && translationService && gamifyData ? (
		<ModalComponentWithoutActionButton
			isLarge={true}
			title={entityData.name || entityData.title}
			titleClassName="max-line-3"
			cancel={cancel}>
			{entityTypeId === GAMIFY_SECTION_ENTITY_TYPE_ID ||
			entityTypeId === GAMIFY_GROUP_ENTITY_TYPE_ID ? null : (
				<>
					<div className="row align-self-stretch">
						<div className="col-12">
							<div className="d-flex justify-content-between align-items-center">
								<h3 className="title">
									{translationService.translate("TR_QUEST")}
								</h3>
								{!!gamifyData.quest ||
								user.userTypeId === STUDENT_USER_TYPE ? null : (
									<button
										type="button"
										className={`mindalay--btn-primary-outline mindalay--btn-small my-sm-0 my-2`}
										onClick={() => generateGamifyToken(2, "quest")}>
										+ {translationService.translate("TR_ADD_QUEST")}
									</button>
								)}
							</div>
							{!!gamifyData.quest ? (
								<div className="col-12 col-md-6 col-lg-4 p-2">
									<Card className="w-100 h-100">
										<div
											className="w-100"
											style={{
												backgroundImage: `url(${gamifyData.quest.imagePath})`,
												height: "200px",
												backgroundPosition: "center",
												backgroundSize: "contain",
												backgroundRepeat: "no-repeat",
											}}
										/>
										<Card.Body className="p-2">
											<h3
												className="title pb-0 pt-2 max-line-3"
												style={{ fontSize: "18px", fontWeight: "500" }}
												title={gamifyData.quest.name}>
												{gamifyData.quest.name}
											</h3>
											<hr className="mb-0" />
											<p className="max-line-2">
												{gamifyData.quest.description}
											</p>
											<div className="mt-3">
												{user.userTypeId === STUDENT_USER_TYPE ? null : (
													<button
														className="mindalay--btn-link mindalay--btn-secondary mt-1 w-100 py-2"
														onClick={() => generateGamifyToken(2, "quest")}>
														{translationService.translate("TR_EDIT")}
													</button>
												)}
												<Link
													className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
													to={`/${language}/gamification/quest/${gamifyData.quest.id}`}
													target="_blank">
													{translationService.translate("TR_SHOW_MORE")}
												</Link>
											</div>
										</Card.Body>
									</Card>
								</div>
							) : (
								<div className="no-data-container">
									<div className="no-data-wrapper">
										<img src={NoDataImage} alt="/" height="100px" />
									</div>
								</div>
							)}
							{/* <div className="row align-self-stretch">
								{gamifyData.quests && gamifyData.quests.length ? (
									gamifyData.quests.map((item, index) => {
										return (
											<div key={index} className="col-12 col-md-6 col-lg-4 p-2">
												<Card className="w-100 h-100">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.imagePath})`,
															height: "200px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h3
															className="title pb-0 pt-2 max-line-3"
															style={{ fontSize: "18px", fontWeight: "500" }}
															title={item.name}>
															{item.name}
														</h3>
														<hr className="mb-0" />
														<p className="max-line-2">{item.description}</p>
														<div className="mt-3">
															{user.userTypeId === STUDENT_USER_TYPE ? null : (
																<button
																	className="mindalay--btn-link mindalay--btn-secondary mt-1 w-100 py-2"
																	onClick={() =>
																		generateGamifyToken(2, "quest")
																	}>
																	{translationService.translate("TR_EDIT")}
																</button>
															)}
															<Link
																className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
																to={`/${language}/gamification/quest/${item.id}`}
																target="_blank">
																{translationService.translate("TR_SHOW_MORE")}
															</Link>
														</div>
													</Card.Body>
												</Card>
											</div>
										);
									})
								) : (
									<div className="no-data-container">
										<div className="no-data-wrapper">
											<img src={NoDataImage} alt="/" height="100px" />
										</div>
									</div>
								)}
							</div> */}
						</div>
					</div>
					<hr />
				</>
			)}
			<div className="row">
				<div className="col-12">
					<div className="d-flex justify-content-between align-items-center">
						<h3 className="title">
							{translationService.translate("TR_BADGES")}
						</h3>
						{user.userTypeId === STUDENT_USER_TYPE ? null : (
							<button
								type="button"
								className={`mindalay--btn-primary-outline mindalay--btn-small my-sm-0 my-2`}
								onClick={() => generateGamifyToken(3, "badges")}>
								+ {translationService.translate("TR_ADD_BADGE")}
							</button>
						)}
					</div>
					<div className="row align-self-stretch">
						{gamifyData.badges && gamifyData.badges.length ? (
							gamifyData.badges.map((item, index) => {
								return (
									<div key={index} className="col-12 col-md-6 col-lg-4 p-2">
										<Card className="w-100 h-100">
											<div
												className="w-100"
												style={{
													backgroundImage: `url(${item.imagePath})`,
													height: "200px",
													backgroundPosition: "center",
													backgroundSize: "contain",
													backgroundRepeat: "no-repeat",
												}}
											/>
											<Card.Body className="d-flex flex-column justify-content-between p-2">
												<div>
													<h3
														className="title pb-0 pt-2 max-line-3"
														style={{ fontSize: "18px", fontWeight: "500" }}
														title={item.name}>
														{item.name}
													</h3>
													<hr className="mb-0" />
													<p className="max-line-3">{item.description}</p>
												</div>
												<div className="mt-3">
													{user.userTypeId === STUDENT_USER_TYPE ? null : (
														<button
															className="mindalay--btn-link mindalay--btn-secondary mt-1 w-100 py-2"
															onClick={() =>
																generateGamifyToken(3, "badges", item.id)
															}>
															{translationService.translate("TR_EDIT")}
														</button>
													)}
													<Link
														className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
														to={`/${language}/gamification/badge/${item.id}`}
														target="_blank">
														{translationService.translate("TR_SHOW_MORE")}
													</Link>
												</div>
											</Card.Body>
										</Card>
									</div>
								);
							})
						) : (
							<div className="no-data-container">
								<div className="no-data-wrapper">
									<img src={NoDataImage} alt="/" height="100px" />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<hr />
			<div className="row align-self-stretch">
				<div className="col-12">
					<div className="d-flex justify-content-between align-items-center">
						<h3 className="title">
							{translationService.translate("TR_LEADERBOARDS")}
						</h3>
						{user.userTypeId === STUDENT_USER_TYPE ? null : (
							<button
								type="button"
								className={`mindalay--btn-primary-outline mindalay--btn-small my-sm-0 my-2`}
								onClick={() => generateGamifyToken(4, "leaderboard")}>
								+ {translationService.translate("TR_ADD_LEADERBOARD")}
							</button>
						)}
					</div>
					<div className="row align-self-stretch">
						{gamifyData.leaderboards && gamifyData.leaderboards.length ? (
							gamifyData.leaderboards.map((item, index) => {
								return (
									<div key={index} className="col-12 col-md-6 col-lg-4 p-2">
										<Card className="w-100 h-100">
											<Card.Body className="p-2 d-flex flex-column justify-content-between">
												<div>
													<h3
														className="title pb-0 pt-2 max-line-3"
														style={{ fontSize: "18px", fontWeight: "500" }}
														title={item.name}>
														{item.name}
													</h3>
													<hr className="mb-0" />
												</div>
												<div>
													<p className="max-line-3">{item.description}</p>
													<p className="d-flex flex-wrap justify-content-between mt-3">
														<b className="mr-1">
															{translationService.translate("TR_TYPE")}
														</b>
														<b>
															{translationService.translate(
																MainService.getType(item.type),
															)
																? translationService.translate(
																		MainService.getType(item.type),
																  )
																: MainService.getType(item.type)}
														</b>
													</p>
													{item.startDate ? (
														<p className="d-flex justify-content-between mt-1">
															<b>
																{translationService.translate("TR_START_DATE")}
															</b>
															<b>
																{moment(new Date(item.startDate)).format("ll")}
															</b>
														</p>
													) : null}
													{item.endDate ? (
														<p className="d-flex justify-content-between mt-1">
															<b>
																{translationService.translate("TR_END_DATE")}
															</b>
															<b>
																{moment(new Date(item.endDate)).format("ll")}
															</b>
														</p>
													) : null}
													<div className="mt-3">
														{user.userTypeId === STUDENT_USER_TYPE ? null : (
															<button
																className="mindalay--btn-link mindalay--btn-secondary mt-1 w-100 py-2"
																onClick={() =>
																	generateGamifyToken(4, "leaderboard", item.id)
																}>
																{translationService.translate("TR_EDIT")}
															</button>
														)}
														<Link
															className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
															to={`/${language}/gamification/leaderboard/${item.id}`}
															target="_blank">
															{translationService.translate("TR_SHOW_MORE")}
														</Link>
													</div>
												</div>
											</Card.Body>
										</Card>
									</div>
								);
							})
						) : (
							<div className="no-data-container">
								<div className="no-data-wrapper">
									<img src={NoDataImage} alt="/" height="100px" />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</ModalComponentWithoutActionButton>
	) : null;
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "../../../Services/gamifyApiService";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import {
	ERROR_KEY,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	GAMIFY_QUIZ_ENTITY_TYPE_ID,
	GAMIFY_TOPIC_ENTITY_TYPE_ID,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../../Constants/mainKeys";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import ApiService from "../../../Services/apiService";

const buttonSpinnerId = uuid();
const Quest = props => {
	let parentEntities = [];
	const dispatch = useDispatch();
	const { history } = props;
	const { questId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [quest, setQuest] = useState(null);

	useEffect(() => {
		getQuestById();
		return () => {
			setQuest([]);
		};
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getQuestById = () => {
		if (!questId) {
			return false;
		}
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		const data = {
			id: questId,
			userId: user.studentId ? user.studentId.toString() : null,
			questImageSize: 3,
			resourceImageSize: 2,
			fragmentImageSize: 2,
		};
		GamifyApiService.getQuestById(data)
			.then(response => {
				if (response && response.data) {
					setQuest(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getParents = parent => {
		if (!parent) {
			return false;
		}
		parentEntities.push({
			entityTypeId: parent.entityTypeId,
			organizationEntityId: parent.organizationEntityId,
		});
		getParents(parent.parent);
	};

	const getEntityPath = quest => {
		if (!quest) {
			return false;
		}
		getParents(quest.parent);
		switch (quest.entityTypeId) {
			case GAMIFY_COURSE_ENTITY_TYPE_ID:
				return history.push(
					`/${language}/course/${quest.organizationEntityId}`,
				);

			case GAMIFY_TOPIC_ENTITY_TYPE_ID:
				if (parentEntities && parentEntities.length) {
					let courseId = parentEntities.find(
						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
					)?.organizationEntityId;
					if (courseId) {
						return ApiService.getPublishedCourseByIdByOrgUser(courseId)
							.then(response => {
								if (response && !response.data.length) {
									props.history.push(`/${props.language}/course`);
								}
								if (response && response.data) {
									let data = { ...response.data };
									return props.history.push(
										`/${language}/passcourse/video/${courseId}/${data.studentCourseId}/${quest.organizationEntityId}`,
									);
								} else {
									props.history.push(`/${props.language}/course`);
								}
							})
							.catch(error => getFail(error));
					}
				}

			case GAMIFY_QUIZ_ENTITY_TYPE_ID:
				if (parentEntities && parentEntities.length) {
					let courseId = parentEntities.find(
						item => item.entityTypeId === GAMIFY_COURSE_ENTITY_TYPE_ID,
					)?.organizationEntityId;
					if (courseId)
						return history.push(
							`/${language}/pure/quiz-attempt/view/${courseId}/${quest.organizationEntityId}`,
						);
				}

			default:
				break;
		}
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<h2 className="section-title">
							{quest ? quest.name : translationService.translate("TR_QUEST")}
						</h2>
					</div>
					<PageInfoBlock
						pageTitle="Quest"
						pageName="student_quest_view"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{quest ? (
				<div className="row mt-3">
					<div className="col-12 col-lg-4 px-3">
						<div className="my-2">
							<Card className="w-100 border-0">
								<div
									className="w-100"
									style={{
										backgroundImage: `url(${quest.imagePath})`,
										height: "250px",
										backgroundPosition: "center",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								/>
								<Card.Body>
									<h4 className="text-center mb-2">{quest.name}</h4>
									<p className="word-break-break-word text-center">
										{quest.description}
									</p>
									{user && user.userTypeId === STUDENT_USER_TYPE ? (
										<button
											type="button"
											className="mindalay--btn-link mindalay--btn-dark mt-3 w-100"
											onClick={() => getEntityPath(quest)}>
											{translationService.translate("TR_VIEW_ENTITY")}
										</button>
									) : null}
								</Card.Body>
							</Card>
						</div>
					</div>

					<div className="col-12 col-lg-8 px-3">
						{quest.questResources && quest.questResources.length ? (
							<div className="row align-self-stretch  mt-3">
								<div className="col-12">
									{" "}
									<h2 className="section-title">
										{translationService.translate("TR_RESOURCES")}
									</h2>
								</div>
								{quest.questResources.map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-4 p-2">
											<div className="border rounded h-100">
												<Card className="w-100 border-none">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.resource?.imagePath})`,
															height: "100px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h5 className="text-center">
															{item.resource?.name}
														</h5>
														<hr />
														<p className="d-flex justify-content-between mt-2">
															<b>
																{translationService.translate("TR_AMOUNT")}{" "}
															</b>
															<b>{item.resourceAmount}</b>
														</p>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								})}
							</div>
						) : null}
						{quest.questFragments && quest.questFragments.length ? (
							<div className="row align-self-stretch  mt-3">
								<div className="col-12">
									{" "}
									<h2 className="section-title">
										{translationService.translate("TR_FRAGMENTS")}
									</h2>
								</div>
								{quest.questFragments.map((item, index) => {
									return (
										<div key={index} className="col-12 col-md-4 p-2">
											<div className="border rounded h-100">
												<Card className="w-100 border-none">
													<div
														className="w-100"
														style={{
															backgroundImage: `url(${item.fragment?.imagePath})`,
															height: "100px",
															backgroundPosition: "center",
															backgroundSize: "contain",
															backgroundRepeat: "no-repeat",
														}}
													/>
													<Card.Body className="p-2">
														<h5 className="text-center">
															{item.fragment?.name}
														</h5>
													</Card.Body>
												</Card>
											</div>
										</div>
									);
								})}
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Quest);

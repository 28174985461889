import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { COURSES_KEY, FILE_LIBRARY_KEY, INSTRUCTOR_KEY, QUESTION_BANK_KEY, VIEW_KEY } from "../../../../Constants/urlKeys";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from './../../../../Store/Actions/generalLeftMenu';

const CoursesSubMenu = () => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const showOrHideLeftMenu = (bool) => {
    dispatch(showOrHideGeneralDashboardLeftMenu(bool));
  }

  return translationService ? <aside className="side-left-submenu">
    <div className="side-left-submenu-container">
      <ul className="side-left-submenu-list">
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${VIEW_KEY}`}
            activeClassName="active-text"
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_COURSES")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}`}
            activeClassName="active-text"
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_QUESTION_BANK")}
          </NavLink>
        </li>
        <li className="side-left-submenu-item">
          <NavLink
            to={`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_KEY}`}
            activeClassName="active-text"
            onClick={() => showOrHideLeftMenu(false)}
          >
            {translationService.translate("TR_FILE_LIBRARY")}
          </NavLink>
        </li>
      </ul>
    </div>
  </aside> : null;
}

export default CoursesSubMenu;
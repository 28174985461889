// import React, { Component, useEffect, useState } from "react";
// import { connect, useDispatch, useSelector } from "react-redux";
// import TranslationService from "../../Services/translationService";
// import {
// 	addPartialViewSpinner,
// 	removePartialViewSpinner,
// } from "../../Store/Actions/spinner";
// import uuid from "react-uuid";
// import ApiService from "../../Services/apiService";
// import moment from "moment";
// import MainService from "../../Services/mainService";
// import { withRouter } from "react-router-dom";
// import { compose } from "redux";
// import AlertService from "../../Services/alertService";
// import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
// import NoDataImage from "../../assets/images/illustrations/nodata.svg";
// import {
// 	CONSULTANT_CONSULTATION_CONTRACTS_KEY,
// 	COURSES_KEY,
// 	CONSULTANT_KEY,
// 	STUDENT_CONSULTATION_CONTRACTS_KEY,
// } from "../../Constants/urlKeys";
// import {
// 	ERROR_KEY,
// 	INVALID_DATE_KEY,
// 	NUMBER_KEY,
// 	PAYMENT_TYPES_ORG_ADMIN,
// 	PAYMENT_TYPES_ORG_STUDENT,
// 	STUDENT_USER_TYPE,
// } from "../../Constants/mainKeys";
// import Auxiliary from "../../hoc/auxiliary/auxiliary";
// import ReactPaginate from "react-paginate";
// import _InfoSvg from "./../../Components/Svg/_infoSvg";
// import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

// const Overview = props => {
// 	const paymentTypes = PAYMENT_TYPES_ORG_STUDENT;

// 	const dispatch = useDispatch();
// 	const language = useSelector(state => state.language.language);
// 	const user = useSelector(state => state.user.user);
// 	const translations = useSelector(state => state.translation.translations);
// 	const [translationService, setTranslationService] = useState(null);
// 	const [currentPaymentHistory, setCurrentPaymentHistory] = useState([]);
// 	const [pagination, setPagination] = useState(null);
// 	const [activePageNumber, setActivePageNumber] = useState(0);
// 	const [paymentType, setPaymentType] = useState(7);

// 	useEffect(() => {
// 		getPaymentHistory({
// 			paymentType: 7,
// 			currentPage: 1,
// 		});
// 	}, []);

// 	useEffect(() => {
// 		setTranslationService(new TranslationService(translations));
// 	}, [translations]);

// 	const getPaymentHistory = ({ paymentType, entityType = 0, currentPage }) => {
// 		const spinnerId = uuid();
// 		dispatch(addPartialViewSpinner(spinnerId));
// 		ApiService.getPaymentHistoryWithPagination(
// 			paymentType,
// 			entityType,
// 			currentPage,
// 			10,
// 		)
// 			.then(response => {
// 				if (response && response.pagination)
// 					setPagination(JSON.parse(response.pagination));
// 				setCurrentPaymentHistory(response.data);
// 			})
// 			.catch(error => {
// 				error &&
// 					AlertService.alert(
// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
// 						error,
// 					);
// 			})
// 			.finally(() => {
// 				dispatch(removePartialViewSpinner(spinnerId));
// 			});
// 	};

// 	const handlePageClick = event => {
// 		getPaymentHistory({
// 			paymentType,
// 			currentPage: event.selected + 1,
// 		});
// 		setActivePageNumber(event.selected);
// 	};

// 	const filterPaymentHistory = selectedCategory => {
// 		const spinnerId = uuid();
// 		getPaymentHistory({
// 			paymentType: selectedCategory.value,
// 			currentPage: 1,
// 		});
// 		setActivePageNumber(0);
// 		setPaymentType(selectedCategory.value);
// 	};

// 	return translationService ? (
// 		<div className="container mt-4">
// 			<div
// 				className={`${
// 					user.userTypeId === STUDENT_USER_TYPE ? "m-section" : ""
// 				}`}>
// 				<div className="row">
// 					<div className="col-12">
// 						<div className="row align-items-end">
// 							<div className="col-12">
// 								<h2 className="section-title">
// 									{translationService.translate("TR_PAYMENT_HISTORY")}
// 								</h2>
// 								<PageInfoBlock
// 									pageTitle={translationService.translate("TR_PAYMENT_HISTORY")}
// 									pageName="admin_payment_history"
// 									translationService={translationService}
// 								/>
// 							</div>
// 						</div>
// 						<hr />
// 					</div>
// 					{/* <div className="col-lg-4 col-sm-6 col-12">
// 						<div className="form-group m-0">
// 							<label htmlFor="#">
// 								{translationService.translate("TR_TRANSACTION_TYPES")}
// 							</label>
// 							<ReactSelectOption
// 								value={paymentType}
// 								placeholder={translationService.translate("TR_CHOOSE")}
// 								selectedValue={(() => {
// 									const selectedValue = {
// 										...paymentTypes.find(data => data.value === paymentType),
// 									};
// 									if (Object.keys(selectedValue).length) {
// 										selectedValue.label = translationService.translate(
// 											selectedValue.label,
// 										)
// 											? translationService.translate(selectedValue.label)
// 											: selectedValue.label;
// 										return selectedValue;
// 									} else {
// 										return {
// 											label: translationService.translate("TR_CHOOSE"),
// 											value: 0,
// 										};
// 									}
// 								})()}
// 								items={paymentTypes.map(data => ({
// 									label: translationService.translate(data.label)
// 										? translationService.translate(data.label)
// 										: data.label,
// 									value: data.value,
// 								}))}
// 								onChange={item => filterPaymentHistory(item)}
// 							/>
// 						</div>
// 					</div> */}
// 					<div className="col-12">
// 						{currentPaymentHistory && currentPaymentHistory.length ? (
// 							<div className="row">
// 								<div className="col-12">
// 									<div className="mindayal-admin-table mt-3 table-responsive">
// 										<table className="table">
// 											<thead>
// 												<tr>
// 													<th className="text-nowrap">
// 														{translationService.translate("TR_PRICE")}
// 													</th>
// 													<th className="text-nowrap">
// 														{translationService.translate(
// 															"TR_TRANSACTION_DATE",
// 														)}
// 													</th>
// 													<th className="text-nowrap">
// 														{translationService.translate("TR_PROVIDER")}
// 													</th>
// 													<th className="text-nowrap">
// 														{translationService.translate("TR_ENTITY_NAME")}
// 													</th>

// 													<th className="text-nowrap">
// 														{translationService.translate("TR_STATUS")}
// 													</th>
// 												</tr>
// 											</thead>
// 											<tbody>
// 												{currentPaymentHistory.map((item, index) => {
// 													return (
// 														<tr key={index} className="cursor-default">
// 															<td>{item.amountWithCurrency}</td>
// 															<td>
// 																{moment(
// 																	MainService.convertUTCDateToLocalDate(
// 																		new Date(item.createDate),
// 																	),
// 																).format("ll (HH:mm)")}
// 															</td>
// 															<td>{item?.paymentSystemName}</td>
// 															<td>{item?.entityName}</td>

// 															<td>
// 																<div>
// 																	<span
// 																		title={item.statusName}
// 																		className={`payment-status ${
// 																			+item.paymentStatus === 0
// 																				? "orange-border orange-color"
// 																				: +item.paymentStatus === 1
// 																				? "green-border green-color"
// 																				: +item.paymentStatus === 2
// 																				? "blue-border blue-color"
// 																				: +item.paymentStatus === 3
// 																				? "red-border red-color"
// 																				: ""
// 																		}`}>
// 																		{item.status}
// 																	</span>
// 																</div>
// 															</td>
// 														</tr>
// 													);
// 												})}
// 											</tbody>
// 										</table>
// 									</div>
// 								</div>
// 							</div>
// 						) : (
// 							<Auxiliary>
// 								<div className="no-data-container mt-3">
// 									<div className="no-data-wrapper">
// 										<p>
// 											{translationService.translate(
// 												"TR_THERE_IS_NO_TRANSACTIONS_OF_THIS_TYPE",
// 											)}
// 										</p>
// 										<img src={NoDataImage} alt="/" />
// 									</div>
// 								</div>
// 							</Auxiliary>
// 						)}
// 					</div>
// 					{pagination && pagination.TotalPages > 1 ? (
// 						<div className="col-12 mt-4">
// 							<div className="d-flex justify-content-end w-100">
// 								<ReactPaginate
// 									nextLabel={translationService.translate("TR_NEXT")}
// 									onPageChange={handlePageClick}
// 									pageRangeDisplayed={3}
// 									marginPagesDisplayed={2}
// 									pageCount={pagination.TotalPages}
// 									previousLabel={translationService.translate("TR_PREVIOUS")}
// 									pageClassName="page-item"
// 									pageLinkClassName="page-link"
// 									previousClassName="page-item"
// 									previousLinkClassName="page-link"
// 									nextClassName="page-item"
// 									nextLinkClassName="page-link"
// 									breakLabel="..."
// 									breakClassName="page-item"
// 									breakLinkClassName="page-link"
// 									containerClassName="pagination"
// 									activeClassName="active"
// 									renderOnZeroPageCount={null}
// 									forcePage={activePageNumber}
// 								/>
// 							</div>
// 						</div>
// 					) : null}
// 				</div>
// 			</div>
// 		</div>
// 	) : null;
// };
// export default withRouter(Overview);

import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import moment from "moment";
import MainService from "../../Services/mainService";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import AlertService from "../../Services/alertService";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import {
	CONSULTANT_CONSULTATION_CONTRACTS_KEY,
	COURSES_KEY,
	CONSULTANT_KEY,
	STUDENT_CONSULTATION_CONTRACTS_KEY,
} from "../../Constants/urlKeys";
import {
	ERROR_KEY,
	INVALID_DATE_KEY,
	NUMBER_KEY,
	PAYMENT_TYPES_ORG_ADMIN,
	PAYMENT_TYPES_ORG_STUDENT,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ReactPaginate from "react-paginate";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const Overview = props => {
	const paymentTypes = PAYMENT_TYPES_ORG_STUDENT;

	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [currentPaymentHistory, setCurrentPaymentHistory] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [paymentType, setPaymentType] = useState(1);

	useEffect(() => {
		getPaymentHistory({
			paymentType: 1,
			currentPage: 1,
		});
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getPaymentHistory = ({ paymentType, entityType = 0, currentPage }) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentHistoryWithPagination(
			paymentType,
			entityType,
			currentPage,
			10,
		)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				setCurrentPaymentHistory(response.data);
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const handlePageClick = event => {
		getPaymentHistory({
			paymentType,
			currentPage: event.selected + 1,
		});
		setActivePageNumber(event.selected);
	};

	const filterPaymentHistory = selectedCategory => {
		const spinnerId = uuid();
		getPaymentHistory({
			paymentType: selectedCategory.value,
			currentPage: 1,
		});
		setActivePageNumber(0);
		setPaymentType(selectedCategory.value);
	};

	return translationService ? (
		<div className="container mt-4">
			<div
				className={`${
					user.userTypeId === STUDENT_USER_TYPE ? "m-section" : ""
				}`}>
				<div className="row">
					<div className="col-12">
						<div className="row align-items-end">
							<div className="col-12">
								<h2 className="section-title">
									{translationService.translate("TR_PAYMENT_HISTORY")}
								</h2>
								<PageInfoBlock
									pageTitle={translationService.translate("TR_PAYMENT_HISTORY")}
									pageName="admin_payment_history"
									translationService={translationService}
								/>
							</div>
						</div>
						<hr />
					</div>
					<div className="col-lg-4 col-sm-6 col-12">
						<div className="form-group m-0">
							<label htmlFor="#">
								{translationService.translate("TR_TRANSACTION_TYPES")}
							</label>
							<ReactSelectOption
								value={paymentType}
								placeholder={translationService.translate("TR_CHOOSE")}
								selectedValue={(() => {
									const selectedValue = {
										...paymentTypes.find(data => data.value === paymentType),
									};
									if (Object.keys(selectedValue).length) {
										selectedValue.label = translationService.translate(
											selectedValue.label,
										)
											? translationService.translate(selectedValue.label)
											: selectedValue.label;
										return selectedValue;
									} else {
										return {
											label: translationService.translate("TR_CHOOSE"),
											value: 0,
										};
									}
								})()}
								items={paymentTypes.map(data => ({
									label: translationService.translate(data.label)
										? translationService.translate(data.label)
										: data.label,
									value: data.value,
								}))}
								onChange={item => filterPaymentHistory(item)}
							/>
						</div>
					</div>
					<div className="col-12">
						{currentPaymentHistory && currentPaymentHistory.length ? (
							<div className="row">
								<div className="col-12">
									<div className="mindayal-admin-table mt-3 table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="text-nowrap">
														{translationService.translate("TR_PRICE")}
													</th>
													<th className="text-nowrap">
														{translationService.translate(
															"TR_TRANSACTION_DATE",
														)}
													</th>
													{paymentType === 0 || paymentType === 1 ? (
														<th className="text-nowrap">
															{translationService.translate("TR_PROVIDER")}
														</th>
													) : (
														<th className="text-nowrap">
															{translationService.translate("TR_ENTITY_NAME")}
														</th>
													)}
													{paymentType === 0 || paymentType !== 1 ? (
														<th className="text-nowrap">
															{translationService.translate("TR_ENTITY_TYPE")}
														</th>
													) : null}

													{(paymentType === 0 || paymentType === 1) && (
														<th className="text-nowrap">
															{translationService.translate("TR_STATUS")}
														</th>
													)}
												</tr>
											</thead>
											<tbody>
												{currentPaymentHistory.map((item, index) => {
													return (
														<tr key={index} className="cursor-default">
															<td>{item.amountWithCurrency}</td>
															<td>
																{moment(
																	MainService.convertUTCDateToLocalDate(
																		new Date(item.createDate),
																	),
																).format("ll (HH:mm)")}
															</td>
															<td>
																{item?.entityName || item?.paymentProviderName}
															</td>
															{paymentType !== 1 ? (
																<td>
																	{item?.entityTypeName ||
																		item?.paymentProviderName}
																</td>
															) : null}

															{typeof item?.paymentStatus === NUMBER_KEY &&
															item.statusName ? (
																<td>
																	<div>
																		<span
																			title={item.statusName}
																			className={`payment-status ${
																				+item.paymentStatus === 0
																					? "orange-border orange-color"
																					: +item.paymentStatus === 1
																					? "green-border green-color"
																					: +item.paymentStatus === 2
																					? "blue-border blue-color"
																					: +item.paymentStatus === 3
																					? "red-border red-color"
																					: ""
																			}`}>
																			{item.statusName}
																		</span>
																	</div>
																</td>
															) : null}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						) : (
							<Auxiliary>
								<div className="no-data-container mt-3">
									<div className="no-data-wrapper">
										<p>
											{translationService.translate(
												"TR_THERE_IS_NO_TRANSACTIONS_OF_THIS_TYPE",
											)}
										</p>
										<img src={NoDataImage} alt="/" />
									</div>
								</div>
							</Auxiliary>
						)}
					</div>
					{pagination && pagination.TotalPages > 1 ? (
						<div className="col-12 mt-4">
							<div className="d-flex justify-content-end w-100">
								<ReactPaginate
									nextLabel={translationService.translate("TR_NEXT")}
									onPageChange={handlePageClick}
									pageRangeDisplayed={3}
									marginPagesDisplayed={2}
									pageCount={pagination.TotalPages}
									previousLabel={translationService.translate("TR_PREVIOUS")}
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									containerClassName="pagination"
									activeClassName="active"
									renderOnZeroPageCount={null}
									forcePage={activePageNumber}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};
export default withRouter(Overview);

import ApiService from "./apiService";
import AlertService from "./alertService";
import { ERROR_KEY } from "../Constants/mainKeys";
import uuid from "react-uuid";
import { addPageSpinner, removePageSpinner } from "../Store/Actions/spinner";

class ParserService {
	// Parses the HTML string into a Document object
	static parseHTML(htmlString) {
		const parser = new DOMParser();
		return parser.parseFromString(htmlString, "text/html");
	}

	// Extracts alt values from img tags, converting them to numbers
	static extractAltValues(doc) {
		const imgTags = [...doc.querySelectorAll("img")];
		return imgTags
			.map(img => {
				const alt = img.getAttribute("alt");
				return alt ? Number(alt) : null;
			})
			.filter(val => val !== null);
	}

	// Updates the src attributes of img tags based on data received
	static updateImgSrc(doc, data) {
		const imgTags = doc.querySelectorAll("img");

		imgTags.forEach(img => {
			const alt = img.getAttribute("alt");
			if (alt && !isNaN(alt)) {
				const newSrc = data.find(item => item.id === Number(alt))?.filePath;
				if (newSrc) {
					img.setAttribute("src", newSrc);
				}
			}
		});

		return doc.documentElement.outerHTML;
	}

	// Static method to process the HTML string
	static async processHTML(htmlString) {
		// Check if htmlString is a string
		if (typeof htmlString !== "string") {
			console.error("Error processing HTML: Input is not a string");
			return htmlString;
		}

		// Parse the HTML string
		const doc = this.parseHTML(htmlString);

		// Extract alt values
		const altValues = this.extractAltValues(doc);

		// Return the original HTML if no numeric alt values or img tags are found
		if (altValues.length === 0) {
			return htmlString;
		}

		// Generate a spinner ID
		const spinnerId = uuid();
		// if (window.Storage && window.Storage.dispatch) {
		// 	window.Storage.dispatch(addPageSpinner(spinnerId));
		// }

		try {
			// Send altValues to the server and get the data
			const response = await ApiService.sendAltValues(altValues);
			if (response && Array.isArray(response.data) && response.data.length) {
				// Update img src attributes with the response data
				const updatedHTML = this.updateImgSrc(doc, response.data);
				return updatedHTML;
			} else {
				// Return the original HTML if the response data is not valid
				return htmlString;
			}
		} catch (error) {
			console.error("Error updating image sources:", error);
			if (error) {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			}
			return htmlString; // Return original HTML on error
		} finally {
			// if (window.Storage && window.Storage.dispatch) {
			// 	window.Storage.dispatch(removePageSpinner(spinnerId));
			// }
		}
	}

	static extractUniqueAltTexts(data) {
		// Set to store unique alt attributes
		const uniqueAlts = new Set();
		const checkForHTML = value => {
			if (typeof value === "string" && /<\/?[a-z][\s\S]*>/i.test(value)) {
				return true;
			}
			return false;
		};

		// Function to extract alt values from img tags, converting them to numbers
		const extractAltValues = doc => {
			const imgTags = [...doc.querySelectorAll("img")];
			return imgTags
				.map(img => {
					const alt = img.getAttribute("alt");
					return alt ? Number(alt) : null;
				})
				.filter(val => val !== null);
		};

		const processField = field => {
			if (field) {
				const doc = this.parseHTML(field);
				this.extractAltValues(doc).forEach(alt => uniqueAlts.add(alt));
			}
		};

		Object.values(data).forEach(value => {
			if (value && Array.isArray(value) && value.length) {
				value.forEach(item => {
					if (item.text) processField(item.text);
					if (item.feedback) processField(item.feedback);
				});
			} else if (checkForHTML(value)) {
				processField(value);
			}
		});
		return Array.from(uniqueAlts);
	}

	static async parseHTMLData(data) {
		const checkForHTML = value => {
			if (typeof value === "string" && /<\/?[a-z][\s\S]*>/i.test(value)) {
				return true;
			}
			return false;
		};

		const altValues = this.extractUniqueAltTexts(data);
		if (!altValues.length) return data;

		try {
			const response = await ApiService.sendAltValues(altValues);
			if (response && Array.isArray(response.data) && response.data.length) {
				let newData = {};
				Object.keys(data).forEach(key => {
					const value = data[key];
					if (value && Array.isArray(value) && value.length) {
						newData[key] = value.map(el => {
							let newElement = {};
							Object.entries(el).forEach(([key, elvalue]) => {
								if (checkForHTML(elvalue)) {
									const doc = this.parseHTML(elvalue);
									newElement[key] = this.updateImgSrc(doc, response.data);
								} else {
									newElement[key] = elvalue;
								}
							});
							return newElement;
						});
					} else if (checkForHTML(value)) {
						const doc = this.parseHTML(value);
						newData[key] = this.updateImgSrc(doc, response.data);
					} else {
						newData[key] = value;
					}
				});
				return newData;
			}
		} catch (error) {
			console.error("Error updating image sources:", error);
			if (error) {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
			}
			return data; // Return original data on error
		}
	}
}

export default ParserService;
